import { Range as FormRange } from 'components/placeholders';
import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES, FORM_ITEM_TYPES } from 'utils/prop-types';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { BASIC_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const Range: Mapping = {
  name: 'Range',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  title: '滑块',
  iconCode: 'Range',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    label: {
      label: '滑块',
      type: 'string',
      value: '滑块',
      disabled: true,
    },
    name: {
      label: '数据字段',
      type: 'string',
      value: `range${HTD_PROPS_HOLDER}`,
    },
    fieldKey: {
      type: 'string',
      // value: uuid.v4(),
      value: '',
      label: '标题',
      hidden: true,
      ignore: true,
      isDS: true,
    },
    step: {
      type: 'number',
      label: '步长',
      isDS: true,
    },
    disabled: {
      type: 'boolean',
      label: '禁用',
      value: false,
      isDS: true,
      valueConfigurable: true,
    },
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  placeholderComponent: FormRange,
  children: [],
};

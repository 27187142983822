import React, { useMemo, useContext } from 'react';
import { Button, Popconfirm, Select, Form as AntdForm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import EditTable from 'components/EditTable';
import MyIcon from 'components/myIcon';
import { isArray } from 'lodash';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';
import SelectRightImg from 'components/selectRightImg';
import './index.less';
import EditPanel from './EditPanel';
import { dragIcon } from '../../../../utils/unifiedExportIcon';

const ButtonsPanel: React.FC = () => {
  const { settingItem: item, forceUpdate } = useContext(DesignContext);
  const { configItem, onSettingConfig } = useContext(ConfigContext);
  const buttonList = useMemo(() => {
    return [
      { value: 'add', meaning: '新增' },
      { value: 'delete', meaning: '删除' },
      { value: 'remove', meaning: '移除' },
      { value: 'save', meaning: '保存' },
      { value: 'query', meaning: '查询' },
      { value: 'reset', meaning: '重置' },
      { value: 'export', meaning: '导出' },
      { value: 'custom', meaning: '自定义' },
    ];
  }, []);

  const dataSource: any[] = item?.props?.buttons?.value?.map((i: any) => ({
    type: typeof i === 'string' ? i : 'custom',
    key: i && i.length && i.length > 1 ? i[1].key : '',
  }));

  // 当前数据
  const currentRecord =
    configItem?.index !== undefined && configItem?.type === 'buttons'
      ? dataSource[configItem.index]
      : undefined;

  /**
   * 设置当前编辑按钮
   */
  const handleEditItemClick = (_: any, index: number) => {
    onSettingConfig?.(
      configItem?.index === index && configItem?.type === 'buttons'
        ? undefined
        : { index, type: 'buttons' }
    );
  };

  /**
   * 修改按钮
   * @param index
   * @param value
   */
  const handleChangeButtons = (index: number, value: any) => {
    if (item?.props?.buttons?.value) {
      // 自定义按钮
      if (value === 'custom') {
        value = [
          'reset',
          { children: '自定义', icon: '', key: `customButton-${index}` },
        ];
      }
      (item?.props?.buttons?.value as any[]).splice(index, 1, value);
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 添加按钮
   */
  const handleAddLine = () => {
    const newLine: any[] = [
      buttonList.filter((i) => !isButtonOptionDisable(i))[0].value,
    ];
    // 自定义按钮
    if (newLine[0] === 'custom') {
      newLine[0] = [
        'reset',
        {
          children: '自定义',
          icon: '',
          key: `customButton-${item?.props?.buttons?.value?.length || 0}`,
        },
      ];
    }
    if (item?.props?.buttons?.value) {
      item.props.buttons.value = [...item.props.buttons.value, ...newLine];
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 删除按钮
   */
  const handleDeleteLine = (index: number) => {
    if (item?.props?.buttons?.value) {
      (item.props.buttons.value as any[]).splice(index, 1);
    }
    forceUpdate?.(item?.id);
  };

  const isButtonOptionDisable = (button: any) => {
    if (button.value === 'custom') return false;
    if (!dataSource) return false;
    const idx = dataSource.findIndex((d) => d.type === button.value);
    return idx !== -1;
  };

  /**
   * 拖动排序
   */
  const handleDragEnd = (dataSource: any[]) => {
    if (item?.props?.buttons?.value) {
      // @ts-ignore
      item?.props?.buttons?.value?.sort((a: any, b: any) => {
        if (isArray(a) && !isArray(b)) {
          const aValue = (a.length && a.length > 1 && a[1] && a[1].key) || '';
          const aIndex = dataSource.findIndex(
            (record) => record.key === aValue
          );
          const bIndex = dataSource.findIndex((record) => record.type === b);
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else if (!isArray(a) && isArray(b)) {
          const bValue = (b.length && b.length > 1 && b[1] && b[1].key) || '';
          const aIndex = dataSource.findIndex((record) => record.type === a);
          const bIndex = dataSource.findIndex(
            (record) => record.key === bValue
          );
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else if (isArray(a) && isArray(b)) {
          const aValue = (a.length && a.length > 1 && a[1] && a[1].key) || a;
          const bValue = (b.length && b.length > 1 && b[1] && b[1].key) || b;
          const aIndex = dataSource.findIndex(
            (record) => record.key === aValue
          );
          const bIndex = dataSource.findIndex(
            (record) => record.key === bValue
          );
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else {
          const aIndex = dataSource.findIndex((record) => record.type === a);
          const bIndex = dataSource.findIndex((record) => record.type === b);
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        }
      });
    }
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: () => {
        return (
          <img
            alt='drag'
            src={dragIcon}
            className='htd-aside-right-columns-table-status-box-img'
          />
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      render: (value, record, index) => {
        if (record.$form) {
          return (
            <AntdForm.Item name='type'>
              <Select
                onChange={handleChangeButtons.bind(this, index)}
                suffixIcon={<SelectRightImg />}
              >
                {buttonList.map((button) => (
                  <Select.Option
                    value={button.value}
                    key={button.value}
                    disabled={isButtonOptionDisable(button)}
                  >
                    {button.meaning}
                  </Select.Option>
                ))}
              </Select>
            </AntdForm.Item>
          );
        }
        return value;
      },
    },
    {
      width: 60,
      align: 'center',
      className: 'htd-aside-right-buttons-table-operator',
      render: (_, record, index) => (
        <>
          {record.type === 'custom' && (
            <MyIcon
              type='icon-a-edit2x'
              className='left-svg-cla'
              onClick={handleEditItemClick.bind(this, record, index)}
            />
          )}
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, index)}
          >
            <MyIcon
              type='icon-a-delete2x'
              style={{ marginRight: '6px' }}
              className='left-svg-cla'
            />
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <>
      <EditTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        onDragEnd={handleDragEnd}
        className='htd-aside-right-buttons-table'
        showHeader={Boolean(dataSource?.length)}
      />
      {currentRecord && <EditPanel />}
      <div className='htd-aside-right-buttons-button-wrapper jus-conn'>
        <Button
          icon={<PlusOutlined />}
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine}
        >
          新增
        </Button>
      </div>
    </>
  );
};

export default ButtonsPanel;

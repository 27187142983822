import { MappingListItem } from '../util';
import { Table } from './Table';
import { Tree } from './Tree';
import { PerformanceTable } from './PerformanceTable';
import { Form } from '../container/Form';

export const dataList: Array<MappingListItem> = [
  {
    id: 'htd-advanced-pro-table',
    name: '表格',
    mapping: Table,
  },
  {
    id: 'htd-advanced-pro-tree',
    name: '树',
    mapping: Tree,
  },
  {
    id: 'htd-advanced-pro-transfer',
    name: '穿梭框',
  },
  {
    id: 'htd-container-pro-form',
    name: '表单',
    mapping: Form,
  },
  {
    id: 'htd-general-performance-table',
    name: '大数据表格',
    mapping: PerformanceTable,
  },
];

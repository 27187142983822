// import { executedComponentLifeCycle } from '../../utils';
import componentEnhance from './wapper';
/**
 * 装饰器 - 扩展组件属性
 * @param {React.Component} WrappedComponent
 * @param {Function} getConfig 扩展规则配置
 * @returns {React.Component}
 */
const ComponentEnhanceWrapperHoc = (extensionConfig) => {
    return (WrappedComponent) => class extends WrappedComponent {
        // 生命周期扩展 未来可以进行生命周期代理，实现性能监控，默认处理等扩展
        // componentDidMount(...args) {
        //   return executedComponentLifeCycle(
        //     WrappedComponent,
        //     'componentDidMount',
        //     args
        //   );
        // }
        render() {
            const eleTree = super.render();
            return componentEnhance.bind(this)(eleTree, extensionConfig);
        }
    };
};
export default ComponentEnhanceWrapperHoc;

import React, { FC } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import cls from 'classnames';
import './index.less';
import { ObjectType } from '@htd/common';

export const ModalPlaceholder: FC<any> = ({
  children,
  title,
  model,
  closable,
  showFooter,
  modalSize,
  buttonList,
}) => {
  let size: ObjectType = {
    width: modalSize.width ? `${modalSize.width}px` : '500px',
    height: modalSize.height ? `${modalSize.height}px` : '500px',
  };
  if (model && model === 'drawer') {
    size = {};
  }
  // 当前弹框中是否有子元素
  const isHaveChildrenModelFlag: number =
    children &&
    children['props'] &&
    children.props.children &&
    children.props.children.length;
  return (
    <div
      className={cls(
        'htd-placeholder-modal',
        model === 'drawer' && 'htd-placeholder-modal-drawer'
      )}
    >
      <div className='htd-placeholder-modal-mask' />
      <section
        className={cls('htd-placeholder-modal-content')}
        style={model === 'drawer' ? {} : size}
      >
        <header className='htd-placeholder-modal-content-header'>
          <span>{title}</span>
          {closable && <CloseOutlined />}
        </header>
        <main
          className={cls(
            'htd-placeholder-modal-content-main',
            isHaveChildrenModelFlag && 'not-chr'
          )}
        >
          {children}
        </main>
        {showFooter && (
          <footer className='htd-placeholder-modal-content-footer'>
            {buttonList.reverse().map((but: any) => {
              if (but.type === 'confirm') {
                return (
                  <button className='htd-placeholder-modal-content-footer-btn htd-placeholder-modal-content-footer-btn-primary'>
                    {but.name || '确定'}
                  </button>
                );
              } else if (but.type === 'cancle') {
                return (
                  <button className='htd-placeholder-modal-content-footer-btn'>
                    {but.name || '取消'}
                  </button>
                );
              } else {
                return (
                  <button className='htd-placeholder-modal-content-footer-btn'>
                    {but.name || '自定义'}
                  </button>
                );
              }
            })}
          </footer>
        )}
      </section>
    </div>
  );
};

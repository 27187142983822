import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataSet, Modal, Select, Table } from 'choerodon-ui/pro';
import RadioGroup from 'choerodon-ui/lib/radio/group';
import { FIELD_TYPE_LIST } from 'utils/prop-types';
import MyIcon from 'components/myIcon';
import { baseList } from '../../../../mapping/base';
import { Radio } from 'choerodon-ui';
import craeteComp from './CreateComp';
import Record from 'choerodon-ui/pro/lib/data-set/Record';
import { replaceUnderLine } from '.';
import { DragColumnAlign } from 'choerodon-ui/pro/lib/table/enum';

/**
 * 业务模型
 */
export function openBusinessEntityCreateCompModal({
  data,
  ctx,
  dropRes,
}: {
  data: any;
  ctx: any;
  dropRes?: any;
}) {
  Modal.open({
    title: `标准组件生成`,
    style: { top: 0, width: '60%' },
    children: (
      <BusinessEntityCreateCompModal data={data} ctx={ctx} dropRes={dropRes} />
    ),
    okText: '确定',
  });
}

const BusinessEntityCreateCompModal: React.FC<any> = ({
  modal,
  data,
  ctx,
  dropRes,
}) => {
  const [compType, setCompType] = useState('table');
  const { tree, forceUpdate, onSetting, cMap } = ctx;
  const [compconfigDs, columns] = useMemo(() => {
    const ds = new DataSet({
      autoQuery: false,
      paging: false,
      fields: [
        { name: 'name', label: '字段名称' },
        { name: 'label', label: '字段描述' },
        { name: 'dbType', label: '数据库字段类型' },
        {
          name: 'type',
          label: compType === 'table' ? '表格字段类型' : '匹配表单组件',
        },
      ],
      data: dealData(data, compType),
    });
    const columns = [
      { name: 'name' },
      { name: 'label', width: 140 },
      { name: 'dbType', width: 140 },
      {
        name: 'type',
        renderer:
          compType === 'table' ? rendererTableFieldType : rendererFormFieldType,
      },
    ];

    defSelectRecord(ds);
    return [ds, columns];
  }, [compType, data]);
  const handleOk = useCallback(() => {
    craeteComp(
      compType,
      compconfigDs,
      { tree, forceUpdate, onSetting, cMap },
      data,
      dropRes
    );
  }, [
    compType,
    tree,
    forceUpdate,
    onSetting,
    cMap,
    compconfigDs,
    data,
    dropRes,
  ]);
  useEffect(() => {
    modal.handleOk(handleOk);
  }, [modal, handleOk]);
  return (
    <div className='htd-entity-comp'>
      <RadioGroup
        onChange={(v) => setCompType(v.target.value)}
        defaultValue='table'
      >
        <Radio value='table'>标准表格</Radio>
        <Radio value='form'>标准表单</Radio>
      </RadioGroup>
      <Table
        dataSet={compconfigDs}
        columns={columns}
        dragColumnAlign={DragColumnAlign.left}
        rowDraggable
      />
    </div>
  );
};

function dealData(data: any, compType: string) {
  if (data.originFields instanceof Array) {
    return data.originFields
      .map(
        (dd: {
          column_name: string;
          column_comment: string;
          column_type: string;
          data_type: string;
          fieldKey: string;
        }) => {
          const name = replaceUnderLine(dd.column_name);
          return {
            name,
            label: dd.column_comment || name,
            dbType: dd.column_type,
            type:
              compType === 'table'
                ? tableCompRelpType(dd.data_type?.toLocaleLowerCase())
                : formCompRelpType(dd.data_type?.toLocaleLowerCase()),
            fieldKey: dd.fieldKey,
          };
        }
      )
      .filter(({ name }: { name: string }) => {
        return !WHO_FIELDS.includes(name.toLocaleUpperCase());
      });
  }
  return [];
}

function tableCompRelpType(type: string) {
  switch (type) {
    case 'long':
    case 'int':
    case 'number':
      return 'number';
    case 'date':
    case 'datetime':
    case 'time':
      return 'dateTime';
    case 'boolean':
      return 'boolean';
    default:
      return 'string';
  }
}

function formCompRelpType(type: string) {
  switch (type) {
    case 'number':
    case 'long':
    case 'int':
      return 'NumberField';
    case 'date':
    case 'datetime':
    case 'time':
      return 'DatePicker';
    case 'boolean':
      return 'Switch';
    case 'intl':
      return 'IntlField';
    default:
      return 'TextField';
  }
}

const WHO_FIELDS = [
  'OBJECTVERSIONNUMBER',
  'LASTUPDATEDBY',
  'LASTUPDATEDATE',
  'CREATEDBY',
  'CREATIONDATE',
  'CREATEDATE',
  'TENANTID',
];
function defSelectRecord(ds: DataSet) {
  ds.forEach((r) => {
    const name = (r.get('name') as string).toUpperCase();
    if (!WHO_FIELDS.includes(name) && name.indexOf('ATTRIBUTE') === -1) {
      ds.select(r);
    }
  });
}

function rendererTableFieldType({
  record,
  name,
}: {
  record?: Record | null | undefined;
  name?: string | undefined;
}) {
  return (
    <Select
      record={record as Record}
      name={name}
      onChange={(v) => record?.set(name!, v)}
    >
      {FIELD_TYPE_LIST.map((type) => (
        <Select.Option value={type.value} key={type.value}>
          {type.meaning}
        </Select.Option>
      ))}
    </Select>
  );
}

function rendererFormFieldType({
  record,
  name,
}: {
  record?: Record | null | undefined;
  name?: string | undefined;
}) {
  return (
    <Select
      record={record as Record}
      name={name}
      onChange={(v) => record?.set(name!, v)}
    >
      {baseList
        .filter((b) => b.mapping)
        .map((b) => (
          <Select.Option value={b.mapping?.name} key={b.mapping?.name}>
            <MyIcon type={`icon-${b.mapping?.iconCode}`} />

            <span style={{ marginLeft: '6px' }}>{b.mapping?.title}</span>
          </Select.Option>
        ))}
    </Select>
  );
}

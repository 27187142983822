import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ConfigPanelComponentProp } from '@htd/common';
import { PlusOutlined, createFromIconfontCN } from '@ant-design/icons';
import { Form as AntdForm, Input, Button } from 'antd';
import EditTable from 'components/EditTable';
import VariableInput from 'components/VariableInput';
import { debounce } from 'lodash';

const MyIcon = createFromIconfontCN({
  scriptUrl: 'iconfont.js', // 在 iconfont.cn 上生成
});

const SetDataForm: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const [[form]] = useState(AntdForm.useForm());

  /**
   * record变更时，重设form的值
   */
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      dataQuery_url: item.props?.dataQuery_url.value,
    });
  }, [form, item.props]);

  const dataSource = useMemo(() => {
    return item.props?.dataConfig_arr.value;
  }, [item]);

  const dataConfigType = item.props?.dataConfigType.value;

  const handleValuesChange = useCallback(
    (changedValues) => {
      if (!item.props) return;
      for (const key in changedValues) {
        if (key === 'dataQuery_url') {
          item.props['dataQuery_url'].value = changedValues[key];
        }
      }
      forceUpdate?.(item?.id);
    },
    [forceUpdate, item.props, item?.id]
  );

  const columns = [
    {
      title: '节点颜色',
      dataIndex: 'color',
      render: () => (
        <AntdForm.Item name='color'>
          <Input />
        </AntdForm.Item>
      ),
    },
    {
      title: '显示文本',
      dataIndex: 'value',
      render: () => (
        <AntdForm.Item name='value'>
          <VariableInput
            globalDsConfig={tree.dsConfig}
            example={
              <span>
                /hfpm/v1/${'\u007b'}this.getCurrentTenant().tenantId{'\u007d'}
                /bank/list
              </span>
            }
          />
        </AntdForm.Item>
      ),
    },
    {
      title: '操作',
      width: 30,
      dataIndex: 'options',
      render: (_: any, __: any, index: number) => (
        <MyIcon
          type='icon-a-delete2x'
          style={{ marginRight: '6px' }}
          className='left-svg-cla'
          onClick={handleDeleteLine.bind(undefined, index)}
        />
      ),
    },
  ];

  const rowValueChange = debounce(
    useCallback(
      (record, index) => {
        if (item.props) {
          item.props.dataConfig_arr.value[index] = record;
        }
        forceUpdate?.(item?.id);
      },
      [forceUpdate, item?.id, item.props]
    ),
    500
  );

  const handleDeleteLine = useCallback(
    (index) => {
      dataSource.splice(index, 1);
      forceUpdate?.(item?.id);
    },
    [forceUpdate, item?.id, dataSource]
  );

  const handleAddLine = useCallback(() => {
    dataSource.push({ color: '', value: undefined });
    forceUpdate?.(item?.id);
  }, [forceUpdate, item?.id, dataSource]);

  return (
    <section className='htd-aside-right-main-section'>
      <AntdForm
        form={form}
        labelAlign='left'
        onValuesChange={handleValuesChange}
      >
        {dataConfigType === 'api' && (
          <AntdForm.Item
            label='接口地址'
            name='dataQuery_url'
            tooltip="接口数据返回格式应为：[{color: 'red', value: 'xxx'},{color: '#000', value: 'xxx'}]"
          >
            <VariableInput
              globalDsConfig={tree.dsConfig}
              example={
                <span>
                  /hfpm/v1/${'\u007b'}this.getCurrentTenant().tenantId{'\u007d'}
                  /bank/list
                </span>
              }
            />
          </AntdForm.Item>
        )}
        {dataConfigType === 'fixed' && (
          <>
            <div className='htd-aside-right-columns-button-wrapper'>
              <Button
                icon={<PlusOutlined />}
                size='middle'
                type='text'
                onClick={handleAddLine}
              >
                新增
              </Button>
            </div>
            <EditTable
              className='htd-aside-right-columns-table'
              columns={columns}
              rowValueChange={rowValueChange}
              dataSource={dataSource}
              pagination={false}
              showHeader={Boolean(dataSource?.length)}
              size='small'
              bordered={false}
            />
          </>
        )}
      </AntdForm>
    </section>
  );
};

export default SetDataForm;

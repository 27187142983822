import React from 'react';
import { Modal } from 'choerodon-ui/pro';
import { DesignComponent } from '@htd/common';
/**
 * 删除实体
 */
export function openDeleteBackendModal({
  es,
  pageList,
  handleOk,
}: {
  es?: any;
  pageList: any;
  handleOk?: Function;
}) {
  const dsKey = es?.dsKey;
  const tableName = es?.tableName;
  let deleteFlag = true;
  const onClickFunc = () => {
    deleteFlag = false;
  };
  Modal.open({
    title: `删除实体`,
    children: (
      <BusinessModelModal
        pageList={pageList}
        dsKey={dsKey}
        onClickFunc={onClickFunc}
      />
    ),
    okText: '确定',
    onOk: () => handleOk?.(deleteFlag, dsKey, tableName),
  });
}

const BusinessModelModal = (pageList: any) => {
  const meaningFunc = (pageList: any) => {
    const dsKey = pageList.dsKey;
    // 页面数据
    const list = pageList.pageList || [];
    const onClickFunc = pageList.onClickFunc;
    const compList: any[] = [];
    list.forEach((tree: any) => {
      // 页面名称
      const pageName = tree.pageName;
      const treeList = tree.tree?.children || [];
      const findComp = (children: DesignComponent[]) => {
        children.forEach((child: any) => {
          if (child.dsKey === dsKey) {
            const obj = {
              pageName,
              title: child.title,
            };
            compList.push(obj);
          }
          if (child.children && child.children.length) {
            findComp(child.children);
          }
        });
      };
      findComp(treeList);
    });
    let finalMeaning = ['确认删除？'];
    let titleMeaning: any = [];
    if (compList.length > 0) {
      compList.forEach((cf: any) => {
        titleMeaning.push(
          `【${cf.pageName}】页面存在【${cf.title}】组件绑定当前实体`
        );
      });
    }
    if (titleMeaning.length) {
      titleMeaning.push('不可删除！');
      finalMeaning = titleMeaning;
      onClickFunc(false);
    }
    return finalMeaning;
  };
  return (
    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
      {meaningFunc(pageList).map((item: any) => {
        return <div style={{ textAlign: 'center' }}>{item}</div>;
      })}
    </div>
  );
};

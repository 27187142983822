import { Span as SpanPlaceholder } from 'components/placeholders';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Span: Mapping = {
  name: 'span',
  title: '行内元素',
  type: 'inline-block',
  lib: '',
  iconCode: 'Span',
  props: {
    ...BASE_PROPS_TYPES,
    children: {
      type: 'string',
      value: `文本内容${HTD_PROPS_HOLDER}`,
      label: '内容',
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，文本处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      ),
      label: '隐藏',
    },
  },

  placeholderComponent: SpanPlaceholder,
  children: [],
};

import { FC } from 'react';
import cls from 'classnames';

import './index.less';

const Step: FC<any> = (props) => {
  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenTabPaneFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;
  return (
    <div
      className={cls(
        'htd-placeholder-tabs-tabpane',
        isHaveChildrenTabPaneFlag && 'not-chr'
      )}
    >
      {props.children}
    </div>
  );
};

export { Step };

import { MappingListItem } from '../util';
import { Header, Content, Footer } from './Page';

export const hiddenList: Array<MappingListItem> = [
  {
    id: 'hzero-header',
    name: 'Header',
    mapping: Header,
  },
  {
    id: 'hzero-content',
    name: 'Content',
    mapping: Content,
  },
  {
    id: 'custom-footer',
    name: 'Footer',
    mapping: Footer,
  },
];

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'choerodon-ui/dist/choerodon-ui.css';
import 'choerodon-ui/dist/choerodon-ui-pro.css';
import {
  ACCESS_TOKEN_KEY,
  API_HOST_KEY,
  ORGANIZATION_ID_KEY,
  initShadowCallRemote,
  PubSub,
  setSession,
  setStorage,
  setUserData,
} from './utils';

import { loadConfig } from './c7n-ui-config';
import { fetchSelf } from './services/login';

// 调试使用
window.$htd = new PubSub();

loadConfig();
initShadowCallRemote();

window.addEventListener('message', (e) => {
  const { type, payload } = e.data || {};
  if (type === 'htd/init') {
    window.opener?.postMessage('getSuccessMsg', '*');
    setStorage(ACCESS_TOKEN_KEY, payload.token);
    setSession(API_HOST_KEY, payload.API_HOST);
    setSession(ORGANIZATION_ID_KEY, payload.organizationId);
    fetchSelf().then((res) => {
      setUserData(res);
    });
  }
});

ReactDOM.render(<App config={{} as any} />, document.getElementById('root'));

import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Input,
  Form as AntdForm,
  Switch,
  FormInstance,
  Select,
  // Collapse,
} from 'antd';
import { DesignContext } from 'views/Home/DesignContext';
import { FunctionContext } from 'views/Home/FunctionContext';
import cls from 'classnames';
import { DsProps, TemplateParamType } from '@htd/common';
// import { CaretRightOutlined } from '@ant-design/icons';
import VariableInput from 'components/VariableInput';
import ArrayInput from './ArrayInput';
import SelectRightImg from 'components/selectRightImg';

const Form: FC<{
  globalDsConfig?: DsProps[];
  currentEvent: any;
  currentAction: any;
  paramForm: FormInstance<any>;
  defaultParam?: any;
}> = ({
  globalDsConfig,
  currentEvent,
  currentAction,
  paramForm,
  defaultParam,
}) => {
  useEffect(() => {
    paramForm.setFieldsValue(defaultParam);
  }, [defaultParam, paramForm]);

  const { tree, backendConfig } = useContext(DesignContext);
  const { pageList } = useContext(FunctionContext);
  const modalList = useMemo(() => {
    return tree.children[1].children
      .filter((c) => c.name === 'Modal')
      .map((m) => ({
        value: m.props?.['data-hcg_flag'].value,
        name: m.props?.title.value,
      }));
  }, [tree]);

  const pages = useMemo(() => {
    return (pageList || []).map((p: any) => ({
      pageCode: p.pageCode,
      pageName: p.pageName,
      routerPath: p.routerPath,
    }));
  }, [pageList]);

  const entitys = useMemo(() => {
    const es = ((Object.values(backendConfig)[0] as any) || {}).entitys;
    return Object.values(es || {});
  }, [backendConfig]);
  /**
   * 根据参数类型渲染输入框
   */
  const getInputComp = useCallback(
    (param: any) => {
      const disabled = defaultParam && defaultParam[param.index] !== undefined;
      switch (param.type) {
        case TemplateParamType.string:
          return <Input disabled={disabled} />;
        case TemplateParamType.dataSetName:
          return (
            <Select disabled={disabled} suffixIcon={<SelectRightImg />}>
              {(globalDsConfig || []).map((ds) => (
                <Select.Option value={ds.dsName}>{ds.dsName}</Select.Option>
              ))}
            </Select>
          );
        case TemplateParamType.boolean:
          return (
            <Switch defaultChecked={paramForm.getFieldValue(param.index)} />
          );
        case TemplateParamType.dataSetMappingArray:
          return (
            <ArrayInput
              globalDsConfig={globalDsConfig}
              form={paramForm}
              param={param}
              isKeyValue
              valueType={TemplateParamType.dataSetName}
            />
          );
        // case TemplateParamType.dataSetMapping:
        case TemplateParamType.keyValueArray:
          return (
            <ArrayInput
              globalDsConfig={globalDsConfig}
              form={paramForm}
              param={param}
              isKeyValue
            />
          );
        case TemplateParamType.select:
          return (
            <Select suffixIcon={<SelectRightImg />}>
              {param.options.map((o: string) => (
                <Select.Option value={o}>{o}</Select.Option>
              ))}
            </Select>
          );
        case TemplateParamType.modal:
          return (
            <Select suffixIcon={<SelectRightImg />}>
              {modalList.map((o: any) => (
                <Select.Option value={o.value}>{o.name}</Select.Option>
              ))}
            </Select>
          );
        case TemplateParamType.variableString:
          return (
            <VariableInput
              globalDsConfig={globalDsConfig}
              value={{ __isVariable: true }}
              example={param.helper}
            />
          );
        case TemplateParamType.pageList:
          return (
            <Select
              suffixIcon={<SelectRightImg />}
              onChange={(v) => {
                const r = pages.find((p: any) => p.pageCode === v) || {};
                paramForm.setFieldsValue({ name: r.pageName });
                paramForm.setFieldsValue({ path: r.routerPath });
              }}
            >
              {pages.map((o: any) => (
                <Select.Option value={o.pageCode}>{o.pageCode}</Select.Option>
              ))}
            </Select>
          );
        case TemplateParamType.entityMappingArray:
          return (
            <Select suffixIcon={<SelectRightImg />} mode='multiple'>
              {entitys.map((o: any) => (
                <Select.Option value={JSON.stringify(o)}>
                  {o.entityName}
                </Select.Option>
              ))}
            </Select>
          );
      }
      return null;
    },
    [defaultParam, paramForm, globalDsConfig, modalList, pages, entitys]
  );
  return (
    <div className='htd-common-action-modal-form'>
      {currentAction?.actionType !== 'custom' && (
        <>
          <AntdForm
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            form={paramForm}
            labelAlign='left'
          >
            {(currentEvent?.requiredParam || []).map((param: any) => (
              <AntdForm.Item
                label={param.name}
                tooltip={param.describe}
                name={param.index}
                key={param.index}
                rules={[{ required: true, message: `请输入${param.name}` }]}
                className='other-param-box'
              >
                {getInputComp(param)}
              </AntdForm.Item>
            ))}
            {(currentEvent?.otherParam || []).map((param: any) => (
              <AntdForm.Item
                label={param.name}
                tooltip={param.describe}
                name={param.index}
                key={param.index}
                // className='other-param-box other-param-item-width'
                className={cls('other-param-box', {
                  'other-param-item-width': param.index === 'search',
                })}
              >
                {getInputComp(param)}
              </AntdForm.Item>
            ))}
          </AntdForm>
          {!currentEvent?.requiredParam && !currentEvent?.otherParam && (
            <i>说明：该动作不需要配置！</i>
          )}
        </>
      )}
      {currentAction?.actionType === 'custom' && (
        <i>说明：自定义动作需要在二开文件中编写！</i>
      )}
    </div>
  );
};

export default Form;

import { createFormField } from 'components/placeholders';
import { Mapping } from '@htd/common';
import moment, { Moment } from 'moment';
import { DatePicker as APicker } from 'antd';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import React, { createElement } from 'react';
import { isArray } from 'lodash';
import { OTHER_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const DatePicker: Mapping = {
  name: 'DatePicker',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  title: '日期选择框',
  iconCode: 'datePicker',
  fieldType: 'date',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('date', '日期选择'),
    displayMode: {
      label: '显示模式',
      type: 'collection',
      ignore: true,
      group: OTHER_PROPS,
      value: 'date',
      order: 1,
      options: [
        { label: '日期', value: 'date' },
        { label: '日期+时间', value: 'dateTime' },
        { label: '时间', value: 'time' },
        { label: '年', value: 'year' },
        { label: '月', value: 'month' },
        { label: '周', value: 'week' },
      ],
    },
    format: {
      label: '日期格式',
      type: 'string',
      group: OTHER_PROPS,
      order: 2,
    },
    range: {
      label: '范围选择',
      type: 'boolean',
      group: OTHER_PROPS,
      isDS: true,
      order: 3,
    },
    defaultValue: {
      label: '默认值',
      type: 'string',
      value: undefined,
      group: OTHER_PROPS,
      valueConfigurable: true,
      isDS: true,
      defaultComponent: () => createElement(ValueComponent),
      order: 4,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  validate: {},
  showValidate: ['min', 'max'],
  placeholderComponent: createFormField({ htdType: 'datePicker' }),
  children: [],
};

const typeMap: any = {
  date: APicker,
  dateTime: APicker,
  time: APicker.TimePicker,
  year: APicker.YearPicker,
  month: APicker.MonthPicker,
  week: APicker.WeekPicker,
};

const timeFormat = { format: 'HH:mm' };

function ValueComponent(props: any): React.ReactElement {
  const {
    onChange,
    value,
    allValue: { displayMode, range } = {} as any,
  } = props;
  let component = typeMap[displayMode] || APicker;
  const handleChange = (
    m: Moment | null | [Moment, Moment],
    dateStr: string
  ) => {
    if (range && m && (m as any).length >= 2) {
      return onChange((m as any[]).map((mm) => mm.toString()));
    }
    onChange(m ? m.toString() : null);
  };
  if (range) {
    component = APicker.RangePicker;
  }
  const rangeProps = {
    picker: displayMode !== 'dateTime' ? displayMode : undefined,
  };
  let passValue = value && moment(value);
  // 范围选择时，value是一个数组
  if (isArray(value)) {
    passValue = value.forEach((v: string) => {
      return moment(v);
    });
  }
  return createElement(component, {
    onChange: handleChange,
    ...(range ? rangeProps : {}),
    showTime: displayMode === 'dateTime' && timeFormat,
    value: passValue,
  });
}

import React, { FC, useState, useMemo } from 'react';
import { Button, Col, Row } from 'antd';
import cls from 'classnames';
import { SettingOutlined } from '@ant-design/icons';
import ActionModal from './ActionModal';
import { AvaliableEvents, DsProps, PropType } from '@htd/common/lib';
import MyIcon from 'components/myIcon';

const CommonActionPanel: FC<{
  fieldName?: string;
  configProps?: any;
  compFlag: string;
  globalDsConfig?: DsProps[];
  avaliableEvents: AvaliableEvents;
  noCustomEvent?: boolean;
  onActionOk?: (e: any) => any;
  onDeleteEvent?: (eventName: string) => any;
}> = ({
  fieldName,
  configProps,
  compFlag,
  avaliableEvents,
  globalDsConfig,
  onActionOk,
  onDeleteEvent,
  noCustomEvent,
}) => {
  const [settingItem, setSettingItem] = useState<{
    eventName: string;
    eventMeaning: string;
    props?: PropType;
  }>();
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenActionModal = () => {
    setModalVisible(true);
  };

  const configEventList = useMemo(() => {
    return avaliableEvents
      .map((item) => ({
        eventName: item.name,
        eventMeaning: item.meaning,
        props:
          configProps?.[fieldName ? `${fieldName}_${item.name}` : item.name],
      }))
      .filter((item) => item?.props?.value);
  }, [avaliableEvents, configProps, fieldName]);

  const handleOk = (e: any) => {
    onActionOk?.(e);
    setModalVisible(false);
    setSettingItem(undefined);
  };

  const handleCloseActionModal = () => {
    setModalVisible(false);
    setSettingItem(undefined);
  };

  const handleDeleteEvent = (eventName: string) => {
    onDeleteEvent?.(eventName);
  };

  const handleSettingEvent = (item: {
    eventName: string;
    eventMeaning: string;
    props?: PropType;
  }) => {
    setSettingItem(item);
    handleOpenActionModal();
  };

  return (
    <div className='htd-common-action-panel common-action-panel-box'>
      <div className='htd-aside-right-operator-button-wrapper'>
        <Button
          type='primary'
          block
          onClick={handleOpenActionModal}
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
        >
          新建动作
        </Button>
      </div>
      <div
        className={cls('htd-common-action-panel-list', {
          'list-top-margin': configEventList.length,
        })}
      >
        {configEventList.map((item, index) => (
          <div className='htd-common-action-panel-item' key={item.eventName}>
            <Row>
              <Col span={6}>动作{index + 1}</Col>
              <Col span={18}>
                <span>{item.eventMeaning}</span>
                <span>
                  <SettingOutlined
                    onClick={handleSettingEvent.bind(this, item)}
                  />
                  {/* <DeleteOutlined
                    onClick={handleDeleteEvent.bind(this, item.eventName)}
                  /> */}
                  <MyIcon
                    type='icon-a-delete2x'
                    className='left1-svg1-cla1'
                    onClick={handleDeleteEvent.bind(this, item.eventName)}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={18} offset={6}>
                {item.props?.value?.eventName || '自定义事件'}
              </Col>
            </Row>
          </div>
        ))}
      </div>
      <ActionModal
        fieldName={fieldName}
        compFlag={compFlag}
        settingItem={settingItem}
        globalDsConfig={globalDsConfig}
        avaliableEvents={avaliableEvents}
        modalVisible={modalVisible}
        onOk={handleOk}
        onCancel={handleCloseActionModal}
        noCustomEvent={noCustomEvent}
      />
    </div>
  );
};

export default CommonActionPanel;

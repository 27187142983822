import React, { FC, useContext } from 'react';
import cls from 'classnames';
import './index.less';
import { DesignContext } from 'views/Home/DesignContext';

const FormField: FC<any> = (props, showValidate) => {
  const { disabled, label, placeholder, htdType, range } = props;
  const { cMap } = useContext(DesignContext);
  const item = cMap.get(props.designerProps.id);
  const clsNames = cls(
    'htd-placeholder-form-field',
    item?.validate?.required?.value && 'htd-placeholder-form-field-required',
    `htd-placeholder-form-field-${htdType}`,
    disabled && 'htd-placeholder-form-field-disabled',
    range && 'htd-placeholder-form-field-range'
  );
  return (
    <div className={clsNames}>
      <div className='htd-placeholder-form-field-label'>{label}</div>
      <div className='htd-placeholder-form-field-content'>
        {placeholder}
        <span className='htd-placeholder-form-field-content-extra' />
      </div>
    </div>
  );
};

export const createFormField = (extraProps: any = {}) => {
  return (props: any) => <FormField {...props} {...extraProps} />;
};

export { FormField };

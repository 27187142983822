import React from 'react';
import { useMemo } from 'react';
import { DsApiProps, DsTransportEnum } from '@htd/common';
import { Row, Col, Icon } from 'choerodon-ui';
import {
  Select,
  TextField,
  // Button,
  Tooltip,
  Modal,
  message,
} from 'choerodon-ui/pro';
import { useState } from 'react';
// import { ButtonColor, FuncType } from 'choerodon-ui/pro/lib/button/enum';
import { getFormatResponseData } from 'utils';
import { getEnvConfig } from '@/c7n-ui-config';
import MyIcon from 'components/myIcon';
// import SelectRightImg from 'components/selectRightImg';
interface Props {
  modal?: {
    handleOk: Function;
  };
  currentApiList: DsApiProps[];
  editApi?: DsApiProps;
  onConfirm: Function;
  dsKey?: string;
}

const AddFieldModal: React.FC<Props> = ({
  modal,
  currentApiList,
  editApi,
  onConfirm,
  dsKey,
}) => {
  const [addApiList, setAddApiList] = useState<DsApiProps[]>([editApi || {}]);

  const isEdit = useMemo(() => !!editApi, [editApi]);

  const typeOptionsList = useMemo(
    () => [
      { value: 'read', meaning: DsTransportEnum['read'] },
      { value: 'create', meaning: DsTransportEnum['create'] },
      { value: 'update', meaning: DsTransportEnum['update'] },
      { value: 'submit', meaning: DsTransportEnum['submit'] },
      { value: 'destroy', meaning: DsTransportEnum['destroy'] },
    ],
    []
  );

  const methodOptionsList = useMemo(
    () => [
      { value: 'get', meaning: 'GET' },
      { value: 'post', meaning: 'POST' },
      { value: 'put', meaning: 'PUT' },
      { value: 'delete', meaning: 'DELETE' },
    ],
    []
  );

  modal?.handleOk(async () => {
    // 校验必填
    const [notValidate] = addApiList.filter(
      (api) => !api.type || !api.method || !api.url
    );
    if (!!notValidate) {
      message.error('请填写必填项');
      return false;
    }
    const [apiFormatValidate] = addApiList.filter(
      (api) =>
        !api.url?.startsWith('/') &&
        !api.url?.startsWith('http://') &&
        !api.url?.startsWith('https://')
    );
    if (!!apiFormatValidate) {
      message.error(`请求地址需要以'/'或'http://'或'https://'开头`);
      return false;
    }
    const params = isEdit ? addApiList[0] : addApiList;
    // 如果存在readApi，则尝试调用接口获取字段
    const readApi = addApiList.find((api) => api.type === 'read');
    if (readApi && !isEdit) {
      try {
        const fields = await handleGetData(readApi);
        onConfirm(params, dsKey, fields);
        return true;
      } catch (error) {
        const button = await Modal.confirm({
          title: '提示',
          children: (
            <>
              <p>根据查询请求地址获取字段信息失败！</p>
              <p>点击“修改”按钮可重新填写请求地址；</p>
              <p>点击“继续”按钮将不自动生成字段信息。</p>
            </>
          ),
          okText: '修改',
          cancelText: '继续',
        });
        if (button === 'ok') {
          return false;
        } else {
          onConfirm(params, dsKey);
          return true;
        }
      }
    } else {
      onConfirm(params, dsKey);
      return true;
    }
  });

  /**
   * 根据接口获取字段信息
   * @param api
   */
  const handleGetData = async (api: DsApiProps) => {
    try {
      const { url } = api;
      let realUrl: string = '';
      if (
        (url as string).startsWith('http://') ||
        (url as string).startsWith('https://')
      ) {
        realUrl = url!;
      } else {
        const { API_HOST } = getEnvConfig();
        realUrl = `${API_HOST}${url}`;
      }
      const data = await fetch(realUrl, {
        method: api.method!,
        headers: {
          authorization: `bearer ${window.localStorage
            .getItem('ACCESS_TOKEN')
            ?.replace(/"/g, '')}`,
        },
      }).then((res) => res.json());
      if (data.success === false || data.failed === true) {
        throw data.message;
      }
      const fields = getFormatResponseData(data);
      return fields;
    } catch (error) {
      throw error;
    }
  };

  /**
   * 点击添加API
   */
  const handleClickAddApi = () => {
    setAddApiList([...addApiList, {}]);
  };

  /**
   * 点击删除API
   * @param _api
   * @param index
   */
  const handleClickDeleteApi = (_api: DsApiProps, index: number) => {
    addApiList.splice(index, 1);
    setAddApiList([...addApiList]);
  };

  /**
   * 修改API类型
   * @param api
   * @param option
   */
  const handleChangeApiType = (api: DsApiProps, value: string) => {
    api.type = value;
    api.name = typeOptionsList.find(
      (option) => option.value === value
    )?.meaning;
    setAddApiList([...addApiList]);
  };

  /**
   * 修改API请求方式
   * @param api
   * @param option
   */
  const handleChangeApiMethod = (api: DsApiProps, value: string) => {
    api.method = value;
    setAddApiList([...addApiList]);
  };

  /**
   * 修改API请求地址
   * @param api
   * @param option
   */
  const handleChangeApiUrl = (api: DsApiProps, value: string) => {
    api.url = value;
    setAddApiList([...addApiList]);
  };

  /**
   * 渲染提示信息
   * @param api
   */
  const renderAddonAfter = (api: DsApiProps) => {
    if (api.type === 'read' && !isEdit) {
      return (
        <Tooltip title='系统会自动从查询请求地址中获取接口的字段信息'>
          <Icon type='help_outline' />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      <Row
        className='htd-add-field-modal-row htd-add-field-modal-row-title'
        gutter={8}
      >
        <Col span={6}>类型</Col>
        <Col span={6}>请求方式</Col>
        <Col
          span={10}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          请求地址
          <Tooltip title='说明：如果项目配置了API_HOST，则接口可以不输入完整地址，例如：/api/v1/list。'>
            <Icon type='help_outline' style={{ marginLeft: 4 }} />
          </Tooltip>
        </Col>
      </Row>
      {addApiList.map((api, index) => (
        <div className='add-api-list-box'>
          <Row
            className='htd-add-field-modal-row htd-add-field-modal-row-form'
            gutter={8}
            key={index}
          >
            <Col span={6} style={{ paddingLeft: '6px' }}>
              <Select
                // suffixIcon={<SelectRightImg />}
                required
                style={{ width: '100%' }}
                value={api.type}
                onChange={handleChangeApiType.bind(this, api)}
                disabled={isEdit}
              >
                {typeOptionsList
                  .filter((option) => {
                    const [isRepeat] = [
                      ...currentApiList,
                      ...addApiList,
                    ].filter(
                      (item) =>
                        item.type === option.value && api.type !== item.type
                    );
                    return !isRepeat;
                  })
                  .map((option) => (
                    <Select.Option value={option.value} key={option.value}>
                      {option.meaning}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col span={6}>
              <Select
                required
                style={{ width: '100%' }}
                value={api.method}
                onChange={handleChangeApiMethod.bind(this, api)}
              >
                {methodOptionsList.map((option) => (
                  <Select.Option value={option.value} key={option.value}>
                    {option.meaning}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={12} style={{ paddingRight: '6px' }}>
              <TextField
                required
                style={{ width: '100%' }}
                value={api.url}
                onChange={handleChangeApiUrl.bind(this, api)}
                addonAfter={renderAddonAfter(api)}
              />
            </Col>
          </Row>
          {/* <Col span={2}> */}
          {!isEdit && (
            <MyIcon
              type='icon-a-delete2x'
              onClick={handleClickDeleteApi.bind(this, api, index)}
            />
            // <Button
            //   icon={<MyIcon type='icon-a-delete2x' />}
            //   funcType={FuncType.flat}
            //   onClick={handleClickDeleteApi.bind(this, api, index)}
            // />
          )}
          {/* </Col> */}
        </div>
      ))}
      {!isEdit && (
        <div className='t-j-api-model-box' onClick={handleClickAddApi}>
          <MyIcon type='icon-a-add_circle2x' className='left-svg-cla' />
          <span className='t-j-api-model-box-title'>添加</span>
        </div>
        // <Button
        //   icon='add'
        //   funcType={FuncType.flat}
        //   color={ButtonColor.primary}
        //   onClick={handleClickAddApi}
        // >
        //   添加一行
        // </Button>
      )}
    </>
  );
};

export default AddFieldModal;

import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Modal,
  Row,
  Col,
  Collapse,
  Button,
  Table,
  Checkbox,
  message,
} from 'antd';
import cls from 'classnames';
import AddFunctionModal from './AddFunctionModal';
import { PlusOutlined } from '@ant-design/icons';
import MyIcon from 'components/myIcon';
import { DesignComponent } from '@htd/common';
import './index.less';
import { ConfigContext } from '../ConfigPanel/ConfigContext';

const DynamicConfigModal: FC<{
  modalVisible: boolean;
  onCancel?: () => any;
  onOk?: (f: any) => any;
  onDelete?: (i: number) => any;

  item: DesignComponent;
}> = ({ modalVisible, onCancel, onOk, onDelete, item }) => {
  const [logicFunctions, setlogicFunctions] = useState<any[]>([]);
  const [currentFuncIndex, setcurrentFuncIndex] = useState<
    number | undefined
  >();
  const [currentEditor, setCurrentEditor] = useState();
  const [currentEditorIndex, setCurrentEditorIndex] = useState(-1);

  useEffect(() => {
    setlogicFunctions(item.props?.dynamicTableConfig?.value || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  const { dsQueryFields, tableColumns, dsFields } = useContext(ConfigContext);

  const dataSource = useMemo(() => {
    console.log(tableColumns);

    return tableColumns
      .filter((c) => !c.command)
      .map((tc) => {
        const field = dsFields?.find((f) => tc.name === f.name);
        return { ...tc, label: field?.label };
      });
  }, [tableColumns, dsFields]);

  const handleModalCancel = () => {
    onCancel?.();
  };
  const handleModalOk = useCallback(() => {
    onOk?.(logicFunctions);
  }, [logicFunctions, onOk]);
  const [addModalVisible, setModalVisible] = useState(false);
  const handleOpenAddFunctionModal = () => {
    setModalVisible(true);
  };

  const handleCloseAddFunctionModal = () => {
    setCurrentEditor(undefined);
    setCurrentEditorIndex(-1);
    setModalVisible(false);
  };
  const handleConfirmAddFunctionModal = useCallback(
    (data: any) => {
      if (!data.fxDesc) {
        message.warning('请输入当前函数描述');
        return;
      }
      setModalVisible(false);
      if (data.functionConfigIndex !== -1) {
        logicFunctions[data.functionConfigIndex] = data;
        setlogicFunctions([...logicFunctions]);
      } else {
        setlogicFunctions([...logicFunctions, data]);
      }
    },
    [setModalVisible, setlogicFunctions, logicFunctions]
  );

  const fieldBindFuncDisabled = useCallback(
    (name: string) => {
      const lf = logicFunctions.findIndex((l) =>
        (l.fields || []).includes(name)
      );
      return lf !== -1 && lf !== currentFuncIndex;
    },
    [logicFunctions, currentFuncIndex]
  );

  const fieldBindFuncValue = useCallback(
    (name: string) => {
      const lf =
        logicFunctions.findIndex((l) => (l.fields || []).includes(name)) !== -1;

      return lf;
    },
    [logicFunctions]
  );

  const handleSetFieldBind = useCallback(
    (value, name) => {
      if (currentFuncIndex === undefined) return;
      if (value.target.checked) {
        if (!logicFunctions[currentFuncIndex].fields) {
          logicFunctions[currentFuncIndex].fields = [];
        }
        logicFunctions[currentFuncIndex].fields.push(name);
      } else {
        logicFunctions[currentFuncIndex].fields.splice(
          logicFunctions[currentFuncIndex].fields.indexOf(name),
          1
        );
      }
      setlogicFunctions([...logicFunctions]);
    },
    [logicFunctions, currentFuncIndex, setlogicFunctions]
  );

  const columns = [
    {
      title: '字段名',
      dataIndex: 'label',
      align: 'center',
      render: (text: string, record: any, index: number) => {
        return (
          <div className='columns-checked-box'>
            <Checkbox
              disabled={fieldBindFuncDisabled(record.name)}
              checked={fieldBindFuncValue(record.name)}
              onChange={(v) => {
                handleSetFieldBind(v, record.name);
              }}
            />
            {text}
          </div>
        );
      },
    },
    {
      title: '字段',
      dataIndex: 'name',
      align: 'center',
    },
  ];

  const renderUseage = useCallback(() => {
    if (
      logicFunctions &&
      currentFuncIndex !== undefined &&
      logicFunctions[currentFuncIndex]
    ) {
      const fieldsStr =
        logicFunctions[currentFuncIndex].fields?.join(',') || '';
      return `当前函数【${logicFunctions[currentFuncIndex].fxDesc}】条件达成情况下， 表格列【${fieldsStr}】字段隐藏`;
    }
  }, [logicFunctions, currentFuncIndex]);

  const handleEditorFuctionConfig = useCallback(
    (e, index) => {
      e.stopPropagation();
      setCurrentEditor(logicFunctions[index]);
      setCurrentEditorIndex(index);
      handleOpenAddFunctionModal();
    },
    [logicFunctions]
  );

  const handleDeleteFuctionConfig = useCallback(
    (e: any, index: number) => {
      e.stopPropagation();
      onDelete?.(index);
      logicFunctions.splice(index, 1);
      setlogicFunctions([...logicFunctions]);
    },
    [logicFunctions, onDelete]
  );

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        title='动态列配置'
        width='60%'
        className='htd-common-action-modal dynamic-model'
        okText='确定'
        cancelText='取消'
        destroyOnClose
      >
        <Row className='top-title-box-row'>
          <Col span={7}>
            <div>参考选择</div>
          </Col>
          <Col span={9} />
          <Col span={7}>
            <div>用法</div>
          </Col>
        </Row>
        <Row className='htd-common-action-modal-content'>
          <Col
            span={7}
            className='htd-common-action-modal-content-action-from dynamic-box'
          >
            {/* <div>参考选择</div> */}
            <Collapse
              className='htd-config-panel'
              defaultActiveKey={'function'}
            >
              <Collapse.Panel key='function' header='函数' className='func-box'>
                {logicFunctions.map((lf, index) => {
                  return (
                    <div
                      onClick={() => {
                        setcurrentFuncIndex(index);
                      }}
                      className={cls(
                        'func-list',
                        index === currentFuncIndex ? 'clock-color' : 'no-color'
                      )}
                    >
                      case {index} : {lf.fxDesc}
                      {/* <span style={{ float: 'right' }}>
                        <EditOutlined
                          onClick={(e) => {
                            handleEditorFuctionConfig(e, index);
                          }}
                        />
                      </span>
                      <span style={{ float: 'right' }}>
                        <EditOutlined
                          onClick={(e) => {
                            handleDeleteFuctionConfig(e, index);
                          }}
                        />
                      </span> */}
                      <div style={{ float: 'right' }}>
                        <span>
                          <MyIcon
                            type='icon-a-edit2x'
                            className='svg-cla'
                            onClick={(e: any) =>
                              handleEditorFuctionConfig(e, index)
                            }
                          />
                        </span>
                        <span>
                          <MyIcon
                            type='icon-a-delete2x'
                            style={{ marginRight: '6px' }}
                            className='svg-cla'
                            onClick={(e: any) => {
                              handleDeleteFuctionConfig(e, index);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Collapse.Panel>
            </Collapse>
            <div>
              <div className='htd-aside-right-columns-button-wrapper jus-conn'>
                <Button
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                  className='htd-home-header-actions-generate htd-home-header-actions-generate-primary button-box'
                  onClick={handleOpenAddFunctionModal}
                >
                  函数
                </Button>
              </div>
            </div>
          </Col>
          <Col
            span={9}
            className='htd-common-action-modal-content-action-from model-field'
          >
            <Table
              dataSource={dataSource}
              // @ts-ignore
              columns={columns}
              pagination={false}
            />
          </Col>
          <Col span={7} className='htd-common-action-modal-content-action-from'>
            用法
            {renderUseage()}
          </Col>
        </Row>
      </Modal>
      <AddFunctionModal
        modalVisible={addModalVisible}
        onCancel={handleCloseAddFunctionModal}
        onOk={handleConfirmAddFunctionModal}
        tableQueryFields={dsQueryFields}
        functionConfig={currentEditor}
        functionConfigIndex={currentEditorIndex}
      />
    </div>
  );
};

export default DynamicConfigModal;

import React from 'react';
import { errorYIcon } from '../../utils/unifiedExportIcon';
import './index.less';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleOnClickBackFunc() {
    window.close();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary-box'>
          <img
            alt='error'
            src={errorYIcon}
            className='error-boundary-box-img'
          />
          <h1>抱歉，出错了，请检查变量等配置！</h1>
          <div
            className='error-boundary-box-button'
            onClick={this.handleOnClickBackFunc}
          >
            知道了
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;

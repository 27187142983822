import React, { useState } from 'react';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ConfigPanelComponentProp, DsProps } from '@htd/common';
// import * as uuid from 'uuid';
import ButtonsPanel from '../ButtonsPanel';
import ColumnPanel from '../ColumnPanel';
import DataSetPanel from '../DataSetPanel';
import OperatorColumnPanel from '../OperatorColumnPanel';
import PaginationPanel from '../PaginationPanel';
import DynamicTablePanel from '../DynamicTablePanel';
import QueryPanel from '../QueryPanel';
import { ConfigItem, ConfigProvider } from './ConfigContext';
import { isEqual } from 'lodash';
import { FieldProps } from 'choerodon-ui/pro/lib/data-set/Field';
import { ColumnProps } from 'choerodon-ui/pro/lib/table/Column';
import OtherPanel from '../OtherPanel';
import DataSourcePanel from '../DataSourcePanel';
import './index.less';

const ConfigPanel: React.FC<ConfigPanelComponentProp> = (props) => {
  const { item, tree } = props;

  // 当前选中的字段 { index: 字段索引, type: 属性类型 }
  const [configItem, setConfigItem] = useState<ConfigItem>();

  // 表格列
  const tableColumns: ColumnProps[] = item?.props?.columns?.value;
  // .map(
  //   (vv: any) => {
  //     if (!vv.fieldKey) {
  //       vv.fieldKey = uuid.v4();
  //     }
  //     return vv;
  //   }
  // );

  // 数据源配置
  const dsConfig: DsProps = tree.dsConfig.find((i) => i.dsKey === item?.dsKey)!;

  // ((item?.props?.dataSet?.value || {}).fields || []).forEach((f: any) => {
  //   if (!f.fieldKey) {
  //     f.fieldKey = (
  //       item?.props?.columns?.value.find((c: any) => c.name === f.name) || {}
  //     ).fieldKey;
  //   }
  // });

  // 表格组件中的数据源字段信息
  const tableDsConfig: DsProps = item?.props?.dataSet?.value;

  // // 数据源字段
  const dsFields: FieldProps[] = item?.dsKey
    ? dsConfig?.fields!
    : tableDsConfig?.fields!;

  // 数据源查询字段
  const dsQueryFields: FieldProps[] = item?.dsKey
    ? dsConfig?.queryFields!
    : tableDsConfig?.queryFields!;

  // 表格属性Context
  const configContextValue = {
    configItem,
    onSettingConfig: (item?: ConfigItem) => {
      if (!isEqual(configItem, item)) {
        setConfigItem(item);
      }
    },
    dsConfig,
    tableDsConfig,
    dsFields,
    dsQueryFields,
    tableColumns,
  };

  return (
    <ConfigProvider value={configContextValue}>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='htd-config-panel'
        defaultActiveKey={['data']}
      >
        <Collapse.Panel key='data' header='数据属性' forceRender>
          <DataSetPanel {...props} />
          <ColumnPanel />
        </Collapse.Panel>
        <Collapse.Panel key='other' header='行属性' forceRender>
          <OtherPanel />
        </Collapse.Panel>
        <Collapse.Panel key='dataSource' header='自定义数据源' forceRender>
          <DataSourcePanel />
        </Collapse.Panel>
        <Collapse.Panel key='query' header='查询域'>
          <QueryPanel />
        </Collapse.Panel>
        <Collapse.Panel
          key='buttons'
          header='顶部操作'
          className='query-button-panel-box'
        >
          <ButtonsPanel {...props} />
        </Collapse.Panel>
        <Collapse.Panel key='operator' header='操作列'>
          <OperatorColumnPanel {...props} />
        </Collapse.Panel>
        <Collapse.Panel key='pagination' header='分页设置'>
          <PaginationPanel {...props} />
        </Collapse.Panel>
        <Collapse.Panel key='dynamicTable' header='动态列配置'>
          <DynamicTablePanel {...props} />
        </Collapse.Panel>
      </Collapse>
    </ConfigProvider>
  );
};

export default ConfigPanel;

import React, {
  createElement,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { isDsComponent, ObjectType } from '@htd/common';
import { Icon } from 'choerodon-ui';
import './index.less';
import { DesignContext } from '../DesignContext';
import CommonConfigPanel from 'components/CommonConfigPanel';
// import { validateDs } from 'views/Home/AsideRight/dsValidator';
import './index.less';
import { handleFormCuDataFunc } from './utils';
import { emptyObject } from 'utils';
import { cloneDeep } from 'lodash';

const readChildren = (children: any) => {
  const arr = [];
  for (const item of children) {
    if (item.name === 'Collapse' || item.name === 'Tabs') {
      arr.push(item);
    }
    if (item.children && item.children.length) {
      readChildren(item.children);
    }
  }
  return arr;
};

const AsideRight: FC = () => {
  const {
    tree,
    settingItem: item,
    onSetting,
    forceUpdate,
    isMultilingual,
    cMap,
  } = useContext(DesignContext);
  const { dsConfig } = tree;
  const [, setForceUpdate] = useState(true);

  // 处理applicationFor数据
  let itemCopy: any = cloneDeep(item);
  const parentComponent: any = cMap.get(item?.parentId);
  if (parentComponent?.name === 'Form' && dsConfig && dsConfig.length) {
    const dsKey = parentComponent?.dsKey;
    const cuConfig = dsConfig.find((df: any) => dsKey && dsKey === df.dsKey);
    const itemFieldKey = item?.props?.fieldKey?.value || itemCopy?.itemCopy;
    const currentField: any = cuConfig?.fields?.find(
      (cff: any) =>
        itemFieldKey &&
        (cff.fieldKey === itemFieldKey ||
          cff?.props?.fieldKey?.value === itemFieldKey)
    );
    if (currentField) {
      const data = handleFormCuDataFunc(parentComponent, currentField, [
        'bind',
        'defaultValue',
        'lovCode',
        'lookupCode',
        'multiple',
      ]);
      if (data && data.props) {
        ['bind', 'defaultValue', 'lovCode', 'lookupCode', 'multiple'].forEach(
          (cuKey: string) => {
            if (itemCopy.props && itemCopy.props[cuKey]) {
              itemCopy.props[cuKey].value =
                data.props[cuKey]?.value || data[cuKey];
            }
          }
        );
      }
    }
  }

  const handleConfigChange = useCallback(
    (values: ObjectType) => {
      setForceUpdate((i) => !i);
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          // 多语言code value绑定
          if (key.endsWith('MultilingualCode')) {
            const propsName = key.split('MultilingualCode')[0];
            const prop = item?.props?.[propsName];
            if (prop) {
              prop['multilingualCode'] = values[key];
            }
          }
          const prop = item?.props?.[key];
          const dsProp = item?.dsProps?.[key];
          if (prop) {
            prop.value = values[key];
          } else if (dsProp) {
            dsProp.value = values[key];
          }
        }
      }
      if (values.tab === '子标签页' || values.label === '子折叠面板') {
        const temObj: any[] = [];
        const tem = readChildren(tree.children[1].children);
        tem.forEach((item: any) => {
          if (item.children.length > 0) {
            item.children.forEach((item2: any) => {
              item2.props.key.value = item2.props?.['data-hcg_flag'].value;
              if (item2.props.defaultActive.value) {
                temObj.push(item2.props?.['data-hcg_flag'].value);
              }
            });
            values.label === '子折叠面板'
              ? (item.props.defaultActiveKey.value = temObj)
              : (item.props.defaultActiveKey.value = temObj[temObj.length - 1]);
          }
        });
      }
      forceUpdate?.(item?.id);
    },
    [forceUpdate, item?.dsProps, item?.id, item?.props, tree.children]
  );

  const handleDsChange = useCallback(
    (value, oldValue) => {
      if (item!.props!.dataSet) {
        if (!value) {
          const dsConfig = tree.dsConfig.find((c) => c.dsKey === oldValue);
          item!.props!.dataSet.value = dsConfig || {};
        }
      }
      forceUpdate?.(item?.id);
    },
    [forceUpdate, item, tree.dsConfig]
  );
  const handleActionChange = useCallback(
    (e: any) => {
      if (item?.props) {
        item.props[e.eventName] = {
          ...item.props[e.eventName],
          value: e.eventValue,
        };
        item.props = cloneDeep(item.props);
        forceUpdate?.(item?.id);
      }
    },
    [forceUpdate, item]
  );
  const handleDeleteEvent = useCallback(
    (eventName: string) => {
      if (item?.props) {
        item.props[eventName].value = undefined;
        item.props = cloneDeep(item.props);
      }
      forceUpdate?.();
    },
    [forceUpdate, item]
  );
  return (
    <aside
      className='htd-aside-right'
      style={{ display: item ? 'block' : 'none' }}
    >
      {item ? (
        <>
          <header className='htd-aside-right-header box-header'>
            <span>{item?.title || item.name} 设置</span>
            <Icon type='close' onClick={onSetting?.bind(this, undefined)} />
          </header>
          <main className='htd-aside-right-main'>
            <CommonConfigPanel
              configTypes={itemCopy.props || emptyObject}
              configDSTypes={item.dsProps || emptyObject}
              useDs={isDsComponent(item)}
              onChange={handleConfigChange}
              extra={item.extraConfigSections}
              avaliableEvents={item.availableEvents}
              globalDsConfig={dsConfig}
              config={item}
              onDsChange={handleDsChange}
              onActionChange={handleActionChange}
              onDeleteEvent={handleDeleteEvent}
              isMultilingual={isMultilingual}
              setForceUpdate={setForceUpdate}
            />
            {item.configPanelComponent &&
              createElement(item.configPanelComponent, {
                tree,
                item,
                forceUpdate,
              })}
          </main>
        </>
      ) : null}
    </aside>
  );
};

export default AsideRight;

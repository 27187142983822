import React, { useContext, useEffect } from 'react';
import { Form as AntdForm, Input, Select, Collapse, Switch } from 'antd';
import { Icon } from 'choerodon-ui';
import { FIELD_TYPE_LIST } from 'utils/prop-types';
import VariableInput from 'components/VariableInput';
import { DesignContext } from 'views/Home/DesignContext';
import { CaretRightOutlined } from '@ant-design/icons';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import RelationAttribute from './RelationAttribute';
import './index.less';
import SelectRightImg from 'components/selectRightImg';
import QueryAttribute from './QueryAttribute';
interface Props {
  record?: any;
}

const FieldEditPanel: React.FC<Props> = ({ record }) => {
  const { tree, settingItem, forceUpdate } = useContext(DesignContext);
  const { configItem, onSettingConfig, dsQueryFields } =
    useContext(ConfigContext);
  const [form] = AntdForm.useForm();

  // 当前查询字段
  const currentQueryField = dsQueryFields?.find((f) => f.name === record.name);

  useEffect(() => {
    form.setFieldsValue(currentQueryField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryField?.type]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    onSettingConfig?.(undefined);
  };

  /**
   * 表单值变更
   * @param changedValues
   */
  const handleChangeValues = (changedValues: any) => {
    if (currentQueryField) {
      for (const key in changedValues) {
        const value = changedValues[key];
        if (key === 'type') {
          if (value !== 'string') {
            delete currentQueryField.lookupCode;
          }
          if (value !== 'object') {
            delete currentQueryField.lovCode;
          }
        }
        if (key === 'bind') {
          if (value.split('.')[0] === currentQueryField.name) {
            form.setFields([{ name: 'bind', errors: ['不能绑定当前字段'] }]);
            continue;
          } else {
            form.setFields([{ name: 'bind', errors: [] }]);
          }
        }
        (currentQueryField as any)[key] = value;
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header className='htd-aside-right-header'>
        <span>查询字段属性</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main className='htd-aside-right-main' style={{ paddingTop: 8 }}>
        <AntdForm
          form={form}
          labelAlign='left'
          initialValues={currentQueryField}
          onValuesChange={handleChangeValues}
          className='htd-aside-right-main-from antd-form-box-padding'
        >
          <AntdForm.Item label='字段名' name='name'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item label='标题' name='label'>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item label='字段类型' name='type'>
            <Select suffixIcon={<SelectRightImg />}>
              {FIELD_TYPE_LIST.map((type) => (
                <Select.Option value={type.value} key={type.value}>
                  {type.meaning}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
          <AntdForm.Item label='字段绑定' name='bind'>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item label='只读' name='readonly' valuePropName='checked'>
            <Switch />
          </AntdForm.Item>
          <AntdForm.Item label='禁用' name='disabled' valuePropName='checked'>
            <Switch />
          </AntdForm.Item>
          <AntdForm.Item label='必填' name='required' valuePropName='checked'>
            <Switch />
          </AntdForm.Item>

          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Collapse.Panel key='other' header='其他属性'>
              {record.type === 'string' && (
                <AntdForm.Item label='值集编码' name='lookupCode'>
                  <Input />
                </AntdForm.Item>
              )}
              {record.type === 'object' && (
                <AntdForm.Item label='Lov编码' name='lovCode'>
                  <Input />
                </AntdForm.Item>
              )}
              <AntdForm.Item label='默认值' name='defaultValue'>
                <VariableInput
                  globalDsConfig={tree.dsConfig}
                  extraVariable={{
                    params: {
                      name: '校验参数',
                      code: '(params) =>',
                      usage: (
                        <>
                          <p>
                            可获取行数据以及字段名，例如：params.record、params.name
                          </p>
                          <p>
                            使用方法示例：(params) ={'>'}{' '}
                            params.record.get('fieldName') === 'field1'
                          </p>
                        </>
                      ),
                    },
                  }}
                />
              </AntdForm.Item>
              <AntdForm.Item
                label='多值'
                name='multiple'
                // valuePropName='checked'
              >
                {/* <Switch /> */}
                <Select allowClear suffixIcon={<SelectRightImg />}>
                  <Select.Option value='true'>true</Select.Option>
                  <Select.Option value=','>,</Select.Option>
                  <Select.Option value=';'>;</Select.Option>
                  <Select.Option value='#'>#</Select.Option>
                  <Select.Option value='_'>_</Select.Option>
                </Select>
              </AntdForm.Item>
              <AntdForm.Item label='忽略提交' name='ignore'>
                <Select allowClear suffixIcon={<SelectRightImg />}>
                  <Select.Option value='always'>总是忽略</Select.Option>
                  <Select.Option value='clean'>值未变化时忽略</Select.Option>
                  <Select.Option value='never'>从不忽略</Select.Option>
                </Select>
              </AntdForm.Item>
            </Collapse.Panel>
            {record.type === 'object' && (
              <Collapse.Panel key='query' header='查询参数'>
                <QueryAttribute record={record} />
              </Collapse.Panel>
            )}
            {(record.type === 'string' || record.type === 'object') && (
              <Collapse.Panel key='relation' header='关系'>
                <RelationAttribute record={record} />
              </Collapse.Panel>
            )}
          </Collapse>
        </AntdForm>
      </main>
    </div>
  );
};

export default FieldEditPanel;

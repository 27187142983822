import React, { useContext, useEffect, useMemo } from 'react';
import { Form as AntdForm, Input, Select, Switch } from 'antd';
import { FIELD_TYPE_LIST } from 'utils/prop-types';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import SelectRightImg from 'components/selectRightImg';
import {
  handleCuDataFunc,
  handleChnageTablePropsFunc,
  handleQueryFieldDataFunc,
} from '@/mapping/data/Table/utils';
// import { cloneDeep } from 'lodash';

const BaseAttribute: React.FC<{ record: any; item?: any }> = ({
  record,
  item,
}) => {
  const { settingItem, forceUpdate } = useContext(DesignContext);
  const { dsFields, dsQueryFields, tableColumns, configItem } =
    useContext(ConfigContext);
  const [fieldForm] = AntdForm.useForm();
  const [queryForm] = AntdForm.useForm();

  // 当前字段
  const currentField = useMemo(
    () => dsFields?.find((f: any) => f.fieldKey === record?.ds_fieldKey),
    [dsFields, record]
  );

  // 当前列
  const currentColumn = tableColumns.find(
    (c: any) => c.fieldKey === record?.ds_fieldKey
  );
  // 从applicationFor中处理数据
  const currentFieldCopy: any = useMemo(
    () => handleCuDataFunc(currentField, item, ['bind', 'query']),
    [currentField, item]
  );

  useEffect(() => {
    // fieldForm.setFieldsValue(currentField);
    // 字段不一致  导致“查询字段”显示不出
    // record.query = record.ds_query;
    const cuField: any = dsFields?.find(
      (df: any) => df.fieldKey === record?.ds_fieldKey
    );
    let cuData: any = {};
    if (cuField) {
      const queryList = cuField?.applicationFor?.query || [];
      const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
      cuData = queryList.find(
        (qf: any) => data_hcg_flag && qf.cmp === data_hcg_flag
      );
    }
    record.query = cuData?.value || record.ds_query;
    queryForm.setFieldsValue(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.query, currentFieldCopy, dsFields]);

  useEffect(() => {
    fieldForm.resetFields();
    queryForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * fieldForm表单值变更
   * @param changedValues
   */
  const handleChangeFieldValues = (changedValues: any) => {
    let isUpdate = true;
    for (const key in changedValues) {
      const value = changedValues[key];
      if (key === 'name') {
        if (value) {
          // 校验字段是否驼峰命名
          if (!new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/).test(value)) {
            isUpdate = false;
            fieldForm.setFields([{ name: 'name', errors: ['请以驼峰命名'] }]);
            continue;
            // }
            // 筛选是否存在重复字段
            // const existIndex = dsFields?.findIndex(
            //   (d) => d.name === value && d.name !== record[key]
            // );
            // if (existIndex! > -1) {
            //   fieldForm.setFields([{ name: 'name', errors: ['字段名不能重复'] }]);
            //   continue;
          } else {
            fieldForm.setFields([{ name: 'name', errors: [] }]);
            // 修改列字段名
            (currentColumn as any)[key] = value;
          }
        } else {
          isUpdate = false;
          fieldForm.setFields([{ name: 'name', errors: ['字段名不能为空'] }]);
          continue;
        }
      }
      if (key === 'type') {
        if (value !== 'string') {
          delete currentField?.lookupCode;
        }
        if (value !== 'object') {
          delete currentField?.lovCode;
        }
        if (['date', 'dateTime', 'time', 'number'].includes(value)) {
          delete currentField?.min;
          delete currentField?.max;
        }
      }
      if (key === 'bind') {
        if (value.split('.')[0] === currentField?.name) {
          fieldForm.setFields([{ name: 'bind', errors: ['不能绑定当前字段'] }]);
          continue;
        } else {
          fieldForm.setFields([{ name: 'bind', errors: [] }]);
        }
      }
      if (currentField) {
        handleChnageTablePropsFunc(item, currentField, key, value);
      }
    }
    if (isUpdate) {
      forceUpdate?.(settingItem?.id);
    }
  };

  /**
   * queryForm表单值变更
   * @param changedValues
   */
  const handleChangeQueryValues = (changedValues: any) => {
    for (const key in changedValues) {
      const value = changedValues[key];
      if (key === 'query') {
        handleQueryFieldDataFunc(
          item,
          currentField,
          key,
          value,
          dsQueryFields,
          record
        );
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  return (
    <div className='file-edit-panel-content-back base-box'>
      <AntdForm
        form={fieldForm}
        labelAlign='left'
        initialValues={currentFieldCopy}
        onValuesChange={handleChangeFieldValues}
      >
        <AntdForm.Item label='字段名' name='name'>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item label='标题' name='label'>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item label='字段类型' name='type'>
          <Select suffixIcon={<SelectRightImg />}>
            {FIELD_TYPE_LIST.map((type) => (
              <Select.Option value={type.value} key={type.value}>
                {type.meaning}
              </Select.Option>
            ))}
          </Select>
        </AntdForm.Item>
        <AntdForm.Item label='字段绑定' name='bind'>
          <Input />
        </AntdForm.Item>
      </AntdForm>
      <AntdForm
        form={queryForm}
        labelAlign='left'
        initialValues={currentFieldCopy}
        onValuesChange={handleChangeQueryValues}
      >
        <AntdForm.Item label='查询字段' name='query' valuePropName='checked'>
          <Switch />
        </AntdForm.Item>
      </AntdForm>
    </div>
  );
};

export default BaseAttribute;

import React, { FC } from 'react';
import { Timeline } from 'choerodon-ui';

const TimeLine: FC<any> = (props) => {
  const dataConfig_arr = props.dataConfig_arr || [];
  return (
    <Timeline>
      {dataConfig_arr.map((da: { color: string; value: any }) => (
        <Timeline.Item color={da.color}>
          {!da.value || typeof da.value === 'string'
            ? da.value
            : da.value.value}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export { TimeLine };

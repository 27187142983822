import { Form, FormInstance, Input, Select, Switch } from 'antd';
import React, { FC, useContext } from 'react';
import VariableInput from 'components/VariableInput';
import { DesignContext } from 'views/Home/DesignContext';
import SelectRightImg from 'components/selectRightImg';
import './index.less';

const ValidateConfigPanel: FC<{
  form: FormInstance;
  patternList: any[];
  attrName: string;
  messageAttrName?: string;
  messagePlaceholder?: string;
  merge?: boolean;
  label: string;
  defaultInput?: React.ReactNode;
  needVariable?: boolean;
}> = ({
  form,
  patternList,
  attrName,
  messageAttrName,
  messagePlaceholder,
  merge,
  label,
  defaultInput,
  needVariable,
}) => {
  const { tree } = useContext(DesignContext);

  /**
   * 变量输入框
   * @returns
   */
  const getInputComp = () => {
    if (needVariable) {
      return (
        <VariableInput
          globalDsConfig={tree.dsConfig}
          extraVariable={{
            params: {
              name: '校验参数',
              code: '(params) =>',
              usage: (
                <>
                  <p>
                    可获取行数据以及字段名，例如：params.record、params.name
                  </p>
                  <p>
                    使用方法示例：(params) ={'>'} params.record.get('fieldName')
                    === 'field1'
                  </p>
                </>
              ),
            },
          }}
        >
          {defaultInput}
        </VariableInput>
      );
    }
    return defaultInput;
  };

  const getFieldName = () => {
    if (merge) {
      return attrName;
    }
    return [attrName, 'value'];
  };

  /**
   * 正则表达式输入组件
   * @returns
   */
  const getPatternField = () => {
    if (attrName === 'pattern') {
      return (
        <>
          <Form.Item label='正则类型' name={[attrName, '__patternType']}>
            <Select suffixIcon={<SelectRightImg />}>
              {patternList.map((p) => (
                <Select.Option value={p.code} key={p.code}>
                  {p.name}
                </Select.Option>
              ))}
              <Select.Option value='custom'>自定义正则</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='正则表达式' name={[attrName, 'value']}>
            <Input
              disabled={
                form.getFieldValue([attrName, '__patternType']) !== 'custom'
              }
              title={form.getFieldValue([attrName, 'value'])}
            />
          </Form.Item>
        </>
      );
    }
    return null;
  };

  return (
    <section className='htd-validate-config-panel'>
      {!merge && (
        <Form.Item
          label='启用'
          name={[attrName, '__isEnable']}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      )}
      {getPatternField()}
      {defaultInput && (
        <Form.Item label={label} name={getFieldName()}>
          {getInputComp()}
        </Form.Item>
      )}
      {messageAttrName && (
        <Form.Item
          label='错误提示'
          name={['defaultValidationMessages', messageAttrName]}
        >
          <Input title={messagePlaceholder} defaultValue={messagePlaceholder} />
        </Form.Item>
      )}
    </section>
  );
};

export default ValidateConfigPanel;

import { transform } from '@babel/standalone';
import { ObjectType } from '@htd/common';
import { umdConfig } from '@/init-global';

function transformFile(filename: string, source: string) {
  // 编译为ES5
  return transform(source, {
    filename,
    presets: ['react', 'typescript', 'es2015', 'env'],
    plugins: [
      [
        require('@babel/plugin-proposal-decorators'),
        {
          legacy: true,
        },
      ],
      [
        require('@babel/plugin-transform-modules-umd'),
        { ...umdConfig, moduleId: filename },
      ],
      [require('@babel/plugin-syntax-class-properties'), { loose: true }],
      [require('@babel/plugin-proposal-class-properties')],
    ],
    babelrc: false,
  })?.code;
}

function getIndexFileContent(content: string) {
  return `
    import ReactDom from "react-dom";
    import ErrorBoundary from "ErrorBoundary";
    ${content}
    ReactDom.render(<ErrorBoundary><Page /></ErrorBoundary>, document.getElementById("htd-page-preview-root"))
  `;
}

function compile(fileMap: ObjectType<string>) {
  const fileNames = Object.keys(fileMap).filter(
    (f) => !f.startsWith('_smock.')
  );
  const indexFileName = fileNames.find((k) => k.startsWith('index.'))!;
  const otherFilesName = fileNames.filter((k) => !k.startsWith('index.'));
  const awaitCompileList = [
    ...otherFilesName.map((f) => ({ name: f, content: fileMap[f] })),
    {
      name: indexFileName,
      content: getIndexFileContent(fileMap[indexFileName]),
    },
  ];

  return awaitCompileList
    .map(({ name, content }) => transformFile(name, content))
    .join('\n\n');
}

export function sourceTransform(source: ObjectType<string>) {
  return compile(source);
}

import React from 'react';
import { Button, message } from 'choerodon-ui';

const ExcelExport = (props) => {
  const handleClick = () => {
    message.info('hzero标准导出按钮，此处仅预览');
  };
  return (
    <Button icon={props.buttonIcon} onClick={handleClick}>
      {props.buttonText}
    </Button>
  );
};

export default ExcelExport;

import React, { FC, useState, useCallback, useContext } from 'react';
import cls from 'classnames';
import { Popconfirm } from 'antd';
import './index.less';
// import { deleteSIcon } from '../../utils/unifiedExportIcon';
import { ifCanSaveAsBlock } from 'utils';
import MyIcon from 'components/myIcon';
import { baseList } from '../../mapping/base';
import { DesignContext } from 'views/Home/DesignContext';
import BlockSaveModal from './BlockSaveModal';
import { ReactComponent as SaveAsBlockIcon } from './assets/save-as-block.svg';
import { MappingListItem } from '@/mapping/util';
import ChangecFormComponentModal from './ChangecFormComponentModal';

/**
 * 支持转换组件列表
 */
export const ENABLE_CHANGE_COMPONENT = [
  ...baseList
    .filter((b: MappingListItem) => b.mapping)
    .map((b: MappingListItem) => b.mapping?.name),
];

const DragContainer: FC<{
  data: any;
  isActive?: boolean;
  onSetting?: (data: any) => any;
  onDelete?: (data: any) => any;
  onAdd?: () => any;
}> = (props) => {
  const { onSetting, onDelete, data } = props;
  const [blockFormVisible, setBlockFormVisible] = useState(false);
  const [changeFormComponent, setChangeFormComponent] = useState(false);

  const { tree, forceUpdate } = useContext(DesignContext);

  const handleChangeFormComponentClonse = useCallback(() => {
    setChangeFormComponent(false);
  }, []);

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onSetting?.(data);
    },
    [onSetting, data]
  );
  const handleClickDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      onDelete?.(data);
    },
    [onDelete, data]
  );
  const handleClickDeleteFooter = useCallback(
    (e: any) => {
      e.stopPropagation();
      tree.isShowFooter = false;
      onSetting?.(undefined);
      forceUpdate?.();
    },
    [tree, forceUpdate, onSetting]
  );
  const handleClickHidden = useCallback(
    (e: any) => {
      e.stopPropagation();
      onSetting?.(undefined);
    },
    [onSetting]
  );

  const handleBlockModalClose = useCallback(() => {
    setBlockFormVisible(false);
  }, []);

  const canSaveAsBlock = ifCanSaveAsBlock(data);
  return (
    <div
      className={cls(
        'htd-drag-item-container',
        props.isActive && 'htd-drag-item-container-active'
      )}
      onClick={handleClick}
    >
      {!['Header', 'Content'].includes(props.data.name) && (
        <>
          <header className='htd-drag-item-container-title'>
            {!['Header', 'Content'].includes(props.data.name) &&
              (props.data.title || props.data.name)}
          </header>
          <header className='htd-drag-item-container-header'>
            {props.data.name === 'Modal' && (
              <span
                className='htd-drag-item-container-header-action'
                onClick={handleClickHidden}
                title='隐藏容器'
              >
                <MyIcon type='icon-a-yincang2x' />
              </span>
            )}
            {props.data.name === 'Footer' && (
              <Popconfirm
                title='请确认是否删除?'
                onConfirm={handleClickDeleteFooter}
                cancelText='取消'
                okText='确认'
              >
                <span className='htd-drag-item-container-header-action htd-drag-item-container-header-action-delete'>
                  {/* <img alt='delete' src={deleteSIcon} /> */}
                  <MyIcon type='icon-a-delete2x' />
                </span>
              </Popconfirm>
            )}
            {props.onAdd && (
              <span
                className='htd-drag-item-container-header-action'
                onClick={props.onAdd}
              >
                {/* <Icon type='add' /> */}
                <MyIcon type='icon-a-add_circle2x' />
              </span>
            )}
            {canSaveAsBlock && (
              <span
                className='htd-drag-item-container-header-action'
                onClick={() => setBlockFormVisible(true)}
              >
                <SaveAsBlockIcon />
              </span>
            )}
            {!props.data.fixed &&
              (props.data.type === 'container' ? (
                <Popconfirm
                  title='请确认是否删除?'
                  onConfirm={handleClickDelete}
                  cancelText='取消'
                  okText='确认'
                  placement={
                    ['Modal'].includes(props.data.name) ? 'bottom' : 'top'
                  }
                >
                  <span className='htd-drag-item-container-header-action htd-drag-item-container-header-action-delete'>
                    {/* <img alt='delete' src={deleteSIcon} /> */}
                    <MyIcon type='icon-a-delete2x' />
                  </span>
                </Popconfirm>
              ) : (
                <span
                  className='htd-drag-item-container-header-action htd-drag-item-container-header-action-delete'
                  onClick={handleClickDelete}
                >
                  {/* <img alt='delete' src={deleteSIcon} /> */}
                  <MyIcon type='icon-a-delete2x' />
                </span>
              ))}
            {ENABLE_CHANGE_COMPONENT.includes(props.data.name) && (
              <span
                className='htd-drag-item-container-header-action'
                title='组件转换'
                onClick={() => setChangeFormComponent(true)}
              >
                <MyIcon type='icon-Exchange' />
              </span>
            )}
          </header>
        </>
      )}
      <main className='htd-drag-item-container-main'>{props.children}</main>
      <BlockSaveModal
        data={data}
        visible={blockFormVisible}
        onClose={handleBlockModalClose}
      />
      <ChangecFormComponentModal
        visible={changeFormComponent}
        onClose={handleChangeFormComponentClonse}
      />
    </div>
  );
};

export default DragContainer;

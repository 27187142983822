import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Form, Input, Popconfirm } from 'antd';
import {
  ConfigPanelComponentProp,
  DesignComponent,
  DsProps,
} from '@htd/common';
import { BASE_DS_PROPS } from 'utils/prop-types';
import { ColumnsType } from 'antd/lib/table';
import {
  // DeleteOutlined,
  // AimOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { DesignContext } from 'views/Home/DesignContext';
import MyIcon from 'components/myIcon';
import { addDsFieldsToModel } from '@/mapping/container/Form/utils';
import './index.less';

const FormItem: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const { onSetting, onTreeSelect, backendConfig } = useContext(DesignContext);
  const [notExistFieldList, setNotExistFieldList] = useState<any[]>([]); // 切换ds后，ds中不存在的字段列表

  // 数据源配置
  const dsConfig: DsProps | undefined = useMemo(
    () => tree.dsConfig.find((i) => i.dsKey === item.dsKey),
    [item.dsKey, tree.dsConfig]
  );

  const dsFields = useMemo(
    () => getDsFieldsByFormChildren(item, dsConfig || {}),
    [item, dsConfig]
  );

  const dataSourceList = dsFields.map((it: any) => {
    if (
      it.props?.fieldKey?.value &&
      notExistFieldList.includes(it.props?.fieldKey?.value)
    ) {
      it.__status = 'notexist';
    } else {
      it.__status = '';
    }
    return it;
  });
  const [dataSource, setDataSource] = useState<any[]>([]);

  const update = useCallback(() => {
    item.children = cloneDeep(item.children);
    tree.dsConfig = cloneDeep(tree.dsConfig);
    forceUpdate?.(item?.id);
  }, [forceUpdate, item, tree]);

  const [oldDsKey, setOldDsKey] = useState(item.dsKey);
  const [oldItemId, setOldItemId] = useState(item.id);
  /**
   * dsKey变化时，添加DS字段
   */
  useEffect(() => {
    setDataSource(dataSourceList);
    if (oldItemId !== item.id || (item.dsKey && oldDsKey !== item.dsKey)) {
      updateDsConfig(dsConfig || {}, dataSourceList, 'add', backendConfig);
      setOldDsKey(item.dsKey);
      setOldItemId(item.id);
      update();
    }
    // 将不在实体中的字段保存标识
    const cuDsKey = dsConfig?.dsKey;
    const es = ((Object.values(backendConfig)?.[0] as any) || {}).entitys || {};
    const cuBackendData: any =
      Object.values(es).find((obf: any) => cuDsKey && obf.dsKey === cuDsKey) ||
      {};
    const column = cuBackendData.column || [];
    const columnCopy = cloneDeep(column);
    const columnKeyList =
      columnCopy.map((i: any) => i.fieldKey || i.props?.fieldKey?.value) || [];
    const columnNameList = columnCopy.map((i: any) => i.name) || [];
    const fieldList: any = [];
    dsFields?.forEach((df: any) => {
      if (
        df.props?.fieldKey?.value &&
        !columnKeyList.includes(df.props?.fieldKey?.value) &&
        !fieldList.includes(df.props?.fieldKey?.value) &&
        !columnNameList.includes(df.name)
      ) {
        fieldList.push(df.props?.fieldKey?.value);
      }
    });
    setNotExistFieldList(fieldList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [item.dsKey, item.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.dsKey]);

  // 删除行
  const handleDeleteLine = useCallback(
    (record, index) => {
      item.children.splice(index, 1);
      // 更新数据源字段列表
      const dataList = dsConfig?.fields || [];
      dataList.forEach((value, index) => {
        if (value.name === record.name) {
          dataList.splice(index, 1);
        }
      });
      // 更新右侧面板数据
      dataSource.forEach((value, index) => {
        if (value.name === record.name) {
          dataSource?.splice(index, 1);
        }
      });
      setDataSource(dataSource);
      update();
    },
    [dataSource, dsConfig?.fields, item.children, update]
  );

  const handelSelected = (key: any) => {
    const temItem: any = item.children.filter((item) => item.id === key);
    onSetting?.(temItem[0]);
    onTreeSelect?.(key);
  };

  /**
   * 删除
   */
  const handleDeleteField = (record: any, index: number) => {
    // 去掉  不存在标识
    const notExistFieldIndex = notExistFieldList.findIndex(
      (f) => f === record.props?.fieldKey?.value
    );
    if (notExistFieldIndex > -1) {
      // 去掉 不存在标识
      notExistFieldList.splice(notExistFieldIndex, 1);
      setNotExistFieldList([...notExistFieldList]);
    }
  };

  /**
   * 确认保留字段在ds中
   */
  const handleConfirmField = (record: any) => {
    const notExistFieldIndex = notExistFieldList.findIndex(
      (f) => f === record.props?.fieldKey?.value
    );
    if (notExistFieldIndex > -1) {
      // 去掉 不存在标识
      notExistFieldList.splice(notExistFieldIndex, 1);
      setNotExistFieldList([...notExistFieldList]);
      // 实体中添加字段
      if (dsConfig) {
        const name = record.name;
        const label = record.label;
        const fieldKey = record.props?.fieldKey?.value;
        const dsFieldProps = {
          ...BASE_DS_PROPS,
          ...record,
          name,
          label,
          fieldKey,
          fieldCodeJava: name,
          fieldName: label,
          fieldOrder: null,
          fieldType: 'string',
          relationField: 0,
          searchable: 0,
          inputNecessary: 0,
          defaultValue: null,
          lookupCode: null,
          codeRule: null,
          valid: null,
          // validValue: null,
          primaryKey: null,
        };
        const cuDsKey = dsConfig.dsKey;
        const backendData: any = Object.values(backendConfig)?.[0];
        const entitys = backendData?.entitys || {};
        const currentEntitysDataList = Object.values(entitys) || [];
        const cuData: any = currentEntitysDataList.find(
          (ite: any) => ite.dsKey === cuDsKey
        );
        if (cuData) {
          const column = cuData?.column || [];
          const keyList = column.map((c: any) => c.fieldKey);
          const nameList = column.map((c: any) => c.name);
          if (column.length) {
            if (!keyList.includes(fieldKey) && !nameList.includes(name)) {
              column.push(dsFieldProps);
            }
          } else {
            const list = [];
            list.push(dsFieldProps);
            cuData.column = list;
          }
        }
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      render: (_, record, index) => {
        switch (record.__status) {
          case 'notexist':
            return (
              <div className='htd-aside-right-columns-table-status-box'>
                <Popconfirm
                  trigger='hover'
                  title='实体中不存在该字段，是否保留？'
                  okText='保留'
                  cancelText='移除'
                  cancelButtonProps={{ danger: true }}
                  onConfirm={handleConfirmField.bind(this, record)}
                  onCancel={handleDeleteField.bind(this, record, index)}
                >
                  <InfoCircleOutlined
                    style={{ color: '#f66', cursor: 'pointer' }}
                  />
                </Popconfirm>
              </div>
            );
          default:
            return null;
        }
      },
    },
    {
      title: '标题',
      dataIndex: 'label',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='label'>
            <Input disabled />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '数据字段',
      dataIndex: 'name',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='name'>
            <Input disabled />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '操作',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <>
            <MyIcon
              type='icon-a-dingwei2x'
              className='svg-cla'
              onClick={handelSelected.bind(this, record.key)}
            />
            <Popconfirm
              title='确认删除？'
              okText='确认'
              cancelText='取消'
              onConfirm={handleDeleteLine.bind(this, record, index)}
            >
              <MyIcon
                type='icon-a-delete2x'
                style={{ marginRight: '6px' }}
                className='svg-cla'
              />
            </Popconfirm>
          </>
        </>
      ),
    },
  ];

  return (
    <>
      <EditTable
        className='htd-aside-right-columns-table table-form-item-box'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        showHeader={Boolean(dataSource?.length)}
      />
    </>
  );
};

export default FormItem;

/**
 * 获取Form组件下所有的数据输入组件属性，并生成ds fields config
 * 同时判断绑定的ds里是否原有其中字段
 */
export function getDsFieldsByFormChildren(
  formTree: DesignComponent,
  dsConfig: any
) {
  const dsFileds = dsConfig.fields || [];
  const formItemChildren: any[] = [];
  formTree.children?.forEach((c: DesignComponent) => {
    const { label, name } = c.props || {};
    if (label?.value && name?.value) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const dsfIndex = dsFileds.findIndex((f: any) => f.name === name.value);
      const dsField = {
        name: name.value,
        label: label.value,
        type: c.fieldType || 'string',
        props: c.props,
        flag: c.props?.['data-hcg_flag']?.value,
        key: c.id,
        // __status: dsfIndex===-1 ? 'notexist' : '',
      };
      formItemChildren.push(dsField);
    }
  });
  return formItemChildren;
}

/**
 * 向当前表单绑定的数据源更新字段
 */
function updateDsConfig(
  dsConfig: any,
  fields: any[],
  type: 'add' | 'delete' | 'update',
  backendConfig: any
) {
  if (!dsConfig.fields) {
    return;
  }
  fields.forEach((f) => {
    const dsfieldIndex = dsConfig.fields.findIndex(
      (dsf: any) => dsf.name === f.name
    );
    if (dsfieldIndex !== -1) {
      const cuField = dsConfig.fields[dsfieldIndex] || {};
      const fieldKey = cuField.fieldKey || cuField.props?.fieldKey?.value;
      const data = cloneDeep(f);
      if (f.props && f.props.fieldKey && f.props.fieldKey.value) {
        f.props.fieldKey.value = fieldKey;
      }
      if (data.props && data.props.fieldKey && data.props.fieldKey.value) {
        data.props.fieldKey.value = fieldKey;
      }
      if (type === 'update') {
        dsConfig.fields[dsfieldIndex].props = data.props;
        dsConfig.fields[dsfieldIndex].type = data.type;
        dsConfig.fields[dsfieldIndex].label = data.label;
      } else if (type === 'delete') {
        dsConfig.fields.splice(dsfieldIndex, 1);
      } else {
        dsConfig.fields[dsfieldIndex].props = data.props;
        dsConfig.fields[dsfieldIndex].type = data.type;
        dsConfig.fields[dsfieldIndex].label = data.label;
      }
    } else if (type === 'add') {
      dsConfig.fields.push(f);
    }
  });
  addDsFieldsToModel(backendConfig, dsConfig, 'form');
}

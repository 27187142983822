import React, { FC, useContext, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import cls from 'classnames';
import { DRAG_TYPE_DEFAULT, PLACEHOLDER_ID } from 'utils';
import { debounce, noop } from 'lodash';
import { DragDropContext, DropResult, ReorderResult } from '../Context';
import { Mapping, DesignComponent, isButton } from '@htd/common';

import './index.less';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { openBusinessEntityCreateCompModal } from 'views/Home/AsideLeft/BackendDesign/BusinessEntityCreateCompModal';
import { DesignContext } from 'views/Home/DesignContext';

export interface DropAreaProps {
  acceptType?: string;
  onDrop?: (data: any, monitor: DropTargetMonitor) => any;
  capture?: boolean; // 是否捕获drop事件
  className?: string;
  id?: string;
  beforeDrop?(data?: any): boolean;
  getDropTarget?(): Mapping;
  renderContent?(child: DesignComponent, index: number, context?: string): any;
}

const DropArea: FC<DropAreaProps> = (props) => {
  const {
    acceptType = DRAG_TYPE_DEFAULT,
    children,
    capture,
    className,
    id,
    onDrop = noop,
    getDropTarget,
  } = props;
  const ref = useRef<HTMLDivElement>();
  const temp = useRef<any>({});
  const context = useContext(DragDropContext);
  const designContext = useContext(DesignContext);

  const isReverse = useMemo(() => {
    if (ref.current) {
      return (
        window.getComputedStyle(ref.current).flexDirection.indexOf('reverse') >
        -1
      );
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
  const [dropInfo, dropRef] = useDrop(
    {
      accept: acceptType,
      hover: debounce((item: any, monitor) => {
        const compConfig = item.from === 'list' ? item.data.mapping : item.data;
        if (!monitor.isOver({ shallow: true })) return;
        if (
          id === 'root' ||
          (!isButton(compConfig.name) && id === 'hzero-header')
        )
          return;
        const canDrop =
          (getDropTarget?.().onDrop?.(compConfig) ?? true) &&
          (compConfig?.canDrop?.(getDropTarget?.()) ?? true);
        if (!canDrop) return;
        if (!ref.current) return;
        // 对比拖拽组件位置与已有组件位置
        // 判断已有组件是不是容器
        // 根据已有组件类型以及相对位置，计算占位符位置
        // 将占位符插入dom树
        const cursorRect = monitor.getClientOffset(); // 拖拽组件的位置

        if (cursorRect) {
          const dragBoxList = ref.current?.children;
          ref.current.setAttribute(
            'style',
            'background-color: rgba(#e27279,0.08)'
          );
          const noPlaceholderList = Array.from(dragBoxList).filter(
            (i) =>
              Array.from(i.classList).includes('htd-drag-box') &&
              !Array.from(i.classList).includes('htd-components-PLACEHOLDER')
          );
          let targetIndex: number =
            getDropTarget?.().children.filter((i) => i.id !== PLACEHOLDER_ID)
              .length || 0;
          let targetDisplay: string;

          switch (compConfig?.type) {
            case 'inline-container':
            case 'inline-block':
            case 'inline':
              targetDisplay = 'inline-block';
              break;
            default:
              targetDisplay = 'block';
              break;
          }

          if (noPlaceholderList.length) {
            for (let i = 0; i < noPlaceholderList.length; i++) {
              // 组件宽高
              const { height, y, width, x } =
                noPlaceholderList[i].getBoundingClientRect();
              if (!isReverse) {
                const toBottom = y + height - cursorRect.y;
                if (toBottom < 0) continue;
                const toRight = x + width - cursorRect.x;
                if (toRight < 0) continue;
                if (targetDisplay === 'block') {
                  const toTop = cursorRect.y - y;
                  const distance = Math.min(toBottom, toTop);
                  if (distance === toBottom) {
                    targetIndex = i + 1;
                  } else {
                    targetIndex = i;
                  }
                } else {
                  const toLeft = cursorRect.x - x;
                  const distance = Math.min(toLeft, toRight);
                  if (distance === toRight) {
                    targetIndex = i + 1;
                  } else {
                    targetIndex = i;
                  }
                }
              } else {
                const toTop = cursorRect.y - y;
                if (toTop < 0) continue;
                const toLeft = cursorRect.x - x;
                if (toLeft < 0) continue;
                if (targetDisplay === 'block') {
                  const toBottom = y + height - cursorRect.y;
                  const distance = Math.min(toBottom, toTop);
                  if (distance === toTop) {
                    targetIndex = i + 1;
                  } else {
                    targetIndex = i;
                  }
                } else {
                  const toRight = x + width - cursorRect.x;
                  const distance = Math.min(toLeft, toRight);
                  if (distance === toLeft) {
                    targetIndex = i + 1;
                  } else {
                    targetIndex = i;
                  }
                }
              }
              break;
            }
          }
          const result: ReorderResult = {
            targetContainer: id!,
            targetIndex: targetIndex,
            item,
            targetDisplay,
          };
          temp.current.dropIndex = targetIndex;
          // 将占位符插入tree树
          context.onReOrderReady?.(result);
        }
      }, 50),
      drop(item: any, monitor) {
        const compConfig = item.from === 'list' ? item.data.mapping : item.data;
        if (item.from !== 'backend') {
          // 拖拽完成  鼠标松开 清除样式
          ref.current?.setAttribute('style', '');
          if (!monitor.isOver({ shallow: true })) return;
          if (
            id === 'root' ||
            (!isButton(compConfig.name) && id === 'hzero-header')
          )
            return;
          const canDrop =
            (getDropTarget?.().onDrop?.(compConfig) ?? true) &&
            (compConfig?.canDrop?.(getDropTarget?.()) ?? true);
          if (!canDrop) return;
          const result: DropResult = {
            ...item,
            to: id,
            index:
              typeof temp.current.dropIndex === 'number'
                ? temp.current.dropIndex
                : ref.current?.children.length,
          };

          if (capture || !monitor.didDrop()) {
            context.onDrop?.(result);
            onDrop(result, monitor);
          }
        } else if (item.from === 'backend') {
          // 拖拽完成  鼠标松开 清除样式
          ref.current?.setAttribute('style', '');
          if (!monitor.isOver({ shallow: true })) return;
          const canDrop =
            (getDropTarget?.().onDrop?.(compConfig) ?? true) &&
            (compConfig?.canDrop?.(getDropTarget?.()) ?? true);
          if (!canDrop) return;
          if (capture || !monitor.didDrop()) {
            // 打开一个选项框
            openBusinessEntityCreateCompModal({
              data: item.data.cuData,
              ctx: designContext,
              dropRes: {
                to: id,
                index:
                  typeof temp.current.dropIndex === 'number'
                    ? temp.current.dropIndex
                    : ref.current?.children.length,
              },
            });
          }
        }
      },
      collect(monitor: DropTargetMonitor) {
        return {
          isHover: monitor.isOver(),
          isDrop: monitor.didDrop(),
        };
      },
    },
    [id, acceptType, context, onDrop, isReverse]
  );

  useEffect(() => {
    if (!dropInfo.isHover && !dropInfo.isDrop) {
      // 清除所有placeholder
      context.onClearPlaceholder?.();
      // 清除样式
      ref.current?.setAttribute('style', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropInfo.isHover, dropInfo.isDrop]);

  return (
    <div
      className={cls('htd-drop-area', className)}
      // @ts-ignore
      ref={dropRef(ref)}
    >
      {(children as any)?.length ? (
        children
      ) : (
        <span className='htd-drop-area-placeholder' />
      )}
    </div>
  );
};

export default DropArea;

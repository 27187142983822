import { MappingListItem } from '../util';
import { Button } from './Button';
import { ExcelExport } from './ExcelExport';
import { TimeLine } from './TimeLine';
import { Span } from './Span';
import { WorkFlowButton } from './WorkFlowButton';

export const otherList: Array<MappingListItem> = [
  {
    id: 'hcg-other-pro-button',
    name: '按钮',
    mapping: Button,
  },
  {
    id: 'hcg-other-hzero-excelExport',
    name: '导出',
    mapping: ExcelExport,
  },
  {
    id: 'htd-advanced-anchor',
    name: '锚点导航',
  },
  {
    id: 'htd-advanced-steps',
    name: '步骤条',
  },
  {
    id: 'htd-advanced-pro-progress',
    name: '进度条',
  },
  {
    id: 'htd-other-timeline',
    name: '时间轴',
    mapping: TimeLine,
  },
  {
    id: 'htd-other-span',
    name: '行内元素',
    mapping: Span,
  },
  {
    id: 'hcg-other-pro-workflow-button',
    name: '工作流按钮',
    mapping: WorkFlowButton,
  },
];

/*
 * @Author: quanhe.zhao@hand-china.com
 * @Date: 2021-09-23 15:12:49
 * @LastEditTime: 2021-09-26 10:24:53
 * @LastEditors: quanhe.zhao@hand-china.com
 * @Description: 自定义Tooptip
 */
import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import './popconfirm.less';

const prefixCls = 'my-popconfirm';
export declare type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';
export interface PopconfirmProps {
  children?: JSX.Element;
  title?: string;
  desc?: string;
  onCancel?: React.MouseEventHandler;
  onConfirm?: React.MouseEventHandler;
  onDeleteOther?: React.MouseEventHandler;
  cancelText?: string;
  confirmText?: string;
  disabled?: boolean;
  placement?: string;
  onVisibleChange?: (
    visible: boolean,
    e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>
  ) => void;
}

const PopConfirm = React.forwardRef<unknown, PopconfirmProps>((props, ref) => {
  const [visible, setVisible] = useState(false);

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    settingVisible(false, e);
    props.onCancel?.call(this, e);
  };

  const onVisibleChange = (value: boolean) => {
    setVisible(value);
  };

  const settingVisible = (
    value: boolean,
    e?:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    setVisible(value);

    props.onVisibleChange?.(value, e);
  };

  const onConfirm = (e: React.MouseEvent<HTMLButtonElement>) =>
    props.onConfirm?.call(this, e);

  const onDeleteOther = (e: React.MouseEvent<HTMLButtonElement>) =>
    props.onDeleteOther?.call(this, e);

  const renderOverlay = () => {
    const { title } = props;
    return (
      <div className={`${prefixCls}-contents`}>
        <div className={`${prefixCls}-message`}>
          <ExclamationCircleFilled
            style={{
              color: '#faad14',
            }}
          />
          <div className={`${prefixCls}-message-title`}>{title}</div>
        </div>
        <div className={`${prefixCls}-buttons`}>
          <Button
            onClick={onCancel}
            size='small'
            style={{ marginRight: '4px' }}
          >
            取消
          </Button>
          <Button
            onClick={onConfirm}
            size='small'
            type='primary'
            style={{ marginRight: '4px' }}
          >
            确定
          </Button>
          <Button
            onClick={onDeleteOther}
            size='small'
            disabled={props.disabled}
          >
            删除查询域
          </Button>
        </div>
      </div>
    );
  };
  const { children, disabled, placement, ...restProps } = props;
  return (
    <Tooltip
      {...restProps}
      trigger={['click']}
      color='#fff'
      overlay={renderOverlay}
      visible={visible}
      onVisibleChange={onVisibleChange}
      placement={placement as TooltipPlacement}
      ref={ref as any}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentNode
          ? (triggerNode.parentNode as HTMLElement)
          : document.body
      }
    >
      <div style={{ display: 'inline-block', marginLeft: '8px' }}>
        {children}
      </div>
    </Tooltip>
  );
});

export default PopConfirm;

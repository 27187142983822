"use strict";
/*
 * @Author: wen.chen02@hand-china.com
 * @Description: 函数模板参数类型定义
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateParamType = void 0;
/**
 * 变量输入参数类型
 */
var TemplateParamType;
(function (TemplateParamType) {
    TemplateParamType["string"] = "string";
    TemplateParamType["dataSetName"] = "dataSetName";
    TemplateParamType["dataSetMapping"] = "dataSetMapping";
    TemplateParamType["dataSetMappingArray"] = "dataSetMappingArray";
    TemplateParamType["entityMappingArray"] = "entityMappingArray";
    TemplateParamType["pageList"] = "pageList";
    TemplateParamType["keyValueArray"] = "keyValueArray";
    TemplateParamType["boolean"] = "boolean";
    TemplateParamType["select"] = "select";
    TemplateParamType["modal"] = "modalName";
    TemplateParamType["variableString"] = "variableString";
    TemplateParamType["variableSwitch"] = "variableSwitch";
})(TemplateParamType = exports.TemplateParamType || (exports.TemplateParamType = {}));

import { FC, useEffect, useContext, useState } from 'react';
import {
  Form as AntdForm,
  Slider,
  Select,
  Input,
  Popconfirm,
  Popover,
} from 'antd';
import { SettingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';
import MyIcon from 'components/myIcon';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';

import { usePrevious } from '@htd/common';
import './index.less';
import FieldEditPanel from './FieldEditPanel';
// import { arrowDown } from '../../../../utils/unifiedExportIcon';
import FieldListPopover from './FieldListPopover';
import { cloneDeep } from 'lodash';
import SelectRightImg from 'components/selectRightImg';
import { dragIcon } from '../../../../utils/unifiedExportIcon';

const QueryFieldsPanel: FC = () => {
  const { tree, settingItem: item, forceUpdate } = useContext(DesignContext);
  const {
    configItem,
    onSettingConfig,
    dsQueryFields,
    tableDsConfig,
    dsFields,
  } = useContext(ConfigContext);
  const [form] = AntdForm.useForm();
  const [notExistFieldList, setNotExistFieldList] = useState<any[]>([]); // 切换ds后，ds中不存在的字段列表

  // 切换ds前的dsKey
  const previousItemKey = usePrevious<string>(item?.dsKey);

  // 切换组件前的组件id
  const previousItemId = usePrevious<string>(item?.id);

  // 查询字段列表
  const dataSource = (dsQueryFields || []).map((f) => ({
    ...f,
    __status: notExistFieldList.includes(f.name) ? 'notexist' : null,
  }));

  // 当前数据
  const currentRecord =
    configItem?.index !== undefined && configItem?.type === 'query'
      ? dataSource[configItem.index]
      : undefined;

  /**
   * 切换组件重置表单
   */
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  /**
   * 表单值变更事件
   * @param changedValues
   */
  const handleValuesChange = (changedValues: any) => {
    for (const key in changedValues) {
      if (item?.props?.[key]) {
        item.props[key].value = changedValues[key];
      }
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 删除列
   * 关联了数据源的情况下，不删除关联数据源q的字段
   */
  const handleDeleteLine = (_record: any, index: number) => {
    // 同步属性面板和实体
    if (_record) {
      const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
      const fieldData: any = dsFields?.find(
        (i: any) => i.name === _record.name && i.fieldKey === _record.fieldKey
      );
      if (fieldData) {
        const cuApplicationForData = fieldData.applicationFor;
        if (cuApplicationForData) {
          const cuQueryList = cuApplicationForData?.query || [];
          cuQueryList.forEach((cf: any, index: number) => {
            if (data_hcg_flag && cf.cmp === data_hcg_flag) {
              cuQueryList.splice(index, 1);
            }
          });
        }
        fieldData.query = false;
        fieldData.searchable = 0;
      }
    }
    dsQueryFields!.splice(index, 1);
    forceUpdate?.(item?.id);
  };

  /**
   * 修改查询字段属性
   * @param {string} attrName 字段属性名
   * @param {record} record 字段所有属性
   * @param {any} value 字段属性值
   * @param {number} index 索引
   */
  const handleChangeValue = (
    attrName: string,
    _: any,
    value: any,
    index: number
  ) => {
    (dsQueryFields![index] as any)[attrName] = value;
    forceUpdate?.(item?.id);
  };

  /**
   * 设置当前编辑字段
   */
  const handleEditItemClick = (_: any, index: number) => {
    onSettingConfig?.(
      configItem?.index === index && configItem?.type === 'query'
        ? undefined
        : { index, type: 'query' }
    );
  };

  /**
   * 删除ds以及columns字段
   */
  const handleDeleteField = (index: number) => {
    dsQueryFields?.splice(index, 1);
    forceUpdate?.(item?.id);
  };

  /**
   * 确认保留字段在ds中
   */
  const handleConfirmField = (record: any) => {
    const notExistFieldIndex = notExistFieldList.findIndex(
      (f) => f === record.name
    );
    if (notExistFieldIndex > -1)
      notExistFieldList.splice(notExistFieldIndex, 1);
    setNotExistFieldList([...notExistFieldList]);
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: (_, record, index) => {
        switch (record.__status) {
          case 'notexist':
            return (
              <Popconfirm
                trigger='hover'
                title='数据源中不存在该字段，是否同步？'
                okText='同步'
                cancelText='移除'
                cancelButtonProps={{ danger: true }}
                onConfirm={handleConfirmField.bind(this, record)}
                onCancel={handleDeleteField.bind(this, index)}
              >
                <InfoCircleOutlined style={{ color: '#f66' }} />
              </Popconfirm>
            );
          default:
            return (
              <img
                alt='drag'
                src={dragIcon}
                className='htd-aside-right-columns-table-status-box-img'
              />
            );
        }
      },
    },
    {
      title: '标题',
      dataIndex: 'label',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='label'>
            <Input
              onBlur={(e) => {
                handleChangeValue('label', record, e.target.value, index);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '字段名',
      dataIndex: 'name',
      render: (value, record) =>
        record.$form ? (
          <AntdForm.Item name='name'>
            <Input disabled />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      width: 40,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      title: (
        <Popover trigger='click' content={<FieldListPopover />}>
          <SettingOutlined />
        </Popover>
      ),
      render: (_, record, index) => (
        <>
          <MyIcon
            type='icon-a-edit2x'
            className='left-svg-cla'
            onClick={handleEditItemClick.bind(this, record, index)}
          />
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            <MyIcon
              type='icon-a-delete2x'
              style={{ marginRight: '6px' }}
              className='left-svg-cla'
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  /**
   * 拖动排序
   */
  const handleDragEnd = (source: any[]) => {
    dsQueryFields?.sort((a: any, b: any) => {
      const aIndex = source.findIndex((record) => record.name === a.name);
      const bIndex = source.findIndex((record) => record.name === b.name);
      return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
    });
    forceUpdate?.(item?.id);
  };

  /**
   * dsKey变化时，添加DS查询字段
   */
  useEffect(() => {
    if (
      item?.id === previousItemId &&
      item?.dsKey !== previousItemKey &&
      item?.dsKey
    ) {
      const oldDsQueryFields = previousItemKey
        ? tree.dsConfig.find((i) => i.dsKey === previousItemKey)?.queryFields
        : tableDsConfig?.queryFields;
      if (oldDsQueryFields && dsQueryFields) {
        const notExistFieldList: any[] = [];
        oldDsQueryFields.forEach((column) => {
          const dsQueryFieldIndex = dsQueryFields.findIndex(
            (field) => field.name === column.name
          );
          if (typeof dsQueryFieldIndex === 'number') {
            if (dsQueryFieldIndex > -1) {
              dsQueryFields[dsQueryFieldIndex] = cloneDeep(column);
            } else {
              dsQueryFields.push(column);
              notExistFieldList.push(column.name);
            }
          }
        });
        // console.log(notExistFieldList);
        setNotExistFieldList(notExistFieldList);
      }
      forceUpdate?.(item?.id);
    } else if (item?.id !== previousItemId) {
      setNotExistFieldList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.dsKey, item?.id]);

  return (
    <div className='field-edit-panel-box'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={{
          queryFieldsLimit: item?.props?.queryFieldsLimit?.value,
          queryBar: item?.props?.queryBar?.value,
        }}
        onValuesChange={handleValuesChange}
        // style={{ padding: '0 8px' }}
        className='query-box'
      >
        <AntdForm.Item label='查询条类型' name='queryBar'>
          <Select suffixIcon={<SelectRightImg />}>
            <Select.Option value='professionalBar'>专业搜索条</Select.Option>
            <Select.Option value='filterBar'>动态筛选条</Select.Option>
            <Select.Option value='advancedBar'>高级搜索条</Select.Option>
            <Select.Option value='bar'>过滤条</Select.Option>
            <Select.Option value='none'>不显示</Select.Option>
            {/* <Select.Option value='default'>默认</Select.Option> */}
          </Select>
        </AntdForm.Item>
        <AntdForm.Item label='查询字段数' name='queryFieldsLimit'>
          <Slider min={1} max={4} step={1} />
        </AntdForm.Item>
      </AntdForm>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        // showHeader={Boolean(dataSource?.length)}
        showHeader={true}
        onDragEnd={handleDragEnd}
      />
      {currentRecord && <FieldEditPanel record={currentRecord} />}
      {/* <div className='htd-aside-right-columns-button-wrapper jus-conn'>
        <Button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine.bind(this, undefined, false)}
        >
          添加字段
        </Button>
      </div> */}
    </div>
  );
};

export default QueryFieldsPanel;

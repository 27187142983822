import { layoutList } from './layout';
import { baseList } from './base';
import { containerList } from './container';
import { dataList } from './data';
import { advancedList } from './advanced';
import { otherList } from './other';
import { hiddenList } from './hidden';
import { MappingList } from './util';

export const mappings: MappingList[] = [
  // { name: 'layout', title: '布局', list: layoutList },
  {
    name: 'container',
    title: '布局组件',
    list: [...layoutList, ...containerList],
  },
  { name: 'data', title: '数据结构组件', list: dataList },
  { name: 'base', title: '表单组件', list: baseList },
  { name: 'other', title: '其他', list: [...otherList] },
  { name: 'advanced', title: '高级组件', list: advancedList },
  { name: 'hidden', title: '隐藏', list: hiddenList, hidden: true },
];

import { createContext } from 'react';
import { DsProps } from '@htd/common';
import { FieldProps } from 'choerodon-ui/pro/lib/data-set/Field';
import { ColumnProps } from 'choerodon-ui/pro/lib/table/Column';

export type ConfigItem = {
  index: number;
  type: 'column' | 'query' | 'operator' | 'buttons'; // column: 数据字段配置, query: 查询字段配置, operator: 操作列, buttons: 顶部操作
};

export interface ConfigContextValue {
  configItem?: ConfigItem;
  onSettingConfig?: (configItem?: ConfigItem) => any;
  dsConfig?: DsProps;
  tableDsConfig?: DsProps;
  dsFields?: FieldProps[];
  dsQueryFields?: FieldProps[];
  tableColumns: ColumnProps[];
}

export const ConfigContext = createContext<ConfigContextValue>({
  tableColumns: [],
});

export const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

import { List, Checkbox } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'choerodon-ui';
import React, { useContext, useState } from 'react';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';
import MyIcon from 'components/myIcon';

const { Search } = Input;
interface FieldListProps {
  label: string;
  name: string;
  checked: boolean;
}

const FieldListPopover: React.FC = () => {
  const { settingItem, forceUpdate } = useContext(DesignContext);
  const { dsFields, dsQueryFields } = useContext(ConfigContext);

  const [searchStr, setSearchStr] = useState('');

  const fieldList: FieldListProps[] = dsFields!.map((item) => {
    const exist = dsQueryFields?.find((i) => i.name === item.name);
    return {
      label: item.label as string,
      name: item.name!,
      checked: !!exist,
    };
  });

  const handleChangeChecked = (item: FieldListProps, e: any) => {
    const fieldData: any = dsFields?.find((i) => i.name === item.name);
    if (!e.target.checked) {
      const index = dsQueryFields?.findIndex((i) => i.name === item.name);
      if (typeof index === 'number' && index > -1) {
        dsQueryFields?.splice(index, 1);
      }
      // 同步属性面板和实体
      if (fieldData) {
        fieldData.query = false;
        fieldData.searchable = 0;
      }
    } else {
      const currentField = dsFields?.find((i) => i.name === item.name);
      // 将所有field属性复制并添加到queryFields中
      dsQueryFields?.push({
        ...currentField,
      });
      if (fieldData) {
        fieldData.query = true;
        fieldData.searchable = 1;
      }
    }
    forceUpdate?.(settingItem?.id);
  };

  const handleRenderItem = (item: FieldListProps) => (
    <List.Item
      // style={{ paddingRight: 8, display: 'flex', alignItems: 'center' }}
      className='field-list-popover-list-item'
    >
      <Checkbox
        checked={item.checked}
        onChange={handleChangeChecked.bind(this, item)}
        style={{ marginRight: 8 }}
      />
      <span>
        {item.label}（{item.name}）
      </span>
    </List.Item>
  );

  const handleSearchChange = (e: any) => {
    setSearchStr(e?.target?.value || '');
  };

  return (
    <div className='field-list-popover-box query-field-seach'>
      {/* <Input
        value={searchStr}
        suffix={<SearchOutlined />}
        onChange={handleSearchChange}
      /> */}
      <Search
        placeholder='输入关键字'
        className='htd-aside-left-search'
        enterButton={<MyIcon type='icon-a-search2x' className='left-svg-cla' />}
        onChange={handleSearchChange}
      />
      <List
        dataSource={fieldList.filter(
          (f) =>
            f.name.indexOf(searchStr) > -1 || f.label.indexOf(searchStr) > -1
        )}
        renderItem={handleRenderItem}
        // @ts-ignore
        // style={{ maxHeight: 300, overflowY: 'auto', width: 260 }}
        className='field-list-popover-list'
      />
    </div>
  );
};

export default FieldListPopover;

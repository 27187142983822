"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformKvArr2Obj = exports.isEmptyStr = exports.transformArrayParams2Object = exports.getParam = exports.isPromise = void 0;
/**
 * 判断对象是不是Promise
 * @param {*} obj
 */
function isPromise(obj) {
    return (!!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function');
}
exports.isPromise = isPromise;
/**
 * 从参数数组中获取参数值
 * @param {string} key
 * @param {Array} params , [{key,value}]
 * @param {*} defaultValue
 * @returns {*} value
 */
function getParam(key, params, defaultValue) {
    if (params === void 0) { params = []; }
    var param = params.find(function (p) { return p.key === key; });
    if (param) {
        if (param.value !== undefined) {
            return param.value;
        }
        return defaultValue;
    }
    else {
        console.warn('can not find param from ', params, ' with ', key, ' use default value of ', defaultValue);
        return defaultValue;
    }
}
exports.getParam = getParam;
/**
 * 将数组类型的参数结构转成对象
 * @param {Array} params
 * @returns {Object}
 */
function transformArrayParams2Object(params) {
    if (params === void 0) { params = []; }
    var res = {};
    if (params instanceof Array) {
        params.forEach(function (p) {
            var _a;
            if (p.key) {
                res = __assign(__assign({}, res), (_a = {}, _a[p.key] = p.value, _a));
            }
        });
    }
    else {
        console.warn('can not transform params ', params, ' besouse it not array, eg: [{key: id, value: 1}]');
    }
    return res;
}
exports.transformArrayParams2Object = transformArrayParams2Object;
/**
 * 判断是否为有效字符串
 */
function isEmptyStr(str) {
    if (str && typeof str === 'string' && (str === 'undefined' || str === '')) {
        return true;
    }
    return false;
}
exports.isEmptyStr = isEmptyStr;
/**
 * 将[{key: a, value: 1}, {key: b, value: 2}]格式转换成
 * {a:1,b:2}
 */
function transformKvArr2Obj(arr) {
    var res = {};
    if (arr instanceof Array) {
        arr.forEach(function (a) {
            var _a;
            if (a.key) {
                res = __assign(__assign({}, res), (_a = {}, _a[a.key] = a.value, _a));
            }
        });
    }
    else {
        console.warn('can not transform array ', arr, ' to object, return {}');
    }
    return res;
}
exports.transformKvArr2Obj = transformKvArr2Obj;

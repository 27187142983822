import { ConfigPanelComponentProp, Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { ModalPlaceholder } from 'components/placeholders';
import { ModalSizeInput } from './ModalSizeInput';
import OperatorButtonPanel from './OperatorButtonPanel';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

export const Modal: Mapping = {
  name: 'Modal',
  type: 'container',
  lib: 'choerodon-ui',
  title: '弹框',
  iconCode: 'Modal',
  props: {
    ...BASE_PROPS_TYPES,
    title: {
      type: 'string',
      label: '标题',
      value: '弹窗',
    },
    model: {
      type: 'collection',
      value: 'center',
      label: '模式',
      options: [
        { value: 'center', label: '中心弹框' },
        { value: 'drawer', label: '右侧抽屉' },
      ],
    },
    closable: {
      type: 'boolean',
      label: '关闭按钮',
      value: true,
    },
    maskClosable: {
      type: 'boolean',
      label: '关闭遮罩',
      value: true,
    },
    movable: {
      type: 'boolean',
      label: '可移动',
      value: false,
    },
    modalSize: {
      type: 'object',
      label: '弹窗尺寸',
      ignore: true,
      value: {
        width: 500,
        height: 500,
      },
      defaultComponent: () => <ModalSizeInput />,
    },
    zIndex: {
      type: 'number',
      label: '层级',
      value: 99,
      hidden: true,
    },
    showFooter: {
      type: 'boolean',
      label: '底部按钮',
      value: true,
    },
    buttonList: {
      type: 'object',
      label: '',
      hidden: true,
      ignore: true,
      value: [
        {
          type: 'confirm',
          name: '确认',
        },
        {
          type: 'cancle',
          name: '取消',
        },
      ],
    },
  },
  placeholderComponent: ModalPlaceholder as any,
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    if (!props?.item?.props?.showFooter.value) {
      return null;
    }
    return (
      <Collapse
        defaultActiveKey={[1]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='htd-aside-edit-table-panel'
      >
        <Collapse.Panel key='1' header='操作按钮'>
          <OperatorButtonPanel {...props} />
        </Collapse.Panel>
      </Collapse>
    );
  },
  children: [],
  canDrop(target) {
    return target?.parentId === 'root' && target?.name === 'Content';
  },
};

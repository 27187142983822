import { FC, cloneElement, useMemo } from 'react';
import { Collapse as ProCollapse } from 'choerodon-ui';
import { useState } from 'react';
import { emptyArray } from 'utils';
import cls from 'classnames';

const Collapse: FC = (props: any) => {
  const TabPaneList = props.children?.props?.children || emptyArray;
  const [defaultActiveKey, SetDefaultActiveKey] = useState([]);

  useMemo(() => {
    let tem: any = []
    props?.columns.forEach((item: any, index: any) => {
      if(item.defaultActive) {
        tem.push(`${index}`)
        SetDefaultActiveKey(tem);
      }
    });
  }, [props?.columns]);

  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenCollapseFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;

  return (
    <div
      className={cls(
        'htd-placeholder-tabs',
        isHaveChildrenCollapseFlag && 'not-chr'
      )}
    >
      {cloneElement(
        props.children,
        {},
        TabPaneList.length && [
          <ProCollapse
            key='tabs'
            activeKey={defaultActiveKey}
            expandIconPosition={props.expandIconPosition}
            bordered={props.bordered}
            accordion={props.accordion}
            trigger={props.trigger}
          >
            {TabPaneList.map((tabpane: any, index: any) => {
              return (
                <ProCollapse.Panel
                  header={
                    props?.columns[index]?.label || `${index}`
                  }
                  key={index}
                >
                  {tabpane}
                </ProCollapse.Panel>
              );
            })}
          </ProCollapse>,
        ]
      )}
    </div>
  );
};

export { Collapse };

/* eslint-disable @typescript-eslint/no-unused-vars */
// import { telPhoneValidator } from './validators';
/*
 * @Description: DataSet 字段的预定义校验逻辑
 */
const DataSetFieldValidators = [
    // {
    //   code: 'TEL_FORMART_VALIDATE',
    //   name: '电话号码格式校验(复杂模式)',
    //   describe:
    //     '支持中国大陆(Zh-CN)、香港(Zh-HK)、台湾(Zh-TW)、英国(En-UK)、美国(En-US)国家地区手机和电话号码校验',
    //   otherParam: [
    //     {
    //       name: '国家/地区',
    //       index: 'local',
    //       type: TemplateParamType.string,
    //     },
    //     {
    //       name: '校验失败消息',
    //       index: 'errorMsg',
    //       type: TemplateParamType.string,
    //     },
    //   ],
    //   validatType: 'validator',
    //   function: telPhoneValidator,
    // },
    {
        code: 'PHONE_FORMART_VALIDATE',
        name: '固话号码',
        describe: '国内固话号码',
        validatType: 'pattern',
        value: /\d{3}-\d{8}|\d{4}-\{7,8}/,
        errorMsg: '输入有误，请检查固话号码是否输入正确',
    },
    {
        code: 'EMAIL_FORMART_VALIDATE',
        name: '邮箱格式正则匹配',
        describe: '合法的邮箱地址',
        validatType: 'pattern',
        value: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        errorMsg: '输入有误，请检查邮箱地址是否输入正确',
    },
    {
        code: 'IP_FORMART_VALIDATE',
        name: 'IP:port地址',
        describe: '合法的IP:port',
        validatType: 'pattern',
        value: /^((ht|f)tps?:\/\/)?[\w-]+(\.[\w-]+)+:\d{1,5}\/?$/,
        errorMsg: '输入有误，请检查IP地址是否输入正确',
    },
    {
        code: 'ID_FORMART_VALIDATE',
        name: '中国大陆身份证格式',
        describe: '合法的身份证号码(1代&2代)',
        validatType: 'pattern',
        value: /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/,
        errorMsg: '输入有误，请检查身份证号码是否输入正确',
    },
    {
        code: 'BANK_NUM_FORMART_VALIDATE',
        name: '银行卡号码',
        describe: '银行卡号(对公&对私 10~30位)',
        validatType: 'pattern',
        value: /^[1-9]\d{9,29}$/,
        errorMsg: '输入有误，请检查银行卡号是否输入正确',
    },
    {
        code: 'CAR_NUM_FORMART_VALIDATE',
        name: '车牌号',
        describe: '车牌号',
        validatType: 'pattern',
        value: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([DF]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/,
        errorMsg: '输入有误，请检查车牌号是否输入正确',
    },
    {
        code: 'CREDIT_CODE_FORMART_VALIDATE',
        name: '国内统一信用代码',
        describe: '企业信用代码',
        validatType: 'pattern',
        value: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
        errorMsg: '输入有误，请检查信用代码是否输入正确',
    },
];
export default DataSetFieldValidators;

import { axios, callRemote } from 'utils';

export async function saveLocalBlock(payload: {
  name: string;
  type: string;
  data: any;
}) {
  return callRemote({ type: 'block/save', payload });
}

export async function saveInlineBlock(payload: {
  name?: string;
  type?: string;
  data?: any;
  id?: number;
  categoryId?: number;
  _token?: string;
  objectVersionNumber?: number;
}) {
  const url = `/hcgr/v1/0/hand-builder-category-block-infos`;
  const params = [
    {
      blockCode: payload.name,
      blockName: payload.name,
      blockData: JSON.stringify(payload.data),
      id: payload?.id,
      objectVersionNumber: payload?.objectVersionNumber,
      categoryId: payload.type, // 分类ID
      _token: payload?._token,
    },
  ];
  return axios.post(url, params);
}

export async function fetchLocalBlockList() {
  return callRemote({ type: 'block/list' });
}

export async function fetchInlineBlockList(params: object) {
  const url = `/hcgr/v1/0/hand-builder-category-block-infos?page=-1`;
  return axios.get(url, {
    params: {
      ...params,
    },
  });
}

// 删除在线区块
export async function deleteInlineBlock(payload: {
  name?: string;
  type?: string;
  data?: any;
  id?: number;
  _token?: string;
}) {
  const url = `/hcgr/v1/0/hand-builder-category-block-infos`;
  return axios.delete(url, {
    data: {
      blockCode: payload.name,
      blockName: payload.name,
      blockData: JSON.stringify(payload.data),
      categoryCode: payload.type,
      id: payload?.id,
      _token: payload?._token,
    },
  });
}

export async function fetchLocalCategoryList() {
  return callRemote({ type: 'block/category' });
}

export async function fetchInlineCategoryList() {
  const url = `/hcgr/v1/0/hand-builder-category-infos?page=-1`;
  return axios.get(url);
}

// 添加在线分类
export async function addInlineCategory(payload: {
  categoryCode?: string;
  categoryName?: string;
  data?: any;
  id?: number;
  _token?: string;
  objectVersionNumber?: number;
}) {
  const url = `/hcgr/v1/0/hand-builder-category-infos`;
  const params = [
    {
      categoryCode: payload.categoryCode,
      categoryName: payload.categoryName,
      id: payload?.id,
      objectVersionNumber: payload?.objectVersionNumber,
      _token: payload?._token,
    },
  ];
  return axios.post(url, params);
}

export async function addLocalCategory(name: string) {
  return callRemote({ type: 'block/category-add', payload: name });
}
export async function updateLocalCategory(name: string, newName: string) {
  return callRemote({
    type: 'block/category-update',
    payload: { name, newName },
  });
}

export async function renameLocalBlock(data: {
  oldName: string;
  name: string;
  category: string;
}) {
  return callRemote({ type: 'block/block-rename', payload: data });
}

export async function deleteLocalBlock(data: {
  name: string;
  category: string;
}) {
  return callRemote({ type: 'block/delete', payload: data });
}
// 添加model
export async function addModelBackendJson(payload: any) {
  const url = `/hcgr/v1/0/function-page-jsons`;
  const backendModelList = Object.keys(payload) || [];
  const currentModelData =
    (backendModelList &&
      backendModelList.length &&
      payload[backendModelList[0]]) ||
    {};
  const params = [
    {
      _innerMap: null,
      functionId: null,
      id: null,
      modelJson: currentModelData,
      uiJson: null,
    },
  ];
  return axios.post(url, params);
}

// export async function getFieldTypeFunc() {
//   const url = `/hpfm/v1/lovs/data?lovCode=HCGR.TYPE_MEANING_FRONT`;
//   return axios.get(url);
// }

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useContext, useState } from 'react';
import cls from 'classnames';
import * as uuid from 'uuid';
import { DesignComponent, isContainer } from '@htd/common';
// import { debounce } from 'lodash';
import { useKeyPress } from 'ahooks';
import { DesignContext } from '../DesignContext';
import './index.less';
import DropArea from 'components/DragDrop/DropArea';
import DragBox from 'components/DragDrop/DragBox';
import { renderComponent } from 'utils/render-component';
import { getDragClassName, getJsxFlag, PLACEHOLDER_ID } from 'utils';
import DragContainer from 'components/DragContainer';
import { cloneDeep } from 'lodash';
import { baseList } from '../../../mapping/base';
import { otherList } from '../../../mapping/other';
import { MappingListItem } from '@/mapping/util';

/**
 * 支持复制的组件列表
 */

export const ENABLE_ALT_C_COMPONENT = [
  ...baseList
    .filter((b: MappingListItem) => b.mapping)
    .map((b: MappingListItem) => b.mapping?.name),
  ...otherList
    .filter((b: MappingListItem) => b.mapping)
    .map((b: MappingListItem) => b.mapping?.name),
];

const draggingCls = 'htd-canvas-draggable-dragging';
const draggableContainerCls = 'htd-canvas-draggable-container';
const draggableContainerAciveCls = 'htd-canvas-draggable-container-active';

export interface CanvasProps {
  onDrop?: (item: { data: any; from: string; to: string }) => any;
}

const Canvas: FC<CanvasProps> = (props) => {
  const { onDrop } = props;
  const {
    tree,
    settingItem,
    currentMenu,
    onSetting,
    onDelete,
    onAdd,
    cMap,
    forceUpdate,
  } = useContext(DesignContext);
  // 动态计算 中心画布框高度
  let flag = false;
  const rootHeightValue = document.getElementById('root')?.scrollHeight || 0;
  const rootHeightValue1 = rootHeightValue && rootHeightValue - 126;
  const contentNode = document.getElementsByClassName(
    'htd-canvas-droppable-inner-container'
  );
  const contentNodeList =
    (contentNode &&
      contentNode.length &&
      contentNode.length > 1 &&
      contentNode[1] &&
      contentNode[1].children) ||
    [];
  let heightValue = 0;
  for (let i = 0; i < contentNodeList.length; i++) {
    heightValue += contentNodeList[i].scrollHeight;
  }
  if (heightValue > rootHeightValue1) {
    flag = true;
  }

  // htd-canvas-droppable-container htd-canvas-droppable-inner-container
  const handleDrop = useCallback(
    (item: { data: any; from: string; to: string }) => {
      onDrop?.(item);
    },
    [onDrop]
  );

  const renderContent = (
    child: DesignComponent,
    index: number,
    context?: string
  ) => {
    if (!child) {
      return;
    }
    const isActive =
      child.id === settingItem?.id || child.id === settingItem?.id;
    const dragCls = cls(
      draggableContainerCls,
      isActive ? draggableContainerAciveCls : '',
      getDragClassName(child, cMap.get(settingItem?.id) || settingItem),
      `htd-drag-box-${child.type}`
    );
    // div 组件时  直接将样式加在画布上
    if (child.iconCode === 'Div') {
      // @ts-ignore
      const { width, height, backgroundColor, textAlign, className } =
        child.props;
      const style: any = {
        width:
          ![null, ''].includes(width.value) && !isNaN(width.value)
            ? width.value + 'px'
            : width.value,
        height:
          ![null, ''].includes(height.value) && !isNaN(height.value)
            ? height.value + 'px'
            : height.value,
        backgroundColor: backgroundColor.value,
        textAlign: textAlign.value,
        boxSizing: 'border-box',
        overflow: 'auto',
        paddingTop: '20px',
        marginTop: '-20px',
      };
      const dragCls = cls(
        draggableContainerCls,
        isActive ? draggableContainerAciveCls : '',
        getDragClassName(child, cMap.get(settingItem?.id) || settingItem),
        `htd-drag-box-${child.type}`,
        className
      );
      return (
        <DragBox
          context={context}
          index={index}
          id={child.id}
          key={child.id}
          data={child}
          className={dragCls}
          draggingCls={draggingCls}
          canDrag={!child.fixed}
          boxStyle={style}
        >
          <DragContainer
            isActive={isActive}
            data={child}
            onSetting={onSetting}
            onDelete={onDelete}
            onAdd={
              child.name === 'Col'
                ? () => {
                    onAdd?.(index, child, cMap.get(context) as DesignComponent);
                  }
                : undefined
            }
          >
            {renderComponent(
              tree,
              child,
              child.children?.map((c, i) => renderContent(c, i, child.id)),
              {
                id: child.id,
                className:
                  'htd-canvas-droppable-container htd-canvas-droppable-inner-container',
                onDrop: handleDrop,
                getDropTarget: () => child,
              }
            )}
          </DragContainer>
        </DragBox>
      );
    }
    return isContainer(child.type) ? (
      <DragBox
        context={context}
        index={index}
        id={child.id}
        key={child.id}
        data={child}
        className={dragCls}
        draggingCls={draggingCls}
        canDrag={!child.fixed}
        boxStyle={child.boxStyle}
      >
        <DragContainer
          isActive={isActive}
          data={child}
          onSetting={onSetting}
          onDelete={onDelete}
          onAdd={
            child.name === 'Col'
              ? () => {
                  onAdd?.(index, child, cMap.get(context) as DesignComponent);
                }
              : undefined
          }
        >
          {renderComponent(
            tree,
            child,
            child.children?.map((c, i) => renderContent(c, i, child.id)),
            {
              id: child.id,
              className:
                'htd-canvas-droppable-container htd-canvas-droppable-inner-container',
              onDrop: handleDrop,
              getDropTarget: () => child,
            }
          )}
        </DragContainer>
      </DragBox>
    ) : (
      <DragBox
        canDrag={!child.fixed}
        boxStyle={child.boxStyle}
        placeholder={child.id === PLACEHOLDER_ID}
        context={context}
        index={index}
        id={child.id}
        key={child.id}
        data={child}
        className={dragCls}
        draggingCls={draggingCls}
      >
        <DragContainer
          data={child}
          isActive={isActive}
          onSetting={onSetting}
          onDelete={onDelete}
        >
          {renderComponent(tree, child)}
        </DragContainer>
      </DragBox>
    );
  };
  // const ref = useRef();
  const [currentCopyItem, setCurrentCopyItem] = useState<any>();
  // 键盘事件 - 画布组件复制粘贴
  useKeyPress(['alt.c'], () => {
    if (settingItem && ENABLE_ALT_C_COMPONENT.includes(settingItem.name)) {
      setCurrentCopyItem(settingItem);
    } else {
      console.log('没选中组件，无法复制');
    }
  });
  useKeyPress(
    ['alt.v'],
    () => {
      const parent = cMap.get(settingItem?.parentId);
      const parentAreaChildren = parent?.children;
      if (parent?.name && parentAreaChildren && currentCopyItem) {
        const targetIndex = parentAreaChildren.findIndex(
          (c) => c.id === settingItem?.id
        );
        if (targetIndex !== -1) {
          const newCopyItem = cloneDeep(currentCopyItem);
          // 修改唯一标识
          newCopyItem.props['data-hcg_flag'].value = getJsxFlag(
            newCopyItem.name,
            newCopyItem
          );
          if (newCopyItem.props.fieldKey) {
            newCopyItem.props.fieldKey.value = uuid.v4();
          }
          newCopyItem.id = uuid.v4();
          newCopyItem.parentId = settingItem?.parentId;
          parentAreaChildren.splice(targetIndex + 1, 0, newCopyItem);
          // onSetting?.(newCopyItem);
          cMap.set(newCopyItem.id!, newCopyItem);
          forceUpdate?.(settingItem?.parentId);
        }
      }
    }
    // { target: ref }
  );
  // 键盘事件 - 画布组件左移右移
  useKeyPress(['shift.right'], () => {
    const parentAreaChildren = cMap.get(settingItem?.parentId)?.children;
    if (parentAreaChildren) {
      const targetIndex = parentAreaChildren.findIndex(
        (c) => c.id === settingItem?.id
      );
      if (parentAreaChildren.length !== targetIndex && targetIndex !== -1) {
        parentAreaChildren.splice(targetIndex, 1);
        parentAreaChildren.splice(targetIndex + 1, 0, settingItem!);
        forceUpdate?.(settingItem?.parentId);
      }
    }
  });
  useKeyPress(['shift.left'], () => {
    const parentAreaChildren = cMap.get(settingItem?.parentId)?.children;
    if (parentAreaChildren) {
      const targetIndex = parentAreaChildren.findIndex(
        (c) => c.id === settingItem?.id
      );
      if (targetIndex !== 0) {
        parentAreaChildren.splice(targetIndex, 1);
        parentAreaChildren.splice(targetIndex - 1, 0, settingItem!);
        forceUpdate?.(settingItem?.parentId);
      }
    }
  });

  return (
    <div
      className='htd-canvas-wrap'
      // @ts-ignore
      // ref={ref}
      style={{
        width: currentMenu ? 'calc(100% - 615px)' : 'calc(100% - 415px)',
        marginTop: 40,
      }}
    >
      <DropArea
        id={'root'}
        className='htd-canvas-droppable-container htd-canvas-droppable-root-container'
        onDrop={handleDrop}
      >
        <div className='canvas-header-box'>
          {tree &&
            tree.children &&
            tree.children.length &&
            renderContent(tree.children[0], 0, 'root')}
        </div>
        <main
          className={cls(
            'htd-canvas',
            { 'htd-canvas-height': flag },
            { 'htd-canvas-height2': tree.isShowFooter }
          )}
          onKeyDown={(e) => {
            console.log(e);
          }}
        >
          {/* <main className='htd-canvas'> */}
          {tree &&
            tree.children &&
            tree.children.length &&
            tree.children.length > 0 &&
            renderContent(tree.children[1], 1, 'root')}
        </main>
        <div className='canvas-footer-box'>
          {tree &&
            tree.isShowFooter &&
            tree.children &&
            tree.children.length &&
            tree.children.length > 1 &&
            renderContent(tree.children[2], 2, 'root')}
        </div>
      </DropArea>
    </div>
  );
};
export default Canvas;

import { Button as ButtonPlaceholder } from 'components/placeholders';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { IconPicker } from 'choerodon-ui/pro';
import ButtonEvents from '@htd/helper/lib/templates/button-events';
import { OTHER_PROPS } from 'utils/config';

export const Button: Mapping = {
  name: 'Button',
  title: '按钮',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  iconCode: 'button',
  props: {
    ...BASE_PROPS_TYPES,
    newLine: {
      label: '换行',
      type: 'boolean',
      value: false,
      showHelp: true,
      help: '开启后，当前字段在新的一行显示',
      group: OTHER_PROPS,
    },
    children: {
      type: 'string',
      value: `按钮${HTD_PROPS_HOLDER}`,
      label: '标题',
    },
    color: {
      type: 'collection',
      value: 'default',
      label: '按钮类型',
      options: [
        { value: 'primary', label: '主按钮' },
        { value: 'default', label: '次按钮' },
      ],
    },
    style: {
      type: 'number',
      value: 75,
      label: '宽度',
    },
    icon: {
      type: 'string',
      value: undefined,
      label: '按钮图标',
      defaultComponent: () => <IconPicker />,
    },
    disabled: {
      type: 'boolean',
      value: false,
      label: '禁用',
      showHelp: true,
      help: '当条件达成时，按钮处于禁用状态',
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      ),
    },
    onClick: {
      type: 'function',
      label: '点击事件',
      hidden: true,
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，按钮处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      ),
      label: '隐藏',
    },
    permissionCode: {
      type: 'string',
      value: undefined,
      label: '权限编码',
      showHelp: true,
      help: '融合hzero按钮权限设计，可在菜单权限集配置显示禁用模式，此处填写权限编码',
    },
    // positionType: {
    //   type: 'collection',
    //   value: 'inLeft',
    //   label: '按钮位置',
    //   options: [
    //     { value: 'inLeft', label: '左' },
    //     { value: 'inCenter', label: '中' },
    //     { value: 'inRight', label: '右' },
    //   ],
    // },
  },
  availableEvents: [
    {
      name: 'onClick',
      meaning: '点击事件',
      list: ButtonEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  placeholderComponent: ButtonPlaceholder,
  children: [],
};

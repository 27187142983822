import { cloneDeep, isArray } from 'lodash';
/**
 * Table组件
 */
const asd = (max: any, min: any, minL: any, maxL: any, patt: any) => {
  let valid: any = {};
  if (max || min || minL || maxL || patt) {
    let list = [max, min, minL, maxL, patt];
    let list1 = ['maxValue', 'minValue', 'minLength', 'maxLength', 'pattern'];
    list.forEach((LF: any, index: number) => {
      if (typeof LF?.value !== 'object' && LF?.value) {
        let as = list1[index];
        if (list1[index] === 'pattern') {
          if (patt?.__patternType === 'custom') {
            // @ts-check
            valid[as] = LF?.value;
          }
        } else {
          // @ts-check
          valid[as] = LF?.value;
        }
      }
    });
  }
  return valid;
};

/**
 *
 * @param backendConfigData 实体数据
 * @param dsConfigData 数据源数据
 * @param record 当前变更数据
 * @param type 表格列编辑、详情属性面板编辑
 * @param componentType form/表格发生变化
 */
export function updateDsFieldsToModel(
  backendConfigData: any,
  dsConfigData: any,
  record: any,
  item?: any,
  type?: string,
  componentType?: string,
  componentValidateType?: string,
  dsData?: any
) {
  if (record) {
    // const { dsKey = '' } = dsConfigData;
    const dsKey = dsConfigData?.dsKey || dsData?.dsKey;
    const { ds_fieldKey, fieldKey } = record;
    const backendModelMap: any = Object.values(backendConfigData)?.[0];
    const entitys = backendModelMap?.entitys || {};
    const currentEntitysList = Object.values(entitys) || [];
    const currentEntity: any = currentEntitysList.find(
      (item: any) => item.dsKey === dsKey
    );
    const column = currentEntity?.column || [];
    let currentField: any;
    let changeData: any = {};
    if (!!(componentType && componentType === 'form' && item)) {
      // 表单组件变化
      currentField = column.find(
        (field: any) =>
          field.fieldKey &&
          item?.props?.fieldKey?.value &&
          field.fieldKey === item?.props?.fieldKey?.value
      );
      if (currentField) {
        const recordData = item.validate || {};
        const valid = asd(
          recordData.max,
          recordData.min,
          recordData.minLength,
          recordData.maxLength,
          recordData.pattern
        );
        if (componentValidateType) {
          // 表单校验变化
          changeData = {
            searchable: 0,
            bind: item?.props?.bind?.value || null,
            lovCode: record.lovCode || null,
            editor: record.editor || null,
            ignore: record.ignore || null,
            fieldType: item.fieldType || null,
            type: item.fieldType || null,
            multiple: record.multiple || null,
            fieldCodeJava: item?.props?.name?.value || null,
            fieldName: item?.props?.label?.value || null,
            label: item?.props?.label?.value || null,
            name: item?.props?.name?.value || null,
            inputNecessary: recordData.required?.value ? 1 : 0,
            defaultValue:
              typeof record.defaultValue === 'object'
                ? null
                : item?.props?.defaultValue?.value,
            lookupCode: record.lookupCode || null,
            valid,
          };
        } else {
          changeData = {
            searchable: 0,
            bind: record.bind || null,
            lovCode: record.lovCode || null,
            editor: record.editor || null,
            ignore: record.ignore || null,
            fieldType: item.fieldType || null,
            type: item.fieldType || null,
            multiple: record.multiple || null,
            fieldCodeJava: record.name || null,
            fieldName: record.label || null,
            label: record.label || null,
            name: record.name || null,
            inputNecessary: recordData.required?.value ? 1 : 0,
            defaultValue:
              typeof record.defaultValue === 'object'
                ? null
                : record.defaultValue,
            lookupCode: record.lookupCode || null,
            valid,
          };
        }
      }
    } else {
      // 表格组件变化
      if (type) {
        currentField = column.find(
          (field: any) => field.fieldKey && field.fieldKey === fieldKey
        );
        if (currentField) {
          const valid = asd(
            record.max,
            record.min,
            record.minLength,
            record.maxLength,
            record.pattern
          );
          changeData = {
            searchable: record.query ? 1 : 0,
            bind: record.bind,
            lovCode: record.lovCode,
            editor: record.editor,
            ignore: record.ignore,
            fieldType: record.type,
            type: record.type,
            multiple: record.multiple,
            fieldCodeJava: record.name,
            fieldName: record.label,
            label: record.label,
            name: record.name,
            inputNecessary: record.required ? 1 : 0,
            defaultValue:
              typeof record.defaultValue === 'object'
                ? null
                : record.defaultValue,
            lookupCode: record.lookupCode,
            valid,
          };
        }
      } else {
        currentField = column.find(
          (field: any) => field.fieldKey && field.fieldKey === ds_fieldKey
        );
        if (currentField) {
          const valid = asd(
            record.ds_max,
            record.ds_min,
            record.ds_minLength,
            record.ds_maxLength,
            record.ds_pattern
          );
          changeData = {
            searchable: record.ds_query ? 1 : 0,
            bind: record.ds_bind,
            lovCode: record.ds_lovCode,
            editor: record.ds_editor,
            ignore: record.ds_ignore,
            fieldType: record.ds_type,
            type: record.ds_type,
            multiple: record.ds_multiple,
            fieldCodeJava: record.name,
            fieldName: record.ds_label,
            label: record.ds_label,
            name: record.name,
            inputNecessary: record.ds_required ? 1 : 0,
            defaultValue:
              typeof record.ds_defaultValue === 'object'
                ? null
                : record.ds_defaultValue,
            lookupCode: record.ds_lookupCode,
            valid,
          };
          const changeDataKeyList = Object.keys(changeData);
          changeDataKeyList.forEach((item: any) => {
            if (item === 'valid') {
              if (Object.keys(changeData[item]).length) {
                currentField[item] = { ...changeData[item] };
              }
            } else {
              if (![undefined, null].includes(changeData[item])) {
                currentField[item] = changeData[item];
              }
            }
          });
        }
      }
    }
    const changeDataKeyList = Object.keys(changeData) || [];
    changeDataKeyList.forEach((item: any) => {
      if (item === 'valid') {
        if (Object.keys(changeData[item]).length) {
          currentField[item] = { ...changeData[item] };
        }
      } else {
        if (![undefined, null].includes(changeData[item])) {
          currentField[item] = changeData[item];
        }
      }
    });
  }
}

// 从applicationFor中提取数据(Table的)
export function handleCuDataFunc(
  currentField: any,
  item: any,
  attributeList: any
) {
  const cuData: any = cloneDeep(currentField);
  // const isNewDsFlag = item?.props?.createNewDs?.value || false;
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  if (data_hcg_flag) {
    attributeList.forEach((af: any) => {
      const cuBindList = cuData?.applicationFor?.[af] || [];
      if (cuBindList.length) {
        for (let i = 0; i < cuBindList.length; i++) {
          if (cuBindList[i].cmp === data_hcg_flag) {
            cuData[af] = cuBindList[i].value;
            break;
          }
        }
      }
    });
  }
  return cuData;
}

// 设置applicationFor(Table的)
export function handleChnageTablePropsFunc(
  item: any,
  currentField: any,
  key: string,
  value: any
) {
  const isNewDsFlag = item?.props?.createNewDs?.value || false;
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  const list = ['required', 'pattern', 'min', 'max', 'maxLength', 'minLength'];
  if (isNewDsFlag && data_hcg_flag) {
    // 设置了 单独ds
    const applicationFor: any = (currentField as any).applicationFor || {};
    const list1 = [
      'bind',
      'query',
      'lovCode',
      'ignore',
      'multiple',
      'required',
      'pattern',
      'lookupCode',
      'defaultValue',
      'min',
      'max',
      'maxLength',
      'minLength',
    ];
    if (list1.includes(key)) {
      const appData = applicationFor[key];
      let specialValue = value;
      let oldData: any = {};
      let oldDataIndex: any = -1;
      if (isArray(appData)) {
        oldData = applicationFor[key].find(
          (af: any) => af.cmp === data_hcg_flag
        );
        oldDataIndex = applicationFor[key].findIndex(
          (af: any) => af.cmp === data_hcg_flag
        );
        specialValue =
          typeof value === 'object' ? { ...oldData?.value, ...value } : value;
      }
      if (isArray(appData)) {
        // const oldData = applicationFor[key].find((af: any) => af.cmp === data_hcg_flag);
        if (oldData) {
          if (specialValue) {
            oldData.value = specialValue;
          } else {
            // 没有数据 就删除掉 这个单独数据
            if (oldDataIndex! > -1) {
              appData.splice(oldDataIndex, 1);
            }
          }
        } else {
          applicationFor[key].push({
            cmp: data_hcg_flag,
            value: specialValue,
          });
        }
      } else {
        applicationFor[key] = [
          {
            cmp: data_hcg_flag,
            value: specialValue,
          },
        ];
      }
      // 没有数据就删除掉这个属性
      if (appData && !appData.length) {
        delete applicationFor[key];
      }
    } else {
      const cuValue = key === 'required' ? { value: value } : value;
      (currentField as any)[key] = cuValue;
      const cuValidate = cloneDeep(currentField.validate) || {};
      const totalData = { ...cuValidate, [key]: cuValue };
      if (list.includes(key)) {
        // 设置一份表单显示的数据格式
        currentField.validate = totalData;
      }
    }
    // cuField.applicationFor = applicationFor;
    (currentField as any).applicationFor = applicationFor;
  } else {
    const cuValue = key === 'required' ? { value: value } : value;
    (currentField as any)[key] = cuValue;
    const cuValidate = cloneDeep(currentField.validate) || {};
    const totalData = { ...cuValidate, [key]: cuValue };
    if (list.includes(key)) {
      // 设置一份表单显示的数据格式
      currentField.validate = totalData;
    }
  }
}

// 查询域处理applicationFor(Table的)
export function handleQueryFieldDataFunc(
  item: any,
  currentField: any,
  key: string,
  value: any,
  dsQueryFields: any,
  record: any
) {
  const createNewDs = item?.props?.['createNewDs']?.value;
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  let currentFieldData: any = currentField;
  if (createNewDs) {
    handleChnageTablePropsFunc(item, currentField, key, value);
    currentFieldData = handleCuDataFunc(currentField, item, ['bind', 'query']);
  } else {
    const cuData: any = cloneDeep(currentField);
    delete cuData?.applicationFor;
    currentFieldData = cuData;
  }
  if (value) {
    // 将所有field属性复制并添加到queryFields中
    dsQueryFields?.push({
      ...currentFieldData,
      name: record.name,
    });
  } else {
    if (createNewDs) {
      const totalData =
        (dsQueryFields as any[]).find(
          (dsf: any) => dsf.applicationFor && dsf.name === record.name
        ) || {};
      const queryDataIndex = (dsQueryFields as any[]).findIndex(
        (df: any) => df.applicationFor && df.name === record.name
      );
      const cuApplicationForQueryData = totalData.applicationFor?.query || [];
      for (let i = 0; i < cuApplicationForQueryData.length; i++) {
        if (cuApplicationForQueryData[i].cmp === data_hcg_flag) {
          cuApplicationForQueryData.splice(i, 1);
        }
      }
      // 开标识了，设置查询了 再关掉查询
      if (!cuApplicationForQueryData.length && queryDataIndex! > -1) {
        (dsQueryFields as any[]).splice(queryDataIndex!, 1);
      }
    } else {
      const queryFieldIndex = dsQueryFields?.findIndex(
        (f: any) => f.name === record.name && !f.applicationFor
      );
      if (queryFieldIndex! > -1) {
        (dsQueryFields as any[]).splice(queryFieldIndex!, 1);
      }
    }
  }
  // 将数据放入dsconfig 以便批量操作显示
  if (!createNewDs) {
    // @ts-ignore
    currentField.query = value;
  }
}
// 取消 传入组件标识
export function handleTableCancelNewCreateFunc(dsConfig: any, item: any) {
  const itemDsKey = item?.dsKey;
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  const list = ['required', 'pattern', 'min', 'max', 'maxLength', 'minLength'];
  if (itemDsKey && dsConfig) {
    const fields = dsConfig.fields || [];
    const queryFields = dsConfig.queryFields || [];
    const itemFields = item?.props?.columns?.value || [];
    itemFields.forEach((isf: any) => {
      const cuDsField: any = fields.find(
        (ff: any) => ff.fieldKey === isf.fieldKey || ff.name === isf.name
      );
      const cuDsQueryField: any = queryFields.find(
        (qf: any) => qf.fieldKey === isf.fieldKey || qf.name === isf.name
      );
      // 处理数据
      if (cuDsField) {
        const cuFieldApplicationFor = cuDsField.applicationFor || {};
        Object.keys(cuFieldApplicationFor).forEach((vf: any) => {
          const cuKeyValue = cuFieldApplicationFor[vf];
          if (isArray(cuKeyValue) && cuKeyValue.length && data_hcg_flag) {
            cuKeyValue.forEach((vfh: any, index: number) => {
              if (vfh.cmp === data_hcg_flag) {
                if (list) {
                  // 校验部分
                  const cuValidate = cloneDeep(cuDsField.validate) || {};
                  let totalValidate = { ...cuValidate, [vf]: vfh.value };
                  if (vf === 'required') {
                    totalValidate = {
                      ...cuValidate,
                      [vf]: { value: vfh.value },
                    };
                  }
                  cuDsField.validate = totalValidate;
                }
                // 设置外层的值
                cuDsField[vf] = vfh.value;
                if (cuDsField && cuDsField.props && cuDsField.props[vf]) {
                  // 设置里层的值
                  cuDsField.props[vf].value = vfh.value;
                }
                // 清除applicationFor里面对应的值
                cuKeyValue.splice(index, 1);
              }
            });
          }
        });
      }
      // 处理查询域
      if (cuDsQueryField) {
        const cuQueryFieldApplicationFor = cuDsQueryField.applicationFor || {};
        const cuQueryList = cuQueryFieldApplicationFor.query || [];
        if (isArray(cuQueryList) && cuQueryList.length && data_hcg_flag) {
          cuQueryList.forEach((vfh: any, index: number) => {
            if (vfh.cmp === data_hcg_flag) {
              // 清除applicationFor里面对应的值
              cuQueryList.splice(index, 1);
            }
          });
        }
      }
    });
  }
}

import React, { FC } from 'react';
import cls from 'classnames';
// import { Card as ProCard } from 'choerodon-ui';
import './styles.less';

const Card: FC<any> = (props) => {
  const CardCls = cls(
    props.bordered
      ? 'htd-placeholder-card-border'
      : 'htd-placeholder-card-noborder'
  );
  const CardLabel = cls('htd-placeholder-card-label-pre');
  const CardLabelCls = cls('htd-placeholder-card-label');
  const Cardlabeltext = cls('htd-placeholder-card-label-text');
  const CardContent = cls('htd-placeholder-card-content');
  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenCardFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;

  return (
    <div className={CardCls}>
      <div className={CardLabelCls}>
        <span className={CardLabel}></span>
        <span className={Cardlabeltext}>{props.title}</span>
      </div>
      <div className={cls(CardContent, isHaveChildrenCardFlag && 'not-chr')}>
        {props.children}
      </div>
    </div>
  );
};

export { Card };

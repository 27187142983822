import template from '@babel/template';
import generate from '@babel/generator';
import { getParam, regxReplaceVariable, transformKvArr2Obj, transformObject2Ast, transformString2Expression, transformString2Str, } from '@htd/common';
/**
 * 点击重新查询数据源
 */
export function clickForQuery({ dataSet, otherParam = [], }) {
    const search = getParam('search', otherParam, []);
    const createFunctionBodyAST = template(`{
    setQueryParameter(this.getDs(PLACEHOLDER_DATASET_NAME), PLACEHOLDER_SEARCH);
    this.getDs(PLACEHOLDER_DATASET_NAME).query();
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_DATASET_NAME: transformString2Str(dataSet),
        PLACEHOLDER_SEARCH: transformObject2Ast(transformKvArr2Obj(search)),
    });
    return {
        libs: [
            {
                name: ['setQueryParameter'],
                path: '@htd/helper/lib/utils/dataSet',
            },
        ],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 点击设置行编辑状态
 */
export function clickForEditor() {
    const createFunctionBodyAST = template(`{
    record.setState('editing', true);
  }`);
    const functionBody = createFunctionBodyAST({});
    return {
        libs: [],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 点击跳转页面
 */
export function clickForChangeRoute({ path, name, otherParam, }) {
    const search = transformKvArr2Obj(getParam('search', otherParam, []));
    const local = transformKvArr2Obj(getParam('local', otherParam, []));
    let key = path;
    if (local) {
        Object.keys(local).forEach((lk) => {
            key = regxReplaceVariable(key, lk, local[lk]);
        });
    }
    const createFunctionBodyAST = template(`{
    openTab({
      key: PLACEHOLDER_KEY,
      path: PLACEHOLDER_KEY,
      title: PLACEHOLDER_NAME,
      search: PLACEHOLDER_SEARCH,
      closable: true,
      type: 'menu',
    });
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_KEY: transformString2Expression(key),
        PLACEHOLDER_NAME: transformString2Expression(name),
        PLACEHOLDER_SEARCH: transformObject2Ast(search || {}),
    });
    return {
        libs: [
            {
                name: ['openTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * Hzero项目内部跳转页签 - 多页面模式
 */
export function goToHzeroTabDomain({ path, name, otherParam = [], }) {
    const openInCurrent = getParam('openInCurrent', otherParam, false);
    const search = transformKvArr2Obj(getParam('search', otherParam, []));
    const local = transformKvArr2Obj(getParam('local', otherParam, []));
    let key = path;
    if (local) {
        Object.keys(local).forEach((lk) => {
            key = regxReplaceVariable(key, lk, local[lk]);
        });
    }
    let functionBody;
    const PLACEHOLDER_SEARCH = transformObject2Ast(search || {});
    const isEmpAstObj = PLACEHOLDER_SEARCH === '{}';
    if (openInCurrent) {
        const createFunctionBodyAST = template(`{
      ${isEmpAstObj ? 'const search = {}' : 'const search = PLACEHOLDER_SEARCH'}
      this.props.history.push(PLACEHOLDER_KEY${isEmpAstObj
            ? ''
            : '.concat("?").concat(Object.keys(search).map(k=> `${k}=${search[k]}`).join("&"))'});
    }`);
        if (isEmpAstObj) {
            functionBody = createFunctionBodyAST({
                PLACEHOLDER_KEY: transformString2Expression(key),
            });
        }
        else {
            functionBody = createFunctionBodyAST({
                PLACEHOLDER_KEY: transformString2Expression(key),
                PLACEHOLDER_SEARCH,
                // PLACEHOLDER_JSON: 'JSON',
            });
        }
    }
    else {
        const createFunctionBodyAST = template(`{
      openTab({
        key: PLACEHOLDER_KEY,
        path: PLACEHOLDER_KEY,
        title: PLACEHOLDER_NAME,
        search: PLACEHOLDER_SEARCH,
        closable: true,
        type: 'menu',
      });
    }`);
        functionBody = createFunctionBodyAST({
            PLACEHOLDER_KEY: transformString2Expression(key),
            PLACEHOLDER_NAME: transformString2Expression(name),
            PLACEHOLDER_SEARCH: transformObject2Ast(search || {}),
        });
    }
    return {
        libs: [
            {
                name: ['openTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 点击保存行
 */
export function clickForSave({ url, method }) {
    const createFunctionBodyAST = template(`{
    const submitDs = new DataSet({
      autoCreate: true,
      transport: {
        create: () => ({
          url: PLACEHOLDER_URL,
          method: PLACEHOLDER_METHOD,
          data: record.toJSONData(),
        }),
      },
    });
    return submitDs.submit().then(()=>{record.dataSet.query()});
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_URL: transformString2Expression(url),
        PLACEHOLDER_METHOD: transformString2Expression(method),
    });
    return {
        libs: [],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 点击删除行
 */
export function clickForDelete({ url, method, }) {
    const createFunctionBodyAST = template(`{
    const submitDs = new DataSet({
      autoCreate: true,
      transport: {
        create: () => ({
          url: PLACEHOLDER_URL,
          method: PLACEHOLDER_METHOD,
          data: record.toJSONData(),
        }),
      },
    });
    return submitDs.submit().then(()=>{record.dataSet.query()});
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_URL: transformString2Expression(url),
        PLACEHOLDER_METHOD: transformString2Expression(method),
    });
    return {
        libs: [],
        async: false,
        functionParameter: ['record'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 打开页面内弹框
 */
export function openPageModal({ modalFlag }) {
    const createFunctionBodyAST = template(`{
    this.setState({PLACEHOLDER_MODAL_VISIBLE: true})
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_MODAL_VISIBLE: `${modalFlag}Visible`,
    });
    return {
        libs: [],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}

import { createFormField } from 'components/placeholders';
import { Mapping } from '@htd/common';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import { OTHER_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const NumberField: Mapping = {
  name: 'NumberField',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  title: '数字输入框',
  fieldType: 'number',
  iconCode: 'number',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('number', '数字输入'),
    defaultValue: {
      type: 'number',
      label: '默认值',
      isDS: true,
      group: OTHER_PROPS,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = 10; // 你的逻辑... value = 100;
          return value;{'\u007d'}
        </pre>
      ),
    },
    step: {
      type: 'number',
      label: '步距',
      value: undefined,
      group: OTHER_PROPS,
    },
    // nonStrictStep: { type: 'boolean', label: '非严格步距', group: OTHER_PROPS },
    precision: {
      type: 'number',
      label: '小数点位数',
      value: undefined,
      group: OTHER_PROPS,
    },
    numberGrouping: {
      type: 'boolean',
      label: '显示千分位',
      value: false,
      group: OTHER_PROPS,
    },
    range: {
      type: 'boolean',
      label: '范围值',
      value: false,
      group: OTHER_PROPS,
      isDS: true,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  validate: {},
  showValidate: ['min', 'max', 'minLength', 'maxLength'],
  placeholderComponent: createFormField({ htdType: 'number' }),
  children: [],
};

import React, { forwardRef, useMemo, useCallback, useContext } from 'react';
import { useState } from 'react';
import cls from 'classnames';
import { DesignContext } from 'views/Home/DesignContext';
import { DesignComponent } from '../../../../common/lib';
import '../../../src/views/Home/common.less';

interface TensileProps {
  rowId: string; // 父节点ID
  colIndex: number; // 当前的Key, 两个子节点中加一个拖动元素
}

/**
 * 支持拖动组件，根据上层组件id获取子节点位置，重新设置子节点属性
 */
const Tensile = (props: React.PropsWithChildren<TensileProps>) => {
  const { colIndex, rowId } = props;

  const [mouseMove, setMouseMove] = useState(0); // 相比上一次拖动后的位置
  const [isMoveing, setMoveing] = useState(false); // 是否处于拖动状态
  const { cMap, forceUpdate } = useContext(DesignContext);

  const handleStartMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.persist();
      setMouseMove(event.clientX);
      setMoveing(true);
    },
    []
  );

  const handleStopMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.persist();
      setMouseMove(event.clientX);
      setMoveing(false);
    },
    []
  );

  const changeSpan = useCallback(
    (direction: 'left' | 'right') => {
      // 不能使用useMemo缓存计算Col信息，因为更新不及时会导致新加的Col数据缺失
      const currentCol = cMap.get(rowId)?.children[colIndex] as DesignComponent; // 当前col节点
      const nextCol = cMap.get(rowId)?.children[
        colIndex + 1
      ] as DesignComponent; // 下一个col节点
      if (currentCol?.props?.span && nextCol?.props?.span) {
        const currentColSpan = currentCol.props.span.value as number;
        const nextColSpan = nextCol.props.span.value as number;
        if (direction === 'left' && currentColSpan > 1 && nextColSpan < 24) {
          currentCol.props.span.value = currentColSpan - 1;
          nextCol.props.span.value = nextColSpan + 1;
        } else if (
          direction === 'right' &&
          nextColSpan > 1 &&
          currentColSpan < 24
        ) {
          currentCol.props.span.value = currentColSpan + 1;
          nextCol.props.span.value = nextColSpan - 1;
        }
        forceUpdate?.();
      }
    },
    [rowId, colIndex, cMap, forceUpdate]
  );

  /**
   * 支持拖动
   */
  const handleMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!isMoveing || event.clientX === 0) {
        return;
      }
      event.persist();
      const distance = event.clientX - mouseMove;
      // TODO: feat: 这个正负50根据屏幕进行判断，如何与24栅格进行关联
      if (distance < -40) {
        // 向左拖动
        changeSpan('left');
        setMouseMove(event.clientX);
      } else if (distance > 40) {
        // 向右拖动
        changeSpan('right');
        setMouseMove(event.clientX);
      }
    },
    [isMoveing, mouseMove, changeSpan]
  );

  return (
    <div className='htd-placeholder-row-tensile'>
      <div
        className='htd-placeholder-row-tensile-line'
        onDragStart={handleStartMove}
        onDrag={handleMove}
        onDragEnd={handleStopMove}
        draggable
      ></div>
    </div>
  );
};

interface DesignerProps {
  id: string;
}

interface RowProps extends React.PropsWithoutRef<any> {
  designerProps: DesignerProps;
}

export const Row = forwardRef((props: RowProps, ref: any) => {
  /**
   * 向两个Col之间插入拖动元素,重新计算Row的子元素
   */
  const chr: React.ReactElement = useMemo(() => {
    const children: React.ReactElement[] = [];
    const rowId = props.designerProps.id;
    let rowChildren = props.children as React.ReactElement;
    const cols = rowChildren.props.children as React.ReactElement[];
    if (cols) {
      cols.forEach((col, index) => {
        children.push(col);
        if (index < cols.length - 1) {
          children.push(
            React.createElement(Tensile, {
              rowId,
              colIndex: index,
              key: `${rowId}-${index}`,
            })
          );
        }
      });
      return React.cloneElement(
        rowChildren,
        { ...rowChildren.props },
        children
      );
    } else {
      return rowChildren;
    }
  }, [props.designerProps.id, props.children]);
  // 判断 是否有下级内容元素
  const isHaveChildrenFlag =
    chr && chr.props && chr.props.children && chr.props.children.length;

  return (
    <div
      className={cls('htd-placeholder-row', isHaveChildrenFlag && 'not-chr')}
      ref={ref}
    >
      {chr}
    </div>
  );
});

export const Col = forwardRef((props: any, ref: any) => {
  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenColFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;

  return (
    <div
      className={cls('htd-placeholder-col', isHaveChildrenColFlag && 'not-chr')}
      ref={ref}
      style={{ textAlign: props.textLayout }}
    >
      {props.children}
    </div>
  );
});

import React, { useState, useEffect, useContext } from 'react';
import { Form as AntdForm, Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import EditTable from 'components/EditTable';
import { isEqual, isObject } from 'lodash';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import './index.less';

const RelationAttribute: React.FC<{ record: any }> = ({ record }) => {
  const { settingItem, forceUpdate } = useContext(DesignContext);
  const { dsQueryFields, configItem } = useContext(ConfigContext);

  const [dataSource, setDataSource] = useState<any[]>([]);

  // 当前查询字段
  const currentQueryField = dsQueryFields?.find((f) => f.name === record?.name);

  /**
   * 初始化级联数据
   */
  useEffect(() => {
    const source = [];
    if (
      currentQueryField?.cascadeMap &&
      isObject(currentQueryField?.cascadeMap)
    ) {
      for (const key in currentQueryField.cascadeMap) {
        source.push({
          fileLabel: key,
          fileValue: (currentQueryField.cascadeMap as any)[key],
        });
      }
    }
    if (!isEqual(dataSource, source)) {
      setDataSource(source);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * 新增一行级联
   */
  const handleAddLine = () => {
    setDataSource([...dataSource, {}]);
  };

  /**
   * 删除级联关系
   * @param cascadeRecord
   * @param index
   */
  const handleDeleteLine = (cascadeRecord: any, index: number) => {
    dataSource.splice(index, 1);
    setDataSource([...dataSource]);
    if (
      currentQueryField?.cascadeMap &&
      (currentQueryField.cascadeMap as any)[cascadeRecord.fileLabel]
    ) {
      delete (currentQueryField.cascadeMap as any)[cascadeRecord.fileLabel];
      // onChange('ds_cascadeMap', record, { ...record.ds_cascadeMap });
    }
    forceUpdate?.(settingItem?.id);
  };

  /**
   * 修改级联数据
   */
  const handleCascadeValueChange = (
    fieldName: string,
    _: any,
    value: string,
    index: number
  ) => {
    if (currentQueryField) {
      const cascadeItem = dataSource[index];
      const newCascadeItem = { ...cascadeItem, [fieldName]: value };
      dataSource.splice(index, 1, newCascadeItem);
      setDataSource(dataSource);
      if (newCascadeItem.fileLabel && newCascadeItem.fileValue) {
        currentQueryField.cascadeMap = {
          ...currentQueryField.cascadeMap,
          [newCascadeItem.fileLabel]: newCascadeItem.fileValue,
        };
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: '查询参数',
      dataIndex: 'fileLabel',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='fileLabel'>
            <Input
              onBlur={(e) => {
                handleCascadeValueChange(
                  'fileLabel',
                  record,
                  e.target.value,
                  index
                );
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '级联字段名',
      dataIndex: 'fileValue',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='fileValue'>
            <Input
              onBlur={(e) => {
                handleCascadeValueChange(
                  'fileValue',
                  record,
                  e.target.value,
                  index
                );
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className='file-edit-panel-content-back'>
      <div className='htd-aside-right-columns-button-wrapper'>
        <Button
          icon={<PlusOutlined />}
          size='middle'
          type='text'
          onClick={handleAddLine}
        >
          添加级联关系
          <Tooltip
            title={
              <div>
                cascadeMap：快码和Lov查询时的级联参数映射，例如：cascadeMap：
                {'{'}parentCodeValue: ‘city’{'}'},
                其中city是当前所在数据源的其他字段名，parentCodeValue是快码和Lov的查询参数。
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://open.hand-china.com/document-center/doc/product/10217/10561?doc_id=157480&doc_code=130503'
                >
                  详情
                </a>
              </div>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Button>
      </div>
      <EditTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        showHeader={Boolean(dataSource?.length)}
      />
    </div>
  );
};

export default RelationAttribute;

import React from 'react';
import { message } from 'choerodon-ui';
import { Button } from 'choerodon-ui/pro';

const PermissionButton = (props) => {
  // console.log(props);
  const { color, children, disabled } = props;
  const handleClick = () => {
    message.info('hzero标准权限按钮，此处仅预览');
  };
  return (
    <Button
      icon={props.buttonIcon}
      onClick={handleClick}
      color={color}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export { PermissionButton as Button };

import { Mapping, ConfigPanelComponentProp } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { Collapse } from 'antd';
import * as uuid from 'uuid';
import { CaretRightOutlined } from '@ant-design/icons';
import { Steps as ProSteps, Step as ProStep } from 'components/placeholders';
import ColumnPanel from './StepsColumnPanel';
import './index.less';

export const Steps: Mapping = {
  name: 'Steps',
  iconCode: 'Steps',
  type: 'container',
  lib: 'choerodon-ui',
  title: '步骤条',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '步骤条',
      label: '标题',
      ignore: true,
    },
    progressDot: {
      label: '样式',
      type: 'collection',
      value: false,
      options: [
        { value: false, label: '默认' },
        { value: true, label: '点状' },
      ],
    },
    rowNum: {
      type: 'number',
      value: 1,
      label: '行数',
      hidden: true,
      ignore: true,
    },
    colNum: {
      type: 'number',
      value: 3,
      label: '步骤条选项数',
      hidden: true,
      ignore: true,
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，步骤条处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    columns: {
      type: 'object',
      label: '步骤条配置',
      hidden: true,
      value: [
        {
          title: '步骤名称',
          description: '操作步骤的具体描述',
          key: 'step1',
          dataIndex: `Step_${uuid.v4().slice(0, 5)}`,
        },
        {
          title: '步骤名称',
          description: '操作步骤的具体描述',
          key: 'step2',
          dataIndex: `Step_${uuid.v4().slice(0, 5)}`,
        },
        {
          title: '步骤名称',
          description: '操作步骤的具体描述',
          key: 'step3',
          dataIndex: `Step_${uuid.v4().slice(0, 5)}`,
        },
      ],
    },
  },
  canDrop(target) {
    return target?.name !== 'Steps' && target?.name !== 'Step';
  },
  onDrop(item) {
    return item?.name === 'Step';
  },
  placeholderComponent: ProSteps,
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    return (
      <Collapse
        defaultActiveKey='1'
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='htd-aside-edit-table-panel'
      >
        <Collapse.Panel key='1' header='步骤项'>
          <ColumnPanel {...props} />
        </Collapse.Panel>
      </Collapse>
    );
  },
  children: [],
};

export const Step: Mapping = {
  name: 'Step',
  title: '步骤条内容',
  type: 'container',
  lib: 'choerodon-ui',
  from: 'Steps.Step',
  iconCode: 'Step',
  fixed: true,
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '步骤条选项',
      label: '标题',
      ignore: true,
    },
  },
  canDrop: () => false,
  placeholderComponent: ProStep as any,
  children: [],
};

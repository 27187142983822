import { FC, useMemo } from 'react';
import {
  PerformanceTable as ProPerformanceTable,
  CheckBox,
  Pagination,
} from 'choerodon-ui/pro';

const PerformanceTable: FC<any> = (props) => {
  const columns = useMemo(() => {
    return props.columns.map((p: any, index: number) => {
      return {
        ...p,
        renderer: undefined,
        flexGrow: index === props.columns.length - 1 ? 1 : 0,
      };
    });
  }, [props.columns]);
  if (props.rowSelect) {
    columns.splice(0, 0, {
      title: <CheckBox />,
      width: 40,
      render: () => <CheckBox />,
    });
  }
  if (props.editor) {
    columns.push({
      title: '编辑',
      width: 80,
      render: () => {
        return <a href=' '>编辑</a>;
      },
    });
  }
  return (
    <>
      <ProPerformanceTable
        rowKey={props.rowKey}
        // 大数据表格不可拖动问题
        // className='htd-placeholder-table'
        columns={columns}
        bordered={props.bordered}
        // // @ts-ignore
        // toolbar={props.toolbar.value}
        data={[{}]}
      />
      {props.query.paging && (
        <div style={{ textAlign: 'right' }}>
          <Pagination
            total={10000}
            page={1}
            pageSize={500}
            pageSizeOptions={['500', '1000', '2000', '5000', '10000']}
          />
        </div>
      )}
    </>
  );
};

export { PerformanceTable };

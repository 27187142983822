import { FC } from 'react';
import { RichText as FormRichText } from 'choerodon-ui/pro';

const RichText: FC<any> = (props) => {
  return (
    <FormRichText defaultValue={props.defaultValue} disabled={props.disabled} />
  );
};

export { RichText };

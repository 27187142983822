/*
 * @Author: wen.chen02@hand-china.com
 */
import { TemplateParamType } from '@htd/common';
import { closeCurrentTab, closePageModal, closeTab, dataSetsSubmit, dataSetsSubmitDomain, dataSetSubmit, goToHzeroTab, goToHzeroTabDomain, openPageModal, } from './events';
const ButtonEvents = [
    {
        code: 'OPEN_HZERO_TAB',
        name: '打开页签',
        describe: '根据路径打开一个新的页签，支持路径参数及查询参数拼接',
        requiredParam: [
            {
                name: '路径',
                index: 'path',
                describe: '如需获得dataSet内部数据，可输入【/xx/xx/:id】后在路径参数进行配置id取值【this.getDs("dsName").current.get("id")】',
                type: TemplateParamType.string,
            },
            {
                name: '页签名称',
                index: 'name',
                type: TemplateParamType.string,
            },
        ],
        otherParam: [
            {
                name: '查询参数',
                index: 'search',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '路径参数',
                index: 'local',
                type: TemplateParamType.keyValueArray,
            },
        ],
        function: goToHzeroTab,
    },
    {
        code: 'OPEN_HZERO_TAB_DOMIAN',
        name: '模型下页面跳转',
        describe: '当前业务模型下多页面之间的跳转配置',
        requiredParam: [
            {
                name: '页签编码',
                index: 'code',
                describe: '设计器内部多页面对应的页面编码',
                type: TemplateParamType.pageList,
            },
            {
                name: '页签路由',
                index: 'path',
                describe: 'Hzero系统中页面访问路径',
                type: TemplateParamType.string,
            },
            {
                name: '页签名称',
                index: 'name',
                describe: 'Hzero系统前端打开时页签的显示名称',
                type: TemplateParamType.string,
            },
        ],
        otherParam: [
            {
                name: '查询参数',
                index: 'search',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '路径参数',
                index: 'local',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '当前页面打开',
                index: 'openInCurrent',
                describe: '默认不勾选，打开新页签',
                type: TemplateParamType.boolean,
            },
        ],
        function: goToHzeroTabDomain,
    },
    {
        code: 'CLOSE_TAB',
        name: '关闭页签',
        requiredParam: [
            {
                name: '路由',
                index: 'path',
                type: TemplateParamType.string,
            },
        ],
        otherParam: [
            {
                name: '路径参数',
                index: 'local',
                type: TemplateParamType.keyValueArray,
            },
        ],
        function: closeTab,
    },
    {
        code: 'CLOSE_CURRENT_TAB',
        name: '关闭当前页签',
        function: closeCurrentTab,
    },
    {
        code: 'FULL_SCREEN_MODEL',
        name: '全屏',
    },
    {
        code: 'REFRESH_PAGE',
        name: '刷新当前页面',
    },
    {
        code: 'DATASET_SUBMIT',
        name: '单数据源提交',
        describe: '触发单一实体的数据提交动作',
        requiredParam: [
            {
                name: '数据源',
                index: 'dataSetName',
                type: TemplateParamType.dataSetName,
            },
        ],
        otherParam: [
            {
                name: '关闭页签',
                index: 'isCloseTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '刷新页签',
                index: 'isRefreshTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '成功提示',
                index: 'successNotice',
                type: TemplateParamType.string,
            },
            {
                name: '失败提示',
                index: 'failedNotice',
                type: TemplateParamType.string,
            },
        ],
        function: dataSetSubmit,
    },
    {
        code: 'DATASETS_SUBMIT',
        name: '提交数据源(多数据源)',
        describe: '根据接口配置及数据源字段映射，触发接口请求',
        requiredParam: [
            {
                name: '接口地址',
                index: 'url',
                describe: '租户信息：{tenantId}，用户信息：{currentUserId}',
                type: TemplateParamType.string,
            },
            {
                name: '接口类型',
                index: 'method',
                describe: 'POST,PUT,DELETE',
                type: TemplateParamType.select,
                defaultValue: 'POST',
                options: ['post', 'put', 'delete'],
            },
            {
                name: '执行对象(数据源)',
                index: 'dataSets',
                describe: 'key代表当前数据源数据解析后的字段映射关系，如不需要映射，可不用填写',
                type: TemplateParamType.dataSetMappingArray,
            },
        ],
        otherParam: [
            {
                name: '关闭页签',
                describe: '提交后是否关闭当前页签',
                index: 'isCloseTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '刷新页签',
                describe: '提交后是否刷新当前页签',
                index: 'isRefreshTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '成功提示',
                describe: '提交成功后显示自定义提示语',
                index: 'successNotice',
                type: TemplateParamType.string,
            },
            {
                name: '失败提示',
                describe: '提交失败后显示自定义提示语',
                index: 'failedNotice',
                type: TemplateParamType.string,
            },
        ],
        function: dataSetsSubmit,
    },
    {
        code: 'DATASETS_SUBMIT_DOMAIN',
        name: '模型下提交数据',
        describe: '当前业务模型下实体数据组合提交',
        requiredParam: [
            {
                name: '数据选择',
                index: 'entitys',
                describe: '选择那些数据需要提交到后端接口',
                type: TemplateParamType.entityMappingArray,
            },
            {
                name: '接口地址',
                index: 'url',
                describe: '租户信息：{tenantId}，用户信息：{currentUserId}',
                type: TemplateParamType.string,
            },
            {
                name: '接口类型',
                index: 'method',
                describe: 'POST,PUT,DELETE',
                type: TemplateParamType.select,
                defaultValue: 'POST',
                options: ['post', 'put', 'delete'],
            },
        ],
        otherParam: [
            {
                name: '关闭页签',
                describe: '提交后是否关闭当前页签',
                index: 'isCloseTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '刷新页签',
                describe: '提交后是否刷新当前页签',
                index: 'isRefreshTab',
                type: TemplateParamType.boolean,
            },
            {
                name: '成功提示',
                describe: '提交成功后显示自定义提示语',
                index: 'successNotice',
                type: TemplateParamType.string,
            },
            {
                name: '失败提示',
                describe: '提交失败后显示自定义提示语',
                index: 'failedNotice',
                type: TemplateParamType.string,
            },
        ],
        function: dataSetsSubmitDomain,
    },
    {
        code: 'OPEN_PAGE_MODAL',
        name: '打开页面弹框',
        describe: '根据弹框组件的key打开弹框',
        requiredParam: [
            {
                name: '选择弹框',
                index: 'modalFlag',
                describe: '需要打开的弹框的组件唯一标识',
                type: TemplateParamType.modal,
            },
        ],
        function: openPageModal,
    },
    {
        code: 'CLOSE_PAGE_MODAL',
        name: '关闭页面弹框',
        describe: '根据弹框组件的key关闭弹框',
        requiredParam: [
            {
                name: '选择弹框',
                index: 'modalFlag',
                describe: '需要关闭的弹框的组件唯一标识',
                type: TemplateParamType.modal,
            },
        ],
        function: closePageModal,
    },
];
export default ButtonEvents;

import React, { FC, useContext } from 'react';
import { RouterContext } from './context';

export interface RouterProps {}

const Router: FC<RouterProps> = () => {
  const { matched } = useContext(RouterContext);

  return <>{matched && React.createElement(matched.component, null)}</>;
};

export { Router };

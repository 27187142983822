import React, {
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  DsProps,
  DsApiProps,
  DsTransportEnum,
  DesignComponent,
} from '@htd/common';
import { Collapse, Popconfirm, Table, Input } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
// import { Table } from 'choerodon-ui';
import { Modal } from 'choerodon-ui/pro';
import { ColumnProps } from 'choerodon-ui/lib/table';
import { FieldProps } from 'choerodon-ui/pro/lib/data-set/Field';
import MyIcon from 'components/myIcon';
import { DATA_HCG_FLAG } from 'utils';
import { cloneDeep } from 'lodash';
import cls from 'classnames';

import { DesignContext } from 'views/Home/DesignContext';
import AddFieldModal from './AddFieldModal';
import './index.less';
import { noDataIcon } from '../../../../../utils/unifiedExportIcon';

interface Props {
  dsConfig: DsProps;
  noDeleteKeyList: string[];
  setNoDeleteKeyList: Function;
}

const Panel = Collapse.Panel;

const DataSetConfig: React.FC<Props> = ({
  dsConfig,
  noDeleteKeyList,
  setNoDeleteKeyList,
}) => {
  const { tree, forceUpdate, onSetting } = useContext(DesignContext);
  const { fields, queryFields } = useMemo(
    () => ({ fields: dsConfig.fields, queryFields: dsConfig.queryFields }),
    [dsConfig.queryFields, dsConfig.fields]
  );
  // 保存 当前打开的折叠面板key数据
  const [isActiveList, setActiveList] = useState(['apis']);

  const setFields = useCallback(
    (list) => {
      dsConfig.fields = cloneDeep(list);
      forceUpdate?.();
    },
    [dsConfig, forceUpdate]
  );

  const setQueryFields = useCallback(
    (list) => {
      dsConfig.queryFields = cloneDeep(list);
      forceUpdate?.();
    },
    [dsConfig, forceUpdate]
  );
  const apiList = useMemo(() => {
    if (dsConfig.transport) {
      return Object.keys(dsConfig.transport).map((item) => {
        const data = (dsConfig.transport as any)[item];
        return {
          ...data,
          type: item,
          name: (DsTransportEnum as any)[item],
        };
      });
    } else {
      return [];
    }
  }, [dsConfig.transport]);
  const setApiList = useCallback(
    (list: DsApiProps[]) => {
      const transport: any = {};
      list.forEach((item) => {
        const { type, name, ...data } = item;
        transport[type!] = data;
      });
      dsConfig.transport = cloneDeep(transport);
      forceUpdate?.();
    },
    [dsConfig, forceUpdate]
  );

  const connectComp = useMemo(() => {
    const compList: DesignComponent[] = [];
    const findComp = (children: DesignComponent[]) => {
      children.forEach((child) => {
        if (child.dsKey === dsConfig.dsKey) {
          compList.push(child);
        }
        if (child.children && child.children.length) {
          findComp(child.children);
        }
      });
    };
    findComp(tree.children);
    return compList;
  }, [dsConfig.dsKey, tree]);

  useEffect(() => {
    const set = new Set(noDeleteKeyList);
    if (connectComp.length) {
      set.add(dsConfig.dsKey);
    } else {
      set.delete(dsConfig.dsKey);
    }
    setNoDeleteKeyList(Array.from(set));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectComp.length, dsConfig.dsKey]);

  /**
   * 添加API
   * @param list
   * @param newFields
   */
  const handleAddApi = (
    list: DsApiProps[],
    _: any,
    newFields?: FieldProps[]
  ) => {
    setApiList([...apiList, ...list]);
    if (newFields) {
      const addFields = newFields.filter(
        (nf) => fields?.findIndex((f) => f.name === nf.name) === -1
      );
      setFields([...(fields || []), ...addFields]);
    }
  };

  /**
   * 编辑API
   * @param api
   * @param fields
   */
  const handleEditApi = (api: DsApiProps, fields?: FieldProps[]) => {
    setApiList([
      ...apiList.map((item) => (item.type === api.type ? api : item)),
    ]);
    if (api.type === 'read' && fields) {
      setFields(fields);
    }
  };

  /**
   * 点击添加API
   */
  const handleClickAddApi = () => {
    Modal.open({
      title: '添加API',
      children: (
        <AddFieldModal currentApiList={apiList} onConfirm={handleAddApi} />
      ),
      className: 'htd-add-field-modal',
    });
  };

  /**
   * 点击编辑
   * @param api
   */
  const handleClickEditApi = (api: DsApiProps) => {
    Modal.open({
      title: '编辑API',
      children: (
        <AddFieldModal
          currentApiList={apiList}
          editApi={api}
          onConfirm={handleEditApi}
        />
      ),
      className: 'htd-add-field-modal',
    });
  };

  /**
   * 点击删除API
   * @param _api
   * @param index
   */
  const handleClickDeleteApi = (_api: DsApiProps, index: number) => {
    apiList.splice(index, 1);
    setApiList([...apiList]);
  };

  /**
   * 删除字段
   */
  const handleDeleteField = (record: any, index: number) => {
    fields?.splice(index, 1);
    setFields([...fields!]);

    // 删除关联表格的列
    connectComp.forEach((comp) => {
      if (comp.name === 'Table' && comp.props?.columns?.value) {
        comp.props.columns.value = comp.props.columns.value.filter(
          (c: any) => c.name !== record.name
        );
        forceUpdate?.(comp.id);
      }
    });
  };

  /**
   * 删除查询字段
   */
  const handleDeleteQueryField = (_: any, index: number) => {
    queryFields?.splice(index, 1);
    setQueryFields([...queryFields!]);
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '标题',
      dataIndex: 'label',
      key: 'label',
      width: 100,
      align: 'left',
      render: (value) => {
        return <Input value={value} disabled />;
      },
    },
    {
      title: '字段名',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'left',
      render: (value) => {
        return <Input value={value} disabled />;
      },
    },
    // {
    //   title: '主键',
    //   dataIndex: 'primaryKey',
    //   key: 'primaryKey',
    //   align: 'center',
    //   render: (value) => {
    //     return <Checkbox value={value} />;
    //   },
    // },
    // {
    //   title: '外键',
    //   dataIndex: 'foreignKey ',
    //   key: 'foreignKey',
    //   align: 'center',
    //   render: (value) => {
    //     return <Checkbox value={value} />;
    //   },
    // },
    {
      align: 'center',
      width: 30,
      render: (_text, record, index) => {
        return (
          <Popconfirm
            title='确认删除？'
            onConfirm={handleDeleteField.bind(this, record, index)}
            okText='确认'
            cancelText='取消'
            className='asd'
          >
            {/* <Icon type='delete' /> */}
            <MyIcon type='icon-a-delete2x' style={{ color: '#999' }} />
          </Popconfirm>
        );
      },
    },
  ];

  const queryFieldsColumns: ColumnProps<any>[] = [
    {
      title: '标题',
      dataIndex: 'label',
      key: 'label',
      width: 100,
      align: 'left',
      render: (value) => {
        return <Input value={value} disabled />;
      },
    },
    {
      title: '字段名',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value) => {
        return <Input value={value} disabled />;
      },
    },
    {
      width: 30,
      align: 'center',
      render: (_text, record, index) => {
        return (
          <Popconfirm
            title='确认删除？'
            onConfirm={handleDeleteQueryField.bind(this, record, index)}
            okText='确认'
            cancelText='取消'
            className='asd'
          >
            {/* <Icon type='delete' /> */}
            <MyIcon type='icon-a-delete2x' style={{ color: '#999' }} />
          </Popconfirm>
        );
      },
    },
  ];

  const renderConnectComp = () => {
    if (!connectComp.length) {
      return <span style={{ color: '#525A6E' }}>无</span>;
    }
    return connectComp.map((comp, index) => (
      <span key={index}>
        <span onClick={() => onSetting?.(comp)}>
          {comp.props![DATA_HCG_FLAG].value}
        </span>
        {index !== connectComp.length - 1 && '、'}
      </span>
    ));
  };

  const renderApiList = () => {
    return apiList.map((item, index) => (
      <div className='htd-ds-config-api-row' key={item.type}>
        <div className='htd-ds-config-api-row-left'>
          <span className='htd-ds-config-api-name'>{item.name}</span>
          <span
            className={`htd-ds-config-api-method htd-ds-config-api-method-${item.method}`}
            title={item.method}
          >
            {item.method}
          </span>
          <span className='htd-ds-config-api-url' title={item.url}>
            {item.url}
          </span>
        </div>
        <div className='htd-ds-config-api-row-right'>
          <MyIcon
            type='icon-a-edit2x'
            className='left-svg-cla'
            onClick={handleClickEditApi.bind(this, item)}
          />
          <MyIcon
            type='icon-a-delete2x'
            className='left-svg-cla'
            onClick={handleClickDeleteApi.bind(this, item, index)}
          />
        </div>
      </div>
    ));
  };
  // 保存 当前打开的折叠面板key数据
  const handleOnChangeFunc = (key: any) => {
    setActiveList(key);
  };

  return (
    <>
      <span className='render-top-span'>
        <span className='render-top-span-title'>关联组件</span>
        {renderConnectComp()}
      </span>
      <Collapse
        className='htd-ds-config-collapse query-api-table-no-data-box'
        bordered={false}
        defaultActiveKey={['apis']}
        expandIconPosition='right'
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={handleOnChangeFunc}
      >
        <Panel key='apis' header='API列表' className='htd-ds-config-top-border'>
          <div className='htd-ds-config-back'>
            {renderApiList()}
            <div className='t-j-api-box' onClick={handleClickAddApi}>
              <MyIcon type='icon-a-tianjia2x' />
              <span style={{ marginLeft: '2px' }}>添加API</span>
            </div>
            {/* <Button
              icon={<MyIcon type='icon-a-tianjia2x' className='left-svg-cla' />}
              // @ts-ignore
              funcType={FuncType.link}
              color={ButtonColor.primary}
              size={Size.small}
              onClick={handleClickAddApi}
              className='htd-ds-config-but'
            >
              添加API
            </Button> */}
          </div>
        </Panel>
        {/* 当前折叠面板被打开 并且 其下表格无数据 时  去掉折叠面板下边框 */}
        <Panel
          key='fields'
          header='字段列表'
          className={cls({
            'no-data':
              isActiveList.includes('fields') && (!fields || !fields.length),
          })}
        >
          {fields && fields.length ? (
            <Table
              className='htd-ds-config-field htd-aside-left-fields-table'
              //@ts-ignore
              columns={columns}
              dataSource={fields}
              pagination={false}
              filterBar={false}
              scroll={{ y: 240, x: 350 }}
              empty={'暂无字段' as any}
              rowKey='name'
              // showHeader={Boolean(fields?.length)}
            />
          ) : (
            <div className='table-null-left-box'>
              <img alt='No data' src={noDataIcon} />
              <span>暂无数据</span>
            </div>
          )}
        </Panel>
        <Panel
          key='queryFields'
          header='查询字段列表'
          style={{ paddingTop: '2px' }}
          className={cls({
            'no-data':
              isActiveList.includes('queryFields') &&
              (!queryFields || !queryFields.length),
          })}
        >
          {queryFields && queryFields.length ? (
            <Table
              className='htd-ds-config-field htd-aside-left-fields-table'
              //@ts-ignore
              columns={queryFieldsColumns}
              dataSource={queryFields}
              pagination={false}
              filterBar={false}
              scroll={{ y: 240 }}
              empty={'暂无字段' as any}
              rowKey='name'
              // showHeader={Boolean(queryFields?.length)}
            />
          ) : (
            <div className='table-null-left-box'>
              <img alt='No data' src={noDataIcon} />
              <span>暂无数据</span>
            </div>
          )}
        </Panel>
        {/* 给最后一个 加一个after */}
        {/* <div style={{ height: '20px' }}></div> */}
      </Collapse>
    </>
  );
};

export default DataSetConfig;

/*
 * @Author: your name
 * @Date: 2021-11-14 22:59:11
 * @LastEditTime: 2022-04-28 14:49:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /hzero-code-generator/packages/designer/src/views/Home/pageInfoDS.ts
 */
import { FieldIgnore, FieldType } from 'choerodon-ui/pro/lib/data-set/enum';

const pageInfoDS = () => ({
  fields: [
    { name: 'intlPrefix', label: '多语言前缀', require: false },
    { name: 'pageName', label: '页面名称', required: true },
    {
      name: 'pageCode',
      label: '页面编码',
      required: true,
      pattern: '^[a-zA-Z][a-zA-Z0-9_]+$',
      defaultValidationMessages: {
        patternMismatch: '只支持字母和数字并且必须以字母开头，可包含“_”',
      },
    },
    {
      name: 'routerPath',
      label: '路由地址',
      required: true,
      pattern: '^[/][a-zA-Z0-9//:_-]+$',
      defaultValidationMessages: {
        patternMismatch:
          '只支持字母和数字并且必须以/开头，可包含“【:、_、-、/】”',
      },
    },
    {
      name: 'pagePath',
      label: '代码路径',
      required: true,
      pattern: '^[A-Z][a-zA-Z0-9]+$',
      defaultValidationMessages: {
        patternMismatch: '只支持字母和数字并且必须以大写字母开头',
      },
    },
    {
      name: 'pageTemplateLov',
      label: '模板名称',
      type: FieldType.object,
      // lovCode: 'HCGR.PAGE_TEMPLATE',
      textField: 'functionName',
      ignore: FieldIgnore.always,
      required: false,
    },
    {
      name: 'pageTemplateId',
      label: '模板Id',
      bind: 'pageTemplateLov.id',
      required: false,
    },
  ],
  events: {
    update: ({ record, name, value }: any) => {
      if (name === 'pageCode') {
        const re = /_(\w)/g;
        const pagePathValue = value.replace(re, function ($0: any, $1: any) {
          return $1.toUpperCase();
        });
        record.set('routerPath', `/${value.replace(/_/g, '-')}`);
        record.set(
          'pagePath',
          pagePathValue.slice(0, 1).toUpperCase() + pagePathValue.slice(1)
        );
      }
    },
  },
});

export default pageInfoDS;

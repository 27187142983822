import { useStore } from '@/store';
// import { renameLocalBlockEffect } from '@/store/reducers/block';
import { saveInlineBlock } from '@/services/block-service';
import { Form, Input, Modal, message } from 'antd';
import { ObjectType } from '@htd/common';
import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export interface RenameModalProps {
  visible?: boolean;
  onClose?: Function;
  fetchInlineBlockListEffect?: Function;
  block?: any;
}

const RenameModal: FC<RenameModalProps> = ({
  visible,
  onClose,
  block = {},
  fetchInlineBlockListEffect,
}) => {
  const [formValue, setFormValue] = useState('');
  const [form] = Form.useForm();
  const { state } = useStore((state) => state.block);
  const localList: ObjectType<any[]> = state.local || {};
  const handleAddCategory = async () => {
    await saveInlineBlock({
      name: formValue,
      id: block.id,
      // type: block.category,
      _token: block._token,
      objectVersionNumber: block.objectVersionNumber,
    });
    form.resetFields();
    // console.log(block);

    fetchInlineBlockListEffect?.({
      categoryId: block.categoryId,
    });
    onClose?.();
    message.success('修改区块成功');
  };
  useEffect(() => {
    // console.log(block?.name);
    if (visible) {
      form.setFieldsValue({ name: block?.name });
    }
  }, [block, form, visible]);
  return (
    <Modal
      visible={visible}
      onOk={handleAddCategory}
      onCancel={() => onClose?.()}
      title='区块重命名'
      width={500}
      okButtonProps={{
        // disabled: !formValue || state.localCategoryList.includes(formValue),
        disabled:
          !formValue ||
          localList[block?.category]
            ?.map((ele: any) => ele?.name)
            .includes(formValue),
      }}
    >
      <Form form={form}>
        <Form.Item
          label='输入区块名'
          name='name'
          initialValue={formValue}
          rules={[{ required: true, message: '区块名为必填项' }]}
        >
          <Input onChange={(v) => setFormValue(v.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameModal;

/*
 * @Author: your name
 * @Date: 2021-11-23 15:28:27
 * @LastEditTime: 2022-01-19 11:29:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \hzero-code-generator\packages\designer\src\mapping\other\WorkFlowButton\ConfigModal.tsx
 */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { DataSet, Form, Lov } from 'choerodon-ui/pro';
import { Form as AntdForm } from 'antd';
import VariableInput from 'components/VariableInput';
import { FieldIgnore, FieldType } from 'choerodon-ui/pro/lib/data-set/enum';
import { axios } from 'utils';
import './index.less';

const WorkFlowConfigModal: React.FC<any> = ({ ok, initData, modal, tree }) => {
  const [processParams, setprocessParams] = useState<any>([]);
  const [form] = AntdForm.useForm();

  useEffect(() => {
    if (initData && initData.processParams) {
      setprocessParams(initData.processParams.config);
      form.setFieldsValue(initData.processParams.value);
    }
    return () => {
      setprocessParams([]);
    };
  }, [initData, form]);

  const dataSet = useMemo(
    () =>
      new DataSet({
        autoCreate: true,
        fields: [
          {
            name: 'processDefinition',
            label: '流程定义',
            lovCode: 'HWFP.PROCESS_DEFINITION',
            type: FieldType.object,
            required: true,
            ignore: FieldIgnore.always,
          },
          {
            name: 'processCode',
            bind: 'processDefinition.key',
            defaultValue: initData?.processData?.processCode,
          },
          {
            name: 'processName',
            bind: 'processDefinition.name',
            defaultValue: initData?.processData?.processName,
          },
          {
            name: 'tenantId',
            defaultValue: 0,
          },
        ],
        events: {
          update: ({ name, value }: { name: string; value: any }) => {
            if (name === 'processDefinition' && value) {
              getProcessParam(value.key).then((data) => {
                if (data instanceof Array) {
                  setprocessParams(data);
                }
              });
            }
          },
        },
      }),

    [initData]
  );

  const handleSetConfig = useCallback(() => {
    ok(
      {
        processData: dataSet.current?.toJSONData(),
        processParams: { config: processParams, value: form.getFieldsValue() },
      },
      modal
    );
  }, [dataSet, form, modal, ok, processParams]);

  modal.handleOk(handleSetConfig);
  return (
    <>
      <Form dataSet={dataSet}>
        {/* <Lov name='employeeObj' /> */}
        {/* <TextField name='businessKey' /> */}
        <Lov name='processDefinition' />
      </Form>
      <ProcessParamsForm
        processParams={processParams}
        form={form}
        tree={tree}
      />
    </>
  );
};

export default WorkFlowConfigModal;

export async function getProcessParam(processDefinitionKey: string) {
  const url = `/hwfp/v1/0/process/instance/variable?processDefinitionKey=${processDefinitionKey}&tenantId=0`;
  return axios.get(url, {
    params: '',
  });
}

const ProcessParamsForm: React.FC<any> = ({ processParams, form, tree }) => {
  return (
    <div className='wfb-config-modal-antdform'>
      <AntdForm form={form}>
        <AntdForm.Item
          required
          label='业务主键'
          name='businessKey'
          rules={[{ required: true, message: `业务主键必填` }]}
        >
          <VariableInput globalDsConfig={tree.dsConfig} />
        </AntdForm.Item>

        {processParams.map((p: any) => {
          return (
            <AntdForm.Item
              required={p.requiredFlag === 1}
              label={p.name}
              name={p.name}
              rules={[{ required: true, message: `${p.description}必填` }]}
            >
              <VariableInput globalDsConfig={tree.dsConfig} />
            </AntdForm.Item>
          );
        })}
      </AntdForm>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regxReplaceVariable = exports.regxReplaceLocalVariable = exports.strTemplateVariables = exports.templateVariables = void 0;
exports.templateVariables = {
    '{organizationId}': {
        literal: '${this.getCurrentTenant().tenantId}',
        name: '租户ID',
        code: 'this.getCurrentTenant().tenantId',
        usage: "\n      <p>\n       <span class=\"code\">this.getCurrentTenant()</span>\u53EF\u83B7\u53D6\u5F53\u524D\u7CFB\u7EDF\u79DF\u6237\u4FE1\u606F\uFF0C\u51FD\u6570\u8FD4\u56DE\n       <span class=\"code\">tenantId,tenantName</span>\n      </p>\n    ",
    },
    '{currentUserId}': {
        literal: '${this.getCurrentUser().id}',
        name: '用户ID',
        code: 'this.getCurrentUser().id',
        usage: "\n      <p>\n        <span class=\"code\">this.getCurrentUser()</span>\u53EF\u83B7\u53D6\u5F53\u524D\u7CFB\u7EDF\u767B\u5F55\u7528\u6237\u4FE1\u606F\uFF0C\u51FD\u6570\u8FD4\u56DE<span class=\"code\">id, realName, loginName</span>\n      </p>\n    ",
    },
    '{currentUserRealName}': {
        literal: '${this.getCurrentTenant().realName}',
        name: '用户名',
        code: 'this.getCurrentTenant().realName',
        usage: "\n      <p>\n        <span class=\"code\">this.getCurrentUser()</span>\u53EF\u83B7\u53D6\u5F53\u524D\u7CFB\u7EDF\u767B\u5F55\u7528\u6237\u4FE1\u606F\uFF0C\u51FD\u6570\u8FD4\u56DE<span class=\"code\">id, realName, loginName</span>\n      </p>\n    ",
    },
    '{currentUserLoginName}': {
        literal: '${this.getCurrentUser().loginName}',
        name: '用户登录名',
        code: 'this.getCurrentUser().loginName',
        usage: "\n      <p>\n        \u53EF\u83B7\u53D6\u5F53\u524D\u7CFB\u7EDF\u767B\u5F55\u7528\u6237\u4FE1\u606F\uFF0C\u51FD\u6570\u8FD4\u56DE<span class=\"code\">id, realName, loginName</span>\n      </p>\n    ",
    },
    '{systemLang}': {
        literal: '${this.getSystem().lang}',
        name: '系统语言',
        code: 'this.getSystem().lang',
        usage: "\n      <p>\n        <span class=\"code\">this.getSystem()</span>\u53EF\u83B7\u53D6\u5F53\u524D\u7CFB\u7EDF\u57FA\u672C\u4FE1\u606F\uFF0C\u51FD\u6570\u8FD4\u56DE<span class=\"code\">lang</span>\n      </p>\n    ",
    },
};
/**
 * 预定义字符串变量模板
 */
exports.strTemplateVariables = {
    '{organizationId}': '${this.getCurrentTenant().tenantId}',
    '{tenantId}': '${this.getCurrentTenant().tenantId}',
    '{currentUserId}': '${this.getCurrentUser().id}',
    '{currentUserRealName}': '${this.getCurrentUser().realName}',
    '{currentUserLoginName}': '${this.getCurrentUser().loginName}',
    '{systemLang}': '${this.getSystem().lang}',
};
/**
 * 正则表达式替换本地预定义字符串变量
 */
function regxReplaceLocalVariable(str) {
    var res = str;
    Object.keys(exports.strTemplateVariables).forEach(function (k) {
        var regx = RegExp(k);
        if (regx.test(str)) {
            res = str.replace(regx, exports.strTemplateVariables[k]);
        }
    });
    return res;
}
exports.regxReplaceLocalVariable = regxReplaceLocalVariable;
/**
 * 正则表达式替换字符串变量
 * @param {string} str 原始字符串
 * @param {string} findStr 目标字符串
 * @param {string} repStr 替换字符串
 */
function regxReplaceVariable(str, findStr, rep) {
    var repStr = rep;
    if (rep && typeof rep !== 'string' && rep.__isVariable) {
        repStr = rep.value;
    }
    var res = str;
    var regxStr = "{" + findStr + "}";
    var tarStr = "${" + repStr + "}";
    var regx = RegExp(regxStr);
    if (regx.test(str)) {
        res = str.replace(regx, tarStr);
    }
    // 支持/xxx/xxx/:xx匹配替换
    var regx2 = RegExp(":" + findStr);
    if (regx2.test(str)) {
        res = str.replace(regx2, tarStr);
    }
    return res;
}
exports.regxReplaceVariable = regxReplaceVariable;

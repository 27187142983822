import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { OptionsType } from '@htd/common';
import { Input, Radio, Select, Slider, Switch, AutoComplete } from 'antd';
import { InputNumber } from 'choerodon-ui';
import { isFunction } from 'lodash';
import { DataSet, Lov } from 'choerodon-ui/pro';
import { FieldType } from 'choerodon-ui/pro/lib/data-set/enum';
import TextArea from 'antd/lib/input/TextArea';
// import VariableInput from 'components/VariableInput';
import { DesignContext } from 'views/Home/DesignContext';
import SelectRightImg from 'components/selectRightImg';
import { cloneDeep } from 'lodash';

const { Group: RadioGroup } = Radio;

export interface PropFieldProps {
  type: any;
  name?: string;
  label?: string | ReactNode;
  disabled?: boolean;
  options?: OptionsType[];
  defaultComponent?: () => React.ReactElement;
  onChange?: (v: any) => any;
  value?: any;
  min?: number;
  max?: number;
  allValue?: any;
  topOnChange?: (v: any) => any;
  parentItem?: any;
}

const PropField: FC<PropFieldProps> = (props) => {
  const { lovData, backendConfig, tree } = useContext(DesignContext);
  const {
    type,
    onChange,
    name,
    label,
    value,
    disabled,
    options,
    defaultComponent,
    min,
    max,
    allValue,
    topOnChange,
    parentItem,
  } = props;
  const [isOptions, setOptions] = useState<
    { value: string; meaning: string; fieldKey: string }[]
  >([]);
  const [isLastingOptions, setLastingOptions] = useState<
    { value: string; meaning: string; fieldKey: string }[]
  >([]);

  useEffect(() => {
    const currentBackendConfig: any = Object.values(backendConfig)?.[0];
    const entitys = currentBackendConfig?.entitys || {};
    const currentData = Object.values(entitys) || [];
    let totalArr: any = [];
    // 保存fieldKey
    let totalArrFieldKey: any = [];
    currentData.forEach((item: any) => {
      totalArr.forEach((tf: any) => {
        if (tf.fieldKey) {
          totalArrFieldKey.push(tf.fieldKey);
        }
      });
      const { column = [] } = item;
      if (column.length) {
        column.forEach((cfn: any) => {
          if (!totalArrFieldKey.includes(cfn.fieldKey)) {
            totalArr.push(cfn);
          }
        });
        // totalArr = totalArr.concat(item.column);
      }
    });
    const fieldsList: any = Array.from(new Set(totalArr));
    const fieldsDataList = fieldsList.map((R: any) => {
      return {
        value: R.fieldCodeJava,
        meaning: R.fieldName,
        fieldKey: R.fieldKey,
      };
    });
    if (fieldsDataList.length) {
      setOptions(fieldsDataList);
      setLastingOptions(fieldsDataList);
    } else {
      setOptions([]);
      setLastingOptions([]);
    }
  }, [backendConfig]);

  const commonProps: any = {
    name,
    label,
    value,
    disabled,
  };

  if (defaultComponent) {
    if (isFunction(defaultComponent)) {
      const comp = defaultComponent();
      return React.cloneElement(comp, {
        ...commonProps,
        allValue,
        onChange,
      });
    }
  }

  // 输入的时候
  const onSearch = (searchText: string) => {
    const isOptionsData = cloneDeep(isLastingOptions);
    onChange?.(searchText);
    if (searchText) {
      const currentOpData = isOptionsData.filter(
        (Res: any) => Res.value.indexOf(searchText) > -1
      );
      setOptions(currentOpData);
    } else {
      setOptions(isOptionsData);
    }
  };

  const onChangeFunc = (data: string) => {
    if (data) {
      const currentMeaning = isOptions.find((res: any) => res.value === data);
      const cuAllValue = cloneDeep(allValue);
      const dsConfig = tree.dsConfig.find(
        (i) => parentItem && parentItem.dsKey && i.dsKey === parentItem.dsKey
      );
      if (dsConfig) {
        const fields = dsConfig.fields || [];
        const cuField: any = fields.find(
          (item: any) => cuAllValue.name && cuAllValue.name === item.name
        );
        if (cuField) {
          cuField.fieldKey = currentMeaning?.fieldKey || allValue.fieldKey;
          if (cuField.props && cuField.props.fieldKey) {
            cuField.props.fieldKey.value =
              currentMeaning?.fieldKey || allValue.fieldKey;
          }
        }
      }
      allValue.label = currentMeaning?.meaning || allValue.label;
      allValue.name = currentMeaning?.value || allValue.name;
      allValue.fieldKey = currentMeaning?.fieldKey || allValue.fieldKey;
      topOnChange?.(allValue);
      // onChange?.(data);
    }
  };

  switch (type) {
    case 'object': {
      const objectOnChange = (v: any) => {
        try {
          onChange?.(JSON.parse(v));
        } catch (e) {}
      };
      return (
        <TextArea
          {...commonProps}
          onChange={objectOnChange}
          value={JSON.stringify(value)}
        />
      );
    }
    case 'number': {
      return (
        <InputNumber
          min={min}
          max={max}
          step={1}
          {...commonProps}
          onChange={onChange}
        />
      );
    }
    case 'number-range': {
      return (
        <Slider
          min={min}
          max={max}
          step={1}
          {...commonProps}
          onChange={onChange}
        />
      );
    }
    case 'boolean': {
      const { value, ...otherProps } = commonProps;

      return (
        <>
          <Switch {...otherProps} checked={!!value} onChange={onChange} />
        </>
      );
    }
    case 'collection': {
      return (options?.length || 0) <= 4 ? (
        <RadioGroup
          optionType='button'
          options={options}
          {...commonProps}
          onChange={onChange}
        />
      ) : (
        <Select
          allowClear
          {...commonProps}
          options={options}
          onChange={onChange}
          suffixIcon={<SelectRightImg />}
        />
      );
    }
    case 'collection-select': {
      return (
        <Select
          allowClear
          {...commonProps}
          options={options}
          onChange={onChange}
          suffixIcon={<SelectRightImg />}
        />
      );
    }
    case 'string': {
      // @ts-ignore
      if (props?.id === 'name') {
        return (
          <AutoComplete
            // @ts-ignore
            options={isOptions}
            onSelect={onChangeFunc}
            onSearch={onSearch}
            // onChange={autoCompleteOnChange}
            {...commonProps}
            defaultValue={allValue?.name}
          />
        );
      }
      return <Input {...commonProps} onChange={onChange} />;
    }
    // case 'array': {
    //   return (
    //     <ArrayField {...commonProps} type={type} onChange={onChange as any} />
    //   );
    // }
    case 'lov': {
      const lovDS = new DataSet({
        data: lovData,
        fields: [
          {
            name: 'lovCode',
            label: '值集编码',
            type: 'string' as FieldType,
          },
          {
            name: 'viewName',
            label: '视图名称',
            type: 'string' as FieldType,
          },
        ],
      });
      return <Lov dataSet={lovDS} name='lovCode' />;
    }
    // case 'hidden': {
    //   return (
    //     <VariableInput globalDsConfig={tree.dsConfig} onChange={onChange} {...props}>
    //       <Switch checked={!!props.value} />
    //     </VariableInput>
    //   )
    // }
    default: {
      return <Input {...commonProps} onChange={onChange} />;
    }
  }
};

export default PropField;

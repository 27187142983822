import { Modal } from 'choerodon-ui/pro';
import { Button as ButtonPlaceholder } from 'components/placeholders';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { Collapse, Button } from 'antd';
import { ConfigPanelComponentProp, DesignConfig, Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { IconPicker } from 'choerodon-ui/pro';
import WorkFlowConfigModal from './ConfigModal';

export const WorkFlowButton: Mapping = {
  name: 'Button',
  title: '工作流按钮',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  iconCode: 'button',
  props: {
    ...BASE_PROPS_TYPES,
    children: {
      type: 'string',
      value: `工作流按钮${HTD_PROPS_HOLDER}`,
      label: '标题',
    },
    color: {
      type: 'collection',
      value: 'default',
      label: '按钮类型',
      options: [
        { value: 'primary', label: '主按钮' },
        { value: 'default', label: '次按钮' },
      ],
    },
    style: {
      type: 'number',
      value: 111,
      label: '宽度',
    },
    icon: {
      type: 'string',
      value: undefined,
      label: '按钮图标',
      defaultComponent: () => <IconPicker />,
    },
    disabled: {
      type: 'boolean',
      value: false,
      label: '禁用',
      showHelp: true,
      help: '当条件达成时，按钮处于禁用状态',
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      ),
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，按钮处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      ),
      label: '隐藏',
    },
    permissionCode: {
      type: 'string',
      value: undefined,
      label: '权限编码',
      showHelp: true,
      help: '融合hzero按钮权限设计，可在菜单权限集配置显示禁用模式，此处填写权限编码',
    },
    // 工作流流程配置属性
    processConfig: {
      type: 'object',
      label: '',
      hidden: true,
      ignore: true,
      value: {},
    },
    workFlowButtonFlag: {
      type: 'boolean',
      label: '',
      hidden: true,
      ignore: true,
      value: true,
    },
  },
  placeholderComponent: ButtonPlaceholder,
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    const { item, forceUpdate } = props;
    const handleWorkFlowConfigModalOk = (data: any, modal: any) => {
      if (item.props) {
        item.props.processConfig.value = data;
      }
      modal.close();
      forceUpdate?.();
    };
    return (
      <Collapse defaultActiveKey={1}>
        <Collapse.Panel key='1' header='工作流设置'>
          <div className='htd-aside-right-columns-button-wrapper'>
            <Button
              // icon={<PlusOutlined />}
              size='middle'
              type='text'
              onClick={() =>
                openWorkFlowConfigModal({
                  ok: handleWorkFlowConfigModalOk,
                  initData: item.props?.processConfig.value,
                  tree: props.tree,
                })
              }
            >
              配置
            </Button>
          </div>
          {item.props?.processConfig.value.processData?.processName && (
            <div>
              当前配置
              <br />
              <div>
                {item.props.processConfig.value.processData?.processName}
              </div>
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
    );
  },
  children: [],
};

export function openWorkFlowConfigModal({
  ok,
  initData,
  tree,
}: {
  ok: (data: any, modal: any) => void;
  initData?: any;
  tree: DesignConfig;
}) {
  Modal.open({
    title: '配置工作流流程',
    children: <WorkFlowConfigModal ok={ok} initData={initData} tree={tree} />,
    okText: '确定',
  });
}

/*
 * @Author: wen.chen02@hand-china.com
 * @Description: 多语言配置生成工具包
 */

import { ObjectType, PropTypesObj } from '@htd/common';

/**
 * key: 组件名称, values: 组件需要录入的文本属性名
 */
const useMultilingualMap: ObjectType<string[]> = {
  Header: ['title'],
  Button: ['children'],
  TabPane: ['tab'],
  Panel: ['header'],
  ExcelExport: ['buttonText', 'title'],
  Card: ['title'],
};
/**
 * 是否使用多语言输入框
 */
export function shouldUseMultilingualCode(
  componentName: string,
  propsName: string,
  configTypes: PropTypesObj,
  pl: any[]
) {
  const name = `${propsName}MultilingualCode`;
  if (!configTypes[name] && isMultilingualProps(componentName, propsName)) {
    const label = `${configTypes[propsName].label}多语言`;
    pl.push({
      type: 'string',
      name,
      label,
    });
  }
}

export function isMultilingualProps(componentName: string, propsName: string) {
  const config = useMultilingualMap[componentName];
  if (config && config.findIndex((c) => c === propsName) !== -1) {
    return true;
  }
  return false;
}

import React, { useContext, useEffect, useMemo } from 'react';
import { Icon } from 'choerodon-ui';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import { Collapse, Form, Input } from 'antd';
import { IconPicker } from 'choerodon-ui/pro';
import CommonActionPanel from 'components/CommonActionPanel';
import { DATA_HCG_FLAG } from 'utils';
import LineEvents from '@htd/helper/lib/templates/line-events';
import { capitalize } from 'lodash';
import { CaretRightOutlined } from '@ant-design/icons';
import '../index.less';

const EditPanel: React.FC = () => {
  const { tree, settingItem, forceUpdate } = useContext(DesignContext);
  const { configItem, tableColumns, onSettingConfig } =
    useContext(ConfigContext);

  const [form] = Form.useForm();

  // 动作列表
  const eventList = useMemo(() => {
    return LineEvents.filter((i) => i.code && i.name && i.function);
  }, []);

  // 操作列
  const operatorCol = tableColumns.length
    ? tableColumns[tableColumns.length - 1]
    : undefined;

  // 当前操作按钮
  const currentCommand = (operatorCol?.command as any[])?.[configItem!.index];

  // 当前操作按钮属性
  const currentCommandOption = currentCommand?.[1];

  // 表单初始值
  const initialValues = {
    ...currentCommandOption,
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    onSettingConfig?.(undefined);
  };

  /**
   * 表单值变更
   * @param changedValues
   */
  const handleChangeValues = (changedValues: any) => {
    if (currentCommandOption) {
      for (const key in changedValues) {
        const value = changedValues[key];
        currentCommandOption[key] = value;
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  /**
   * 确定动作
   * @param e
   */
  const handleActionOk = (e: any) => {
    if (currentCommandOption && e) {
      currentCommandOption[e.eventName] = { ...e.eventValue };
      forceUpdate?.(settingItem?.id);
    }
  };

  /**
   * 删除动作
   */
  const handleDeleteAction = (eventName: string) => {
    if (currentCommandOption) {
      currentCommandOption[eventName] = undefined;
      forceUpdate?.(settingItem?.id);
    }
  };

  /**
   * 获取默认动作参数
   */
  const getEventProps = () => {
    if (currentCommandOption && currentCommandOption.onClick) {
      return {
        [`${capitalize(currentCommandOption.key?.replace('-', ''))}_onClick`]: {
          value: {
            eventCode: currentCommandOption.onClick?.eventCode,
            eventName: currentCommandOption.onClick?.eventName,
            eventParam: currentCommandOption.onClick?.eventParam,
          },
        },
      };
    }
    return {};
  };

  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header className='htd-aside-right-header'>
        <span>自定义按钮属性</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main className='htd-aside-right-main' style={{ paddingTop: 8 }}>
        <Form
          form={form}
          initialValues={initialValues}
          labelAlign='left'
          onValuesChange={handleChangeValues}
          className='base-box operator-column-panel'
        >
          <Form.Item label='按钮标题' name='children'>
            <Input />
          </Form.Item>
          <Form.Item label='按钮图表' name='icon'>
            <IconPicker />
          </Form.Item>
          <Form.Item label='权限编码' name='permissionCode'>
            <Input />
          </Form.Item>
        </Form>
        <Collapse
          defaultActiveKey={['event']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className='table-operator-box'
        >
          <Collapse.Panel key='event' header='动作'>
            <section className='file-edit-panel-content-back'>
              <CommonActionPanel
                fieldName={capitalize(
                  currentCommandOption.key?.replace('-', '')
                )}
                configProps={getEventProps()}
                compFlag={settingItem?.props?.[DATA_HCG_FLAG].value}
                globalDsConfig={tree.dsConfig}
                avaliableEvents={[
                  {
                    name: 'onClick',
                    meaning: '点击事件',
                    list: eventList,
                  },
                ]}
                onActionOk={handleActionOk}
                onDeleteEvent={handleDeleteAction}
              />
            </section>
          </Collapse.Panel>
        </Collapse>
      </main>
    </div>
  );
};

export default EditPanel;

/*
 * @Author: your name
 * @Date: 2021-09-22 10:52:07
 * @LastEditTime: 2021-11-16 17:37:10
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \hzero-code-generator\packages\designer\src\utils\block-util.ts
 */
import { ComponentTree, isC7nPro, Mapping } from '@htd/common';

const blockComponentList = ['Table'];

const blockBlackList = ['Header', 'Content', 'Footer', 'Step'];

export function ifCanSaveAsBlock(mapping: Mapping): boolean {
  return (
    (mapping.type === 'container' && !blockBlackList.includes(mapping.name)) ||
    blockComponentList.includes(mapping.name)
  );
}

/**
 * 生成用于保存到后端的数据结构: 包含当前组件数据和涉及到的Ds配置
 * @param data {Mapping} 当前组件数据
 * @param tree {ComponentTree} 当前的组件树
 * @returns {Mapping, any[]}
 */
export function generateBlockDataToSave(
  data: Mapping,
  tree: ComponentTree,
  saveDs?: boolean
) {
  // 找到所有引用到的ds
  function findComponentDsMap(data: Mapping) {
    const map = new Map<string, string>();
    function findDs(data: Mapping) {
      if (data.dsKey && !saveDs) {
        delete data.dsKey;
      }
      if (data.dsKey) {
        map.set(data.id!, data.dsKey);
      }
      if (data.children) {
        data.children.forEach(findDs);
      }
    }
    findDs(data);
    return map;
  }
  const dsMap = findComponentDsMap(data);
  let allReferencedDs: any[] = [];
  dsMap.forEach((dsKey) => {
    allReferencedDs.push(tree.dsConfig.find((ds) => ds.dsKey === dsKey));
  });
  allReferencedDs = allReferencedDs.filter(Boolean);
  return {
    data,
    allDs: saveDs ? allReferencedDs : [],
  };
}

export function shouldDisableSaveDs(data: Mapping) {
  let result: boolean = false;
  function findTable(mapping: Mapping) {
    if (result) return;
    if (mapping.name === 'Table' && isC7nPro(mapping)) {
      result = true;
    } else {
      mapping.children.forEach(findTable);
    }
  }
  findTable(data);
  return result;
}

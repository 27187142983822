export function listenMessage(callback: (data: any) => any) {
  const messageHandler = (e: MessageEvent) => {
    if (e.data) {
      try {
        callback(typeof e.data === 'string' ? JSON.parse(e.data) : e.data);
      } catch (error) {}
    }
  };
  window.addEventListener('message', messageHandler);
  return () => {
    window.removeEventListener('message', messageHandler);
  };
}

export function sendMessageToParent(data: any) {
  window.parent.postMessage(data, '*');
}

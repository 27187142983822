import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { Modal, message } from 'choerodon-ui/pro';
import { Tooltip } from 'choerodon-ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragDropContextValue, DragDropProvider } from 'components/DragDrop';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import {
  dropItemToList,
  generateCanvasComponentById,
  initialTree,
  listReorder,
  listReorderReady,
  callRemote,
  clearPlaceholder,
  DATA_HCG_FLAG,
} from 'utils';
import {
  DesignConfig,
  DesignComponent,
  MenuType,
  isDsComponent,
} from '@htd/common';
import AsideLeft from './AsideLeft';
import AsideRight from './AsideRight';
import Canvas from './Canvas';
import { DesignContextValue, DesignProvider } from './DesignContext';
import CodeViewer from 'components/CodeViewer';
import './index.less';
import { merge } from 'lodash';
import { lovView } from '@/services/login';
import { mappingHooks } from '@/mapping/hooks';
// import { sendMessageToParent } from 'utils/message';
import {
  seeIcon,
  codeSee,
  logoIcon,
} from '../../../src/utils/unifiedExportIcon';
import { useMemoState } from '@/hooks';

const Page = (props: any) => {
  const [tree, setTree] = useState<DesignConfig>({
    root: 'page',
    children: [],
    dsConfig: [],
    isTableFormType: '',
  });
  const [isMultilingual] = useState(true);
  const [settingItem, setSettingItem] = useState<DesignComponent>();
  const [currentMenu, setCurrentMenu] = useMemoState<MenuType>(
    'generator-menu',
    'tree'
  );
  const [lovData, setLovData] = useState({});

  useMemo(() => {
    setTimeout(() => {
      lovView({ page: 0, size: 10 }).then((res) => {
        setLovData(res);
      });
    }, 500);
  }, []);

  const pool = useRef({
    containerMap: new Map<string | undefined, DesignComponent>([
      ['root', tree as any],
    ]),
    mounted: false,
  });

  const update = useMemo(
    () => (id?: string, newTree?: any) => {
      setTree((old) => {
        if (id) {
          const updateComponent = pool.current.containerMap.get(id);
          mappingHooks.forEach((h) => h(updateComponent));
        }
        return { ...old, ...(newTree || {}) };
      });
    },
    []
  );

  useEffect(() => {
    const currentPageData = { ...props.pageData, tree };
    const newPage = props.pageList.map((data: any) => {
      return currentPageData.pageId === data.pageId ? currentPageData : data;
    });
    props.setPageList(newPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree]);

  const designContextValue = useMemo<DesignContextValue>(
    () => ({
      tree,
      settingItem,
      currentMenu,
      lovData,
      cMap: pool.current.containerMap,
      updateTree(tree: any) {
        setTree(tree);
      },
      forceUpdate: update,
      backendConfig: props.backendConfig,
      updateBackendConfig: (newData) => props.setBackendConfig(newData),
      onSetting: (item) => {
        if (settingItem !== item) {
          setSettingItem(item);
        }
      },
      onChangeMenu: setCurrentMenu,
      onDelete: (item: DesignComponent) => {
        const { containerMap } = pool.current;
        const parent = containerMap.get(item.parentId as any);
        if (item.parentId && parent) {
          const index = parent.children.findIndex(
            (child) => child.id === item.id
          );
          if (index > -1) {
            parent.children.splice(index, 1);
            containerMap.delete(item.id);
          }
          if (item.id === settingItem?.id) {
            setSettingItem(parent); // 删除一个元素时，将焦点上移一层
          }
          update();
        }
      },
      onAdd: (
        index: number,
        child: DesignComponent,
        parent: DesignComponent
      ) => {
        const newCol = generateCanvasComponentById(
          child.componentId,
          parent.id
        );
        if (newCol) {
          if (newCol.props?.span?.value) {
            // newCol.props.span.value = 24 - allColspan >= 4 ? 4 : allColspan;
            newCol.props.span.value = child.props?.span.value || 4;
          }
          parent.children?.splice(index + 1, 0, newCol);
          const { containerMap } = pool.current;
          containerMap.set(newCol.id, newCol);
          update();
        }
      },
      reset() {
        pool.current.containerMap.clear();
        pool.current.containerMap.set('root', tree as any);
      },
      isMultilingual,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tree,
      settingItem,
      currentMenu,
      lovData,
      update,
      props.backendConfig,
      setCurrentMenu,
      isMultilingual,
    ]
  );

  const dragDropContextValue: DragDropContextValue = useMemo(
    () => ({
      onReOrder: (result) => {
        listReorder(result, pool.current.containerMap, update);
      },
      onReOrderReady: (result) =>
        listReorderReady(result, pool.current.containerMap, update),
      onDrop: (result) => {
        if (
          result.from === 'list' &&
          result.data.id === 'htd-advanced-footer'
        ) {
          // 如果拖入底部通栏组件，设置tree.children 2 显示，同时不插入组件
          tree.isShowFooter = true;
          update();
          return;
        }
        dropItemToList(
          result,
          pool.current.containerMap,
          update,
          setSettingItem,
          tree
        );
      },

      onClearPlaceholder: () => {
        clearPlaceholder(pool.current.containerMap);
        update?.();
      },
    }),
    [tree, update]
  );

  useEffect(() => {
    if (props.pageData.tree.children.length === 0) {
      initialTree(pool.current.containerMap, update, tree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    handleLoadConfig(JSON.stringify(props.pageData.tree));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDsKey = useCallback(() => {
    let noDsKeyList: Array<DesignComponent> = [];
    const filterNoDsKeyList = (children: Array<DesignComponent>) => {
      if (noDsKeyList.length) return;
      if (!children || !children.length) {
        return;
      }
      for (const child of children) {
        if (isDsComponent(child) && !child.dsKey) {
          noDsKeyList.push(child);
        }
        if (child.children.length) {
          filterNoDsKeyList(child.children);
        }
      }
    };
    filterNoDsKeyList(tree.children);
    if (noDsKeyList.length) {
      message.error(
        `${noDsKeyList[0].props?.[DATA_HCG_FLAG].value}组件需要绑定数据源`
      );
    }
    return !noDsKeyList.length;
  }, [tree.children]);

  const handleGenerate = useCallback(
    async (type: string) => {
      tree.intlCode = props.pageData?.intlPrefix;
      tree.intlPrefix = props.pageData?.pagePath;
      tree.isTableFormType = type;
      return callRemote({ type: 'htd/generate', payload: tree }).then(
        (res: any) => {
          Modal.open({
            title: '代码预览',
            children: <CodeViewer codeTree={res} />,
            bodyStyle: { maxHeight: '80vh', overflow: 'hidden' },
            style: { width: '60%' },
            className: 'htd-components-preview-modal',
            autoCenter: true,
            closable: true,
            footer: null,
          });
        }
      );
    },
    [tree, props.pageData]
  );

  const getQueryString = (name: string) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  const generateTreeChildren = useCallback((children: any[]): any[] => {
    if (!children) {
      return [];
    }
    return children.map((item) => {
      const { children: itemChildren, ...otherItem } = item;
      const comp = generateCanvasComponentById(item.componentId);
      const result = {
        ...merge(comp, otherItem),
        children: generateTreeChildren(itemChildren),
      };
      pool.current.containerMap.set(result.id, result);
      return result;
    });
  }, []);

  const handleLoadConfig = useCallback(
    (fileContent: string) => {
      if (fileContent) {
        // 导入成功后，关闭右侧属性编辑弹窗
        setSettingItem(undefined);
      }
      const fileConfig = JSON.parse(fileContent);
      tree.children = generateTreeChildren(fileConfig.children);
      tree.dsConfig = fileConfig.dsConfig;
      setTree({ ...tree });
    },
    [generateTreeChildren, tree]
  );

  const headActions = useMemo(() => {
    const buttons = [
      {
        title: '保 存',
        backColor: 'transparent',
        action: () => {
          props.saveFuntionConfig();
        },
        width: '80px',
        border: '1px solid #fff',
      },
      {
        title: '同 步',
        backColor: 'transparent',
        action: () => {
          props.syncFuntionConfig();
        },
        width: '80px',
        border: '1px solid #fff',
        describe: true,
      },
    ];
    if (getQueryString('pageSource') !== 'hfcg') {
      buttons.push({
        title: '插入项目',
        backColor: '#E27277',
        border: 'none',
        width: '80',
        action: () => {
          if (validateDsKey()) {
            props.handleInsertToProject();
          }
        },
      });
    }
    return buttons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, validateDsKey]);

  // 按钮
  const leftButtonHeadActions = useMemo(
    () => [
      {
        title: '反馈',
        actionUrl:
          'https://open.hand-china.com/community/faq?classify=%E6%BA%90%E7%A0%81%E5%8F%AF%E8%A7%86%E5%8C%96%E5%B7%A5%E5%85%B7&secClassify=&tab=hot',
      },
      {
        title: '帮助',
        actionUrl:
          'https://open.hand-china.com/document-center/doc/product/10217/10561?doc_id=157452&doc_code=130414',
      },
    ],
    []
  );

  const mulButtonHeadActions = useMemo(
    () => [
      {
        title: '预览代码',
        action: (type: string) => {
          handleGenerate(type);
        },
        src: codeSee,
      },
      {
        title: '预览页面',
        action: () => {
          props.handlePreviewPage();
        },
        src: seeIcon,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGenerate]
  );

  return (
    <DragDropProvider value={dragDropContextValue}>
      <DndProvider backend={HTML5Backend}>
        <DesignProvider value={designContextValue}>
          <section className='htd-home'>
            <header className='htd-home-header'>
              <div className='htd-home-header-top-box'>
                <img alt='logo' src={logoIcon}></img>
                <h1 className='htd-home-header-title'>源码可视化工具</h1>
              </div>
              <div className='htd-home-header-actions'>
                <div className='htd-home-header-actions-buttonBox'>
                  {leftButtonHeadActions.map(({ title, actionUrl }) => (
                    <a
                      href={actionUrl}
                      target='_blank'
                      rel='noreferrer'
                      key={title}
                    >
                      {title}
                    </a>
                  ))}
                </div>
                <div className='htd-home-header-actions-buttonBox'>
                  {mulButtonHeadActions.map(({ title, action, src }) => (
                    <Popconfirm
                      title='请选择Table/Form组件的生成类型'
                      onConfirm={() => action('ultra')}
                      onCancel={() => action('pro')}
                      cancelText='pro'
                      okText='ultra'
                    >
                      <Tooltip title={title}>
                        <img
                          alt={title}
                          src={src}
                          style={{ color: '#999999', marginRight: '16px' }}
                          // onClick={action}
                          key={title}
                        />
                      </Tooltip>
                    </Popconfirm>
                    // <Tooltip title={title}>
                    //   <img
                    //     alt={title}
                    //     src={src}
                    //     style={{ color: '#999999', marginRight: '16px' }}
                    //     onClick={action}
                    //     key={title}
                    //   />
                    // </Tooltip>
                  ))}
                </div>
                {headActions.map(
                  ({ title, action, backColor, width, border, describe }) => (
                    <button
                      className='htd-home-header-actions-generate htd-home-header-actions-generate-default'
                      style={{
                        backgroundColor: backColor,
                        width: width,
                        border: border,
                      }}
                      onClick={action}
                      key={title}
                    >
                      {title}
                      {describe && (
                        <QuestionCircleOutlined
                          style={{ marginLeft: '4px' }}
                          title='同步生成后端业务模型【注：多次同步只会同步增量数据】，基于业务模型生成后端代码'
                        />
                      )}
                    </button>
                  )
                )}
              </div>
            </header>
            <main className='htd-home-main'>
              <AsideLeft />
              <Canvas />
              <AsideRight />
            </main>
          </section>
        </DesignProvider>
      </DndProvider>
    </DragDropProvider>
  );
};

export default Page;

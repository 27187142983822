import { Mapping } from '@htd/common';
import { Layout as MappingLayout } from 'components/placeholders';

const targetNameArr = [
  'Col',
  'Content',
  'TabPane',
  'Step',
  'Panel',
  'Modal',
  'Card',
];

export const Layout2_4: Mapping = {
  name: 'Layout',
  iconCode: 'Layout2_4',
  type: 'layout',
  lib: 'choerodon-ui/pro',
  props: {
    rowNum: { type: 'number', value: 2, label: '行数' },
    colNum: { type: 'number', value: 4, label: '列数' },
  },
  placeholderComponent: MappingLayout,
  children: [],
  canDrop: (target) => {
    // 如果不是顶级层次
    if (target) {
      // 则必须放在列容器内
      if (targetNameArr.indexOf(target.name) === -1) {
        return false;
      }
    }
    return true;
  },
};

export const Layout1_3: Mapping = {
  name: 'Layout',
  iconCode: 'Layout1_3',
  type: 'layout',
  lib: 'choerodon-ui/pro',
  props: {
    rowNum: { type: 'number', value: 1, label: '行数' },
    colNum: { type: 'number', value: 3, label: '列数' },
  },
  placeholderComponent: MappingLayout,
  children: [],
  canDrop: (target) => {
    // 如果不是顶级层次
    if (target) {
      // 则必须放在列容器内
      if (targetNameArr.indexOf(target.name) === -1) {
        return false;
      }
    }
    return true;
  },
};

import { Icon } from 'choerodon-ui';
import { Collapse } from 'antd';
import React, { useContext, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { updateDsFieldsToModel } from '@/mapping/data/Table/utils';
import ValidateAttribute from './ValidateAttribute';
import BaseAttribute from './BaseAttribute';
import ColumnAttribute from './ColumnAttribute';
import EventAttribute from './EventAttribute';

import './index.less';
import OtherAttribute from './OtherAttribute';
import RelationAttribute from './RelationAttribute';
import QueryAttribute from './QueryAttribute';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import { DesignContext } from 'views/Home/DesignContext';
// import { isArray } from 'lodash';

interface Props {
  record?: any;
  dsConfigData?: any;
  item?: any;
}

const FieldEditPanel: React.FC<Props> = ({ record, dsConfigData, item }) => {
  const { onSettingConfig } = useContext(ConfigContext);
  const { backendConfig } = useContext(DesignContext);

  // 属性面板中  属性变化同步实体
  useEffect(() => {
    if (dsConfigData) {
      updateDsFieldsToModel(backendConfig, dsConfigData, record);
      // 设置applicationFor
      // const isNewDsFlag = item?.props?.createNewDs?.value || false;
      // const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
      // if(isNewDsFlag) {
      //   // 设置了 单独ds
      //   const Fields = dsConfigData.fields || [];
      //   const recordFieldKey = record.ds_fieldKey;
      //   const cuField = Fields.find((ff: any) => recordFieldKey && ff.fieldKey === recordFieldKey);
      //   const applicationFor:any = cuField.applicationFor || {};
      //   const list = ['ds_bind','ds_query','ds_lovCode','ds_lookupCode','ds_defaultValue','ds_min','ds_max','ds_maxLength','ds_minLength','ds_required'];
      //   const list1 = ['bind','query','lovCode','lookupCode','defaultValue','min','max','maxLength','minLength','required'];
      //   list.forEach((l: any, index: number) => {
      //     if(record[l]) {
      //       if(isArray(applicationFor[list1[index]])) {
      //         const oldData = applicationFor[list1[index]].find((af: any) => af.cmp === data_hcg_flag);
      //         if(oldData) {
      //           oldData.value = record[l]
      //         } else {
      //           applicationFor[list1[index]].push({
      //             cmp: data_hcg_flag,
      //             value: record[l]
      //           })
      //         }
      //       } else {
      //         applicationFor[list1[index]] = [{
      //           cmp: data_hcg_flag,
      //           value: record[l]
      //         }]
      //       }
      //     }
      //   })
      //   cuField.applicationFor = applicationFor;
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    onSettingConfig?.(undefined);
  };

  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header className='htd-aside-right-header'>
        <span>字段属性</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main className='htd-aside-right-main' style={{ paddingTop: 8 }}>
        <BaseAttribute record={record} item={item} />
        <Collapse
          defaultActiveKey={['other', 'column']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel key='other' header='其他属性'>
            <OtherAttribute record={record} item={item} />
          </Collapse.Panel>
          <Collapse.Panel key='column' header='列属性'>
            <ColumnAttribute record={record} />
          </Collapse.Panel>
          <Collapse.Panel
            key='validate'
            header='校验'
            className='validate-attribute-panel-box'
          >
            <ValidateAttribute record={record} item={item} />
          </Collapse.Panel>
          {record.ds_type === 'object' && (
            <Collapse.Panel key='query' header='查询参数'>
              <QueryAttribute record={record} />
            </Collapse.Panel>
          )}
          {(record.ds_type === 'string' || record.ds_type === 'object') && (
            <Collapse.Panel key='relation' header='关系'>
              <RelationAttribute record={record} />
            </Collapse.Panel>
          )}
          <Collapse.Panel key='event' header='动作'>
            <EventAttribute record={record} />
          </Collapse.Panel>
        </Collapse>
      </main>
    </div>
  );
};

export default FieldEditPanel;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.treeMockData = exports.getMockFromType = void 0;
var mockjs_1 = require("mockjs");
function getMockFromType(type) {
    switch (type) {
        case 'string':
            return mockjs_1.Random.csentence(5, 8);
        case 'boolean':
            return mockjs_1.Random.boolean();
        case 'number':
            return mockjs_1.Random.integer(0, 100);
        case 'date':
            return mockjs_1.Random.date();
        case 'time':
            return mockjs_1.Random.time();
        case 'dateTime':
            return mockjs_1.Random.datetime();
        default:
            return undefined;
    }
}
exports.getMockFromType = getMockFromType;
exports.treeMockData = [
    {
        expand: true,
        functionCode: 'HR',
        icon: 'airline_seat_flat-o',
        id: 2,
        ischecked: null,
        score: 10,
        shortcutId: null,
        text: '组织架构',
        url: null,
        symbol: '0',
    },
    {
        expand: false,
        functionCode: 'SYS_REPORT_MANAGE',
        icon: 'airline_seat_flat_angled-o',
        id: 24,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '报表管理',
        url: null,
        symbol: '0',
    },
    {
        expand: false,
        functionCode: 'EMPLOYEE_REACT',
        icon: 'airline_seat_flat-o',
        id: 7,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '员工管理(react)',
        url: 'hap-core/hr/employee',
        symbol: '1',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'HR_UNIT',
        icon: 'airline_seat_flat_angled-o',
        id: 73,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '组织管理',
        url: 'hr/org_unit.html',
        symbol: '0',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'COMPANY_REACT',
        icon: 'airline_seat_flat-o',
        id: 12,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '公司管理(react)',
        url: 'hap-core/hr/company',
        symbol: '1',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'ORGUNIT_REACT',
        icon: 'airline_seat_flat_angled-o',
        id: 5,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '组织管理(react)',
        url: 'hap-core/hr/orgunit',
        symbol: '1',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'POSITION_REACT',
        icon: 'airline_seat_flat-o',
        id: 6,
        ischecked: true,
        score: 10,
        shortcutId: null,
        text: '岗位管理(react)',
        url: 'hap-core/hr/position',
        symbol: '1',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'HR_POSITION',
        icon: 'airline_seat_flat-o',
        id: 75,
        ischecked: true,
        score: 20,
        shortcutId: null,
        text: '岗位管理',
        url: 'hr/position.html',
        symbol: '0',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'HR_EMPLOYEE',
        icon: 'airline_seat_flat-o',
        id: 74,
        ischecked: true,
        score: 30,
        shortcutId: null,
        text: '员工管理',
        url: 'hr/employee.html',
        symbol: '0',
        parentId: 2,
    },
    {
        expand: false,
        functionCode: 'FND_COMPANY',
        icon: 'airline_seat_flat-o',
        id: 76,
        ischecked: null,
        score: 40,
        shortcutId: null,
        text: '公司管理',
        url: 'fnd/company.html',
        symbol: '0',
        parentId: 2,
    },
];

import { createContext } from 'react';
import { DesignComponent, DesignConfig, MenuType } from '@htd/common';

export interface DesignContextValue {
  tree: DesignConfig;
  settingItem?: DesignComponent;
  currentMenu?: MenuType;
  lovData?: any;
  forceUpdate?: (id?: string, newTree?: any) => any;
  updateTree?: (result: DesignConfig) => any;
  onSetting?: (item: DesignComponent | undefined) => any;
  onTreeSelect?: (key: any) => any;
  onChangeMenu?: (menu: MenuType) => any;
  onDelete?: (item: DesignComponent) => any;
  onAdd?: (
    index: number,
    child: DesignComponent,
    parent: DesignComponent
  ) => any;
  cMap: Map<string | undefined, DesignComponent>;
  reset: () => any;
  isMultilingual: boolean;
  backendConfig: any;
  updateBackendConfig?: (newData: any) => any;
}

export const DesignContext = createContext<DesignContextValue>({
  tree: { root: 'page', children: [], dsConfig: [], isTableFormType: '' },
  cMap: new Map(),
  reset() {},
  isMultilingual: false,
  backendConfig: {},
});

export const DesignProvider = DesignContext.Provider;

export const DesignConsumer = DesignContext.Consumer;

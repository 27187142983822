import { Mapping, ConfigPanelComponentProp } from '@htd/common';
import { Collapse } from 'antd';
import { createFormField } from 'components/placeholders';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import { CaretRightOutlined } from '@ant-design/icons';
import { OTHER_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';
import OptionsColumns from './optionsColumns';

export const Select: Mapping = {
  name: 'Select',
  title: '下拉选择',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'string',
  iconCode: 'Select',
  cascadeData: [],
  lovParaData: [],
  props: {
    ...BASE_PROPS_TYPES,
    ...getInputCommon('select', '下拉选择'),
    // 其它属性分组
    ...FORM_ITEM_TYPES,
    lookupCode: {
      label: '值集编码',
      type: 'string',
      value: '',
      group: OTHER_PROPS,
      isDS: true,
    },
    defaultValue: {
      type: 'string',
      label: '默认值',
      value: '',
      isDS: true,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = '张三'; // 你的逻辑... value =
          '李四'; return value;{'\u007d'}
        </pre>
      ),
      group: OTHER_PROPS,
    },
    multiple: {
      // type: 'boolean',
      type: 'collection-select',
      label: '多值',
      value: 'false',
      group: OTHER_PROPS,
      isDS: true,
      options: [
        { label: 'true', value: 'true' },
        { label: 'false', value: 'false' },
        { label: ',', value: ',' },
        { label: ';', value: ';' },
        { label: '#', value: '#' },
        { label: '_', value: '_' },
      ],
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
    options: {
      type: 'object',
      label: '下拉数据源',
      hidden: true,
      isDS: true,
      value: [],
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  validate: {},
  placeholderComponent: createFormField({ htdType: 'select' }),
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    return (
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel key='1' header='下拉数据集'>
          <OptionsColumns {...props} />
        </Collapse.Panel>
      </Collapse>
    );
  },
};

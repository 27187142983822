import { Mapping } from '@htd/common';
import { Switch as FormSwitch } from 'components/placeholders';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { BASE_PROPS_TYPES, FORM_ITEM_TYPES } from 'utils/prop-types';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';
import { BASIC_PROPS, OTHER_PROPS } from 'utils/config';

export const Switch: Mapping = {
  name: 'Switch',
  title: '开关',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'boolean',
  iconCode: 'Switch',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    label: {
      label: '标题',
      type: 'string',
      value: `开关`,
      isDS: true,
    },
    name: {
      label: '数据字段',
      type: 'string',
      value: `switch${HTD_PROPS_HOLDER}`,
    },
    fieldKey: {
      type: 'string',
      // value: uuid.v4(),
      value: '',
      label: '标题',
      hidden: true,
      ignore: true,
      isDS: true,
    },
    readOnly: {
      label: '是否只读',
      type: 'boolean',
      value: false,
      isDS: true,
    },
    disabled: {
      label: '禁用',
      type: 'boolean',
      value: false,
      isDS: true,
    },
    conditionHidden: {
      type: 'boolean',
      label: '隐藏',
      value: false,
      conditionType: 'negative',
      valueConfigurable: true,
      ignore: true,
    },
    trueValue: {
      label: '开启对应值',
      type: 'string',
      value: '',
      isDS: true,
      group: OTHER_PROPS,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = false; // 你的逻辑... value =
          true; return value;{'\u007d'}
        </pre>
      ),
    },
    falseValue: {
      label: '关闭对应值',
      type: 'string',
      value: '',
      isDS: true,
      group: OTHER_PROPS,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = false; // 你的逻辑... value =
          true; return value;{'\u007d'}
        </pre>
      ),
    },
    defaultValue: {
      label: '默认值',
      type: 'string',
      value: '',
      isDS: true,
      group: OTHER_PROPS,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = false; // 你的逻辑... value =
          true; return value;{'\u007d'}
        </pre>
      ),
    },
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  validate: {},
  placeholderComponent: FormSwitch,
};

import { Action, createNamespaceReducer } from '../store';
import {
  addLocalCategory,
  deleteLocalBlock,
  fetchInlineBlockList,
  fetchInlineCategoryList,
  fetchLocalBlockList,
  fetchLocalCategoryList,
  renameLocalBlock,
  updateLocalCategory,
} from '@/services/block-service';
import { message } from 'antd';
import { ObjectType } from '@htd/common';
import { entries } from 'lodash';
import { resolveFragment } from 'utils';
import { createEffect } from '@/store/util';

export interface BlockModuleState {
  local: ObjectType<any[]>;
  localCategoryList: string[];
  inlineCategoryList: string[];
}

export const blockReducer = createNamespaceReducer(
  'block',
  function (state: any, action: Action) {
    switch (action.type) {
      case 'save': {
        return { ...state, ...action.payload };
      }
      default:
        return state;
    }
  }
);

export const fetchLocalBlockListEffect = createEffect(async ({ dispatch }) => {
  const list: ObjectType<any[]> = await fetchLocalBlockList();
  if (list) {
    entries(list).forEach(([k, value]) => {
      list[k] = value.map((v) => {
        const { data, allDs } = resolveFragment(v.content);
        return {
          ...v,
          type: 'block',
          allDs,
          mapping: data,
        };
      });
    });
    dispatch({
      type: 'block/save',
      payload: {
        local: list,
      },
    });
  }
});

export const fetchInlineBlockListEffect = createEffect(
  async ({ dispatch }, args) => {
    const data: any = await fetchInlineBlockList(args);
    const list: ObjectType<any[]> = {};
    data?.content.forEach((d: any) => {
      // if (list[d.categoryCode]) {
      // } else {
      // list[d.categoryCode] = [
      //   {
      //     name: d.blockName,
      //     content: JSON.parse(d.blockData),
      //   },
      // ];
      // }
      if (d.attribute2 && d.blockName) {
        //新建属性名
        if (Object.keys(list).indexOf('' + d.attribute2) === -1) {
          list[d.attribute2] = [];
        }
        //对应插入属性值
        list[d.attribute2].push({
          name: d.blockName,
          content: JSON.parse(d.blockData),
          id: d.id,
          blockData: d.blockData,
          categoryId: d.categoryId,
          _token: d._token,
          objectVersionNumber: d?.objectVersionNumber,
        });
      }
    });
    if (list) {
      entries(list).forEach(([k, value]) => {
        list[k] = value.map((v) => {
          const { data, allDs } = resolveFragment(v.content);
          return {
            ...v,
            type: 'block',
            allDs,
            mapping: data,
          };
        });
      });
      dispatch({
        type: 'block/save',
        payload: {
          local: list,
        },
      });
    }
  }
);

export const fetchLocalCategoryEffect = createEffect(async ({ dispatch }) => {
  const list = await fetchLocalCategoryList();
  dispatch({ type: 'block/save', payload: { localCategoryList: list } });
});

export const fetchInlineCategoryEffect = createEffect(async ({ dispatch }) => {
  const list: any = await fetchInlineCategoryList();
  const inlineData: object[] = list && list.content;
  // 处理 区块分类 数据
  // const inlineData = list && list.content.map((c: any) => {
  //   return [c.categoryName, c.id];
  // });
  dispatch({ type: 'block/save', payload: { inlineCategoryList: inlineData } });
});

export const addLocalCategoryEffect = createEffect(async (_, args) => {
  await addLocalCategory(args);
  await fetchLocalCategoryEffect();
  message.success(`分类: ${args} 添加成功.`);
});

export const updateLocalCategoryEffect = createEffect(
  async (_, args, newArgs) => {
    await updateLocalCategory(args, newArgs);
    await fetchLocalCategoryEffect();
    await fetchLocalBlockListEffect();
    message.success(`分类: ${newArgs} 修改成功.`);
  }
);

export const renameLocalBlockEffect = createEffect(async (_, data) => {
  await renameLocalBlock(data);
  await fetchLocalBlockListEffect();
});

export const deleteLocalBlockEffect = createEffect(async (_, data) => {
  await deleteLocalBlock(data);
  await fetchLocalBlockListEffect();
  message.success('删除成功');
});

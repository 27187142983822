import React, { useState, useContext, useCallback, useEffect } from 'react';
import {
  Collapse,
  Popconfirm,
  message,
  Table,
  Input,
  Checkbox,
  notification,
} from 'antd';
import { DataSet, Modal } from 'choerodon-ui/pro';
import { DsApiProps } from '@htd/common';
import { FieldProps } from 'choerodon-ui/pro/lib/data-set/Field';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import * as uuid from 'uuid';
import cls from 'classnames';
import { cloneDeep } from 'lodash';
import { ColumnProps } from 'choerodon-ui/lib/table';
import { FunctionContext } from 'views/Home/FunctionContext';
import MyIcon from 'components/myIcon';
import { DesignContext } from 'views/Home/DesignContext';
import { openBusinessModelModal } from './BusinessModelModal';
import { openDeleteBackendModal } from './deleteBackendModal';
import { openBusinessEntityModal } from './BusinesEntityModal';
import { noDataIcon } from '../../../../utils/unifiedExportIcon';
import AddFieldModal from '../DataSetList/DataSetConfig/AddFieldModal';
// import ModelFieldsEditPanel from './ModelFieldsEdit';

import './index.less';
import { openBusinessEntityCreateCompModal } from './BusinessEntityCreateCompModal';
import { DragBox } from 'components/DragDrop';
const Panel = Collapse.Panel;

const BackendDesign: React.FC = () => {
  const {
    backendConfig,
    updateBackendConfig,
    tree,
    forceUpdate,
    onSetting,
    cMap,
  } = useContext(DesignContext);

  const { pageList } = useContext(FunctionContext);
  const [businessModel, setBusinessModel] = useState<any>({});
  const [isForceAPIList, setIsForceAPIList] = useState<boolean>(false);
  // 刷新页面
  const [isCurrentChecked, setCurrentChecked] = useState(true);

  const [isForcePageBackendData, setForcePageBackendData] =
    useState<boolean>(true);
  // 保存 当前打开的折叠面板key数据
  const [isActiveList, setActiveList] = useState(['apis']);
  useEffect(() => {
    setBusinessModel(backendConfig);
  }, [backendConfig]);
  const setBackendConfig = useCallback(
    (newData) => {
      setBusinessModel({ ...newData });
      updateBackendConfig?.({ ...newData });
    },
    [updateBackendConfig]
  );

  const handleChangeDmConfig = useCallback(
    (data: any) => {
      const entitys = businessModel[data.modelCode]?.entitys || {};
      businessModel[data.modelCode] = { ...data, entitys };
      setBackendConfig(businessModel);
    },
    [businessModel, setBackendConfig]
  );

  const handleChangeDmByDeConfig = useCallback(
    async (modelCode: string, beData: any) => {
      const bm = businessModel[modelCode];
      beData.apis = getApis(
        getTableType(beData),
        beData.tableName,
        bm.serviceRoute
      );
      if (beData.isNew === true && bm.entitys[beData.tableName]) {
        message.warning('实体表名重复');
        return false; //
      }
      if (
        beData.isNew === true &&
        Object.values(bm.entitys).findIndex(
          (ev: any) => ev.entityName === beData.entityName
        ) !== -1
      ) {
        message.warning('实体名称重复');
        return false; //
      }
      // 查询表
      if (beData.isBaseTable === 1) {
        const queryds = new DataSet({
          autoQuery: false,
          transport: {
            read: () => {
              const qy = {
                schemaName: beData.tableNameOfDatabase.datasourceCode,
                tableName: beData.tableNameOfDatabase.tableName,
              };
              return {
                url: '/hcgr/v1/0/entitys/query-table-field',
                method: 'get',
                params: qy,
              };
            },
          },
        });
        const fields = await queryds.query();
        // 字段数据key存在大写，改为全小写
        const newFields = fields.map((f: any) => {
          objectKeyToLower(f);
          f.fieldKey = uuid.v4();
          return f;
        });
        beData.originFields = newFields;
        // fields.map((f: any) => (f.fieldKey = uuid.v4()));
        // beData.originFields = fields;
        // 将数据库字段默认同步到实体字段中
        originFieldsSyncEntity(beData, newFields);
      }
      bm.entitys[beData.tableName] = beData;
      setBackendConfig(businessModel);
    },
    [businessModel, setBackendConfig]
  );

  const objectKeyToLower = (obj: any) => {
    for (let key in obj) {
      const v = obj[key];
      delete obj[key];
      obj[key.toLowerCase()] = v;
    }
    return obj;
  };

  /**
   * 创建业务模型
   */
  const handleAddBm = () => {
    openBusinessModelModal({ type: 'CREATE', handleOk: handleChangeDmConfig });
  };

  // const handleDeleteBm = (bm: any) => {
  //   delete businessModel[bm.modelCode];
  //   // 删除ds数据
  //   tree.dsConfig = [];
  //   forceUpdate?.();
  //   setBackendConfig({});
  //   message.success('操作成功');
  // };

  const handleEditorBm = (bm: any, e: any) => {
    openBusinessModelModal({
      type: 'EDITOR',
      handleOk: handleChangeDmConfig,
      data: bm,
    });
    e.stopPropagation();
  };

  /**
   * 添加业务实体
   */
  const handleAddBe = (bm: any) => {
    openBusinessEntityModal({
      type: 'CREATE',
      handleOk: (data: any) => {
        const dsKey = uuid.v4();
        data.dsKey = dsKey; // 通过dsKey实现实体和dsCondig的关联
        return handleChangeDmByDeConfig(bm.modelCode, data);
        // addDsConfig(data);
      },
      entitys: bm.entitys,
    });
  };

  /**
   * 添加dataSetConfig
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addDsConfig = useCallback(
    (data) => {
      tree.dsConfig.push({
        dsName: data.tableName,
        dsKey: data.dsKey,
        fields: [],
        queryFields: [],
      });
      forceUpdate?.(undefined, { dsConfig: tree.dsConfig });
    },
    [forceUpdate, tree]
  );

  const handleDeleteBe = (bm: any, tableName: string) => {
    const esList = bm?.entitys || {};
    const es = esList[tableName];

    // TODO: 判断多页面下的模型是否被引用
    openDeleteBackendModal({ es, pageList, handleOk: handleDeleteBeOkFunc });

    // const { dsConfig = [] } = tree;
    // const dsUUidKey = businessModel[bm.modelCode]?.entitys[tableName]?.dsKey;
    // 删除数据源结构数据
    // delete businessModel[bm.modelCode].entitys[tableName];
    // // 删除 ds 数据
    // dsConfig.forEach((item, index) => {
    //   if (dsUUidKey && item.dsKey === dsUUidKey) {
    //     dsConfig.splice(index, 1);
    //   }
    // });
    // forceUpdate?.();
    // setBackendConfig(businessModel);
    // message.success('操作成功');
  };

  // 删除实体 弹框确认 函数
  const handleDeleteBeOkFunc = useCallback(
    (deleteFlag: boolean, cuBackendKey: string, tableName: string) => {
      if (deleteFlag === true && cuBackendKey && tableName) {
        // 可以删除
        const es =
          ((Object.values(backendConfig)[0] as any) || {}).entitys || {};
        delete es[tableName];
        // 删除 ds 数据
        const { dsConfig = [] } = tree;
        dsConfig.forEach((item, index) => {
          if (cuBackendKey && item.dsKey === cuBackendKey) {
            dsConfig.splice(index, 1);
          }
        });
        forceUpdate?.();
        setBackendConfig(backendConfig);
        message.success('操作成功');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backendConfig]
  );

  const handleEditorBe = (bm: any, be: any, e: any) => {
    openBusinessEntityModal({
      type: 'EDITOR',
      handleOk: (data: any) => {
        handleChangeDmByDeConfig(bm.modelCode, data);
      },
      entitys: bm.entitys,
      data: be,
    });
    e.stopPropagation();
  };

  // const businessModelDeleteIcon = (bm: any) => {
  //   // const { dsConfig = [] } = tree;
  //   // const deleteFlag = dsConfig.some(
  //   //   (item) => item.bindComponent && item.bindComponent.length
  //   // );
  //   // if (deleteFlag) {
  //   //   return (
  //   //     <Tooltip title='存在关联组件，无法删除'>
  //   //       <Icon type='delete' />
  //   //     </Tooltip>
  //   //   );
  //   // }
  //   return (
  //     <Popconfirm
  //       title='请确认是否删除该业务模型及模型下的全部实体'
  //       onConfirm={handleDeleteBm.bind(this, businessModel[bm])}
  //       okText='确认'
  //       cancelText='取消'
  //     >
  //       <MyIcon type='icon-a-delete2x' className='svg-cla' />
  //     </Popconfirm>
  //   );
  // };

  const businessEntitysDeleteIcon = (bm: any, cuData: any) => {
    // const { dsConfig = [] } = tree;
    // const { dsKey, tableName } = cuData;
    const { tableName } = cuData;
    // const dsConfigData = dsConfig.find((itemR) => itemR.dsKey === dsKey);
    // const deleteFlag =
    //   dsConfigData &&
    //   dsConfigData.bindComponent &&
    //   dsConfigData.bindComponent.length;
    // if (deleteFlag) {
    //   return (
    //     <Tooltip title='存在关联组件，无法删除'>
    //       <Icon type='delete' />
    //     </Tooltip>
    //   );
    // }
    return (
      <MyIcon
        type='icon-a-delete2x'
        className='svg-cla'
        onClick={handleDeleteBe.bind(this, businessModel[bm], tableName)}
      />
      // <Popconfirm
      //   title='请确认是否删除该业务实体'
      //   onConfirm={handleDeleteBe.bind(this, businessModel[bm], tableName)}
      //   okText='确认'
      //   cancelText='取消'
      // >
      //   <MyIcon type='icon-a-delete2x' className='svg-cla' />
      // </Popconfirm>
    );
  };

  const businessEntitysUseCompIcon = (bm: any, cuData: any) => {
    const { isBaseTable } = cuData;
    // const content = (
    //   <Row>
    //     <Col span={12}>表单</Col>
    //     <Col span={12}>表格</Col>
    //   </Row>
    // );
    return (
      isBaseTable === 1 && (
        <DragBox
          context='backend'
          key='backend-creat-comp'
          data={{
            name: 'backend-creat-comp',
            id: 'backend-creat-comp',
            type: 'inline-block',
            cuData,
          }}
          canDrag
        >
          <MyIcon
            type='icon-Component'
            className='svg-cla'
            onClick={(e: any) => {
              handleOpenBusinessEntityCreateCompModal(cuData);
              e.stopPropagation();
            }}
          />
        </DragBox>
        // <Popover content={content} title='一键生成' trigger='hover'>
        // </Popover>
      )
    );
  };

  const handleOpenBusinessEntityCreateCompModal = useCallback(
    (cuData) => {
      openBusinessEntityCreateCompModal({
        data: cuData,
        ctx: { tree, forceUpdate, onSetting, cMap },
      });
    },
    [tree, forceUpdate, onSetting, cMap]
  );

  // 保存 当前打开的折叠面板key数据
  const handleOnChangeFunc = (key: any) => {
    setActiveList(key);
  };

  /**
   * 删除字段
   */
  const handleDeleteField = (
    record: any,
    index: number,
    columnDataList: any[]
  ) => {
    columnDataList?.splice(index, 1);
    setForcePageBackendData(!isForcePageBackendData);
  };

  /**
   * 修改字段值
   */
  const handleChangeValue = (
    fieldName: string,
    record: any,
    value: any,
    fieldList: any[]
  ) => {
    let primaryKeyData = false;
    let relationFieldData = false;
    fieldList.forEach((item: any) => {
      if (item.primaryKey === 1) {
        primaryKeyData = true;
      }
      if (item.relationField === 1) {
        relationFieldData = true;
      }
    });
    if (primaryKeyData && fieldName === 'primaryKey' && value) {
      // 已存在主键字段
      notification.warning({
        message: '警告',
        description: '有且仅有一个主键字段！',
      });
    } else if (relationFieldData && fieldName === 'relationField' && value) {
      // 已存在外键字段
      notification.warning({
        message: '警告',
        description: '至多设置一个外键字段！',
      });
    } else {
      record[fieldName] = value ? 1 : 0;
      setCurrentChecked(!isCurrentChecked);
      updateBackendConfig?.({ ...backendConfig });
    }
  };

  const getColumns = (fields: any[]) => {
    const columnsList: ColumnProps<any>[] = [
      {
        title: '主键',
        dataIndex: 'primaryKey',
        key: 'primaryKey',
        align: 'center',
        render: (value, record) => {
          return (
            <Checkbox
              checked={value === 1 ? true : false}
              onChange={(e) =>
                handleChangeValue(
                  'primaryKey',
                  record,
                  e.target.checked,
                  fields
                )
              }
            />
          );
        },
      },
      {
        title: '外键',
        dataIndex: 'relationField',
        key: 'relationField',
        align: 'center',
        render: (value, record) => {
          return (
            <Checkbox
              checked={value === 1 ? true : false}
              onChange={(e) =>
                handleChangeValue(
                  'relationField',
                  record,
                  e.target.checked,
                  fields
                )
              }
            />
          );
        },
      },
      {
        title: '字段名',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        align: 'center',
        render: (value) => {
          return <Input value={value} disabled />;
        },
      },
      {
        title: '标题',
        dataIndex: 'label',
        key: 'label',
        width: 100,
        align: 'center',
        render: (value) => {
          return <Input value={value} disabled />;
        },
      },
      {
        align: 'center',
        width: 30,
        render: (_text, record, index) => {
          return (
            <Popconfirm
              title='确认删除？'
              onConfirm={handleDeleteField.bind(this, record, index, fields)}
              okText='确认'
              cancelText='取消'
              className='asd'
            >
              <MyIcon type='icon-a-delete2x' style={{ color: '#999' }} />
            </Popconfirm>
          );
        },
      },
    ];
    return columnsList;
  };

  const getApis = (
    type: string,
    tableCode: string,
    serviceCode: string
  ): any[] => {
    let str = tableCode.replaceAll('_', '-');
    const [, ...orther] = tableCode.split('_');
    if (orther && orther.length >= 1) {
      str = orther.join('-');
    }

    const apsSuffix = str;

    if (type === 'header') {
      return [
        {
          name: '查询',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'read',
        },
        {
          name: '保存',
          method: 'post',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'submit',
        },
        {
          name: '删除',
          method: 'delete',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'destroy',
        },
        {
          name: '导出',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s/export`,
          key: 'export',
        },
      ];
    }
    if (type === 'lines') {
      return [
        {
          name: '查询',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'read',
        },
        {
          name: '保存',
          method: 'post',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'submit',
        },
        {
          name: '删除',
          method: 'delete',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'destroy',
        },
        {
          name: '导出',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s/export`,
          key: 'export',
        },
      ];
    }
    if (type === 'extension') {
      return [
        {
          name: '查询',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'read',
        },
        {
          name: '保存',
          method: 'post',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'submit',
        },
        {
          name: '删除',
          method: 'delete',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s`,
          key: 'destroy',
        },
        {
          name: '导出',
          method: 'get',
          url: `/${serviceCode}/v1/{organizationId}/${apsSuffix}s/export`,
          key: 'export',
        },
      ];
    }
    return [];
  };

  const getTableType = (be: any): string => {
    if (be.parentEntity && be.relType === 'one2many') {
      return 'lines';
    }
    if (be.parentEntity && be.relType === 'one2one') {
      return 'extension';
    }
    return 'header';
  };

  /**
   * 点击编辑
   * @param api
   */
  const handleClickEditApi = (
    api: DsApiProps,
    apiList: any,
    cuDsKey: string
  ) => {
    const currentPpi: any = cloneDeep(api);
    currentPpi.type = currentPpi.name;
    currentPpi.cuDsKey = cuDsKey;
    Modal.open({
      title: '编辑API',
      children: (
        <AddFieldModal
          currentApiList={apiList}
          editApi={currentPpi}
          onConfirm={handleEditApi}
        />
      ),
      className: 'htd-add-field-modal',
    });
  };

  /**
   * 编辑API弹框确认函数
   * @param api
   * @param fields
   */
  const handleEditApi = (api: any, fields?: FieldProps[]) => {
    const cuDsKey = api.cuDsKey;
    if (cuDsKey) {
      //  直接改实体
      const es = ((Object.values(backendConfig)[0] as any) || {}).entitys || {};
      const cuBackendData: any =
        Object.values(es).find((obf: any) => obf.dsKey === cuDsKey) || {};
      const cuApiList = cuBackendData.apis || [];
      cuApiList.forEach((item: any, index: number) => {
        if (item.key && api.key && item.key === api.key) {
          const cuApi = cloneDeep(api);
          delete cuApi.type;
          delete cuApi.cuDsKey;
          cuApiList.splice(index, 1, { ...cuApi });
        }
      });
      // 直接改数据源
      const dsConfig = tree.dsConfig || [];
      const cuDsConfigData: any =
        dsConfig.find((df: any) => df.dsKey === cuDsKey) || {};
      const transport = cuDsConfigData.transport || {};
      Object.keys(transport).forEach((key: any) => {
        if (key === api.key) {
          const cuApi = cloneDeep(api);
          delete cuApi.type;
          delete cuApi.cuDsKey;
          transport[key] = cuApi;
        }
      });
      setIsForceAPIList(!isForceAPIList);
    }
  };

  const renderApiList = (be: any, bm: any) => {
    const dsKey = be?.dsKey;
    const apiList: any[] = be.apis || [];
    return apiList.map((item, index) => (
      <div className='htd-ds-config-api-row' key={item.type}>
        <div className='htd-ds-config-api-row-left'>
          <span className='htd-ds-config-api-name'>{item.name}</span>
          <span
            className={`htd-ds-config-api-method htd-ds-config-api-method-${item.method}`}
            title={item.method}
          >
            {item.method}
          </span>
          <span className='htd-ds-config-api-url' title={item.url}>
            {item.url}
          </span>
        </div>
        <div className='htd-ds-config-api-row-right'>
          <MyIcon
            type='icon-a-edit2x'
            className='left-svg-cla'
            onClick={handleClickEditApi.bind(this, item, apiList, dsKey)}
          />
          {/* <MyIcon
            type='icon-a-delete2x'
            className='left-svg-cla'
            // onClick={handleClickDeleteApi.bind(this, item, index)}
          /> */}
        </div>
      </div>
    ));
  };

  // const handleAddDsConfig = (dsCodeKey: string) => {
  //   setModelShowKey(dsCodeKey);
  // };

  const renderFieldList = (prop: any) => {
    const { column: fields = [] } = prop;
    const columns = getColumns(fields);
    return (
      <>
        <Panel
          key='fields'
          header='字段列表'
          className={cls({
            'no-data':
              isActiveList.includes('fields') && (!fields || !fields.length),
          })}
        >
          {fields && fields.length && (isForcePageBackendData || true) ? (
            <>
              <Table
                className='htd-ds-config-field htd-aside-left-fields-table'
                //@ts-ignore
                columns={columns}
                dataSource={[...fields]}
                pagination={false}
                //@ts-ignore
                filterBar={false}
                scroll={{ y: 240, x: 350 }}
                empty={'暂无字段' as any}
                rowKey='name'
                // showHeader={Boolean(fields?.length)}
              />
              {/* <button
                className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
                style={{
                  width: '82px',
                  height: '28px',
                  borderRadius: '2px',
                  marginBottom: '12px',
                  marginTop: '12px',
                  fontSize: '12px',
                }}
                onClick={() => handleAddDsConfig(dsKey)}
              >
                查看详情
              </button> */}
            </>
          ) : (
            <div className='table-null-left-box'>
              <img alt='No data' src={noDataIcon} />
              <span>暂无数据</span>
            </div>
          )}
        </Panel>
        {/* <ModelFieldsEditPanel
          dsKey={modelShowKey}
          modelShowKey={modelShowKey}
          onClose={() => {
            setModelShowKey('');
          }}
        /> */}
      </>
    );
  };

  return (
    <>
      <div className='htd-aside-left-main-backend'>
        {isEmpty(businessModel) && (
          <button
            className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
            style={{
              width: '128px',
              height: '28px',
              borderRadius: '2px',
              marginBottom: '12px',
              marginTop: '12px',
              fontSize: '12px',
            }}
            onClick={handleAddBm}
          >
            新建模型
          </button>
        )}
        {Object.keys(businessModel).map((bm) => {
          return (
            <Collapse
              className='htd-aside-left-main-dataSet-collapse left-collapse-box'
              bordered={false}
              defaultActiveKey={['backend']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                key='backend'
                className='htd-aside-left-main-dataSet-collapse-panel-bottom'
                header={
                  <div className='htd-collapse-edit-header'>
                    {businessModel[bm].modelName}
                    <div className='model-icon'>
                      <MyIcon
                        type='icon-a-edit2x'
                        className='svg-cla'
                        onClick={(e: any) =>
                          handleEditorBm.bind(this, businessModel[bm], e)()
                        }
                      />
                      {/* {businessModelDeleteIcon(bm)} */}
                    </div>
                  </div>
                }
              >
                {Object.keys(businessModel[bm].entitys).map((tableName) => {
                  const be = businessModel[bm].entitys;
                  return (
                    <Collapse
                      bordered={false}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                    >
                      <Panel
                        key='backend-be'
                        header={
                          <div className='htd-collapse-edit-header'>
                            <div
                              style={{
                                width: '140px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {be[tableName].entityName}({tableName})
                            </div>
                            <div className='model-icon'>
                              <MyIcon
                                type='icon-a-edit2x'
                                className='svg-cla'
                                onClick={(e: any) =>
                                  handleEditorBe.bind(
                                    this,
                                    businessModel[bm],
                                    be[tableName],
                                    e
                                  )()
                                }
                              />
                              {businessEntitysDeleteIcon(bm, be[tableName])}
                              {businessEntitysUseCompIcon(bm, be[tableName])}
                            </div>
                          </div>
                        }
                      >
                        <Collapse
                          className='htd-ds-config-collapse query-api-table-no-data-box'
                          bordered={false}
                          defaultActiveKey={['apis']}
                          expandIconPosition='right'
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                          onChange={handleOnChangeFunc}
                        >
                          <Panel
                            key='apis'
                            header='API列表'
                            className='htd-ds-config-top-border'
                          >
                            <div className='htd-ds-config-back'>
                              {renderApiList(be[tableName], businessModel[bm])}
                            </div>
                          </Panel>
                          {/* 当前折叠面板被打开 并且 其下表格无数据 时  去掉折叠面板下边框 */}
                          {renderFieldList(be[tableName])}
                        </Collapse>
                      </Panel>
                    </Collapse>
                  );
                })}
                <button
                  className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
                  style={{
                    width: '128px',
                    height: '28px',
                    borderRadius: '2px',
                    marginBottom: '12px',
                    marginTop: '12px',
                    fontSize: '12px',
                  }}
                  onClick={handleAddBe.bind(this, businessModel[bm])}
                >
                  <PlusOutlined />
                  添加实体
                </button>
              </Panel>
            </Collapse>
          );
        })}
      </div>
      {/* 删除实体提示弹框 */}
    </>
  );
};

export default BackendDesign;

function originFieldsSyncEntity(beData: any, originFields: any[]) {
  const fs = originFields
    .map((o) => {
      const name = replaceUnderLine(o.column_name);
      const type = (o.data_type as string)?.toLowerCase() || 'string';
      return {
        fieldKey: o.fieldKey,
        fieldCodeJava: name,
        fieldName: name,
        fieldType: type,
        label: o.column_comment || name,
        name: name,
        type: type,
        defaultValue: undefined,
        codeRule: null,
        fieldOrder: null,
        inputNecessary: 0,
        lookupCode: undefined,
        primaryKey: null,
        relatiOnField: 0,
        searchable: 0,
        valid: null,
      };
    })
    .filter(({ name }: { name: string }) => {
      return !WHO_FIELDS.includes(name.toLocaleUpperCase());
    });
  beData.column = fs;
}

const WHO_FIELDS = [
  'OBJECTVERSIONNUMBER',
  'LASTUPDATEDBY',
  'LASTUPDATEDATE',
  'CREATEDBY',
  'CREATIONDATE',
  'CREATEDATE',
  'TENANTID',
];

export const replaceUnderLine = (name: String) => {
  const loweName = name.toLocaleLowerCase();
  let str = loweName;
  const [...orther] = loweName.split('_');
  if (orther && orther.length >= 1) {
    str = orther.join('_');
  }
  // eslint-disable-next-line no-useless-escape
  return str.replace(/\_(\w)/g, (_all, letter) => {
    return letter.toUpperCase();
  });
};

import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { Tabs as ProTabs } from 'components/placeholders';
import { TabPane as ProTabPane } from 'components/placeholders';
import tabsConfig from './TabsConfig';
import * as uuid from 'uuid';

export const Tabs: Mapping = {
  name: 'Tabs',
  iconCode: 'Tabs',
  type: 'container',
  lib: 'choerodon-ui',
  title: '标签页',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '标签页',
      label: '标题',
      hidden: true,
    },
    type: {
      type: 'collection',
      label: '样式',
      value: 'line',
      options: [
        { label: '线条', value: 'line' },
        { label: '卡片', value: 'card' },
      ],
    },
    tabPosition: {
      type: 'collection',
      label: '页签位置',
      value: 'top',
      options: [
        { label: '上', value: 'top' },
        { label: '下', value: 'bottom' },
        { label: '左', value: 'left' },
        { label: '右', value: 'right' },
      ],
    },
    defaultActiveKey: {
      type: 'string',
      value: undefined,
      label: '初始化选中的key',
      hidden: true,
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，标签页处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    columns: {
      type: 'object',
      label: '标签页',
      hidden: true,
      value: [
        {
          dataIndex: `TabPane_${uuid.v4().slice(0, 5)}`,
          label: '标签页',
          defaultActive: true,
          disabled: false,
        },
        {
          dataIndex: `TabPane_${uuid.v4().slice(0, 5)}`,
          label: '标签页',
          defaultActive: false,
          disabled: false,
        },
      ],
    },
  },
  canDrop(target) {
    return target?.name !== 'Tabs' && target?.name !== 'TabPane';
  },
  onDrop(item) {
    return item?.name === 'TabPane';
  },
  placeholderComponent: ProTabs,
  configPanelComponent: tabsConfig,
  children: [],
};

export const TabPane: Mapping = {
  name: 'TabPane',
  title: '子标签页',
  type: 'container',
  lib: 'choerodon-ui',
  from: 'Tabs.TabPane',
  iconCode: 'TabPane',
  fixed: true,
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '子标签页',
      label: '标题',
    },
  },
  canDrop: () => false,
  placeholderComponent: ProTabPane as any,
  children: [],
};

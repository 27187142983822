"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATETIME_MAX = exports.DATETIME_MIN = exports.DEFAULT_TIME_FORMAT = exports.DEFAULT_DATETIME_FORMAT = exports.DEFAULT_DATE_FORMAT = exports.dsComponentsList = exports.formComponentNames = void 0;
/*
 * @Author: your name
 * @Date: 2021-08-25 20:18:18
 * @LastEditTime: 2021-08-25 22:48:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hzero-code-generator\packages\common\src\utils\constants.ts
 */
// TODO: 补充完整
exports.formComponentNames = [
    'TextField',
    'Select',
    'Lov',
    'NumberField',
    'DatePicker',
    'DateTimePicker',
    'TimePicker',
    'MonthPicker',
    'WeekPicker',
    'YearPicker',
    'Output',
    'Range',
    'Radio',
    'TextArea',
    'Password',
    'EmailField',
    'Switch',
    'IntlField',
    'UrlField',
    'Currency',
    'RichText',
];
exports.dsComponentsList = ['Form', 'Table'];
/* 默认前后端交互格式-日期 */
exports.DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
/* 默认前后端交互格式-日期时间 */
exports.DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
/* 默认前后端交互格式-时间 */
exports.DEFAULT_TIME_FORMAT = 'HH:mm:ss';
/* 特定格式的日期处理格式，时分秒指定为00:00:00 */
exports.DATETIME_MIN = 'YYYY-MM-DD 00:00:00';
/* 特定格式的日期处理格式，时分秒指定为23:59:59 */
exports.DATETIME_MAX = 'YYYY-MM-DD 23:59:59';

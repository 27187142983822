import React, { FC, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { Form as AntdForm, Popconfirm, Button, Select, Input } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import { ConfigPanelComponentProp } from '@htd/common';
import MyIcon from 'components/myIcon';
import SelectRightImg from 'components/selectRightImg';
import './index.less';
import { useCallback } from 'react';
import { useEffect } from 'react';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';

const OperatorButtonPanel: FC<ConfigPanelComponentProp> = ({
  item,
  forceUpdate,
}) => {
  const buttonTypeList = useMemo(() => {
    return [
      { value: 'confirm', meaning: '确认' },
      { value: 'cancle', meaning: '取消' },
      { value: 'custom', meaning: '自定义' },
    ];
  }, []);

  /**
   * 操作列按钮数据
   */
  const dataSource: any[] = useMemo(
    () => item?.props?.buttonList.value || [],
    [item?.props?.buttonList.value]
  );

  const [form] = AntdForm.useForm();

  /**
   * 修改操作按钮名称
   */
  const handleNameChange = useCallback(
    (e, value, index) => {
      const val = e?.target ? e?.target?.value : e;
      const updateLine = {
        type: item?.props?.buttonList.value.filter(
          (i: any) => i.name === value
        )[0].type,
        name: val,
      };
      item?.props?.buttonList.value.splice(index, 1, updateLine);
      if (item?.props?.buttonList.value) {
        item.props.buttonList.value = cloneDeep(item?.props?.buttonList.value);
      }
      forceUpdate?.(item?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate, item?.props?.buttonList.value]
  );

  /**
   * 修改操作按钮类型
   */
  const handleChangeCommand = useCallback(
    (index, value) => {
      const updateLine = {
        type: value,
        name: buttonTypeList.filter((i) => i.value === value)[0].meaning,
      };
      // console.log(updateLine);
      item?.props?.buttonList.value.splice(index, 1, updateLine);
      if (item?.props?.buttonList.value) {
        item.props.buttonList.value = cloneDeep(item?.props?.buttonList.value);
      }
      forceUpdate?.(item?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate, item?.props?.buttonList.value]
  );

  /**
   * 添加操作按钮
   */
  const handleAddLine = useCallback(() => {
    const newLine = {
      type: buttonTypeList.filter((i) => !isButtonOptionDisable(i))[0].value,
      name: buttonTypeList.filter((i) => !isButtonOptionDisable(i))[0].meaning,
    };
    // console.log(newLine);
    item?.props?.buttonList.value.push(newLine);
    if (item?.props?.buttonList.value) {
      item.props.buttonList.value = cloneDeep(item?.props?.buttonList.value);
    }
    forceUpdate?.(item?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate, item?.props?.buttonList.value]);

  /**
   * 删除操作按钮
   */
  const handleDeleteLine = useCallback(
    (index) => {
      item?.props?.buttonList.value.splice(index, 1);
      if (item?.props?.buttonList.value) {
        item.props.buttonList.value = cloneDeep(item?.props?.buttonList.value);
      }
      forceUpdate?.(item?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate, item?.props?.buttonList.value]
  );

  const isButtonOptionDisable = (button: any) => {
    if (button.value === 'custom') return false;
    if (!dataSource) return false;
    const idx = dataSource.findIndex((d) => d.type === button.value);
    return idx !== -1;
  };

  const columns: ColumnsType<any> = [
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      width: 100,
      render: (value, record, index) => {
        if (record.$form) {
          return (
            <AntdForm.Item name='type'>
              <Select
                onChange={handleChangeCommand.bind(this, index)}
                suffixIcon={<SelectRightImg />}
              >
                {buttonTypeList.map((button) => (
                  <Select.Option
                    value={button.value}
                    key={button.value}
                    disabled={isButtonOptionDisable(button)}
                  >
                    {button.meaning}
                  </Select.Option>
                ))}
              </Select>
            </AntdForm.Item>
          );
        }
        return value;
      },
    },
    {
      title: '显示文本',
      align: 'center',
      dataIndex: 'name',
      render: (value, record, index) => {
        if (record.$form) {
          return (
            <AntdForm.Item name='name'>
              <Input onBlur={(e) => handleNameChange(e, value, index)} />
            </AntdForm.Item>
          );
        }
        return value;
      },
    },
    {
      // width: 60,
      align: 'center',
      className:
        'htd-aside-right-operator-table-operator operator-button-panel-clu',
      render: (_, _record, index) => (
        <Popconfirm
          title='确认删除？'
          okText='确认'
          cancelText='取消'
          onConfirm={handleDeleteLine.bind(this, index)}
        >
          {/* <DeleteOutlined /> */}
          <MyIcon type='icon-a-delete2x' />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (item.id) {
      form.resetFields();
    }
  }, [form, item.id]);

  return (
    <>
      <EditTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        className='htd-aside-right-operator-table model-right-box'
        showHeader={Boolean(dataSource?.length)}
      />
      <div
        className='htd-aside-left-operator-button-wrapper jus-conn'
        style={{ marginTop: 8 }}
      >
        <Button
          // icon={<PlusOutlined />}
          // className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine}
        >
          添加一项
        </Button>
      </div>
    </>
  );
};

export default OperatorButtonPanel;

import React, { FC, MouseEvent, useRef, useState } from 'react';
// import { DragSourceMonitor, DragPreviewImage, useDrag } from 'react-dnd';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import cls from 'classnames';
import { DRAG_TYPE_DEFAULT } from 'utils';
import './index.less';
// @ts-ignore
// import compImage from '../../../assets/comp@2x.png';
import { useCallback } from 'react';
import { useMemo } from 'react';
// import DropPlaceholder from '../DropPlaceholder';

export interface DragBoxProps {
  data: any;
  draggingCls?: string;
  className?: string;
  index?: number;
  id?: string;
  type?: string;
  context?: string; // 标记在哪个DropArea下
  placeholder?: boolean;
  canDrag: boolean;
  boxStyle?: React.CSSProperties;
}

const DragBox: FC<DragBoxProps> = (props) => {
  const {
    data,
    draggingCls,
    className,
    context,
    placeholder,
    type = DRAG_TYPE_DEFAULT,
    canDrag,
    boxStyle,
  } = props;
  const [hoverItemId, setHoverItemId] = useState<string>();
  const ref = useRef<HTMLDivElement>();
  const temp = useRef({ timer: null } as any);
  // const [dragInfo, dragRef, dragPreview] = useDrag(
  const [dragInfo, dragRef] = useDrag(
    {
      type,
      // item: { from: context, data },
      item: () => {
        // console.log(111111);
        return { from: context, data };
      },
      collect(monitor: DragSourceMonitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
      canDrag: () => canDrag,
      // begin: () =>{},
      // end: () => { console.log(22222) }
    },
    [data]
  );
  const handleMouseOver = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const { timer } = temp.current;
      if (timer) {
        clearTimeout(timer);
      }
      if (!hoverItemId) {
        setHoverItemId(data.id);
      } else {
        temp.current.timer = setTimeout(() => {
          if (hoverItemId !== data.id) {
            setHoverItemId(data.id);
          }
        }, 100);
      }
    },
    [data.id, hoverItemId]
  );

  const handleMouseOut = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setHoverItemId(undefined);
    clearTimeout(temp.current.timer);
  }, []);

  // 行内元素  拖拽放置时 小竖线
  const placeholderCls = useMemo(() => {
    switch (data?.type) {
      case 'inline-container':
      case 'inline-block':
      case 'inline':
        return 'htd-drag-box-placeholder-inline-block';
      default:
        return '';
    }
  }, [data.type]);

  const maskPlaceholderCls = useMemo(() => {
    switch (data?.type) {
      case 'inline-container':
      case 'inline-block':
      case 'inline':
        return 'htd-drag-box-mask-placeholder-inline-block';
      default:
        return '';
    }
  }, [data.type]);
  // const handleDragOverFunc = (e: any) => {
  //   e.preventDefault();
  //   e.dataTransfer.dropEffect = 'move';
  // }
  // onDragOver={handleDragOverFunc}

  return (
    <>
      {/* 拖拽 时的 图标 */}
      {/* <DragPreviewImage connect={dragPreview} src={compImage} /> */}
      <div
        className={cls(
          'htd-drag-box',
          className,
          dragInfo.isDragging && 'htd-drag-box-dragging',
          dragInfo.isDragging && draggingCls,
          placeholder && 'htd-drag-box-placeholder',
          !canDrag && 'htd-drag-box-fixed',
          placeholder && placeholderCls,
          data.id === hoverItemId && 'htd-drag-box-hover'
        )}
        style={boxStyle}
        // @ts-ignore
        ref={dragRef(ref)}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {placeholder && (
          <div
            className={cls(
              'htd-drag-box-mask',
              'htd-drag-box-mask-placeholder',
              maskPlaceholderCls
            )}
          />
        )}
        {!placeholder && (
          <div className='htd-drag-box-content'>{props.children}</div>
        )}
      </div>
    </>
  );
};

export default DragBox;

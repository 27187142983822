import { ConfigPanelComponentProp, Mapping } from '@htd/common';
import { PerformanceTable as tablePlaceholder } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ColumnPanel from './ColumnPanel';
import DataPanel from './DataPanel';
// import ToolBarPanel from './ToolBarPanel';

export const PerformanceTable: Mapping = {
  name: 'PerformanceTable',
  title: '大数据表格',
  iconCode: 'Table',
  type: 'block',
  lib: 'choerodon-ui/pro',
  placeholderComponent: tablePlaceholder,

  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '大数据表格',
      label: '标题',
      ignore: true,
      hidden: true,
    },
    bordered: {
      type: 'boolean',
      label: '边框',
      value: true,
    },
    editor: {
      type: 'boolean',
      label: '行编辑',
      value: false,
    },
    rowSelect: {
      type: 'boolean',
      label: '勾选列',
      value: false,
    },
    rowKey: {
      type: 'string',
      label: '行索引',
      value: 'id',
    },
    // toolbar: {
    //   type: 'object',
    //   label: '工具栏',
    //   value: {
    //     header: undefined,
    //     buttons: [],
    //     settings: [],
    //   },
    //   hidden: true,
    // },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，表格处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    columns: {
      type: 'object',
      label: '列配置',
      hidden: true,
      value: [
        {
          title: '字段1',
          dataIndex: 'field1',
          key: 'field1',
          resizable: true,
          fixed: 'null',
          align: 'null',
        },
        {
          title: '字段2',
          dataIndex: 'field2',
          key: 'field2',
          resizable: true,
          fixed: 'null',
          align: 'null',
        },
      ],
    },
    query: {
      type: 'object',
      label: '列配置',
      hidden: true,
      ignore: true,
      value: {
        url: undefined,
        method: 'GET',
        paging: true,
      },
    },
  },
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    return (
      <>
        <Collapse
          defaultActiveKey={['field_props', 'data_config', 'query_bar']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className='htd-aside-edit-table-panel'
        >
          <Collapse.Panel header='表格列配置' key='field_props'>
            <ColumnPanel {...props} />
          </Collapse.Panel>
          <Collapse.Panel header='数据配置' key='data_config'>
            <DataPanel {...props} />
          </Collapse.Panel>
          {/* <Collapse.Panel header='查询区域' key='query_bar'></Collapse.Panel> */}
          {/* <Collapse.Panel header='操作区域' key='tool_bar'>
            <ToolBarPanel {...props} />
          </Collapse.Panel> */}
        </Collapse>
      </>
    );
  },
  children: [],
};

import { FC } from 'react';
import {
  Form as AntdForm,
  Input,
  Switch,
  Select,
  FormInstance,
  // InputNumber,
} from 'antd';
import { InputNumber } from 'choerodon-ui';
import { DesignComponent } from '@htd/common';
import { FIELD_TYPE_LIST } from 'utils/prop-types';
import SelectRightImg from 'components/selectRightImg';

interface BaseAttributeProps {
  record: any;
  item: DesignComponent;
  form: FormInstance<any>;
}

const BaseAttribute: FC<BaseAttributeProps> = ({ record, item, form }) => {
  return (
    <section className='htd-aside-right-main-section'>
      <AntdForm.Item label='字段名' name='dataIndex'>
        <Input />
      </AntdForm.Item>
      <AntdForm.Item label='标题' name='col_title'>
        <Input />
      </AntdForm.Item>
      {item.props?.editor.value && (
        <>
          <AntdForm.Item
            label='可编辑'
            name='col_editor'
            valuePropName='checked'
          >
            <Switch />
          </AntdForm.Item>
          {record.col_editor && (
            <>
              <AntdForm.Item label='字段类型' name='col_fieldType'>
                <Select suffixIcon={<SelectRightImg />}>
                  {FIELD_TYPE_LIST.map((type) => (
                    <Select.Option value={type.value} key={type.value}>
                      {type.meaning}
                    </Select.Option>
                  ))}
                </Select>
              </AntdForm.Item>
              {record.col_fieldType === 'string' && (
                <>
                  <AntdForm.Item label='值集编码' name='col_lookupCode'>
                    <Input />
                  </AntdForm.Item>
                </>
              )}
              {record.col_fieldType === 'object' && (
                <>
                  <AntdForm.Item label='值集视图编码' name='col_lovCode'>
                    <Input />
                  </AntdForm.Item>
                </>
              )}
              {record.col_fieldType === 'number' && (
                <>
                  <AntdForm.Item label='步距' name='col_step'>
                    <InputNumber />
                  </AntdForm.Item>
                </>
              )}
              {record.col_fieldType === 'boolean' && (
                <>
                  <AntdForm.Item label='选中结果' name='col_trueValue'>
                    <Input />
                  </AntdForm.Item>
                  <AntdForm.Item label='非选中结果' name='col_falseValue'>
                    <Input />
                  </AntdForm.Item>
                </>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default BaseAttribute;

import { RichText as FormRichText } from 'components/placeholders';
import { Mapping } from '@htd/common';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { BASIC_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const RichText: Mapping = {
  name: 'RichText',
  iconCode: 'RichText',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'object',
  title: '富文本编辑器',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('richText', '富文本编辑器'),
    label: {
      label: '富文本',
      type: 'string',
      value: '富文本编辑器',
      disabled: true,
    },
    name: {
      label: '数据字段',
      type: 'string',
      value: `richText${HTD_PROPS_HOLDER}`,
    },
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  validate: {},
  showValidate: ['minLength', 'maxLength'],
  placeholderComponent: FormRichText,
  children: [],
};

import { createContext } from 'react';

export interface FunctionContextValue {
  pageList: any;
}

export const FunctionContext = createContext<FunctionContextValue>({
  pageList: [],
});

export const FunctionProvider = FunctionContext.Provider;

import {
  createContext,
  FC,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { ObjectType } from '@htd/common';

export type Action = {
  type: string;
  payload: any;
};

export type Selector<T = any> = (state: any) => T;

export type UseStore = <T = any>(
  selector?: Selector
) => { state: T; dispatch: Dispatch };

export type Dispatch = (action: Action) => any;

export function createNamespaceReducer(namespace: string, reducer: any) {
  return function (state: any, action: Action) {
    const [actionNamespace, type] = action.type.split('/');
    if (actionNamespace === namespace) {
      const namespaceValue = reducer(state[actionNamespace], {
        type,
        payload: action.payload,
      });
      return {
        ...state,
        [actionNamespace]: namespaceValue,
      };
    }
    return state;
  };
}

export function combineReducer(...reducers: Function[]) {
  return function reducer(state: any, action: Action) {
    return reducers.reduce((prev, r) => ({ ...prev, ...r(state, action) }), {});
  };
}

export const StoreCtx = createContext<{ state: any; dispatch: Dispatch }>(
  {} as any
);

export function createStoreProvider(reducer: any, initState?: ObjectType) {
  const store: { state: any; dispatch: Dispatch } = {} as any;
  const StoreProvider: FC = (props) => {
    const [state, dispatch] = useReducer(reducer, initState || {});
    store.state = state;
    store.dispatch = dispatch;
    return (
      <StoreCtx.Provider value={{ state, dispatch }}>
        {props.children}
      </StoreCtx.Provider>
    );
  };
  return { StoreProvider, store };
}

export const useStore: UseStore = (selector: Selector = (state) => state) => {
  const { state, dispatch } = useContext(StoreCtx);
  return { state: selector(state), dispatch };
};

export function useAsync(
  aFun: (dispatch: Dispatch, ...rest: any[]) => Promise<any>,
  deps: Array<any> = []
) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [state, setState] = useState({ fn: async () => {} });
  const { dispatch } = useStore();
  useEffect(() => {
    let unMount = false;
    const ensureLoading = (value: boolean) => {
      if (!unMount) {
        setLoading(value);
      }
    };
    const fn = async (...args: any[]) => {
      ensureLoading(true);
      const res = await aFun(dispatch, ...args);
      ensureLoading(false);
      if (!unMount) {
        setResult(res);
      }
      return res;
    };
    setState({ fn });
    return () => {
      unMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aFun, dispatch, ...deps]);
  return {
    loading,
    result,
    fn: state.fn,
  };
}

import { blockReducer } from './reducers/block';
import { globalReducer } from './reducers/global';
import { combineReducer, createStoreProvider } from './store';

export * from './store';

const allReducer = combineReducer(globalReducer, blockReducer);

const { StoreProvider, store } = createStoreProvider(allReducer, {
  block: { local: {}, localCategoryList: [], inlineCategoryList: [] },
});

export { StoreProvider, store };

import React, { useContext, useMemo, useEffect } from 'react';
import { Form as AntdForm, Switch, Radio, Select } from 'antd';
import { InputNumber } from 'choerodon-ui';
import {
  COLUMN_LOCK_LIST,
  COLUMN_ALIGN_LIST,
  COLUMN_TOOLTIP_LIST,
} from 'utils/prop-types';
import VariableInput from 'components/VariableInput';
import { DynamicObject } from '@htd/common';
import CellRenderers from '@htd/helper/lib/templates/cell-renderer';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import SelectRightImg from 'components/selectRightImg';

const ColumnAttribute: React.FC<{ record: any }> = ({ record }) => {
  const { tree, settingItem, forceUpdate } = useContext(DesignContext);
  const { tableColumns, configItem, dsFields } = useContext(ConfigContext);

  // 当前字段
  const currentField = dsFields?.find((f) => f.name === record?.name);

  const [form] = AntdForm.useForm();

  // 单元格render
  const cellRendererList = useMemo(() => {
    return CellRenderers.filter((i) => i.code && i.name);
  }, []);

  // 当前列
  const currentColumn = tableColumns.find((c) => c.name === record?.name);

  const initialValues = {
    ...currentColumn,
    lock: currentColumn?.lock || 'null',
    align: currentColumn?.align || 'null',
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * 表单值变更
   * @param changedValues
   */
  const handleChangeValues = (changedValues: any) => {
    for (const key in changedValues) {
      const value = changedValues[key];
      // lock属性以及align属性，如果只为'null'，则直接删除属性
      if (key === 'lock' || key === 'align') {
        if (value === 'null') {
          delete (currentColumn as any)[key];
          continue;
        }
      }
      if (key === 'render') {
        const params: DynamicObject = {};
        if (value === 'customRender') {
          params[
            'functionName'
          ] = `${settingItem?.props?.['data-hcg_flag'].value}${currentColumn?.name}CustomRender`;
        }
        (currentColumn as any)['renderer'] = cellRendererList
          .find((c) => c.code === value)
          ?.function?.(params);
      }
      if (key === 'editor') {
        if (currentField) {
          // @ts-ignore
          currentField.editor = value;
        }
      }
      (currentColumn as any)[key] = value;
    }
    forceUpdate?.(settingItem?.id);
  };

  return (
    <section className='htd-aside-right-main-section file-edit-panel-content-back'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={initialValues}
        onValuesChange={handleChangeValues}
      >
        <AntdForm.Item label='可编辑' name='editor'>
          <VariableInput
            globalDsConfig={tree.dsConfig}
            extraVariable={{
              params: {
                name: '编辑器参数',
                code: '(record, name) =>',
                usage: `<p>
                  使用方法示例：(record, name) ={'>'} record.get('fieldName')
                  === 'field1'
                </p>`,
              },
            }}
          >
            <Switch checked={!!currentColumn?.editor} />
          </VariableInput>
        </AntdForm.Item>
        <AntdForm.Item label='隐藏' name='hidden'>
          <VariableInput globalDsConfig={tree.dsConfig}>
            <Switch checked={!!currentColumn?.hidden} />
          </VariableInput>
        </AntdForm.Item>
        <AntdForm.Item label='可排序' name='sortable' valuePropName='checked'>
          <Switch />
        </AntdForm.Item>
        <AntdForm.Item label='列固定' name='lock'>
          <Radio.Group>
            {COLUMN_LOCK_LIST.map((type) => (
              <Radio.Button value={type.value} key={type.value}>
                {type.meaning}
              </Radio.Button>
            ))}
          </Radio.Group>
        </AntdForm.Item>
        <AntdForm.Item label='对齐方式' name='align'>
          <Radio.Group>
            {COLUMN_ALIGN_LIST.map((type) => (
              <Radio.Button value={type.value} key={type.value}>
                {type.meaning}
              </Radio.Button>
            ))}
          </Radio.Group>
        </AntdForm.Item>
        <AntdForm.Item label='列宽' name='width' className='htd-input-width'>
          <InputNumber />
        </AntdForm.Item>
        <AntdForm.Item label='提示信息' name='tooltip'>
          {/* <Radio.Group>
            {COLUMN_TOOLTIP_LIST.map((type) => (
              <Radio.Button value={type.value} key={type.value}>
                {type.meaning}
              </Radio.Button>
            ))}
          </Radio.Group> */}
          <Select allowClear suffixIcon={<SelectRightImg />}>
            {COLUMN_TOOLTIP_LIST.map((type) => (
              <Select.Option value={type.value} key={type.value}>
                {type.meaning}
              </Select.Option>
            ))}
          </Select>
        </AntdForm.Item>
        <AntdForm.Item label='列渲染' name='render'>
          <Select allowClear suffixIcon={<SelectRightImg />}>
            {cellRendererList.map((renderer) => (
              <Select.Option value={renderer.code} key={renderer.code}>
                {renderer.name}
              </Select.Option>
            ))}
          </Select>
        </AntdForm.Item>
      </AntdForm>
    </section>
  );
};

export default ColumnAttribute;

import { Upload as FormUpload } from 'components/placeholders';
import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { OTHER_PROPS } from 'utils/config';

export const Upload: Mapping = {
  name: 'Upload',
  iconCode: 'Upload',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  title: '上传组件',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      label: '标题',
      type: 'string',
      value: '上传文件',
    },
    action: {
      label: '上传地址',
      type: 'string',
      value: '',
    },
    uploadImmediately: {
      label: '立即上传',
      type: 'boolean',
      value: false,
    },
    showPreviewImage: {
      label: '图片预览',
      type: 'boolean',
      value: false,
    },
    showUploadList: {
      label: '显示列表',
      type: 'boolean',
      value: false,
    },
    appendUpload: {
      label: '批量上传',
      type: 'boolean',
      value: false,
    },
    colSpan: {
      label: '所占列数',
      type: 'number-range',
      value: 1,
      min: 1,
      max: 3,
      group: OTHER_PROPS,
    },
  },
  placeholderComponent: FormUpload,
  children: [],
};

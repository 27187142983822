"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformString2Str = exports.transformString2Expression = exports.transformArray2Ast = exports.transformObject2Ast = void 0;
/**
 * 将Object转换成AST识别的字符串模式
 */
function transformObject2Ast(obj) {
    return "{" + Object.keys(obj).reduce(function (v, next) {
        return v + " " + transformVariableKey(next) + ": " + (valueOfObject(next, obj[next]) === undefined
            ? transformObject2Ast(obj[next])
            : valueOfObject(next, obj[next])) + ",";
    }, '') + "}";
}
exports.transformObject2Ast = transformObject2Ast;
var variableReg = RegExp(/{.*?}/);
function transformVariableKey(key) {
    if (variableReg.test(key)) {
        return key.replace('{', '').replace('}', '');
    }
    return key;
}
function valueOfObject(key, value) {
    // 值是个变量
    if (variableReg.test(key)) {
        return "" + value;
    }
    if (typeof value === 'string') {
        return "'" + value + "'";
    }
    if (value instanceof Object && value.__isVariable) {
        return value.value;
    }
    if (value instanceof Object) {
        return undefined;
    }
    return value;
}
/**
 * 将Object转换成AST识别的字符串模式
 */
function transformArray2Ast(arr) {
    return '['
        .concat(arr
        .map(function (a) {
        return a instanceof Object ? transformObject2Ast(a) : transformString2Str(a);
    })
        .join(','))
        .concat(']');
}
exports.transformArray2Ast = transformArray2Ast;
/**
 * 将字符串字面量转变成字符串表达式
 */
function transformString2Expression(str) {
    return '`'.concat(str).concat('`');
}
exports.transformString2Expression = transformString2Expression;
/**
 * 将字符串字面量转变成字符串
 */
function transformString2Str(str) {
    return "'".concat(str).concat("'");
}
exports.transformString2Str = transformString2Str;

import { Mapping } from '@htd/common';
import { IconPicker } from 'choerodon-ui/pro';
import { ExcelExport as HZeroExcelExport } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
// import ButtonEvents from '@htd/helper/lib/templates/bu。tton-events';

export const ExcelExport: Mapping = {
  name: 'ExcelExport',
  title: 'Excel导出按钮',
  type: 'inline-block',
  lib: 'components/ExcelExport',
  iconCode: 'excelExport',
  props: {
    ...BASE_PROPS_TYPES,
    buttonText: { type: 'string', value: '导出', label: '标题' },
    buttonIcon: {
      type: 'string',
      value: 'export',
      label: '按钮图标',
      defaultComponent: () => <IconPicker />,
    },
    disabled: {
      type: 'boolean',
      label: '禁用',
      value: undefined,
      valueConfigurable: true,
      isDS: true,
    },
    conditionHidden: {
      type: 'boolean',
      label: '隐藏',
      value: false,
      conditionType: 'negative',
      valueConfigurable: true,
      ignore: true,
    },
    permissionCode: {
      type: 'string',
      value: undefined,
      label: '权限编码',
      help: '融合hzero按钮权限设计，可在菜单权限集配置显示禁用模式，此处填写权限编码',
    },
    method: {
      type: 'collection',
      value: 'GET',
      label: '请求方式',
      options: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
    },
    requestUrl: {
      type: 'string',
      valueConfigurable: true,
      variableExample: (
        <pre>
          `/hfpm/${'\u007b'}this.getCurrentTenant().tenantId{'\u007d'}
          /v1/banks/export`
        </pre>
      ),
      value: '',
      label: '导出API',
    },
    title: { type: 'string', value: '导出', label: '导出框标题' },
    onClick: {
      type: 'function',
      label: '点击事件',
      hidden: true,
    },
  },
  // availableEvents: [
  //   {
  //     name: 'onClick',
  //     meaning: '点击事件',
  //     list: ButtonEvents.filter((i) => i.code && i.name && i.function),
  //   },
  // ],
  children: [],
  placeholderComponent: HZeroExcelExport as any,
  isDefaultImport: true,
};

export const HZERO_PLATFORM = '/hpfm';
export const CLIENT_ID = process.env.BUILDER_CLIENT_ID || 'localhost';
export const API_HOST =
  process.env.BUILDER_API_HOST ||
  'http://hzero-backend.open-front.hand-china.com';
export const DEV_API =
  `${process.env.BUILD_NODE_API}` === `build_websocket_port`.toUpperCase()
    ? ''
    : process.env.BUILD_NODE_API || 'http://localhost:3033';

export const BASIC_PROPS = 'basic';
export const OTHER_PROPS = 'other';
export const RELATION_PROPS = 'relation';
export const DATA_PROPS = 'data';

export const propsGroupConfig: Record<
  string,
  { title: string; order?: number }
> = {
  [BASIC_PROPS]: {
    title: '基础属性',
    order: 0,
  },
  [OTHER_PROPS]: {
    title: '其他属性',
    order: 1,
  },
  [RELATION_PROPS]: {
    title: '关系',
    order: 1,
  },
  [DATA_PROPS]: {
    title: '数据属性',
    order: 1,
  },
};

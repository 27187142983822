import React, { useContext, useEffect, useMemo } from 'react';
import { Form as AntdForm, Input, Select } from 'antd';
import VariableInput from 'components/VariableInput';
import { DesignContext } from 'views/Home/DesignContext';
import {
  handleChnageTablePropsFunc,
  handleCuDataFunc,
} from '@/mapping/data/Table/utils';

import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import './index.less';
import SelectRightImg from 'components/selectRightImg';
import { FieldType } from 'choerodon-ui/pro/lib/data-set/enum';

const OtherAttribute: React.FC<{ record: any; item: any }> = ({
  record,
  item,
}) => {
  const { tree, settingItem, forceUpdate } = useContext(DesignContext);
  const { dsFields, configItem } = useContext(ConfigContext);
  const [form] = AntdForm.useForm();

  // 当前字段
  const currentField = useMemo(
    () => dsFields?.find((f: any) => f.fieldKey === record?.ds_fieldKey),
    [dsFields, record]
  );

  // 从applicationFor中处理数据
  const currentFieldCopy: any = useMemo(
    () =>
      handleCuDataFunc(currentField, item, [
        'lovCode',
        'lookupCode',
        'multiple',
        'ignore',
        'defaultValue',
      ]),
    [currentField, item]
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentField?.type, configItem?.index]);

  /**
   * 表单值变更
   * @param changedValues
   */
  const handleChangeValues = (changedValues: any) => {
    if (currentField) {
      for (const key in changedValues) {
        const value = changedValues[key];
        if (currentField) {
          handleChnageTablePropsFunc(item, currentField, key, value);
        }
        // (currentField as any)[key] = value;
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  return (
    <section className='file-edit-panel-content-back'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={currentFieldCopy}
        onValuesChange={handleChangeValues}
      >
        {currentField?.type === 'string' && (
          <AntdForm.Item label='值集编码' name='lookupCode'>
            <Input />
          </AntdForm.Item>
        )}
        {currentField?.type === 'object' && (
          <AntdForm.Item label='Lov编码' name='lovCode'>
            <Input />
          </AntdForm.Item>
        )}
        <AntdForm.Item label='默认值' name='defaultValue'>
          <VariableInput
            globalDsConfig={tree.dsConfig}
            extraVariable={{
              params: {
                name: '校验参数',
                code: '(params) =>',
                usage: (
                  <>
                    <p>
                      可获取行数据以及字段名，例如：params.record、params.name
                    </p>
                    <p>
                      使用方法示例：(params) ={'>'}{' '}
                      params.record.get('fieldName') === 'field1'
                    </p>
                  </>
                ),
              },
            }}
          />
        </AntdForm.Item>
        {currentField?.type !== FieldType.intl && (
          <AntdForm.Item label='多值' name='multiple' valuePropName='checked'>
            {/* <Switch /> */}
            <Select allowClear suffixIcon={<SelectRightImg />}>
              <Select.Option value='true'>true</Select.Option>
              <Select.Option value=','>,</Select.Option>
              <Select.Option value=';'>;</Select.Option>
              <Select.Option value='#'>#</Select.Option>
              <Select.Option value='_'>_</Select.Option>
            </Select>
          </AntdForm.Item>
        )}
        <AntdForm.Item label='忽略提交' name='ignore'>
          <Select allowClear suffixIcon={<SelectRightImg />}>
            <Select.Option value='always'>总是忽略</Select.Option>
            <Select.Option value='clean'>值未变化时忽略</Select.Option>
            <Select.Option value='never'>从不忽略</Select.Option>
          </Select>
        </AntdForm.Item>
      </AntdForm>
    </section>
  );
};

export default OtherAttribute;

import { mappings } from '../mapping';
import * as uuid from 'uuid';
import {
  ComponentTree,
  DesignComponent,
  DesignConfig,
  Mapping,
} from '@htd/common';
import { DATA_HCG_FLAG, PLACEHOLDER_ID } from './constants';
import { processPropsHolder } from './render-component';
import { DropResult, ReorderResult } from '../components/DragDrop';
import { cloneDeep, entries, isFunction } from 'lodash';
import { forEachNum, getJsxFlag } from './utils';
import { MappingListItem } from '@/mapping/util';
import { insertChildrenEffectDsConfig } from '@/mapping/container/Form/utils';

export function getAllMapping(): MappingListItem[] {
  return mappings.reduce(
    (old, cur) => [...old, ...cur.list],
    [] as MappingListItem[]
  );
}

export function findMappingById(id: string = '') {
  const allComponentList = [];
  for (const mapping of mappings) {
    allComponentList.push(...mapping.list);
  }
  const mapping = allComponentList.find((c) => c.id === id)?.mapping;
  if (mapping) {
    return cloneDeep(mapping);
  }
  return undefined;
}

export function generateCanvasComponentById(
  id: string = '',
  to?: string
): DesignComponent | undefined {
  if (id === PLACEHOLDER_ID) {
    return {
      id: PLACEHOLDER_ID,
      name: 'PLACEHOLDER',
    } as any;
  }
  const toAdd = findMappingById(id);
  const newComponent: DesignComponent | undefined = toAdd
    ? {
        ...toAdd,
        componentId: id,
        id: uuid.v4(),
        props: processPropsHolder(toAdd.props) as any,
        children: [],
      }
    : undefined;
  if (to && newComponent) {
    newComponent.parentId = to;
    newComponent.props![DATA_HCG_FLAG] = {
      ...newComponent.props![DATA_HCG_FLAG],
      type: 'string',
      value: getJsxFlag(newComponent.name, newComponent),
    };
    if (newComponent.props!.customizedCode) {
      newComponent.props!.customizedCode.value =
        newComponent.props![DATA_HCG_FLAG].value;
    }
  }
  return newComponent;
}

export function ensureChildren(target: DesignComponent) {
  if (!target.children) {
    target.children = [];
  }
}

export function clearPlaceholder(
  map: Map<string | undefined, DesignComponent>,
  targetContainer?: string
) {
  if (!map) return;
  function clear(key: string) {
    const target = map.get(key);

    if (target) {
      const i = target.children?.findIndex((c) => c.id === PLACEHOLDER_ID);
      if (typeof i === 'number' && i > -1) {
        target.children?.splice(i, 1);
      }
      map.delete(PLACEHOLDER_ID);
    }
  }
  if (targetContainer) {
    clear(targetContainer);
  } else {
    map.forEach((_, key) => clear(key!));
  }
}

export function dropNewItemToList(
  containerMap: Map<any, DesignComponent>,
  to: string,
  target: any,
  index: number,
  tree: DesignConfig,
  id: string,
  setSettingItem?: Function,
  isInitial?: boolean,
  update?: Function,
  extraNewProps?: any[], // 可以额外传入newItem props
  defaultComponent?: DesignComponent | undefined
) {
  let newComponent: DesignComponent | undefined;
  if (defaultComponent) {
    newComponent = defaultComponent;
  } else {
    newComponent = generateCanvasComponentById(id, to);
  }
  if (newComponent) {
    if (isInitial) {
      newComponent.id = id;
    }
    if (extraNewProps) {
      extraNewProps.forEach((p: any) => {
        if (newComponent!.props) {
          newComponent!.props[p.key] = p.value;
        }
      });
    }
    ensureChildren(target);
    if (isInitial) {
      target.children?.push(newComponent);
    } else {
      target.children?.splice(index, 0, newComponent);
    }
    containerMap.set(newComponent.id, newComponent);
    setSettingItem?.(newComponent);
    // 如果拖动输入组件到Form容器，执行特殊逻辑
    // 给Form下组件自动生成fieldKey value
    if (newComponent.props?.fieldKey && !newComponent.props?.fieldKey.value) {
      newComponent.props.fieldKey.value = uuid.v4();
    }
    insertChildrenEffectDsConfig(target, newComponent, tree, update);
  }
}

/**
 * 布局容器渲染及插入tree
 */
function dropNewLayoutToList(
  containerMap: Map<any, DesignComponent>,
  to: string,
  target: any,
  index: number,
  tree: DesignConfig,
  id: string,
  data: any,
  setSettingItem?: Function,
  isInitial?: boolean
) {
  const layout = findMappingById(id);
  if (!layout) {
    return;
  }
  const rowNum = layout.props?.rowNum?.value || 1;
  const colNum =
    layout.props?.colNum?.value || layout.props?.columns?.value.length || 1;

  forEachNum(rowNum, (i: number) => {
    // 先创建一个Row
    const rowComponent = generateCanvasComponentById(
      data.childrenId ? data.id : 'hcg-layout-pro-row',
      target.id
    );
    if (rowComponent) {
      if (isInitial) {
        rowComponent.id = id;
      }
      ensureChildren(target);
      target.children?.splice(index + i, 0, rowComponent);
      containerMap.set(rowComponent.id, rowComponent);
      setSettingItem?.(rowComponent);
      forEachNum(colNum, () => {
        // 再创建一个Col
        const colComponent = generateCanvasComponentById(
          data.childrenId ? data.childrenId : 'hcg-layout-pro-col',
          rowComponent.id
        );
        if (colComponent?.props?.span?.value) {
          colComponent.props.span.value = Math.floor(24 / colNum);
        }
        if (colComponent) {
          if (isInitial) {
            colComponent.id = id;
          }
          ensureChildren(rowComponent);
          rowComponent.children?.push(colComponent);
          containerMap.set(colComponent.id, colComponent);
        }
      });
    }
  });
}

export function dropItemToList(
  item: DropResult,
  containerMap: Map<any, DesignComponent>,
  update: Function,
  setSettingItem: Function,
  tree: DesignConfig,
  isNotDrop?: boolean // 非拖动场景
) {
  const { from, data, to, index } = item;
  clearPlaceholder(containerMap);
  let dropTarget: DesignComponent | undefined;
  if (from === 'list') {
    const target = (dropTarget = containerMap.get(to));
    if (target) {
      // modal嵌套表单默认设置为一行一列
      // if(target.name === "Modal"&&data.mapping!.props!.columns) data.mapping!.props!.columns!.value = 1
      // if(target.name === "Content"&&data.mapping!.props!.columns) data!.mapping!.props!.columns!.value = 3

      // @ts-ignore
      if (data.mapping.type === 'layout' || data.childrenId) {
        dropNewLayoutToList(
          containerMap,
          to,
          target,
          index,
          tree,
          data.id!,
          data,
          setSettingItem
        );
      } else if ((data as any).type === 'block') {
        dropBlockToList(containerMap, to, data as any, index, tree);
      } else {
        dropNewItemToList(
          containerMap,
          to,
          target,
          index,
          tree,
          data.id!,
          setSettingItem,
          false,
          update,
          [],
          isNotDrop && (data as any).mapping
        );
      }
    }
  } else {
    const fromTarget = containerMap.get(from);
    const toTarget = (dropTarget = containerMap.get(to));
    const fromIndex = fromTarget?.children?.findIndex(
      (c) => c.id === item.data.id
    )!;
    if (fromIndex > -1) {
      fromTarget?.children?.splice(fromIndex, 1);
    }
    if (from !== to || fromIndex > index) {
      toTarget?.children?.splice(index, 0, item.data as DesignComponent);
    } else {
      toTarget?.children?.splice(
        index - 1 > -1 ? index - 1 : 0,
        0,
        item.data as DesignComponent
      );
    }
    (data as DesignComponent).parentId = to;
  }
  containerMap.delete(PLACEHOLDER_ID);
  update(dropTarget?.id);
}

export function initialTree(
  containerMap: Map<any, DesignComponent>,
  update: Function,
  tree: DesignConfig
) {
  const hiddenList = mappings.find((m) => m.name === 'hidden');
  hiddenList?.list
    .filter((item) =>
      ['hzero-header', 'hzero-content', 'custom-footer'].includes(item.id)
    )
    .forEach((item) => {
      const to = 'root';
      const target = containerMap.get(to);
      if (target) {
        dropNewItemToList(
          containerMap,
          to,
          target,
          0,
          tree,
          item.id!,
          undefined,
          true
        );
      }
    });
  update();
}

export function listReorder(
  result: ReorderResult,
  containerMap: Map<any, DesignComponent>,
  update: Function
) {
  const { targetContainer, item } = result;
  const target = containerMap.get(targetContainer);
  const from = containerMap.get(item.from);
  const fromIndex = from?.children?.findIndex((c) => c.id === item.data.id)!;
  // 先删掉原来的
  if (fromIndex > -1) {
    from?.children?.splice(fromIndex, 1);
  }
  // 找到placeholder的index
  const pI = target?.children?.findIndex((c) => c.id === PLACEHOLDER_ID);
  if (typeof pI === 'number' && pI > -1) {
    target?.children?.splice(pI, 1);
    target?.children?.splice(pI, 0, item.data);
  }
  // 清除placeholder组件
  containerMap.delete(PLACEHOLDER_ID);
  update();
}

export function listReorderReady(
  result: ReorderResult,
  containerMap: Map<any, DesignComponent>,
  update: Function
) {
  const {
    targetContainer,
    targetIndex,
    targetDisplay,
    // item: { from },
  } = result;
  // 清除占位
  clearPlaceholder(containerMap);

  const oldPlaceholder = containerMap.get(PLACEHOLDER_ID);
  const placeholderComponent = generateCanvasComponentById(PLACEHOLDER_ID)!;
  placeholderComponent.type = targetDisplay as any;
  if (oldPlaceholder) {
    const targetChildren = containerMap.get(targetContainer)?.children || [];
    const index = targetChildren.findIndex((c) => c.id === PLACEHOLDER_ID);
    targetChildren.splice(index, 1);
    targetChildren.splice(targetIndex, 0, placeholderComponent);
  } else {
    const target = containerMap.get(targetContainer);
    target?.children?.splice(targetIndex, 0, placeholderComponent);
  }
  containerMap.set(PLACEHOLDER_ID, placeholderComponent);
  update();
}

export function findMappingByData(data: Mapping) {
  const allMapping = getAllMapping();
  return cloneDeep(
    allMapping.find(
      ({ mapping: { name, lib } = {} }) =>
        name === data?.name && data?.lib === lib
    )
  );
}

export function resolveFragment(content: any) {
  const { data, allDs = [] } = content;
  function getNodeFromData(child: any, parent?: string) {
    const result = { ...child };
    const cur = findMappingByData(child);
    if (!cur) {
      throw new Error('未找到对应映射，请确定代码生成器版本');
    }
    entries(cur.mapping).forEach(([k, v]) => {
      if (k === 'placeholderComponent' || isFunction(v)) {
        result[k] = v;
      }
    });
    if (parent) {
      result.parentId = parent;
    }
    result.id = uuid.v4();
    if (result.children?.length) {
      result.children = result.children.map((c: any) =>
        getNodeFromData(c, result.id)
      );
    }
    return result;
  }
  return { data: getNodeFromData(data), allDs };
}

function dropBlockToList(
  cMap: Map<string, DesignComponent>,
  to: string,
  data: { mapping: DesignComponent; allDs: any[] },
  index: number,
  tree: ComponentTree
) {
  const target = cMap.get(to);
  function setMap(child: DesignComponent) {
    cMap.set(child.id!, child);
    if (child.children) {
      child.children.forEach(setMap);
    }
  }
  if (target) {
    const finalMapping = cloneDeep(data.mapping);
    finalMapping.id = uuid.v4();
    finalMapping.parentId = to;
    target.children.splice(index, 0, finalMapping);
    setMap(finalMapping);
  }
  // 如果DS已存在，就不用再次添加DS
  data.allDs?.forEach((ds) => {
    if (!tree.dsConfig.find((td) => td.dsKey === ds.dsKey)) {
      tree.dsConfig.push(ds);
    }
  });
}

import { Icon } from 'choerodon-ui';
import React, { forwardRef } from 'react';
import { headerRightJTIcon } from '../../utils/unifiedExportIcon';

const Header = forwardRef((props: any, ref: any) => {
  const chrValue: any = props?.children;
  const isHaveChildrenheaderFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;
  return (
    <div
      className='htd-placeholder-header'
      ref={ref}
      style={{ textAlign: props.textLayout }}
    >
      <span>
        {props.backPath !== '' ? <Icon type='arrow_back' /> : null}
        {props.title}
      </span>
      <div>{props.children}</div>
      {!isHaveChildrenheaderFlag && (
        <img
          className='htd-drag-header-right-jt'
          alt='arrow'
          src={headerRightJTIcon}
        />
      )}
    </div>
  );
});

const Content = forwardRef((props: any, ref: any) => {
  return (
    <div className='htd-placeholder-content' ref={ref}>
      {props.children}
    </div>
  );
});

const Footer = forwardRef((props: any, ref: any) => {
  return (
    <div
      className='htd-placeholder-footer'
      ref={ref}
      style={{ textAlign: props.textLayout }}
    >
      <div>{props.children}</div>
    </div>
  );
});

export { Header, Content, Footer };

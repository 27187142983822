import { Mapping } from '@htd/common';
import { Div as DivPlaceholder } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Spin: Mapping = {
  name: 'Spin',
  iconCode: 'spinner',
  type: 'container',
  lib: 'choerodon-ui/pro',
  title: 'Spin',
  props: {
    ...BASE_PROPS_TYPES,
    spinning: {
      label: '旋转状态',
      type: 'string',
      valueConfigurable: true,
      variableExample: <span>this.getDs('xxx').status!=="ready"</span>,
    },
  },
  placeholderComponent: DivPlaceholder,
  children: [],
};

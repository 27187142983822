import { MappingListItem } from '../util';
import { TextField } from './TextField';
import { NumberField } from './NumberField';
import { Select } from './Select';
import { Lov } from './Lov';
import { DatePicker } from './DataPicker';
import { Output } from './Output';
import { Range } from './Range';
import { TextArea } from './TextArea';
import { Password } from './Password';
import { EmailField } from './EmailField';
import { Switch } from './Switch';
import { IntlField } from './IntlField';
import { UrlField } from './UrlField';
import { Currency } from './Currency';
import { RichText } from './RichText';
import { Upload } from './Upload';

export const baseList: Array<MappingListItem> = [
  {
    id: 'hcg-base-pro-button-group',
    name: '按钮组',
  },
  {
    id: 'hcg-base-pro-textField',
    name: '文本输入',
    mapping: TextField,
  },
  {
    id: 'hcg-base-pro-numberField',
    name: '数字输入',
    mapping: NumberField,
  },
  {
    id: 'hcg-base-pro-datePicker',
    name: '日期选择',
    mapping: DatePicker,
  },
  {
    id: 'hcg-base-pro-select',
    name: '下拉选择',
    mapping: Select,
  },
  {
    id: 'hcg-base-pro-selectBox',
    name: '平铺选择',
  },
  {
    id: 'hcg-base-pro-lov',
    name: 'Lov选择',
    mapping: Lov,
  },
  {
    id: 'hcg-base-pro-switch',
    name: '开关',
    mapping: Switch,
  },
  {
    id: 'hcg-base-pro-textArea',
    name: '文本域',
    mapping: TextArea,
  },
  {
    id: 'hcg-base-pro-emailField',
    name: '邮箱输入',
    mapping: EmailField,
  },
  {
    id: 'hcg-base-pro-password',
    name: '密码输入',
    mapping: Password,
  },
  {
    id: 'hcg-base-pro-output',
    name: '文本展示',
    mapping: Output,
  },
  {
    id: 'hcg-base-pro-intlField',
    name: '多语言输入',
    mapping: IntlField,
  },
  {
    id: 'hcg-base-pro-urlField',
    name: 'Url输入',
    mapping: UrlField,
  },
  {
    id: 'hcg-base-pro-currency',
    name: '货币输入',
    mapping: Currency,
  },
  {
    id: 'hcg-base-link',
    name: '超链接',
  },
  {
    id: 'hcg-base-pro-richText',
    name: '富文本',
    mapping: RichText,
  },
  {
    id: 'hcg-base-pro-radio',
    name: '单选框',
    // mapping: Radio,
  },
  {
    id: 'hcg-base-pro-checkbox',
    name: '多选框',
  },
  {
    id: 'hcg-base-pro-cascader',
    name: '级联选择',
  },
  {
    id: 'hcg-base-pro-range',
    name: '滑块',
    mapping: Range,
  },
  {
    id: 'hcg-base-pro-upload',
    name: '上传',
    mapping: Upload,
  },
];

// import { Collapse } from 'antd';
// import { CaretRightOutlined } from '@ant-design/icons';
import {
  // ConfigPanelComponentProp,
  Mapping,
} from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { Tree as treePlaceholder } from 'components/placeholders';
import TreeEvents from '@htd/helper/lib/templates/tree-events';

export const Tree: Mapping = {
  name: 'Tree',
  title: '树',
  iconCode: 'Tree',
  type: 'block',
  lib: 'choerodon-ui/pro',
  placeholderComponent: treePlaceholder,
  props: {
    ...BASE_PROPS_TYPES,

    showLine: { label: '连接线', type: 'boolean', value: true },
    checkable: { label: '勾选', type: 'boolean', value: false },
    selectable: {
      label: '可选中',
      type: 'boolean',
      value: false,
      hidden: true,
    },

    idField: {
      label: '主键字段',
      type: 'string',
      value: 'id',
      ignore: true,
      group: 'data',
    },
    parentField: {
      label: '关联字段',
      type: 'string',
      value: 'parentId',
      ignore: true,
      group: 'data',
    },
    textField: {
      label: '文本字段',
      type: 'string',
      value: 'text',
      ignore: true,
      group: 'data',
    },
    expandField: {
      label: '折叠字段',
      type: 'string',
      value: 'expand',
      ignore: true,
      group: 'data',
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，树组件处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    queryUrl: {
      label: '数据接口',
      type: 'string',
      valueConfigurable: true,
      variableExample: (
        <span>
          /hfpm/v1/${'\u007b'}this.getCurrentTenant().tenantId{'\u007d'}
          /bank/list
        </span>
      ),
      ignore: true,
      group: 'data',
    },
    onSelect: {
      type: 'object',
      label: '选中事件',
      hidden: true,
      ignore: true,
    },
    onClick: {
      type: 'object',
      label: '点击事件',
      hidden: true,
      ignore: true,
    },
  },
  // configPanelComponent: (props: ConfigPanelComponentProp) => {
  //   return (
  //     <Collapse
  //       defaultActiveKey={['dataset_config']}
  //       expandIcon={({ isActive }) => (
  //         <CaretRightOutlined rotate={isActive ? 90 : 0} />
  //       )}
  //     >
  //       <Collapse.Panel header='数据配置' key='dataset_config'>
  //         <DataSetPanel {...props} />
  //       </Collapse.Panel>
  //     </Collapse>
  //   );
  // },

  availableEvents: [
    {
      name: 'onClick',
      meaning: '点击事件',
      list: TreeEvents.filter((i) => i.code && i.name && i.function),
      customHasParam: true,
    },
    {
      name: 'onSelect',
      meaning: '选中事件',
      list: [],
    },
  ],
  children: [],
};

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Select, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import VariableInput from 'components/VariableInput';

const AddFunctionModal: FC<{
  modalVisible: boolean;
  onCancel?: () => any;
  onOk?: (c: any) => any;
  tableQueryFields?: any[];
  functionConfig?: any;
  functionConfigIndex?: number;
}> = ({
  modalVisible,
  onCancel,
  onOk,
  tableQueryFields,
  functionConfig,
  functionConfigIndex,
}) => {
  const [fxType, setfxType] = useState<string>('IF');
  const [fxDesc, setfxDesc] = useState<string>('');

  const defaultvalue = useMemo(
    () => ({
      conditionType: 'QUERY_FIELD',
      fieldName: '',
      logic: 'EQUAL',
      targetType: 'VALUE',
      fxValue: '',
    }),
    []
  );
  const [fxConfigLines, setfxConfigLines] = useState<any[]>([defaultvalue]);

  useEffect(() => {
    if (functionConfig && functionConfig.fxConfigLines) {
      setfxConfigLines(functionConfig.fxConfigLines);
      setfxDesc(functionConfig.fxDesc);
    }
  }, [functionConfig]);

  const handleModalCancel = () => {
    setfxDesc('');
    setfxConfigLines([defaultvalue]);
    onCancel?.();
  };
  const handleModalOk = useCallback(() => {
    onOk?.({ fxDesc, fxConfigLines, functionConfigIndex });
    setfxDesc('');
    setfxConfigLines([defaultvalue]);
  }, [
    onOk,
    fxConfigLines,
    fxDesc,
    setfxConfigLines,
    defaultvalue,
    functionConfigIndex,
  ]);

  const addFxConfigLine = useCallback(() => {
    setfxConfigLines([...fxConfigLines, defaultvalue]);
  }, [fxConfigLines, setfxConfigLines, defaultvalue]);

  const deleteFxConfigLine = useCallback(
    (index: number) => {
      // console.log(fxConfigLines.splice(index, 1));
      // console.log(fxConfigLines);
      fxConfigLines.splice(index, 1);
      setfxConfigLines([...fxConfigLines]);
    },
    [fxConfigLines, setfxConfigLines]
  );

  const updateFxConfigLine = useCallback(
    (index: number, data: any) => {
      fxConfigLines.splice(index, 1, data);
      setfxConfigLines([...fxConfigLines]);
    },
    [fxConfigLines, setfxConfigLines]
  );

  const renderUsage = (): string => {
    const str = [
      '用法：IF函数判断一个条件能否满足；如果满足，返回一个值，如果不满足，则返回另一个值',
      '示例：IF{查询字段A（><=......）值（fx）}&IF{查询字段B（><=......）值（fx）}&......`',
    ];

    return str.join('</br>');
  };

  return (
    <Modal
      visible={modalVisible}
      onCancel={handleModalCancel}
      onOk={handleModalOk}
      title='动态列逻辑配置'
      width='55.6%'
      className='htd-common-action-modal model-add-func'
      okText='确定'
      cancelText='取消'
      destroyOnClose
    >
      <div className='model-body-top-box'>
        fx类型：
        <Select value={fxType} onChange={(v: string) => setfxType(v)}>
          <Select.Option value='IF'>IF(条件)</Select.Option>
          <Select.Option value='SUM' disabled>
            SUM(求和)
          </Select.Option>
          <Select.Option value='AVERAGE' disabled>
            AVERAGE(平均数)
          </Select.Option>
          <Select.Option value='CUSTOM' disabled>
            自定义函数
          </Select.Option>
          <Select.Option value='CONCATENATE' disabled>
            CONCATENATE(合并)
          </Select.Option>
          <Select.Option value='SUMIF' disabled>
            SUMIF(条件汇总)
          </Select.Option>
        </Select>
      </div>
      <div className='model-body-middle-box'>
        <Row
          className='htd-common-action-modal-content'
          style={{ height: '200px', position: 'relative' }}
        >
          <div className='model-body-box-title'>fx配置</div>
          <Col
            span={24}
            className='htd-common-action-modal-content-action-from model-body-middle-box-i'
            style={{ height: '200px', overflowY: 'auto' }}
          >
            {fxConfigLines.map((fc, index) => (
              <FxConfigLine
                index={index}
                addFxConfigLine={addFxConfigLine}
                deleteFxConfigLine={deleteFxConfigLine}
                setFxConfigLines={updateFxConfigLine}
                data={fc}
                currentFxConfigLines={fxConfigLines}
                tableQueryFields={tableQueryFields}
              />
            ))}
          </Col>
        </Row>
      </div>
      <div className='middle-bottom-box'>
        <div className='model-body-butt-box'>
          <Row
            className='htd-common-action-modal-content'
            style={{ height: '200px', position: 'relative' }}
          >
            <div className='model-body-box-title'>fx描述</div>
            <Col
              span={24}
              className='htd-common-action-modal-content-action-from model-body-butt-box-i'
              style={{ height: '200px', overflowY: 'auto' }}
            >
              <Input.TextArea
                value={fxDesc}
                style={{ height: '-webkit-fill-available' }}
                onChange={(e: any) => {
                  setfxDesc(e.target.value);
                }}
                className='model-body-butt-box-i-input'
              />
            </Col>
          </Row>
        </div>
        <div className='model-body-butt-fx'>
          <Row
            className='htd-common-action-modal-content'
            style={{ height: '200px', position: 'relative' }}
          >
            <div className='model-body-box-title'>fx用法</div>
            <Col
              span={24}
              className='htd-common-action-modal-content-action-from'
              style={{ height: '200px', overflowY: 'auto', padding: '16px' }}
            >
              <div dangerouslySetInnerHTML={{ __html: renderUsage() }} />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default AddFunctionModal;

const FxConfigLine: FC<any> = ({
  index,
  addFxConfigLine,
  deleteFxConfigLine,
  setFxConfigLines,
  data,
  currentFxConfigLines,
  tableQueryFields,
}) => {
  const handleChange = useCallback(
    (f: string, v: any) => {
      setFxConfigLines(index, {
        ...data,
        [f]: v,
      });
    },
    [setFxConfigLines, index, data]
  );

  return (
    <Row className='model-body-middle-box-i-list'>
      <Col span={4}>
        <Select
          value={data.conditionType}
          onChange={(v: string) => handleChange('conditionType', v)}
          style={{ width: '100%' }}
        >
          <Select.Option value='FIELD' disabled>
            字段
          </Select.Option>
          <Select.Option value='VALUE' disabled>
            值
          </Select.Option>
          <Select.Option value='QUERY_FIELD'>查询字段</Select.Option>
        </Select>
      </Col>
      <Col span={4}>
        {/* <Input
          value={data.fieldName}
          onChange={(v) => handleChange('fieldName', v.target.value)}
        /> */}
        <Select
          value={data.fieldName}
          style={{ width: '100%' }}
          onChange={(v) => handleChange('fieldName', v)}
        >
          {(tableQueryFields || []).map((tq: any) => {
            return <Select.Option value={tq.name}>{tq.label}</Select.Option>;
          })}
        </Select>
      </Col>
      <Col span={4}>
        <Select
          value={data.logic}
          style={{ width: '100%' }}
          onChange={(v: string) => handleChange('logic', v)}
        >
          <Select.Option value='EQUAL'>等于</Select.Option>
          <Select.Option value='NOT_EQUAL'>不等于</Select.Option>
          <Select.Option value='GREATER'>大于</Select.Option>
          <Select.Option value='LESS'>小于</Select.Option>
          <Select.Option value='GREATER_EQUAL'>大于等于</Select.Option>
          <Select.Option value='LESS_EQUAL'>大于等于</Select.Option>
          {/* <Select.Option value='CONTAIN'>包含</Select.Option>
          <Select.Option value='NOT_CONTAIN'>不包含</Select.Option> */}
          {/* <Select.Option value='IS' disabled>
            是
          </Select.Option>
          <Select.Option value='NOT_IS' disabled>
            不是
          </Select.Option> */}
        </Select>
      </Col>
      <Col span={2}>
        <Select
          value={data.targetType}
          style={{ width: '100%' }}
          onChange={(v: string) => handleChange('targetType', v)}
        >
          <Select.Option value='VALUE'>值</Select.Option>
        </Select>
      </Col>
      <Col className='model-body-middle-fx' span={8}>
        <VariableInput
          // globalDsConfig={tree.dsConfig}
          value={data.fxValue}
          onChange={(v) => handleChange('fxValue', v)}
        />
        <PlusOutlined onClick={addFxConfigLine} style={{ margin: '0 6px' }} />
        {currentFxConfigLines?.length > 1 && (
          <DeleteOutlined
            onClick={() => {
              deleteFxConfigLine(index);
            }}
          />
        )}
      </Col>
    </Row>
  );
};

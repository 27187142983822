import template from '@babel/template';
import generate from '@babel/generator';
import { getParam, regxReplaceLocalVariable, regxReplaceVariable, transformArray2Ast, transformKvArr2Obj, transformObject2Ast, transformString2Expression, transformString2Str, } from '@htd/common';
/**
 * Hzero项目内部跳转页签
 */
export function goToHzeroTab({ path, name, otherParam = [], }) {
    const search = transformKvArr2Obj(getParam('search', otherParam, []));
    const local = transformKvArr2Obj(getParam('local', otherParam, []));
    let key = path;
    if (local) {
        Object.keys(local).forEach((lk) => {
            key = regxReplaceVariable(key, lk, local[lk]);
        });
    }
    const createFunctionBodyAST = template(`{
    openTab({
      key: PLACEHOLDER_KEY,
      path: PLACEHOLDER_KEY,
      title: PLACEHOLDER_NAME,
      search: PLACEHOLDER_SEARCH,
      closable: true,
      type: 'menu',
    });
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_KEY: transformString2Expression(key),
        PLACEHOLDER_NAME: transformString2Expression(name),
        PLACEHOLDER_SEARCH: transformObject2Ast(search || {}),
    });
    return {
        libs: [
            {
                name: ['openTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * Hzero项目内部跳转页签 - 多页面模式
 */
export function goToHzeroTabDomain({ path, name, otherParam = [], }) {
    const openInCurrent = getParam('openInCurrent', otherParam, false);
    const search = transformKvArr2Obj(getParam('search', otherParam, []));
    const local = transformKvArr2Obj(getParam('local', otherParam, []));
    let key = path;
    if (local) {
        Object.keys(local).forEach((lk) => {
            key = regxReplaceVariable(key, lk, local[lk]);
        });
    }
    let functionBody;
    const PLACEHOLDER_SEARCH = transformObject2Ast(search || {});
    const isEmpAstObj = PLACEHOLDER_SEARCH === '{}';
    if (openInCurrent) {
        const createFunctionBodyAST = template(`{
      ${isEmpAstObj ? 'const search = {}' : 'const search = PLACEHOLDER_SEARCH'}
      this.props.history.push(PLACEHOLDER_KEY${isEmpAstObj
            ? ''
            : '.concat("?").concat(Object.keys(search).map(k=> `${k}=${search[k]}`).join("&"))'});
    }`);
        if (isEmpAstObj) {
            functionBody = createFunctionBodyAST({
                PLACEHOLDER_KEY: transformString2Expression(key),
            });
        }
        else {
            functionBody = createFunctionBodyAST({
                PLACEHOLDER_KEY: transformString2Expression(key),
                PLACEHOLDER_SEARCH,
                // PLACEHOLDER_JSON: 'JSON',
            });
        }
    }
    else {
        const createFunctionBodyAST = template(`{
      openTab({
        key: PLACEHOLDER_KEY,
        path: PLACEHOLDER_KEY,
        title: PLACEHOLDER_NAME,
        search: PLACEHOLDER_SEARCH,
        closable: true,
        type: 'menu',
      });
    }`);
        functionBody = createFunctionBodyAST({
            PLACEHOLDER_KEY: transformString2Expression(key),
            PLACEHOLDER_NAME: transformString2Expression(name),
            PLACEHOLDER_SEARCH: transformObject2Ast(search || {}),
        });
    }
    return {
        libs: [
            {
                name: ['openTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 关闭页签
 */
export function closeTab({ path, otherParam = [], }) {
    const local = transformKvArr2Obj(getParam('local', otherParam, []));
    let key = path;
    if (local) {
        Object.keys(local).forEach((lk) => {
            key = regxReplaceVariable(key, lk, local[lk]);
        });
    }
    const createFunctionBodyAST = template(`{
    closeTab(PLACEHOLDER_PATH);
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_PATH: transformString2Expression(key),
    });
    return {
        libs: [
            {
                name: ['closeTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 关闭当前页签
 */
export function closeCurrentTab() {
    const createFunctionBodyAST = template(`{
    const route = this.getCurrentRoute();
    closeTab(route);
  }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['closeTab'],
                path: 'utils/menuTab',
            },
        ],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 全屏当前页面
 */
export function fullLocalScreen() {
    //
}
/**
 * 单数据源提交操作
 */
export function dataSetSubmit({ dataSetName, otherParam = [], }) {
    const isCloseTab = getParam('isCloseTab', otherParam, false);
    const isRefreshTab = getParam('isRefreshTab', otherParam, false);
    const successNotice = getParam('successNotice', otherParam, '');
    const failedNotice = getParam('failedNotice', otherParam, '');
    const createFunctionBodyAST = template(`{
    const ds =this.getDs(PLACEHOLDER_DATASET_NAME);
    return ds.submit().then(res=>
      submitedAction(res,PLACEHOLDER_ACTION_PARAM)
    );
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_DATASET_NAME: transformString2Str(dataSetName),
        PLACEHOLDER_ACTION_PARAM: transformObject2Ast({
            isCloseTab,
            isRefreshTab,
            successNotice,
            failedNotice,
        }),
    });
    return {
        libs: [
            {
                name: ['submitedAction'],
                path: '@htd/helper/lib/utils/dataSet',
            },
        ],
        async: true,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 多数据源提交操作
 * @dataSets [{key: default, value: formDs}, {key: items, value: tableDs}]
 */
export function dataSetsSubmit({ url, method, dataSets, otherParam = [], }) {
    const defDsArr = dataSets
        .filter((c) => !c.key || c.key === 'undefined' || c.key.trim() === '')
        .map((c) => c.value);
    const attrDsArr = dataSets.filter((c) => c.key !== 'default' &&
        c.key !== 'undefined' &&
        c.key !== undefined &&
        c.key.trim() !== '');
    const isCloseTab = getParam('isCloseTab', otherParam, false);
    const isRefreshTab = getParam('isRefreshTab', otherParam, false);
    const successNotice = getParam('successNotice', otherParam, '');
    const failedNotice = getParam('failedNotice', otherParam, '');
    const createFunctionBodyAST = template(`{
    const data = PLACEHOLDER_DEF_DS_ARR.reduce((v,n)=>{return {...v, ...(this.getDs(n)?.current?.toData()||{})}}, {})
    PLACEHOLDER_ATTR_DS_ARR.forEach(a=>{
      data[a.key] = this.getDs(a.value).toData();
    })
    const submitDs = new DataSet({
      autoCreate: true,
      transport: {
        create: () => ({
          url: PLACEHOLDER_URL,
          method: PLACEHOLDER_METHOD,
          data,
        }),
      },
    });
    return submitDs.submit().then(res=>
      submitedAction(res, PLACEHOLDER_ACTION_PARAM)
    );
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_DEF_DS_ARR: transformArray2Ast(defDsArr),
        PLACEHOLDER_ATTR_DS_ARR: transformArray2Ast(attrDsArr),
        PLACEHOLDER_URL: transformString2Expression(regxReplaceLocalVariable(url)),
        PLACEHOLDER_METHOD: transformString2Str(method),
        PLACEHOLDER_ACTION_PARAM: transformObject2Ast({
            isCloseTab,
            isRefreshTab,
            successNotice,
            failedNotice,
        }),
    });
    return {
        libs: [
            {
                name: ['DataSet'],
                path: 'choerodon-ui/pro',
            },
            {
                name: ['submitedAction'],
                path: '@htd/helper/lib/utils/dataSet',
            },
        ],
        async: true,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 多数据源提交操作(模型下)
 * @dataSets [{key: default, value: formDs}, {key: items, value: tableDs}]
 */
export function dataSetsSubmitDomain({ url, method, entitys, otherParam = [], }) {
    const ents = entitys.map((e) => JSON.parse(e));
    console.log(ents);
    // 分析实体间的关系
    // 1 找出所有头表实体【如果没有父实体，或者有父实体但是关系为1对1】
    const headers = ents.filter((e) => !e.parentEntity || (e.parentEntity && e.relType === 'one2one'));
    // 2 找出行表实体，以及行表实体对应的头表实体
    const lines = ents.filter((e) => e.parentEntity && e.relType === 'one2many');
    // 3 在头表实体中找到对应的外键字段
    const defDsArr = headers.map((h) => h.tableName);
    const attrDsArr = lines.map((l) => ({
        key: `${l.entityName
            .slice(0, 1)
            .toLowerCase()
            .concat(l.entityName.slice(1))}List`,
        value: l.tableName,
    }));
    const isCloseTab = getParam('isCloseTab', otherParam, false);
    const isRefreshTab = getParam('isRefreshTab', otherParam, false);
    const successNotice = getParam('successNotice', otherParam, '');
    const failedNotice = getParam('failedNotice', otherParam, '');
    const createFunctionBodyAST = template(`{
    const data = PLACEHOLDER_DEF_DS_ARR.reduce((v,n)=>{return {...v, ...(this.getDs(n)?.current?.toData()||{})}}, {})
    PLACEHOLDER_ATTR_DS_ARR.forEach(a=>{
      data[a.key] = this.getDs(a.value).toData();
    })
    const submitDs = new DataSet({
      autoCreate: true,
      transport: {
        create: () => ({
          url: PLACEHOLDER_URL,
          method: PLACEHOLDER_METHOD,
          data,
        }),
      },
    });
    return submitDs.submit().then(res=>
      submitedAction(res, PLACEHOLDER_ACTION_PARAM)
    );
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_DEF_DS_ARR: transformArray2Ast(defDsArr),
        PLACEHOLDER_ATTR_DS_ARR: transformArray2Ast(attrDsArr),
        PLACEHOLDER_URL: transformString2Expression(regxReplaceLocalVariable(url)),
        PLACEHOLDER_METHOD: transformString2Str(method),
        PLACEHOLDER_ACTION_PARAM: transformObject2Ast({
            isCloseTab,
            isRefreshTab,
            successNotice,
            failedNotice,
        }),
    });
    return {
        libs: [
            {
                name: ['DataSet'],
                path: 'choerodon-ui/pro',
            },
            {
                name: ['submitedAction'],
                path: '@htd/helper/lib/utils/dataSet',
            },
        ],
        async: true,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 打开页面内弹框
 */
export function openPageModal({ modalFlag }) {
    const createFunctionBodyAST = template(`{
    this.setState({PLACEHOLDER_MODAL_VISIBLE: true})
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_MODAL_VISIBLE: `${modalFlag}Visible`,
    });
    return {
        libs: [],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 关闭页面内弹框
 */
export function closePageModal({ modalFlag }) {
    const createFunctionBodyAST = template(`{
    this.setState({PLACEHOLDER_MODAL_VISIBLE: false})
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_MODAL_VISIBLE: `${modalFlag}Visible`,
    });
    return {
        libs: [],
        async: false,
        functionParameter: [],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}

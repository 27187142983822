import { FC, useState, useMemo } from 'react';
import cls from 'classnames';
import { Collapse, FormInstance } from 'antd';
import { Input as c7nProInput } from 'choerodon-ui';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import MyIcon from 'components/myIcon';

const { Search } = c7nProInput;

const List: FC<{
  list: any[];
  type: string;
  paramForm?: FormInstance<any>;
  currentAction: any;
  setCurrentAction: (ca: any) => any;
  noCustomEvent?: boolean;
  defaultParam?: any;
}> = ({
  type,
  list,
  paramForm,
  currentAction,
  setCurrentAction,
  noCustomEvent,
  defaultParam,
}) => {
  const [filterValue, setFilterValue] = useState<string>();
  const realList = useMemo(() => {
    return list.filter((i) => {
      if (!filterValue) {
        return true;
      }
      if (type === 'eventType') {
        return i.meaning.indexOf(filterValue) > -1;
      }
      if (type === 'actionType') {
        return i.name.indexOf(filterValue) > -1;
      }
      return true;
    });
  }, [filterValue, list, type]);
  const handleSetEventType = (item: any) => {
    setCurrentAction({ eventType: item.name });
  };
  const handleSetActionType = (item: any) => {
    paramForm?.resetFields();
    defaultParam && paramForm?.setFieldsValue(defaultParam);
    setCurrentAction({
      eventType: currentAction?.eventType,
      actionType: item.code || 'custom',
    });
  };
  const handleSearchInput = (event: any) => {
    setFilterValue(event.target.value);
  };
  const renderList = () => {
    if (type === 'eventType') {
      return realList.map((item) => (
        <p
          onClick={handleSetEventType.bind(this, item)}
          key={item.name}
          className={cls(currentAction?.eventType === item.name && 'active')}
          title={item.meaning}
        >
          {item.meaning}
        </p>
      ));
    }
    if (type === 'actionType') {
      return currentAction?.eventType ? (
        <Collapse
          defaultActiveKey={['preset', 'custom']}
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {realList.length > 0 && (
            <Collapse.Panel key='preset' header='内置动作'>
              {realList.map((item) => (
                <p
                  onClick={handleSetActionType.bind(this, item)}
                  key={item.code}
                  className={cls(
                    currentAction?.actionType === item.code && 'active'
                  )}
                  title={item.name}
                >
                  <span>{item.name}</span>
                  {item.describe && (
                    <QuestionCircleOutlined title={item.describe} />
                  )}
                </p>
              ))}
            </Collapse.Panel>
          )}
          {!noCustomEvent && (
            <Collapse.Panel key='custom' header='自定义动作'>
              <p
                onClick={handleSetActionType.bind(this)}
                className={cls(
                  currentAction?.actionType === `custom` && 'active'
                )}
              >
                自定义动作
              </p>
            </Collapse.Panel>
          )}
        </Collapse>
      ) : null;
    }
    return <></>;
  };
  return (
    <div className='htd-common-action-modal-list'>
      <header className='htd-common-action-modal-list-search'>
        {/* <Input suffix={<SearchOutlined />} onChange={handleSearchInput} /> */}
        <Search
          placeholder='搜索'
          className='htd-aside-left-search'
          // onSearch={(value) => setValue(value)}
          onChange={handleSearchInput}
          enterButton={<MyIcon type='icon-a-search2x' />}
          style={{ marginBottom: '8px' }}
        />
      </header>
      <main className='htd-common-action-modal-list-content'>
        {renderList()}
      </main>
    </div>
  );
};

export default List;

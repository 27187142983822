import React, { useState } from 'react';
import cls from 'classnames';
import DragBox from '@/components/DragDrop/DragBox';
import { mappings } from '@/mapping';
import { Tabs, Input } from 'choerodon-ui';
import { createFromIconfontCN } from '@ant-design/icons';
import BlockList from './BlockList';
import isEmpty from 'lodash/isEmpty';
import { equipsStringExpUpper } from 'utils';
import { errorIcon1 } from '../../../utils/unifiedExportIcon';
import { useMemoState } from '@/hooks';

const MyIcon = createFromIconfontCN({
  scriptUrl: 'iconfont.js', // 在 iconfont.cn 上生成
});

const { TabPane } = Tabs;
const { Search } = Input;

const CompList: React.FC = () => {
  const [searchValue, setValue] = useState('');
  const [currentTab, setCurrentTab] = useMemoState('resource-list-tab', '1');
  return (
    <Tabs
      className='htd-aside-left-main-components'
      activeKey={currentTab}
      onChange={setCurrentTab}
    >
      <TabPane tab='组件' key='1'>
        <div className='htd-aside-left-main-components-container'>
          <Search
            placeholder='搜索组件'
            className='htd-aside-left-search'
            onSearch={(value) => setValue(value)}
            enterButton={
              <MyIcon type='icon-a-search2x' className='left-svg-cla' />
            }
            style={{ marginBottom: '8px' }}
          />
          {mappings
            .filter((m) => !m.hidden)
            .map((mapping) => (
              <div key={mapping.name}>
                <h4 className='htd-aside-left-main-components-t'>
                  {mapping.title}
                </h4>
                <div className='htd-aside-left-wapper'>
                  {mapping.list
                    .filter((item) => !item.hidden && item.mapping)
                    .filter((item) => {
                      if (isEmpty(searchValue)) {
                        return true;
                      } else if (equipsStringExpUpper(item.name, searchValue)) {
                        return true;
                      }
                      return false;
                    })
                    // .sort((_a, b) => (b.mapping as any) || -1)
                    .map((item) => {
                      const code = 'icon-' + item.mapping?.iconCode;
                      return (
                        <DragBox
                          context='list'
                          key={item.name}
                          data={item}
                          className={cls(
                            'htd-aside-left-wapper-component',
                            !item.mapping &&
                              'htd-aside-left-wapper-component-disabled'
                          )}
                          canDrag={!!item.mapping}
                        >
                          <MyIcon
                            type={code}
                            style={{ marginRight: '6px' }}
                            className='left-svg-cla'
                          />
                          <div title={item.name}>{item.name}</div>
                          <div className='htd-aside-left-wapper-component-left-top-box'>
                            <img
                              alt='warning'
                              src={errorIcon1}
                              // className='htd-aside-left-wapper-component-left-top-box-svg'
                            />
                          </div>
                        </DragBox>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>
      </TabPane>
      <TabPane tab='区块' key='2'>
        <BlockList />
      </TabPane>
    </Tabs>
  );
};

export default CompList;

/**
 * Form组件
 */
import { handleChnageFormPropsFunc } from '../../../views/Home/AsideRight/utils';
import {
  DesignComponent,
  DesignConfig,
  formComponentNames,
  ObjectType,
} from '@htd/common';
import { cloneDeep } from 'lodash';
import { updateDsFieldsToModel } from '@/mapping/data/Table/utils';

/**
 * 插入子元素同步检查关联ds字段
 * 这里只处理拖动form子节点插入字段
 */
export function insertChildrenEffectDsConfig(
  parentItem: DesignComponent,
  childrenItem: DesignComponent,
  tree: DesignConfig,
  update?: Function
) {
  const { label, name } = childrenItem.props || {};
  if (parentItem.name === 'Form' && label?.value && name?.value) {
    const dsConfig = tree.dsConfig.find(
      (i: any) => i.dsKey === parentItem.dsKey
    );

    if (dsConfig) {
      if (dsConfig.fields) {
        if (dsConfig.fields.find((f) => f.name === name.value)) {
          const i = dsConfig.fields.findIndex((f) => f.name === name.value);
          dsConfig.fields.splice(i, 1);
        }
        dsConfig.fields.push({
          label: label.value,
          name: name.value,
          // @ts-ignore
          flag: childrenItem.props?.['data-hcg_flag']?.value,
          type: childrenItem.fieldType as any,
          props: childrenItem.props,
          fieldKey: childrenItem.props?.fieldKey?.value,
        });
      }
      tree.dsConfig = cloneDeep(tree.dsConfig);
      update?.(undefined, tree);
    }
  }
}

/**
 * form item属性变更，同步更新dsConfig
 */
export function formItemPropsChangeEffectDsConfig(
  parentItem: DesignComponent | undefined,
  item: DesignComponent | undefined,
  newProps: ObjectType,
  oldProps: ObjectType,
  tree: DesignConfig,
  backendConfig: any,
  update?: Function
) {
  if (parentItem && item) {
    if (parentItem.name === 'Form' && formComponentNames.includes(item.name)) {
      const dsConfig = tree.dsConfig.find((i) => i.dsKey === parentItem.dsKey);
      if (dsConfig && dsConfig.fields) {
        // 判断new.name 是否已经存在多个，是：删除
        // if (newProps.name !== oldProps.name) {
        //   let num = 0;
        //   dsConfig.fields.forEach((f) => {
        //     if (f.name === oldProps.name) num++;
        //   });
        //   if (num > 0) {
        //     const index = dsConfig.fields.findIndex(
        //       (f) => f.name === oldProps.name
        //     );
        //     dsConfig.fields.splice(index, 1);
        //   }
        // }
        const dsf: any = dsConfig.fields.find(
          (f: any) =>
            oldProps.fieldKey &&
            f.props?.fieldKey?.value &&
            f.props?.fieldKey?.value === oldProps.fieldKey
        );
        const list1 = [
          'bind',
          'query',
          'lovCode',
          'ignore',
          'multiple',
          'required',
          'pattern',
          'lookupCode',
          'defaultValue',
          'min',
          'max',
          'maxLength',
          'minLength',
        ];
        if (dsf) {
          // 设置applicationFor
          for (const key in newProps) {
            if (newProps.hasOwnProperty(key) && list1.includes(key)) {
              const prop: any = dsf?.props?.[key];
              // 设置applicationFor
              const isNewDsFlag =
                parentItem?.props?.createNewDs?.value || false;
              if (isNewDsFlag) {
                handleChnageFormPropsFunc(parentItem, dsf, key, newProps[key]);
              } else {
                prop.value = newProps[key];
                // 将ds相关数据从props同步一份到外层
                dsf[key] = newProps[key] || dsf[key];
              }
            }
          }

          dsf.name = newProps.name;
          dsf.label = newProps.label;
          // @ts-ignore
          dsf.flag = newProps?.['data-hcg_flag'];
        }

        // dsConfig.fields = cloneDeep(dsConfig.fields);
        // 同步实体
        updateDsFieldsToModel(
          backendConfig,
          dsConfig,
          newProps,
          item,
          '',
          'form'
        );
        update?.(undefined, tree);
      }
    }
  }
}

const handleFieldFormatFunc = (addFieldList: any, addType?: string) => {
  const list = addFieldList.map((item: any) => {
    item.fieldCodeJava = item.name;
    item.fieldName = item.label;
    // item.fieldKey = addType ? item.props?.fieldKey?.value : item.fieldKey;
    item.fieldKey = item.props?.fieldKey?.value || item.fieldKey;
    item.fieldOrder = item.fieldOrder || null;
    item.fieldType = item.fieldType || item.type || 'string';
    item.relationField = item.relationField || 0;
    item.searchable = item.searchable || 0;
    item.inputNecessary = item.inputNecessary || 0;
    item.defaultValue = addType
      ? item.props?.defaultValue?.value
      : item.defaultValue;
    item.lookupCode = addType ? item.props?.lookupCode?.value : item.lookupCode;
    item.codeRule = item.codeRule || null;
    item.valid = item.valid || null;
    item.primaryKey = item.primaryKey || null;
    return item;
  });
  return list;
};

export function addDsFieldsToModel(
  backendConfig: any,
  dsConfig: any,
  addType?: string
) {
  if (backendConfig && dsConfig) {
    const cuDsConfig = cloneDeep(dsConfig);
    const { fields = [], dsKey } = cuDsConfig;
    // 将数据同步到模型中
    const backendModelList = Object.keys(backendConfig) || [];
    const currentModelData =
      (backendModelList &&
        backendModelList.length &&
        backendConfig[backendModelList[0]]) ||
      {};
    const { entitys = {} } = currentModelData;
    const currentBackendConfigList = Object.keys(entitys) || [];
    const currentBackendConfig = currentBackendConfigList.find(
      (item) => dsKey && entitys[item].dsKey === dsKey
    );
    if (currentBackendConfig) {
      let cuData = entitys[currentBackendConfig];
      const columnList = cloneDeep(cuData.column) || [];
      let addFieldList: any[] = [];
      const oldFieldList = columnList.map((field: any) => field.name) || [];
      const oldFiledKeyFieldList =
        columnList
          .filter((cf: any) => cf.fieldKey)
          .map((field: any) => field.fieldKey) || [];
      const oldPropsFiledKeyFieldList =
        columnList
          .filter((cf1: any) => cf1?.props?.fieldKey?.value)
          .map((field: any) => field.props?.fieldKey?.value) || [];
      if (columnList.length) {
        fields.forEach((itemR: any) => {
          if (
            !oldFieldList.includes(itemR.name) &&
            !oldFiledKeyFieldList.includes(itemR.fieldKey) &&
            !oldPropsFiledKeyFieldList.includes(itemR.props?.fieldKey?.value)
          ) {
            // 实体中不存在
            const itemData = cloneDeep(itemR);
            addFieldList.push(itemData);
          } else {
            const fieldKey = itemR.fieldKey || itemR.props?.fieldKey?.value;
            let cuColumnFieldIndex = cuData.column.findIndex(
              (cf: any) =>
                cf.name === itemR.name &&
                (cf.fieldKey === fieldKey ||
                  cf.props?.fieldKey?.value === fieldKey)
            );
            if (cuColumnFieldIndex !== -1) {
              const itemData = cloneDeep(itemR);
              const dataList = handleFieldFormatFunc([itemData], addType);
              cuData.column.splice(cuColumnFieldIndex, 1, { ...dataList[0] });
            }
          }
        });
      } else {
        const dataList = cloneDeep(fields);
        addFieldList = dataList;
      }
      const list = handleFieldFormatFunc(addFieldList, addType);
      if (columnList.length) {
        cuData.column = cuData.column.concat(list);
      } else {
        cuData.column = list;
      }
    }
  }
}

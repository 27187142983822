import { FC } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import './myIcon.less';

const CreateMyIcon = createFromIconfontCN({
  scriptUrl: 'iconfont.js', // 在 iconfont.cn 上生成
});
const MyIcon: FC<any> = (props) => {
  const { type, onClick } = props;
  return (
    <CreateMyIcon
      type={type}
      className='left1-svg1-cla1'
      // style={{color: '#999'}}
      onClick={onClick}
    />
  );
};

export default MyIcon;

import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Input, Popconfirm, Button } from 'antd';
import { ConfigPanelComponentProp } from '@htd/common';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { DesignContext } from 'views/Home/DesignContext';
import MyIcon from 'components/myIcon';
import VariableInput from 'components/VariableInput';

const LovPara: React.FC<ConfigPanelComponentProp> = ({ tree, item }) => {
  const { forceUpdate } = useContext(DesignContext);

  // 表格列
  const dataSource: any[] = useMemo(
    () => item?.lovParaData || [],
    [item?.lovParaData]
  );

  const update = useCallback(() => {
    item.lovParaData = cloneDeep(item.lovParaData);
    forceUpdate?.(item?.id);
  }, [forceUpdate, item]);

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index) => {
      // @ts-ignore
      dataSource[index][fieldName] = value;
      update();
    },
    [dataSource, update]
  );

  // 删除行
  const handleDeleteLine = useCallback(
    (record, index) => {
      (item.lovParaData || []).splice(index, 1);
      update();
    },
    [item.lovParaData, update]
  );

  /**
   * 新增字段
   */
  const handleAddLine = useCallback(() => {
    const createData = {
      fileName: '',
      fileValue: '',
    };
    dataSource.push(createData);
    update();
  }, [dataSource, update]);
  const columns: ColumnsType<any> = [
    {
      title: '查询参数',
      dataIndex: 'fileLabel',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='fileLabel'>
            <Input
              onBlur={(e) => {
                handleChangeValue('fileLabel', record, e.target.value, index);
              }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '查询值',
      dataIndex: 'fileValue',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='fileValue'>
            {/* <Input
              onBlur={(e) => {
                handleChangeValue('fileValue', record, e.target.value, index);
              }}
            /> */}
            <VariableInput
              globalDsConfig={tree.dsConfig}
              // onChange={(e) => {
              //   if(typeof e === 'object') {
              //     handleChangeValue('fileValue', record, e, index);
              //   } else {
              //     handleChangeValue('fileValue', record, e, index);
              //   }
              // }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '操作',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            {/* <DeleteOutlined /> */}
            <MyIcon type='icon-a-delete2x' className='left1-svg1-cla1' />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='htd-aside-right-columns-button-wrapper'>
        <Button
          icon={<PlusOutlined />}
          size='middle'
          type='text'
          onClick={handleAddLine.bind(this)}
        >
          添加查询条件
        </Button>
      </div>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        showHeader={Boolean(dataSource?.length)}
        rowValueChange={(v, i) => {
          // dataSource.forEach(R => {
          //   if(R.fileLabel === v.fileLabel) {
          //     R.fileValue = v.fileValue
          //   }
          // })
          dataSource[i].fileValue = v.fileValue;
        }}
      />
    </>
  );
};

export default LovPara;

import React, { FC } from 'react';
import { Button } from 'choerodon-ui';

const ExcelExport: FC<any> = (props) => {
  return (
    <Button icon={props.buttonIcon}>{props.buttonText}</Button>
  );
};

export { ExcelExport };

import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Collapse } from 'antd';
import { Icon } from 'choerodon-ui';
import { debounce } from 'lodash';
import { CaretRightOutlined } from '@ant-design/icons';
import { DesignComponent } from '@htd/common';
import BaseAttribute from './BaseAttribute';
import ColumnAttribute from './ColumnAttribute';
import ValidateAttribute from './ValidateAttribute';

interface Props {
  dataSource: any[];
  record: any;
  index: number;
  setIndex: (index?: number) => any;
  onChange: (fieldName: string, record: any, value: any, index?: number) => any;
  item: DesignComponent;
}

const FieldEditPanel: React.FC<Props> = ({
  dataSource,
  record,
  index,
  setIndex,
  onChange,
  item,
}) => {
  const [[form]] = useState(AntdForm.useForm());

  /**
   * record变更时，重设form的值
   */
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...record,
    });
  }, [form, record]);

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    setIndex(undefined);
  };

  /**
   * 变更字段值
   */
  const handleValuesChange = debounce((changedValues: any) => {
    for (const key in changedValues) {
      const [type] = key.split('_');
      if (type === 'col') {
        record[key] = changedValues[key];
      }
      if (key === 'dataIndex') {
        if (changedValues[key]) {
          // 校验字段是否驼峰命名
          if (!new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/).test(changedValues[key])) {
            form.setFields([{ name: 'dataIndex', errors: ['请以驼峰命名'] }]);
            continue;
          } else {
            // 筛选是否存在重复字段
            const list = dataSource.filter(
              (d) =>
                d.dataIndex === changedValues[key] &&
                d.dataIndex !== record[key]
            );
            if (list.length) {
              form.setFields([
                { name: 'dataIndex', errors: ['字段名不能重复'] },
              ]);
              continue;
            } else {
              form.setFields([{ name: 'dataIndex', errors: [] }]);
            }
          }
        } else {
          form.setFields([{ name: 'dataIndex', errors: ['字段名不能为空'] }]);
          continue;
        }
      }
      onChange(key, record, changedValues[key], index);
    }
  }, 500);

  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header
        className='htd-aside-right-header'
        style={{ borderBottom: 'none' }}
      >
        <span>字段属性</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main className='htd-aside-right-main'>
        <Collapse
          defaultActiveKey={['base', 'fields']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel key='base' header='基础属性'>
            <AntdForm
              form={form}
              labelAlign='left'
              onValuesChange={handleValuesChange}
            >
              <BaseAttribute record={record} item={item} form={form} />
            </AntdForm>
          </Collapse.Panel>
          <Collapse.Panel key='fields' header='字段属性'>
            <AntdForm
              form={form}
              labelAlign='left'
              onValuesChange={handleValuesChange}
            >
              <ColumnAttribute record={record} />
            </AntdForm>
          </Collapse.Panel>
          {record.col_editor && (
            <Collapse.Panel
              key='validate'
              header='校验'
              className='validate-attribute-panel-box'
            >
              <AntdForm
                form={form}
                labelAlign='left'
                onValuesChange={handleValuesChange}
              >
                <ValidateAttribute
                  record={record}
                  onChange={onChange}
                  index={index}
                />
              </AntdForm>
            </Collapse.Panel>
          )}
        </Collapse>
      </main>
    </div>
  );
};

export default FieldEditPanel;

/**
 * 预览里的import语句会全部变成从window上取，所以内部涉及的import的库需要在这里挂到全局上
 */

Object.assign(window as any, {
  choerodonUiPro: require('choerodon-ui/pro/lib/index'), // 必须从lib导入，与主应用保持一致才能获取到全局配置
  choerodonUi: require('choerodon-ui/lib/index'),
  ErrorBoundary: require('./components/ErrorBoundary'),
  moment: require('moment'),
  react: require('react'),
  reactDom: require('react-dom'),
  mobxReact: require('mobx-react'),
  mobx: require('mobx'),
  lodashDecorators: require('lodash-decorators'),
  HPage: require('./hzero-adapter/components/HPage'),
  HExcelExport: require('./hzero-adapter/components/HExcelExport'),
  HPermissionButton: require('./hzero-adapter/components/PermissionButton'),
  FieldTypeEnum: require('choerodon-ui/pro/lib/data-set/enum'),
  htdHelperBaseComponent: require('@htd/helper/lib/components/preview-base-component'),
  htdHelperExtensionHoc: require('@htd/helper/lib/decorators/props-extension-hoc'),
  utilIntl: require('./hzero-adapter/utils/intl'),
  utilFormatterCollections: require('./hzero-adapter/utils/formatterCollections'),
  utilRenderer: require('./hzero-adapter/utils/renderer'),
  utilRequest: require('./hzero-adapter/utils/request'),
});

/**
 * 配置预览的时候对应导入应该从哪个全局变量上找
 * 例如：import { Button } from 'choerodon-ui/pro' 将会被转为：var { Button } = global.choerodonUiPro;
 */
export const umdConfig = {
  globals: {
    'choerodon-ui/pro': 'choerodonUiPro',
    'choerodon-ui': 'choerodonUi',
    react: 'react',
    mobx: 'mobx',
    'react-dom': 'reactDom',
    ErrorBoundary: 'ErrorBoundary',
    'mobx-react': 'mobxReact',
    'lodash-decorators': 'lodashDecorators',
    moment: 'moment',
    'components/Page': 'HPage',
    'hzero-front/lib/components/Page': 'HPage',
    'components/ExcelExport': 'HExcelExport',
    'components/Permission': 'HPermissionButton',
    'hzero-front/lib/components/ExcelExport': 'HExcelExport',
    'choerodon-ui/pro/lib/data-set/enum': 'FieldTypeEnum',
    '@htd/helper/lib/components/preview-base-component':
      'htdHelperBaseComponent',
    '@htd/helper/lib/decorators/props-extension-hoc': 'htdHelperExtensionHoc',
    'utils/intl': 'utilIntl',
    'utils/intl/formatterCollections': 'utilFormatterCollections',
    'utils/renderer': 'utilRenderer',
    'utils/request': 'utilRequest',
  },
  exactGlobals: true,
};

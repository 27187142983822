import React, { useContext, useEffect, useMemo } from 'react';
import {
  Form as AntdForm,
  // InputNumber,
  DatePicker,
  Checkbox,
  Popover,
  Switch,
} from 'antd';
import { InputNumber } from 'choerodon-ui';
import moment from 'moment';
import MyIcon from 'components/myIcon';
import DataSetFieldValidators from '@htd/helper/lib/templates/dataset-field-validator';
import { DesignContext } from 'views/Home/DesignContext';
import {
  handleCuDataFunc,
  handleChnageTablePropsFunc,
} from '@/mapping/data/Table/utils';

import { ConfigContext } from '../../ConfigPanel/ConfigContext';
import ValidateConfigPanel from './ValidateConfigPanel';
import './index.less';

type ValidateAttributeItem = {
  fieldName: string;
  label: string;
  defaultInput?: React.ReactNode;
  messageType?: string;
  messagePlaceholder?: string;
  needVariable?: boolean;
  needConfig?: boolean;
  hidden?: boolean;
  merge?: boolean;
};

const ValidateAttribute: React.FC<{ record: any; item: any }> = ({
  record,
  item: cuItem,
}) => {
  const { settingItem, forceUpdate } = useContext(DesignContext);
  const { dsFields, configItem } = useContext(ConfigContext);

  const [form] = AntdForm.useForm();

  // 正则表达式预置列表
  const patternList = useMemo(() => {
    return DataSetFieldValidators.filter(
      (item) => item.validatType === 'pattern'
    );
  }, []);

  // 当前字段
  const currentField = useMemo(
    () => dsFields?.find((f: any) => f.fieldKey === record?.ds_fieldKey),
    [dsFields, record]
  );
  // 从applicationFor中处理数据
  const currentFieldCopy: any = useMemo(
    () =>
      handleCuDataFunc(currentField, cuItem, [
        'min',
        'max',
        'minLength',
        'maxLength',
        'required',
        'pattern',
      ]),
    [currentField, cuItem]
  );
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * 根据字段类型获取默认组件
   */
  const getComponent = (value: any) => {
    const realValue = moment.isMoment(value) ? value : moment(value);
    switch (currentField?.type) {
      case 'number':
        return <InputNumber value={value} />;
      case 'date':
        return <DatePicker value={realValue} allowClear />;
      case 'dateTime':
        return <DatePicker value={realValue} showTime allowClear />;
      case 'time':
        return <DatePicker value={value} picker='time' allowClear />;
      default:
        return null;
    }
  };

  /**
   * 校验类型列表
   */
  const validateAttributeList: ValidateAttributeItem[] = [
    {
      fieldName: 'required',
      label: '必填',
      defaultInput: <Switch checked={!!currentField?.required} />,
      messageType: 'valueMissing',
      messagePlaceholder: '请输入{label}。',
      needVariable: true,
      needConfig: true,
      merge: true,
    },
    {
      fieldName: 'min',
      label: '最小值',
      defaultInput: getComponent((currentFieldCopy?.min as any)?.value),
      messageType: 'rangeUnderflow',
      messagePlaceholder: '{label}必须大于或等于{min}。',
      needVariable: true,
      needConfig: true,
      hidden: !['number', 'date', 'dateTime', 'time'].includes(
        currentField?.type!
      ),
    },
    {
      fieldName: 'max',
      label: '最大值',
      defaultInput: getComponent((currentFieldCopy?.max as any)?.value),
      messageType: 'rangeOverflow',
      messagePlaceholder: '{label}必须小于或等于{max}。',
      needVariable: true,
      needConfig: true,
      hidden: !['number', 'date', 'dateTime', 'time'].includes(
        currentField?.type!
      ),
    },
    {
      fieldName: 'minLength',
      label: '最小长度',
      defaultInput: (
        <InputNumber
          step='1'
          min={0}
          max={(currentFieldCopy?.maxLength as any)?.value}
          precision={0}
        />
      ),
      messageType: 'tooShort',
      messagePlaceholder:
        '请将该内容增加到{minLength}个或更多字符（目前您使用了{length}个字符）。',
      needConfig: true,
      hidden:
        !['number', 'string'].includes(currentField?.type!) ||
        !!currentField?.lookupCode ||
        !!currentField?.lovCode,
    },
    {
      fieldName: 'maxLength',
      label: '最大长度',
      defaultInput: (
        <InputNumber
          step='1'
          max={(currentFieldCopy?.minLength as any)?.value}
          precision={0}
        />
      ),
      messageType: 'tooLong',
      messagePlaceholder:
        '请将该内容减少到{maxLength}个或更少字符（目前您使用了{length}个字符）。',
      needConfig: true,
      hidden:
        !['number', 'string'].includes(currentField?.type!) ||
        !!currentField?.lookupCode ||
        !!currentField?.lovCode,
    },
    {
      fieldName: 'pattern',
      label: '正则表达式',
      messageType: 'patternMismatch',
      messagePlaceholder: '请输入有效的值。',
      needConfig: true,
      hidden: ['intl'].includes(currentField?.type!),
    },
    {
      fieldName: 'validator',
      label: '自定义校验',
    },
  ];

  /**
   * 渲染校验编辑按钮
   * @param validateItem
   * @returns
   */
  const getExtraContent = (validateItem: ValidateAttributeItem) => {
    return validateItem.needConfig ? (
      <Popover
        content={
          <ValidateConfigPanel
            form={form}
            patternList={patternList}
            attrName={validateItem.fieldName}
            merge={validateItem.merge}
            label={validateItem.label}
            defaultInput={validateItem.defaultInput}
            messageAttrName={validateItem.messageType}
            messagePlaceholder={validateItem.messagePlaceholder}
            needVariable={validateItem.needVariable}
          />
        }
        trigger='click'
        placement='left'
        destroyTooltipOnHide
      >
        {getTextContext(validateItem)}
        <MyIcon
          type='icon-a-edit2x'
          className='left-svg-cla validate-list-img'
        />
      </Popover>
    ) : null;
  };

  /**
   * 获取校验字段名
   * @param validateItem
   * @returns
   */
  const getFieldName = (validateItem: ValidateAttributeItem) => {
    if (validateItem.merge) {
      return validateItem.fieldName;
    }
    return [validateItem.fieldName, '__isEnable'];
  };

  const getTextContext = (validateItem: ValidateAttributeItem) => {
    const numberList = ['max', 'min', 'maxLength', 'minLength'];
    const patternList = ['pattern'];
    if (![...numberList, ...patternList].includes(validateItem.fieldName)) {
      return null;
    }
    const getObjectValue = (data: any) => {
      if (data && (data as any).__isVariable) {
        return (data as any)?.value;
      }
      if (data && ['date'].includes((currentField as any)?.type)) {
        return moment(data).format('YYYY-MM-DD');
      }
      if (data && ['dateTime'].includes((currentField as any)?.type)) {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data && ['time'].includes((currentField as any)?.type)) {
        return moment(data).format('HH:mm:ss');
      }
      return data;
    };
    return (
      <span className='antd-table-number'>
        {patternList.includes(validateItem.fieldName)
          ? (currentFieldCopy as any)[validateItem.fieldName]?.name // 正则表达式显示名称
          : getObjectValue(
              (currentFieldCopy as any)[validateItem.fieldName]?.value
            )}
      </span>
    );
  };

  /**
   * 表单值变更
   * @param changedValues
   */
  const handleChangeValues = (changedValues: any) => {
    if (currentField) {
      for (const key in changedValues) {
        if (key === 'defaultValidationMessages') {
          const messageValue: any = {
            ...(currentField as any)['defaultValidationMessages'],
            ...changedValues[key],
          };
          // 删除值为空的message
          for (const mkey in messageValue) {
            if (!messageValue[mkey]) {
              delete messageValue[mkey];
            }
          }
          (currentField as any)['defaultValidationMessages'] = messageValue;
        } else {
          let value =
            typeof changedValues[key] === 'object'
              ? { ...(currentField as any)[key], ...changedValues[key] }
              : changedValues[key];
          if (key === 'pattern') {
            const { __patternType: patternType } = changedValues[key];
            if (patternType) {
              const patternObj = patternList.find(
                (p) => p.code === patternType
              )!;
              if (patternObj) {
                value = {
                  ...(currentField as any)[key],
                  __patternType: patternType,
                  value: String(patternObj.value),
                  name: patternObj.name,
                };
              }
              const messageValue = patternObj
                ? {
                    ...(currentField as any)['defaultValidationMessages'],
                    patternMismatch: patternObj.errorMsg,
                  }
                : (currentField as any)['defaultValidationMessages'];
              form.setFieldsValue({
                pattern: value,
                defaultValidationMessages: messageValue,
              });
              (currentField as any)['defaultValidationMessages'] = messageValue;
            }
          }
          // 设置applicationFor
          handleChnageTablePropsFunc(cuItem, currentField, key, value);
          // (currentField as any)[key] = value;
        }
      }
      forceUpdate?.(settingItem?.id);
    }
  };

  return (
    <section className='htd-aside-right-main-validator file-edit-panel-content-back'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={currentFieldCopy}
        onValuesChange={handleChangeValues}
      >
        {validateAttributeList
          .filter((validateItem) => !validateItem.hidden)
          .map((validateItem) => (
            <AntdForm.Item
              key={validateItem.fieldName}
              label={validateItem.label}
              name={getFieldName(validateItem)}
              valuePropName='checked'
              extra={getExtraContent(validateItem)}
            >
              <Checkbox className='ant-checkbox-wrapper-checked' />
            </AntdForm.Item>
          ))}
      </AntdForm>
    </section>
  );
};

export default ValidateAttribute;

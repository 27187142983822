/*
 * @Author: wen.chen02@hand-china.com
 * @Description: // 高阶组件
 */
import React from 'react';
import { merge } from 'lodash';
// import { componentKey } from '@htd/helper/lib/config';
import { componentKey } from '../../config';
export default componentEnhance;
/**
 * 增强组件
 * @param {*} Comp 组件
 * @param {Function} getConfig 扩展规则配置
 * @returns {*} 组件
 */
function componentEnhance(Comp, extensionConfig) {
    return elementEnhance(Comp, extensionConfig.bind(this));
}
/**
 * 页面组件增强处理
 * @param {React.Element} treeNode 页面元素
 * @param {Function} getConfig 扩展规则配置
 *
 */
function elementEnhance(treeNode, getConfig) {
    const config = getConfig();
    /**
     * 递归react dom tree
     * @param {React.Element} node 需要被转换的节点树
     * @param {Object} config 扩展规则配置
     * @returns {React.Element}
     */
    function recursiveReactNode(node) {
        if (!node || !node.props) {
            return;
        }
        /*
        递归子节点
        */
        let children = [];
        if (node.props.children instanceof Array) {
            node.props.children.forEach((t) => children.push(recursiveReactNode(t)));
        }
        else if (node.props.children instanceof Object) {
            children.push(recursiveReactNode(node.props.children));
        }
        /*
        归并props, 如果存在自定义子节点，采用末尾追加方式添加自定义组件
        */
        let newProps = node.props;
        if (node.props[componentKey]) {
            const extensions = config[node.props[componentKey]];
            if (extensions) {
                newProps = extensionProps(node, extensions);
                if (extensions.children instanceof Array) {
                    extensions.children.forEach((ec) => {
                        children.push(ec);
                    });
                }
            }
        }
        if (children.length > 0) {
            return React.cloneElement(node, newProps, children);
        }
        return React.cloneElement(node, newProps);
    }
    const cloneTreeNode = recursiveReactNode(treeNode);
    return cloneTreeNode;
}
/**
 * 归并组件原有Props 与 扩展规则Props
 * @param {React.Element} tree 需要扩展的节点
 * @param {Object} config 扩展配置
 * @returns {Object} newProps
 */
function extensionProps(node, extensions) {
    if (extensions.props) {
        const ep = Object.assign({}, extensions.props);
        const np = Object.assign({}, node.props);
        return extensionProp(np, ep);
    }
    return node.props;
}
function extensionProp(np, ep) {
    Object.keys(ep).forEach((propName) => {
        if (ep[propName] instanceof Array) {
            // 如果属性是数组类型，特殊处理 【列属性】
            if (propName === 'columns') {
                extensionTableColumnsProp(np[propName], ep[propName]);
            }
            else {
                np[propName] = ep[propName];
            }
        }
        else {
            // 如果不是数组类型，直接进行替换
            np[propName] = ep[propName];
        }
    });
    return np;
}
/**
 * 针对table colunms属性进行特殊处理
 * @param {Array} sourceArray 原始配置
 * @param {Array} merageArray 增强配置
 */
function extensionTableColumnsProp(sourceArray, merageArray) {
    merageArray.forEach((exCol) => {
        let oldCol = sourceArray.find((sCol) => (sCol.name && exCol.name && sCol.name === exCol.name) ||
            (sCol.header && exCol.header && sCol.header === exCol.header) ||
            (sCol.key && exCol.key && sCol.key === exCol.key));
        if (oldCol) {
            if (exCol._order === -1) {
                let oldColIndex = sourceArray.findIndex((sCol) => (sCol.name && exCol.name && sCol.name === exCol.name) ||
                    (sCol.header && exCol.header && sCol.header === exCol.header) ||
                    (sCol.key && exCol.key && sCol.key === exCol.key));
                sourceArray.splice(oldColIndex, 1);
            }
            else {
                oldCol = merge(oldCol, exCol);
            }
        }
        else {
            if (exCol._order && exCol._order < sourceArray.length) {
                sourceArray.splice(exCol._order, 0, exCol);
            }
            else {
                sourceArray.push(exCol);
            }
        }
    });
}

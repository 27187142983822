import { Modal, Select, Checkbox, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
// import { createFromIconfontCN } from '@ant-design/icons';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';
import { FIELD_TYPE_LIST } from 'utils/prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { updateDsFieldsToModel } from '@/mapping/data/Table/utils';
import {
  handleCuDataFunc,
  handleChnageTablePropsFunc,
  handleQueryFieldDataFunc,
} from '@/mapping/data/Table/utils';
import './index.less';
import { cloneDeep } from 'lodash';

const FieldListBatchChange: React.FC<any> = ({
  onClose,
  onCreate,
  selectedRowKeysList,
  item,
}) => {
  const { dsFields, tableColumns, dsQueryFields, dsConfig } =
    useContext(ConfigContext);
  const { settingItem, forceUpdate, backendConfig } = useContext(DesignContext);

  const [isCurrentChecked, setCurrentChecked] = useState(true);
  const [isFieldList, setFieldList] = useState<any>([]);
  const [validateFields, setValidateFields] = useState<any>([]); // 表格校验信息

  useEffect(() => {
    const fieldsList = cloneDeep(dsFields) || [];
    fieldsList.forEach((fieldItem: any) => {
      const createNewDs = item?.props?.['createNewDs']?.value;
      let cuApplicationForData: any = null;
      const attributeList1 = [
        'bind',
        'query',
        'lovCode',
        'ignore',
        'multiple',
        'required',
        'lookupCode',
        'defaultValue',
      ];
      if (createNewDs) {
        const attributeList = [
          'bind',
          'query',
          'lovCode',
          'ignore',
          'multiple',
          'required',
          'pattern',
          'lookupCode',
          'defaultValue',
          'min',
          'max',
          'maxLength',
          'minLength',
        ];
        cuApplicationForData = handleCuDataFunc(fieldItem, item, attributeList);
      }

      if (cuApplicationForData) {
        attributeList1.forEach((af: any) => {
          fieldItem[af] = cuApplicationForData[af];
        });
        fieldItem.maxIsVariable =
          cuApplicationForData?.max?.__isEnable &&
          typeof cuApplicationForData?.max?.value === 'object' &&
          cuApplicationForData?.max?.value.__isVariable
            ? true
            : false;
        fieldItem.minIsVariable =
          cuApplicationForData?.min?.__isEnable &&
          typeof cuApplicationForData?.min?.value === 'object' &&
          cuApplicationForData?.min?.value.__isVariable
            ? true
            : false;
        fieldItem.max = cuApplicationForData?.max?.__isEnable
          ? typeof cuApplicationForData?.max?.value === 'object' &&
            cuApplicationForData?.max?.value.__isVariable
            ? cuApplicationForData?.max?.value.value
            : cuApplicationForData?.max?.value
          : null;
        fieldItem.min = cuApplicationForData?.min?.__isEnable
          ? typeof cuApplicationForData?.min?.value === 'object' &&
            cuApplicationForData?.min?.value.__isVariable
            ? cuApplicationForData?.min?.value.value
            : cuApplicationForData?.min?.value
          : null;
        fieldItem.maxLength =
          cuApplicationForData?.maxLength?.__isEnable &&
          (cuApplicationForData?.maxLength?.value || null);
        fieldItem.minLength =
          cuApplicationForData?.minLength?.__isEnable &&
          (cuApplicationForData?.minLength?.value || null);
        fieldItem.pattern =
          cuApplicationForData?.pattern?.__isEnable &&
          (cuApplicationForData?.pattern?.value || null);
      } else {
        fieldItem.maxIsVariable =
          fieldItem?.max?.__isEnable &&
          typeof fieldItem?.max?.value === 'object' &&
          fieldItem?.max?.value.__isVariable
            ? true
            : false;
        fieldItem.minIsVariable =
          fieldItem?.min?.__isEnable &&
          typeof fieldItem?.min?.value === 'object' &&
          fieldItem?.min?.value.__isVariable
            ? true
            : false;
        fieldItem.max = fieldItem?.max?.__isEnable
          ? typeof fieldItem?.max?.value === 'object' &&
            fieldItem?.max?.value.__isVariable
            ? fieldItem?.max?.value.value
            : fieldItem?.max?.value
          : null;
        fieldItem.min = fieldItem?.min?.__isEnable
          ? typeof fieldItem?.min?.value === 'object' &&
            fieldItem?.min?.value.__isVariable
            ? fieldItem?.min?.value.value
            : fieldItem?.min?.value
          : null;
        fieldItem.maxLength =
          fieldItem?.maxLength?.__isEnable &&
          (fieldItem?.maxLength?.value || null);
        fieldItem.minLength =
          fieldItem?.minLength?.__isEnable &&
          (fieldItem?.minLength?.value || null);
        fieldItem.pattern =
          fieldItem?.pattern?.__isEnable && (fieldItem?.pattern?.value || null);
      }
      fieldItem.fieldType = fieldItem.type || null;
    });

    setFieldList(fieldsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsFields]);

  const changeValueDataTypeFunc = (
    dataValue: any,
    fieldType?: string,
    isVariableFlag?: boolean
  ) => {
    // 正则
    if (fieldType === 'pattern') {
      return dataValue
        ? { __isEnable: true, __patternType: 'custom', value: dataValue }
        : null;
    } else {
      // 最值
      if (!isVariableFlag) {
        return dataValue && { __isEnable: true, value: Number(dataValue) };
      } else {
        return {
          __isEnable: true,
          value: { value: dataValue, __isVariable: true },
        };
      }
    }
  };

  // 处理valid数据
  const processingValidData = (record: any) => {
    let valid: any = {};
    if (
      record.max ||
      record.min ||
      record.minLength ||
      record.maxLength ||
      record.pattern
    ) {
      let list = [
        record.max,
        record.min,
        record.minLength,
        record.maxLength,
        record.pattern,
      ];
      let list1 = ['maxValue', 'minValue', 'minLength', 'maxLength', 'pattern'];
      list.forEach((LF: any, index: number) => {
        if (typeof LF?.value !== 'object' && LF?.value) {
          let as = list1[index];
          if (list1[index] === 'pattern') {
            if (record.pattern?.__patternType === 'custom') {
              // @ts-check
              valid[as] = LF?.value;
            }
          } else {
            // @ts-check
            valid[as] = LF?.value;
          }
        }
      });
    }
    return valid;
  };

  const handleModalOkFunc = () => {
    if (validateFields.length > 0) {
      // 保存时，校验表格的表单组件是否通过
      return;
    }
    const list = [
      'bind',
      'query',
      'lovCode',
      'ignore',
      'multiple',
      'required',
      'pattern',
      'lookupCode',
      'defaultValue',
      'min',
      'max',
      'maxLength',
      'minLength',
    ];
    const isNewDsFlag = item?.props?.createNewDs?.value || false;
    // 变更dsConfig  和 tableColumns
    isFieldList.forEach((oldValue: any, index: number) => {
      dsFields?.forEach((curValue: any, curIndex: number) => {
        if (oldValue.fieldKey === curValue.fieldKey) {
          if (isNewDsFlag) {
            curValue.name = oldValue.name;
            curValue.label = oldValue.label;
            curValue.type = oldValue.fieldType
              ? oldValue.fieldType
              : curValue.type;
            curValue.editor = oldValue.editor || null;
            curValue.ds_lookupCode = oldValue.lookupCode || null;
            curValue.ds_lovCode = oldValue.lovCode || null;
            curValue.fieldType = oldValue.fieldType || null;
            curValue.ignore = oldValue.ignore;
            curValue.searchable = oldValue.query ? 1 : 0;
            curValue.inputNecessary = oldValue.required ? 1 : 0;

            // 是object 则在批量编辑页面变更过   否则 为未变动（因为进入页面时想要显示数据 需要将外层来的数据转化一遍 useEffect中转完为string）

            curValue.valid = processingValidData(curValue);
            list.forEach((lf: any) => {
              let value = oldValue[lf];
              if (['minLength', 'maxLength', 'min', 'max'].includes(lf)) {
                value =
                  typeof oldValue[lf] === 'object'
                    ? oldValue[lf]
                    : changeValueDataTypeFunc(oldValue[lf]);
              }
              if (['pattern'].includes(lf)) {
                value =
                  typeof oldValue[lf] === 'object'
                    ? oldValue[lf]
                    : changeValueDataTypeFunc(oldValue[lf], 'pattern');
              }
              if (![null, undefined, ''].includes(value)) {
                handleChnageTablePropsFunc(item, curValue, lf, value);
              }
            });
          } else {
            curValue.name = oldValue.name;
            curValue.label = oldValue.label;
            curValue.type = oldValue.fieldType
              ? oldValue.fieldType
              : curValue.type;

            curValue.bind = oldValue.bind || null;
            curValue.query = oldValue.query || null;
            curValue.editor = oldValue.editor || null;
            curValue.required = oldValue.required || null;
            curValue.defaultValue = oldValue.defaultValue || null;
            curValue.lookupCode = oldValue.lookupCode || null;
            curValue.ds_lookupCode = oldValue.lookupCode || null;
            curValue.ds_lovCode = oldValue.lovCode || null;
            curValue.lovCode = oldValue.lovCode || null;
            curValue.multiple = oldValue.multiple || null;
            curValue.fieldType = oldValue.fieldType || null;
            curValue.ignore = oldValue.ignore;

            curValue.searchable = oldValue.query ? 1 : 0;
            curValue.inputNecessary = oldValue.required ? 1 : 0;

            // 是object 则在批量编辑页面变更过   否则 为未变动（因为进入页面时想要显示数据 需要将外层来的数据转化一遍 useEffect中转完为string）
            curValue.max =
              typeof oldValue.max === 'object'
                ? oldValue.max
                : changeValueDataTypeFunc(
                    oldValue.max,
                    'max',
                    oldValue.maxIsVariable
                  );
            curValue.min =
              typeof oldValue.min === 'object'
                ? oldValue.min
                : changeValueDataTypeFunc(
                    oldValue.min,
                    'min',
                    oldValue.minIsVariable
                  );
            curValue.maxLength =
              typeof oldValue.maxLength === 'object'
                ? oldValue.maxLength
                : changeValueDataTypeFunc(oldValue.maxLength);
            curValue.minLength =
              typeof oldValue.minLength === 'object'
                ? oldValue.minLength
                : changeValueDataTypeFunc(oldValue.minLength);
            curValue.pattern =
              typeof oldValue.pattern === 'object'
                ? oldValue.pattern
                : changeValueDataTypeFunc(oldValue.pattern, 'pattern');

            curValue.valid = processingValidData(curValue);
          }
        }
      });
      // tableColumns?.forEach((tableValue: any, tableIndex: number) => {
      // if (index === tableIndex) {
      // tableValue.name = oldValue.name;
      // tableValue.editor = oldValue.editor;
      // }
      // });
      tableColumns?.forEach((tableValue: any, tableIndex: number) => {
        if (oldValue.fieldKey && oldValue.fieldKey === tableValue.fieldKey) {
          tableValue.name = oldValue.name;
          tableValue.editor = oldValue.editor;
        }
      });
      // 同步实体
      updateDsFieldsToModel(backendConfig, dsConfig, oldValue, null, 'modalOk');
      if (dsQueryFields && dsQueryFields.length) {
        dsQueryFields.forEach((queryField: any) => {
          if (oldValue.fieldKey === queryField.fieldKey) {
            queryField.label = oldValue.label;
            queryField.name = oldValue.name;
          }
        });
      }
      // 设置applicationFor
    });
    forceUpdate?.();
    onClose(false);
  };

  // 增加字段规则校验
  const fieldNameValidate = (fieldName: string, record: any, value: any) => {
    if (fieldName === 'name') {
      setNameValidate(record, fieldName, value, [
        { required: true, message: '字段名不能为空' },
        {
          pattern: new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/),
          message: '请以驼峰命名',
        },
      ]);
    }
  };

  const setNameValidate = (
    record: any,
    name: string,
    value: any,
    rules: any
  ) => {
    const changeValidateFields = (message: string) => {
      if (validateFields.find((i: any) => i.fieldKey === record.fieldKey)) {
        const _validateFields = validateFields.map((i: any) => {
          if (i.fieldKey === record.fieldKey) {
            return { ...i, [name]: message };
          }
          return i;
        });
        setValidateFields(_validateFields);
      } else {
        setValidateFields([
          ...validateFields,
          { fieldKey: record.fieldKey, [name]: message },
        ]);
      }
    };
    for (const rule of rules) {
      if (rule.required && !value) {
        changeValidateFields(rule.message);
        return;
      } else if (rule.pattern && !rule.pattern.test(value)) {
        changeValidateFields(rule.message);
        return;
      } else {
        const _validateFields = validateFields.filter(
          (i: any) => i.fieldKey !== record.fieldKey
        );
        setValidateFields(_validateFields);
      }
    }
  };

  /**
   * 修改字段值
   */
  const handleChangeValue = (fieldName: string, record: any, value: any) => {
    fieldNameValidate(fieldName, record, value);
    if (['required', 'query', 'multiple', 'editor'].includes(fieldName)) {
      record[fieldName] = value;
      setCurrentChecked(!isCurrentChecked);
    } else if (['min', 'max', 'maxLength', 'minLength'].includes(fieldName)) {
      if (value) {
        record[fieldName] = {
          __isEnable: true,
          value: Number(value),
        };
      } else {
        record[fieldName] = null;
      }
    } else if (['pattern'].includes(fieldName)) {
      if (value) {
        record[fieldName] = {
          __isEnable: true,
          __patternType: 'custom',
          value: value,
        };
      } else {
        record[fieldName] = null;
      }
    } else {
      record[fieldName] = value;
    }
    // 查询字段 需要将字段添加到queryFields
    if (['query'].includes(fieldName) && dsQueryFields instanceof Array) {
      handleQueryFieldDataFunc(
        item,
        record,
        'query',
        value,
        dsQueryFields,
        record
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: '字段名',
      width: 120,
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (value, record) => {
        const validateRecord = validateFields.find(
          (i: any) => i.fieldKey === record.fieldKey
        );
        return (
          <>
            <Input
              defaultValue={value}
              onBlur={(e) => {
                handleChangeValue('name', record, e.target.value);
              }}
            />
            {validateRecord?.name && (
              <div style={{ color: 'red' }}>{validateRecord?.name}</div>
            )}
          </>
        );
      },
    },
    {
      title: '标题',
      dataIndex: 'label',
      align: 'center',
      key: 'label',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('label', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      width: 100,
      align: 'center',
      // required: true,
      render: (value, record) => {
        return (
          <Select
            defaultValue={value}
            style={{ width: '100%' }}
            onChange={(e) => handleChangeValue('fieldType', record, e)}
          >
            {FIELD_TYPE_LIST.map((type) => (
              <Select.Option value={type.value} key={type.value}>
                {type.meaning}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: '字段绑定',
      dataIndex: 'bind',
      align: 'center',
      key: 'bind',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('bind', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '查询字段',
      dataIndex: 'query',
      align: 'center',
      key: 'query',
      width: 80,
      render: (value, record) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) =>
              handleChangeValue('query', record, e.target.checked)
            }
          />
        );
      },
    },
    {
      title: '必填',
      dataIndex: 'required',
      align: 'center',
      key: 'required',
      width: 60,
      render: (value, record) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) =>
              handleChangeValue('required', record, e.target.checked)
            }
          />
        );
      },
    },
    {
      title: '可编辑',
      dataIndex: 'editor',
      align: 'center',
      key: 'editor',
      width: 70,
      render: (value, record) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) =>
              handleChangeValue('editor', record, e.target.checked)
            }
          />
        );
      },
    },
    {
      title: '默认值',
      dataIndex: 'defaultValue',
      align: 'center',
      key: 'defaultValue',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('defaultValue', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '多值',
      dataIndex: 'multiple',
      align: 'center',
      key: 'multiple',
      width: 60,
      render: (value, record) => {
        return (
          // <Checkbox
          //   checked={value}
          //   onChange={(e) =>
          //     handleChangeValue('multiple', record, e.target.checked)
          //   }
          // />
          <Select allowClear>
            <Select.Option value='true'>true</Select.Option>
            <Select.Option value=','>,</Select.Option>
            <Select.Option value=';'>;</Select.Option>
            <Select.Option value='#'>#</Select.Option>
            <Select.Option value='_'>_</Select.Option>
          </Select>
        );
      },
    },
    {
      title: '忽略提交',
      dataIndex: 'ignore',
      key: 'ignore',
      width: 120,
      align: 'center',
      render: (value, record) => {
        return (
          <Select
            defaultValue={value}
            style={{ width: '100%' }}
            onChange={(e) => handleChangeValue('ignore', record, e)}
          >
            <Select.Option value='always'>总是忽略</Select.Option>
            <Select.Option value='clean'>值未变化时忽略</Select.Option>
            <Select.Option value='never'>从不忽略</Select.Option>
          </Select>
        );
      },
    },
    {
      title: '值集编码',
      dataIndex: 'lookupCode',
      align: 'center',
      key: 'lookupCode',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('lookupCode', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: 'LOV编码',
      dataIndex: 'lovCode',
      align: 'center',
      key: 'lovCode',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('lovCode', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '最小值',
      dataIndex: 'min',
      align: 'center',
      key: 'min',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            disabled={record?.minIsVariable}
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('min', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '最大值',
      dataIndex: 'max',
      align: 'center',
      key: 'max',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            disabled={record?.maxIsVariable}
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('max', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '最小长度',
      dataIndex: 'minLength',
      align: 'center',
      key: 'minLength',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('minLength', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '最大长度',
      dataIndex: 'maxLength',
      align: 'center',
      key: 'maxLength',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('maxLength', record, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '正则表达式',
      dataIndex: 'pattern',
      align: 'center',
      key: 'pattern',
      width: 120,
      render: (value, record) => {
        return (
          <Input
            defaultValue={value}
            onChange={(e) => {
              handleChangeValue('pattern', record, e.target.value);
            }}
          />
        );
      },
    },
  ];

  const rowSelection = {
    onSelect: (record: any, selected: any, selectedRows: any) => {
      if (!selected) {
        const columnIndex = tableColumns.findIndex(
          (i: any) => i.fieldKey === record.fieldKey
        );
        // console.log(
        //   isFieldList.map((i: any) => i.fieldKey),
        //   tableColumns.map((i: any) => i.fieldKey),
        //   record.fieldKey,
        //   columnIndex
        // );
        tableColumns.splice(columnIndex, 1);
      } else {
        const createData = {
          name: record.name,
          label: record.label,
          fieldKey: record.fieldKey,
        };
        onCreate(createData, true);
      }
      forceUpdate?.(settingItem?.id);
    },
  };

  return (
    <Modal
      visible
      onCancel={() => onClose(false)}
      onOk={handleModalOkFunc}
      title={
        <div>
          批量属性维护{' '}
          <QuestionCircleOutlined title='可快速设置数据源中对应属性配置，暂不支持动态配置模式' />
        </div>
      }
      width='70%'
      className='model-box'
    >
      <Table
        //@ts-ignore
        columns={columns}
        dataSource={isFieldList}
        pagination={false}
        //@ts-ignore
        filterBar={false}
        scroll={{ y: 240, x: 350 }}
        empty={'暂无字段' as any}
        rowKey='fieldKey'
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRowKeysList,
        }}
      />
    </Modal>
  );
};

export default FieldListBatchChange;

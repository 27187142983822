import { PropType, PropTypesObj } from '@htd/common';
import { BASIC_PROPS, propsGroupConfig } from 'utils/config';
import { shouldUseMultilingualCode } from 'utils/multilingual-util';

export type PropListItem = PropType & { name: string };

export function parsePropGroups(types: PropTypesObj, opts?: any) {
  const groups: {
    id: string;
    order: number;
    title: string;
    children: PropListItem[];
  }[] = [];
  const { isMultilingual, config = {} } = opts;
  const componentName = config.name;
  Object.keys(types).forEach((k) => {
    const value = types[k];
    const group = value.group || BASIC_PROPS;
    let foundGroup = groups.find((g) => g.id === group);
    if (!foundGroup) {
      foundGroup = {
        id: group,
        title: propsGroupConfig[group].title,
        order: propsGroupConfig[group].order || 100,
        children: [],
      };
      groups.push(foundGroup);
    }
    foundGroup.children.push({ ...value, name: k });
    // 插入多语言Code输入框
    if (isMultilingual) {
      shouldUseMultilingualCode(componentName, k, types, foundGroup.children);
    }
  });
  groups
    .sort((a, b) => b.order - a.order)
    .forEach(({ children }) =>
      children.sort((a, b) => -(b.order ?? 0) + (a.order ?? 0))
    );
  return groups;
}

import { Mapping } from '@htd/common';
import { FormField } from 'components/placeholders';
import { BASIC_PROPS } from 'utils/config';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const Password: Mapping = {
  name: 'Password',
  title: '密码输入框',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'string',
  iconCode: 'password',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('password', '密码框输入'),
    reveal: {
      label: '揭示',
      type: 'boolean',
      value: false,
    },
    // 关系分组
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  placeholderComponent: FormField,
  validate: {},
  showValidate: ['minLength', 'maxLength'],
};

import { Mapping } from '@htd/common';
import { Table as tablePlaceholder } from 'components/placeholders';
// import * as uuid from 'uuid';
import {
  BASE_COLUMN_PROPS,
  BASE_PROPS_TYPES,
  BASE_DS_PROPS,
} from 'utils/prop-types';
import ConfigPanel from './ConfigPanel';

export const Table: Mapping = {
  name: 'Table',
  iconCode: 'Table',
  title: '表格',
  type: 'block',
  lib: 'choerodon-ui/pro',
  placeholderComponent: tablePlaceholder,
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '表格',
      label: '标题',
      ignore: true,
    },
    rowNumber: {
      label: '显示行号',
      type: 'boolean',
      value: false,
      hidden: true,
    },
    queryFieldsLimit: {
      label: '查询域列数',
      hidden: true,
      type: 'number',
      value: 3,
    },
    queryBar: {
      label: '搜索条',
      type: 'string',
      hidden: true,
      value: 'professionalBar',
    },
    columnResizable: {
      label: '可调整列宽',
      type: 'boolean',
      value: true,
    },
    columnHideable: {
      label: '可调整列显示',
      type: 'boolean',
      hidden: true,
      value: true,
    },
    columnTitleEditable: {
      label: '可调整列标题',
      type: 'boolean',
      hidden: true,
      value: true,
    },
    columnDraggable: {
      type: 'boolean',
      label: '列拖拽',
      hidden: true,
      value: true,
    },
    editMode: {
      label: '编辑模式',
      type: 'collection',
      options: [
        { value: 'cell', label: '默认' },
        { value: 'inline', label: '行内编辑' },
      ],
      value: 'cell',
    },
    customizable: {
      type: 'boolean',
      label: '个性化',
      value: false,
    },
    customizedCode: {
      type: 'string',
      label: '客制化代码',
      hidden: true,
      value: '',
    },
    border: { label: '表格边框', type: 'boolean', value: true },
    autoHeight: { label: '高度自适应', type: 'boolean', value: false },
    height: { label: '表格高度', type: 'number', value: '' },
    virtual: { label: '虚拟滚动', type: 'boolean', value: false },
    virtualCell: { label: '虚拟单元格', type: 'boolean', value: false },
    virtualSpin: { label: '虚拟Spin', type: 'boolean', value: false },
    // conditionHidden: { label: '隐藏', type: 'hidden', value: false, conditionType: 'negative' },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，表格处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    attachment: { label: '附件列', type: 'boolean', value: false },
    columns: {
      type: 'object',
      label: '列配置',
      hidden: true,
      value: [
        {
          ...BASE_COLUMN_PROPS,
          name: 'field1',
          type: 'string',
        },
        {
          ...BASE_COLUMN_PROPS,
          name: 'field2',
          type: 'string',
        },
      ],
    },
    dataSet: {
      type: 'object',
      label: '数据源', // 如果关联了数据源，则该属性无效
      hidden: true,
      ignore: true,
      value: {
        fields: [
          { ...BASE_DS_PROPS, name: 'field1', label: '字段1' },
          { ...BASE_DS_PROPS, name: 'field2', label: '字段2' },
        ],
        queryFields: [],
      },
    },
    eventFields: {
      type: 'object',
      label: '',
      hidden: true,
      ignore: true,
      value: [],
    },
    buttons: {
      type: 'object',
      label: '预置按钮',
      hidden: true,
      value: [],
    },
    pagination: {
      type: 'boolean',
      label: '',
      hidden: true,
      value: undefined,
    },
    onRow: {
      type: 'function',
      label: '行属性',
      hidden: true,
    },
    // 动态表格判断逻辑配置项
    dynamicTableConfig: {
      type: 'object',
      label: '',
      hidden: true,
      ignore: true,
      value: [],
    },
  },
  configPanelComponent: ConfigPanel,
  children: [],
};

import React, { FC, useEffect, useState } from 'react';
import { ObjectType } from '@htd/common';
import cls from 'classnames';
import HighLight from 'react-highlight';
import 'highlight.js/styles/default.css';
import './index.less';

const CodeViewer: FC<{
  codeTree: ObjectType<string>;
}> = ({ codeTree }) => {
  const [currentCode, setCurrentCode] = useState('');
  const list = Object.keys(codeTree);
  useEffect(() => {
    // 默认显示的代码
    setCurrentCode('index.js');
  }, [codeTree]);
  return (
    <div className='htd-code-viewer'>
      <header className='htd-code-viewer-header'>
        <ul>
          {list.map((v) => (
            <li
              onClick={() => setCurrentCode(v)}
              key={v}
              className={cls(
                'htd-code-viewer-header-file',
                v === currentCode && 'htd-code-viewer-header-file-active'
              )}
            >
              {v}
            </li>
          ))}
        </ul>
      </header>
      <main className='htd-code-viewer-main'>
        <pre className='htd-code-viewer-main-pre'>
          <HighLight className='javascript'>{codeTree[currentCode]}</HighLight>
        </pre>
      </main>
    </div>
  );
};

export default CodeViewer;

import { DesignComponent, formComponentNames } from '@htd/common';
import oHash from 'object-hash';

function hasFocused(comp: DesignComponent, focusedId?: string): boolean {
  return (
    !!comp.children?.find((c) => c.id === focusedId) ||
    comp.children?.some((c) => hasFocused(c, focusedId))
  );
}

export function getDragClassName(
  comp: DesignComponent,
  selected?: DesignComponent
) {
  const { name, props } = comp;
  let extra = '';
  let clearFloat = '';
  if (name === 'Col') {
    const span = comp.props?.['span'];
    extra =
      typeof span !== 'undefined'
        ? `htd-components-${name}-${span.value}`
        : extra;
  }
  if (name === 'Form') {
    const columns = comp.props?.['columns'];
    extra =
      typeof columns !== 'undefined'
        ? `htd-components-${name}-${columns?.value}`
        : extra;
  }
  if (formComponentNames.includes(name)) {
    extra = `htd-components-form-field-${props?.colSpan?.value}`;
    if (props?.newLine?.value) {
      clearFloat = 'clear-float';
    }
  }
  if (
    name === 'Modal' &&
    selected &&
    (hasFocused(comp, selected.id) || selected.id === comp.id)
  ) {
    extra = [
      'htd-components-Modal-has-focused',
      props?.model.value === 'drawer' && 'htd-components-Modal-drawer',
      props?.model.value === 'center' &&
        props?.fullScreen?.value &&
        'htd-components-Modal-fullscreen',
    ]
      .filter(Boolean)
      .join(' ');
  }
  return `htd-components-${name} ${extra} ${clearFloat}`;
}

export function getJsxFlag(name: string, config: any) {
  return `${name}_${oHash(config || {}).slice(0, 5)}`;
}

export function mapNum(num: number, fun: Function) {
  const res = [];
  for (let i = 0; i < num; i++) {
    res.push(fun(i));
  }
  return res;
}

export function forEachNum(num: number, fun: Function) {
  for (let i = 0; i < num; i++) {
    fun(i);
  }
}

export function equipsStringExpUpper(pre: string, next: string) {
  if (
    pre &&
    next &&
    pre?.toLocaleUpperCase()?.indexOf(next?.toLocaleUpperCase()) > -1 &&
    pre?.toUpperCase()?.indexOf(next?.toUpperCase()) > -1
  ) {
    return true;
  }
  return false;
}

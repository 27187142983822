import { Form, Radio } from 'antd';
import CommonActionPanel from 'components/CommonActionPanel';
import React, { useContext, useEffect, useMemo } from 'react';
import { DATA_HCG_FLAG } from 'utils';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from './ConfigPanel/ConfigContext';
import LineEvents from '@htd/helper/lib/templates/line-events';
import './index.less';
import { DataSetSelection } from 'choerodon-ui/pro/lib/data-set/enum';

const OtherPanel: React.FC = () => {
  const { tree, settingItem: item, forceUpdate } = useContext(DesignContext);
  const { dsConfig, tableDsConfig } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const config = item?.dsKey ? dsConfig : tableDsConfig;

  const initialValues = {
    // selection: config?.selection === false ? 'null' : config?.selection,
    selection:
      dsConfig?.selection === undefined
        ? config?.selection === false
          ? 'null'
          : config?.selection
        : dsConfig?.selection === DataSetSelection.single
        ? 'single'
        : dsConfig?.selection === DataSetSelection.multiple
        ? 'multiple'
        : 'null',
  };

  // 动作列表
  const eventList = useMemo(() => {
    return LineEvents.filter(
      (i) =>
        i.code &&
        i.name &&
        i.function &&
        (i.code === 'CLICK_FOR_CHANGEROUTE' || i.code === 'CLICK_FOR_QUERY')
    );
  }, []);

  useEffect(() => {
    // form.resetFields();
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id, item?.dsKey]);

  /**
   * 值变更
   * @param changedValues
   */
  const handleValuesChange = (changedValues: any) => {
    if (config) {
      for (const key in changedValues) {
        if (changedValues[key] === 'null') {
          config.selection = false;
        } else {
          config.selection = changedValues[key];
        }
      }
      forceUpdate?.(item?.id);
    }
  };

  /**
   * 获取默认动作参数
   */
  const getEventProps = () => {
    if (item?.props?.onRow?.value) {
      return {
        [`Row_onClick`]: {
          value: {
            eventCode: item.props.onRow.value?.eventCode,
            eventName: item.props.onRow.value?.eventName,
            eventParam: item.props.onRow.value?.eventParam,
          },
        },
      };
    }
    return {};
  };

  /**
   * 确定动作
   * @param e
   */
  const handleActionOk = (e: any) => {
    if (item?.props?.onRow) {
      item.props.onRow.value = {
        ...e.eventValue,
      };
      forceUpdate?.(item?.id);
    }
  };

  /**
   * 删除动作
   */
  const handleDeleteAction = () => {
    if (item?.props?.onRow?.value) {
      item.props.onRow.value = undefined;
      forceUpdate?.(item?.id);
    }
  };

  return (
    <>
      <Form
        labelAlign='left'
        initialValues={initialValues}
        onValuesChange={handleValuesChange}
        className='pagination-form-box'
        form={form}
      >
        <Form.Item name='selection' label='行选择'>
          <Radio.Group>
            <Radio.Button value='multiple'>多选</Radio.Button>
            <Radio.Button value='single'>单选</Radio.Button>
            <Radio.Button value='null'>无</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <div style={{ width: '100%', height: '12px' }}></div>
      <CommonActionPanel
        fieldName={'Row'}
        configProps={getEventProps()}
        compFlag={item?.props?.[DATA_HCG_FLAG].value}
        globalDsConfig={tree.dsConfig}
        avaliableEvents={[
          {
            name: 'onClick',
            meaning: '行点击事件',
            list: eventList,
          },
        ]}
        noCustomEvent
        onActionOk={handleActionOk}
        onDeleteEvent={handleDeleteAction}
      />
    </>
  );
};

export default OtherPanel;

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form as AntdForm, Switch, Button, Modal } from 'antd';
import { CodeArea } from 'choerodon-ui/pro';
import './index.less';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from './ConfigPanel/ConfigContext';

/**
 * 代码域配置
 */
const codeAreaOptions = {
  // theme: 'material',
  mode: 'javascript',
  styleActiveLine: true,
  autofocus: true,
  lineNumbers: true,
  lineWrapping: false,
};

const DataSourcePanel: React.FC = () => {
  const { settingItem: item, forceUpdate } = useContext(DesignContext);
  const { dsFields, tableDsConfig } = useContext(ConfigContext);

  const dataConfig = (tableDsConfig as any)?.showCustomData;
  const [show, setShow] = useState(false);
  const [code, setCode] = useState((tableDsConfig as any)?.customData);

  const [form] = AntdForm.useForm();

  /**
   * 修改Ds属性
   */
  const handleValuesChange = (changedValues: any) => {
    if (changedValues.hasOwnProperty('showCustomData')) {
      if (changedValues.showCustomData) {
        (tableDsConfig as any).showCustomData = true;
      } else {
        (tableDsConfig as any).showCustomData = false;
      }
    }
    forceUpdate?.(item?.id);
  };

  const handleMaintainData = () => {
    if (!(tableDsConfig as any)?.customData) {
      let str = '[{\n';
      dsFields?.forEach((field: any) => {
        const { name, label } = field;
        str += `${'  '}${name}: '${label}', // ${label} \n`;
      });
      str += '}]';
      setCode(str);
    }
    setShow(true);
  };

  const handleCodeChange = useCallback((value) => {
    setCode(value);
  }, []);

  const handleCodeOk = () => {
    (tableDsConfig as any).customData = code;
    setShow(false);
    forceUpdate?.(item?.id);
  };

  useEffect(() => {
    if (item?.id) {
      form.resetFields();
      setCode((tableDsConfig as any)?.customData);
    }
  }, [form, item?.id, tableDsConfig]);

  return (
    <div className='htd-operator-box'>
      <AntdForm
        form={form}
        labelAlign='left'
        onValuesChange={handleValuesChange}
        className='htd-operator-box-top'
      >
        <AntdForm.Item label='开启' name='showCustomData'>
          <Switch checked={!!dataConfig} />
        </AntdForm.Item>
        {!!dataConfig && (
          <>
            <div className='htd-aside-right-operator-button-wrapper'>
              <Button
                className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
                onClick={handleMaintainData}
              >
                维护自定义数据源
              </Button>
            </div>
          </>
        )}
      </AntdForm>
      <Modal
        visible={show}
        title='维护自定义数据源'
        closable={false}
        width='51.9%'
        wrapClassName='htd-variable-input-modal-wrap'
        destroyOnClose
        onOk={handleCodeOk}
        onCancel={() => setShow(false)}
      >
        <div className='resize-code-area'>
          <CodeArea
            options={codeAreaOptions}
            value={code}
            onChange={handleCodeChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DataSourcePanel;

import { Form, Input, Select, Switch } from 'antd';
import React, { FC, useEffect, useMemo } from 'react';
import { DesignConfig } from '@htd/common';
import VariableInput from 'components/VariableInput';
import DataSetFieldValidators from '@htd/helper/lib/templates/dataset-field-validator';
import SelectRightImg from 'components/selectRightImg';
import './index.less';
import { isUndefined } from 'lodash';

const ValidateConfigPanel: FC<{
  tree: DesignConfig;
  record: any;
  type: string;
  messageType?: string;
  messagePlaceholder?: string;
  label: string;
  defaultInput?: React.ReactNode;
  needVariable?: boolean;
  handleChangeValue: (fieldName: string, record: any, value: any) => any;
}> = ({
  tree,
  record,
  type,
  messageType,
  messagePlaceholder,
  label,
  defaultInput,
  needVariable,
  handleChangeValue,
}) => {
  const [form] = Form.useForm();

  const patternList = useMemo(() => {
    return DataSetFieldValidators.filter(
      (item) => item.validatType === 'pattern'
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      [type]: record[type],
      [messageType!]: record?.defaultValidationMessages?.[messageType!],
    });
  }, [form, messageType, record, type]);

  const handleValuesChange = (changedValues: any) => {
    for (const key in changedValues) {
      if (key === type) {
        handleChangeValue(type, record, {
          ...record[type],
          ...changedValues[key],
        });
      }
      if (key === messageType) {
        handleChangeValue('defaultValidationMessages', record, {
          ...record.defaultValidationMessages,
          [messageType]: changedValues[key] || undefined,
        });
      }
    }
  };

  const handlePatternTypeChange = (e: any) => {
    let patternObj: any = patternList.find((p) => p.code === e);
    if (e === 'custom') {
      patternObj = {
        name: '自定义正则',
      };
    }
    if (isUndefined(e)) {
      patternObj = {};
    }
    if (patternObj) {
      const values = {
        [type]: {
          ...record[type],
          __patternType: e,
          value: String(patternObj.value || ''),
          name: patternObj.name,
        },
        [messageType!]: patternObj.errorMsg,
      };
      form.setFieldsValue(values);
      handleValuesChange(values);
    }
  };

  const getPatternField = () => {
    if (type === 'pattern') {
      return (
        <>
          <Form.Item label='正则类型' name={[type, '__patternType']}>
            <Select
              onChange={handlePatternTypeChange}
              suffixIcon={<SelectRightImg />}
            >
              {patternList.map((p) => (
                <Select.Option value={p.code} key={p.code}>
                  {p.name}
                </Select.Option>
              ))}
              <Select.Option value='custom'>自定义正则</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='正则表达式' name={[type, 'value']}>
            <Input
              disabled={
                form.getFieldValue([type, '__patternType']) !== 'custom'
              }
              title={form.getFieldValue([type, 'value'])}
            />
          </Form.Item>
        </>
      );
    }
    return null;
  };

  const getInputComp = () => {
    if (needVariable) {
      return (
        <VariableInput
          globalDsConfig={tree.dsConfig}
          extraVariable={{
            params: {
              name: '校验参数',
              code: '(params) =>',
              usage: (
                <>
                  <p>
                    可获取行数据以及字段名，例如：params.record、params.name
                  </p>
                  <p>
                    使用方法示例：(params) ={'>'} params.record.get('fieldName')
                    === 'field1'
                  </p>
                </>
              ),
            },
          }}
          example={
            <pre>
              (params)=&gt;{'\u007b'}
              let flag = false; // 你的逻辑... flag = true; return flag;
              {'\u007d'}
            </pre>
          }
        >
          {defaultInput}
        </VariableInput>
      );
    }
    return defaultInput;
  };

  return (
    <>
      <Form
        form={form}
        className='htd-validate-test'
        onValuesChange={handleValuesChange}
      >
        {type !== 'required' && (
          <Form.Item
            label='启用'
            name={[type, '__isEnable']}
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
        )}

        {getPatternField()}
        {defaultInput && (
          <Form.Item label={label} name={[type, 'value']}>
            {getInputComp()}
          </Form.Item>
        )}
        {messageType && (
          <Form.Item label='错误提示' name={messageType}>
            <Input
              title={messagePlaceholder}
              defaultValue={messagePlaceholder}
            />
          </Form.Item>
        )}
      </Form>
    </>
  );
};

export default ValidateConfigPanel;

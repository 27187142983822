/*
 * @Description: Tree 事件触发预置逻辑
 */
import React from 'react';
import { TemplateParamType } from '@htd/common';
import { treeNodeClickWithQuery } from './events';
const TreeEvents = [
    {
        code: 'TREE_NODE_CLICK_FOR_QUERY',
        name: '点击节点联动查询',
        requiredParam: [
            {
                name: '触发查询dataSet',
                index: 'querydsName',
                describe: '指定那个dataSet执行查询',
                type: TemplateParamType.dataSetName,
            },
        ],
        otherParam: [
            {
                name: '查询参数',
                index: 'search',
                type: TemplateParamType.keyValueArray,
                helper: (React.createElement("div", null,
                    "\u53EF\u901A\u8FC7",
                    React.createElement("span", { className: 'code' }, "params"),
                    "\u53C2\u6570\u83B7\u53D6\u5F53\u524D\u70B9\u51FB\u7684\u8282\u70B9\u8BB0\u5F55",
                    React.createElement("span", { className: 'code' }, "record"),
                    "\uFF0C\u6CE8\u610F\u4EE3\u7801\u540E\u9762\u4E0D\u9700\u8981\u5199\u3010;\u3011,\u3010,\u3011Eg:",
                    React.createElement("pre", { className: 'code' }, "params.record.get(\"fieldA\")"))),
            },
        ],
        function: treeNodeClickWithQuery,
    },
];
export default TreeEvents;

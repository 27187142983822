import { FC, useContext } from 'react';
import { Lov as ProLov } from 'choerodon-ui/pro';
import cls from 'classnames';
import './styles.less';
import { DesignContext } from 'views/Home/DesignContext';

const FormLov: FC<any> = (props) => {
  const { cMap } = useContext(DesignContext);
  const item = cMap.get(props.designerProps.id)
  const clsNames = cls(
    'htd-placeholder-form-field',
    item?.validate?.required?.value && 'htd-placeholder-form-field-required',
  );
  return (
    <div className={clsNames}>
      <div className='htd-placeholder-form-field-label'>{props.label}</div>
      <ProLov className='htd-placeholder-form-field-content' readOnly />
    </div>
  );
};

export { FormLov };

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tooltip, Icon } from 'choerodon-ui';
import { Collapse, Popconfirm } from 'antd';
import * as uuid from 'uuid';
import { Modal, TextField } from 'choerodon-ui/pro';
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { dsNameRegexp } from 'utils';
import { DsProps } from '@htd/common';
import { CaretRightOutlined } from '@ant-design/icons';

import DataSetConfig from './DataSetConfig';
import { deleteIcon as deleteTB } from '../../../../utils/unifiedExportIcon';

import './index.less';
import { DesignContext } from 'views/Home/DesignContext';
import { cloneDeep } from 'lodash';

const Panel = Collapse.Panel;

const DataSetList: React.FC = () => {
  const [noDeleteKeyList, setNoDeleteKeyList] = useState<string[]>([]);
  const collapseRef = useRef();
  const { tree, forceUpdate } = useContext(DesignContext);
  const dsList = useMemo(() => tree.dsConfig, [tree.dsConfig]);

  const setDsList = useCallback(
    (list) => {
      tree.dsConfig = cloneDeep(list);
      forceUpdate?.(undefined, { dsConfig: tree.dsConfig });
    },
    [forceUpdate, tree]
  );
  const [maxLength, setMaxLength] = useState<number>(dsList.length);
  const [activeKey, setActiveKey] = useState<string | string[]>(
    dsList.map((item) => String(item.dsKey))
  );

  /**
   * 添加DataSet配置
   */
  const handleAddDsConfig = () => {
    const newMaxLength = maxLength + 1;
    const dsKey = uuid.v4();
    setDsList([
      ...dsList,
      {
        dsName: `dataSet${newMaxLength}`,
        dsKey,
        fields: [],
        queryFields: [],
      },
    ]);
    setMaxLength(newMaxLength);
    setActiveKey(
      typeof activeKey === 'string' ? [activeKey, dsKey] : [...activeKey, dsKey]
    );
  };

  const handleBeforeChangeDsName = (dsConfig: DsProps, value: string) => {
    // 判断是否为空
    if (!value) return false;
    // 判断是否重复
    const [isRepeat] = dsList.filter(
      (item) => item.dsName === value && item.dsKey !== dsConfig.dsKey
    );
    if (isRepeat) {
      Modal.error('DataSet文件名不允许重复！');
      return false;
    }
    if (!dsNameRegexp.test(value)) {
      Modal.error('DataSet文件名不合法，请使用字母+数字且字母开头的组合命名！');
      return false;
    }
    return true;
  };

  /**
   * 修改DataSet文件名
   * @param dsConfig
   * @param value
   */
  const handleChangeDsName = (dsConfig: DsProps, value: string) => {
    dsConfig.dsName = value;
    setDsList([...dsList]);
  };

  /**
   * 删除DataSet配置
   * @param _dsConfig
   * @param index
   * @param e
   */
  const handleClickDsDelete = (dsConfig: DsProps, index: number, e: any) => {
    dsList.splice(index, 1);
    setDsList([...dsList]);
    if (typeof activeKey === 'string') {
      if (activeKey === dsConfig.dsKey) {
        setActiveKey([]);
      }
    } else {
      setActiveKey(activeKey.filter((key) => key !== dsConfig.dsKey));
    }
  };

  const handleChangeActiveKey = (keys: string | string[]) => {
    setActiveKey(keys);
  };

  const deleteIcon = (dsConfig: DsProps, index: number) => {
    const set = new Set(noDeleteKeyList);
    if (set.has(dsConfig.dsKey)) {
      return (
        <Tooltip title='存在关联组件，无法删除'>
          <Icon type='delete' />
        </Tooltip>
      );
    }
    return (
      <Popconfirm
        title='确认删除？'
        onConfirm={handleClickDsDelete.bind(this, dsConfig, index)}
        okText='确认'
        cancelText='取消'
      >
        {/* <Icon type='delete' /> */}
        <img
          alt='delete'
          src={deleteTB}
          className='htd-collapse-edit-header-imgIcon'
        />
      </Popconfirm>
    );
  };

  return (
    <>
      <div className='htd-aside-left-main-dataSet'>
        {/* <button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          style={{
            width: '64px',
            height: '28px',
            borderRadius: '2px',
            marginBottom: '12px',
            marginTop: '12px',
            fontSize: '12px',
          }}
          onClick={handleAddDsConfig}
        >
          添加
        </button> */}
        <Collapse
          className='htd-aside-left-main-dataSet-collapse left-collapse-box'
          activeKey={activeKey}
          bordered={false}
          onChange={handleChangeActiveKey}
          // @ts-ignore
          ref={collapseRef}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
            // <img alt='' src={isActive ? arrowUpIcon : arrowDownIcon} />
          )}
          // collapsible='header'
          // trigger='icon'
        >
          {dsList.map((dsConfig, index) => (
            <Panel
              // showArrow={false}
              key={dsConfig.dsKey}
              className='htd-aside-left-main-dataSet-collapse-panel-bottom'
              header={
                <div className='htd-collapse-edit-header'>
                  <TextField
                    className='htd-collapse-edit-header-input'
                    value={dsConfig.dsName}
                    onBeforeChange={handleBeforeChangeDsName.bind(
                      this,
                      dsConfig
                    )}
                    onChange={handleChangeDsName.bind(this, dsConfig)}
                  />
                  {deleteIcon(dsConfig, index)}
                </div>
              }
            >
              <DataSetConfig
                dsConfig={dsConfig}
                noDeleteKeyList={noDeleteKeyList}
                setNoDeleteKeyList={setNoDeleteKeyList}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};

export default DataSetList;

import { isC7nPro } from '@htd/common';
import { MappingHook } from './utils';

export const onFormChange: MappingHook = (component) => {
  if (component && component.name === 'Form' && isC7nPro(component)) {
    component.children.forEach((child) => {
      const columns = component.props?.columns.value;
      if (child.props?.colSpan && columns) {
        child.props.colSpan.max = columns;
      }
    });
  }
};

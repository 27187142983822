import { FormLov } from 'components/placeholders';
import { Mapping } from '@htd/common';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import { BASIC_PROPS, OTHER_PROPS } from 'utils/config';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const Lov: Mapping = {
  name: 'Lov',
  title: 'Lov选择器',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'object',
  iconCode: 'Lov',
  cascadeData: [],
  lovParaData: [],
  props: {
    ...BASE_PROPS_TYPES,
    ...getInputCommon('lov', 'lov选择'),
    // ...BASE_UTIL_PROPS_TYPES,
    // 其它属性分组
    ...FORM_ITEM_TYPES,
    lovCode: {
      type: 'string',
      label: '值集视图',
      value: '',
      ignore: true,
      isDS: true,
      group: OTHER_PROPS,
    },
    textField: {
      type: 'string',
      label: '显示字段名',
      value: undefined,
      isDS: true,
      group: OTHER_PROPS,
    },
    valueField: {
      type: 'string',
      label: '值字段名',
      value: undefined,
      isDS: true,
      group: OTHER_PROPS,
    },
    defaultValue: {
      type: 'string',
      label: '默认值',
      value: '',
      isDS: true,
      valueConfigurable: true,
      group: OTHER_PROPS,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = {'\u007b'}name: '张三',id: 1
          {'\u007d'}; // 你的逻辑... value = {'\u007b'}name: '李四',id: 2
          {'\u007d'}; return value;{'\u007d'}
        </pre>
      ),
    },
    multiple: {
      // type: 'boolean',
      type: 'collection-select',
      label: '多值',
      value: 'false',
      isDS: true,
      group: OTHER_PROPS,
      options: [
        { label: 'true', value: 'true' },
        { label: 'false', value: 'false' },
        { label: ',', value: ',' },
        { label: ';', value: ';' },
        { label: '#', value: '#' },
        { label: '_', value: '_' },
      ],
    },
    ignore: {
      type: 'collection-select',
      label: '忽略提交',
      value: 'always',
      help: '前端传参时字段不进行提交，主要针对object类的字段',
      isDS: true,
      options: [
        { label: '总是忽略', value: 'always' },
        { label: '值未变化时忽略', value: 'clean' },
        { label: '从不忽略', value: 'never' },
      ],
      showHelp: true,
      group: OTHER_PROPS,
    },
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  validate: {},
  placeholderComponent: FormLov,
};

import React, { FC } from 'react';
import { Button as ProButton } from 'choerodon-ui/pro';

const Button: FC<any> = (props) => {
  const { style } = props;

  let size = {
    width: style ? `${style}px` : '75px',
  };

  return (
    <ProButton
      className='htd-placeholder-button'
      icon={props.icon}
      color={props.color}
      disabled={props.disabled || props.hidden}
      style={size}
    >
      {props.shadowChildren}
    </ProButton>
  );
};

export { Button };

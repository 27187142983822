import React, { FC, useMemo, useContext, useEffect } from 'react';
import {
  Form as AntdForm,
  // InputNumber,
  DatePicker,
  Checkbox,
  Popover,
  Switch,
} from 'antd';
import { InputNumber } from 'choerodon-ui';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DesignContext } from 'views/Home/DesignContext';
import ValidateConfigPanel from './ValidateConfigPanel';
import { useCallback } from 'react';

type ValidateAttributeItem = {
  fieldName: string;
  label: string;
  defaultInput?: React.ReactNode;
  messageType?: string;
  messagePlaceholder?: string;
  needVariable?: boolean;
  needConfig?: boolean;
  hidden?: boolean;
};

interface ValidateAttributeProps {
  record: any;
  onChange: (fieldName: string, record: any, value: any, index?: number) => any;
  index?: number;
}

const ValidateAttribute: FC<ValidateAttributeProps> = ({
  record,
  onChange,
  index,
}) => {
  const { tree } = useContext(DesignContext);
  const getComponent = useCallback(
    (value: any) => {
      let realValue: any = moment.isMoment(value) ? value : moment(value);
      switch (record.col_fieldType) {
        case 'number':
          return <InputNumber defaultValue={value} />;
        case 'date':
          return <DatePicker defaultValue={realValue} allowClear />;
        case 'dateTime':
          return <DatePicker defaultValue={realValue} showTime allowClear />;
        case 'time':
          return <DatePicker defaultValue={value} picker='time' allowClear />;
        default:
          return null;
      }
    },
    [record.col_fieldType]
  );

  const validateAttributeList: ValidateAttributeItem[] = useMemo(() => {
    return [
      {
        fieldName: 'ds_required',
        label: '必填',
        defaultInput: <Switch checked={record.ds_required?.value} />,
        messageType: 'valueMissing',
        messagePlaceholder: '请输入{label}。',
        needVariable: true,
        needConfig: true,
      },
      {
        fieldName: 'ds_min',
        label: '最小值',
        defaultInput: getComponent(record.ds_min?.value),
        messageType: 'rangeUnderflow',
        messagePlaceholder: '{label}必须大于或等于{min}。',
        needVariable: true,
        needConfig: true,
        hidden: !['number', 'date', 'dateTime', 'time'].includes(
          record.col_fieldType
        ),
      },
      {
        fieldName: 'ds_max',
        label: '最大值',
        defaultInput: getComponent(record.ds_max?.value),
        messageType: 'rangeOverflow',
        messagePlaceholder: '{label}必须小于或等于{max}。',
        needVariable: true,
        needConfig: true,
        hidden: !['number', 'date', 'dateTime', 'time'].includes(
          record.col_fieldType
        ),
      },
      {
        fieldName: 'ds_minLength',
        label: '最小长度',
        defaultInput: (
          <InputNumber
            step='1'
            min={0}
            max={record.ds_maxLength?.value}
            precision={0}
          />
        ),
        messageType: 'tooShort',
        messagePlaceholder:
          '请将该内容增加到{minLength}个或更多字符（目前您使用了{length}个字符）。',
        needConfig: true,
        hidden:
          !['number', 'string'].includes(record.col_fieldType) ||
          record.ds_lookupCode ||
          record.ds_lovCode,
      },
      {
        fieldName: 'ds_maxLength',
        label: '最大长度',
        defaultInput: (
          <InputNumber
            step='1'
            min={record.ds_minLength?.value}
            precision={0}
          />
        ),
        messageType: 'tooLong',
        messagePlaceholder:
          '请将该内容减少到{maxLength}个或更少字符（目前您使用了{length}个字符）。',
        needConfig: true,
        hidden:
          !['number', 'string'].includes(record.col_fieldType) ||
          record.ds_lookupCode ||
          record.ds_lovCode,
      },
      {
        fieldName: 'ds_pattern',
        label: '正则表达式',
        messageType: 'patternMismatch',
        messagePlaceholder: '请输入有效的值。',
        needConfig: true,
      },
      {
        fieldName: 'ds_validator',
        label: '自定义校验',
        // needConfig: true,
      },
    ];
  }, [
    getComponent,
    record.ds_lookupCode,
    record.ds_lovCode,
    record.ds_max,
    record.ds_maxLength,
    record.ds_min,
    record.ds_minLength,
    record.ds_required,
    record.col_fieldType,
  ]);

  const handleValidateConfigPanelChange = (
    fieldName: string,
    changedValues: any
  ) => {
    for (const key in changedValues) {
      onChange(`${fieldName}_${key}`, record, changedValues[key], index);
    }
  };

  const [panelForm] = AntdForm.useForm();

  useEffect(() => {
    panelForm.resetFields();
    panelForm.setFieldsValue({
      ...record,
    });
  }, [panelForm, record]);

  const ExtraContent = (validateItem: ValidateAttributeItem) => {
    return validateItem.needConfig ? (
      <Popover
        content={
          <ValidateConfigPanel
            tree={tree}
            record={record}
            type={validateItem.fieldName}
            label={validateItem.label}
            defaultInput={validateItem.defaultInput}
            messageType={validateItem.messageType}
            messagePlaceholder={validateItem.messagePlaceholder}
            onChange={handleValidateConfigPanelChange}
            needVariable={validateItem.needVariable}
            panelForm={panelForm}
          />
        }
        trigger='click'
        placement='left'
        destroyTooltipOnHide
        onVisibleChange={(v) => {
          if (!v) {
            const values = panelForm.getFieldsValue();
            handleValidateConfigPanelChange(validateItem.fieldName, values);
          }
        }}
      >
        {getTextContext(validateItem)}
        <EditOutlined />
      </Popover>
    ) : null;
  };

  const getTextContext = (validateItem: ValidateAttributeItem) => {
    const numberList = ['ds_max', 'ds_min', 'ds_maxLength', 'ds_minLength'];
    const patternList = ['ds_pattern'];
    if (![...numberList, ...patternList].includes(validateItem.fieldName)) {
      return null;
    }
    const getObjectValue = (data: any) => {
      if (data && (data as any)?.__isVariable) {
        return (data as any)?.value;
      }
      if (data && ['date'].includes((record as any)?.col_fieldType)) {
        return moment(data).format('YYYY-MM-DD');
      }
      if (data && ['dateTime'].includes((record as any)?.col_fieldType)) {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data && ['time'].includes((record as any)?.col_fieldType)) {
        return moment(data).format('HH:mm:ss');
      }
      return data;
    };

    return (
      <span className='antd-table-number'>
        {patternList.includes(validateItem.fieldName)
          ? (record as any)[validateItem.fieldName]?.name // 正则表达式显示名称
          : getObjectValue((record as any)[validateItem.fieldName]?.value)}
      </span>
    );
  };

  return (
    <section className='htd-aside-right-main-validator'>
      {validateAttributeList
        .filter((validateItem) => !validateItem.hidden)
        .map((validateItem) => (
          <>
            <AntdForm.Item name={validateItem.fieldName} noStyle />
            <AntdForm.Item
              key={validateItem.fieldName}
              label={validateItem.label}
              name={[validateItem.fieldName, 'isEnable']}
              valuePropName='checked'
              extra={ExtraContent(validateItem)}
            >
              <Checkbox />
            </AntdForm.Item>
          </>
        ))}
    </section>
  );
};

export default ValidateAttribute;

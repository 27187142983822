import React, { useMemo, useContext, useEffect } from 'react';
import { isArray } from 'lodash';
import {
  Form as AntdForm,
  Switch,
  Radio,
  Popconfirm,
  Button,
  Select,
  Input,
  // Collapse,
  // InputNumber,
} from 'antd';
import MyIcon from 'components/myIcon';
import './index.less';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';
import EditPanel from './EditPanel';
import SelectRightImg from 'components/selectRightImg';
import { dragIcon } from '../../../../utils/unifiedExportIcon';
import VariableInput from 'components/VariableInput';

const OperatorColumnPanel: React.FC = (props) => {
  // @ts-ignore
  const { tree } = props;
  const { settingItem: item, forceUpdate } = useContext(DesignContext);
  const { configItem, tableColumns, onSettingConfig } =
    useContext(ConfigContext);
  const buttonList = useMemo(() => {
    return [
      { value: 'edit', meaning: '编辑', children: '编辑' },
      { value: 'delete', meaning: '删除', children: '删除' },
      { value: 'custom', meaning: '自定义', children: '自定义' },
    ];
  }, []);

  // 操作列
  const operatorCol = tableColumns.length
    ? tableColumns[tableColumns.length - 1].command
      ? tableColumns[tableColumns.length - 1]
      : undefined
    : undefined;

  // 操作列按钮数据
  const dataSource: any[] = (operatorCol?.command as any[])?.map(
    (item: any) => ({
      type: typeof item === 'string' ? item : 'custom',
      label:
        item === 'edit'
          ? '编辑'
          : item === 'delete'
          ? '删除'
          : item[1].children,
      key: item && item.length && item.length > 1 ? item[1].key : '',
    })
  );

  const [form] = AntdForm.useForm();

  // 当前数据
  const currentRecord =
    configItem?.index !== undefined &&
    configItem?.type === 'operator' &&
    dataSource?.length
      ? dataSource[configItem.index]
      : undefined;

  /**
   * 设置当前编辑按钮
   */
  const handleEditItemClick = (_: any, index: number) => {
    onSettingConfig?.(
      configItem?.index === index && configItem?.type === 'operator'
        ? undefined
        : { index, type: 'operator' }
    );
  };

  /**
   * 修改操作列属性
   */
  const handleValuesChange = (changedValues: any) => {
    if (tableColumns) {
      if (changedValues.hasOwnProperty('showOperator')) {
        if (changedValues.showOperator) {
          tableColumns.push({
            header: '操作',
            command: [],
          });
          form.setFieldsValue({ header: '操作' });
        } else {
          tableColumns.pop();
        }
      } else if (operatorCol) {
        for (const key in changedValues) {
          (operatorCol as any)[key] = changedValues[key];
          if (
            (key === 'lock' || key === 'align') &&
            changedValues[key] === 'null'
          ) {
            delete operatorCol[key];
          }
        }
      }
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 修改操作按钮类型
   */
  const handleChangeCommand = (index: number, value: any) => {
    // 自定义按钮
    if (value === 'custom') {
      value = [
        'edit',
        { children: '自定义', icon: '', key: `customOperator-${index}` },
      ];
    }
    (operatorCol?.command as string[]).splice(index, 1, value);
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  /**
   * 添加操作按钮
   */
  const handleAddLine = () => {
    const newLine: any[] = [
      buttonList.filter((i) => !isButtonOptionDisable(i))[0].value,
    ];
    // 自定义按钮
    if (newLine[0] === 'custom') {
      newLine[0] = [
        'edit',
        {
          children: '自定义',
          icon: '',
          key: `customOperator-${operatorCol?.command?.length || 0}`,
        },
      ];
    }
    if (operatorCol && operatorCol.command && isArray(operatorCol.command)) {
      operatorCol.command = [...(operatorCol.command as any[]), ...newLine];
    } else if (operatorCol) {
      operatorCol.command = [...newLine];
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 删除操作按钮
   */
  const handleDeleteLine = (index: number) => {
    (operatorCol?.command as string[]).splice(index, 1);
    forceUpdate?.(item?.id);
  };

  const initialValues = {
    header: operatorCol?.header,
    lock: operatorCol?.lock || 'null',
    align: operatorCol?.align || 'null',
    // @ts-ignore
    more: operatorCol?.more || false,
    // @ts-ignore
    columnWidth: operatorCol?.columnWidth || '',
    // @ts-ignore
    operatorHidden: operatorCol?.operatorHidden || true,
  };

  const isButtonOptionDisable = (button: any) => {
    if (button.value === 'custom') return false;
    if (!dataSource) return false;
    const idx = dataSource.findIndex((d) => d.type === button.value);
    return idx !== -1;
  };

  /**
   * 拖动排序
   */
  const handleDragEnd = (dataSource: any[]) => {
    if (operatorCol?.command) {
      const dataList = operatorCol.command || [];
      // @ts-ignore
      dataList.sort((a: any, b: any) => {
        if (isArray(a) && !isArray(b)) {
          const aValue = (a.length && a.length > 1 && a[1] && a[1].key) || '';
          const aIndex = dataSource.findIndex(
            (record) => record.key === aValue
          );
          const bIndex = dataSource.findIndex((record) => record.type === b);
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else if (!isArray(a) && isArray(b)) {
          const bValue = (b.length && b.length > 1 && b[1] && b[1].key) || '';
          const aIndex = dataSource.findIndex((record) => record.type === a);
          const bIndex = dataSource.findIndex(
            (record) => record.key === bValue
          );
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else if (isArray(a) && isArray(b)) {
          const aValue = (a.length && a.length > 1 && a[1] && a[1].key) || a;
          const bValue = (b.length && b.length > 1 && b[1] && b[1].key) || b;
          const aIndex = dataSource.findIndex(
            (record) => record.key === aValue
          );
          const bIndex = dataSource.findIndex(
            (record) => record.key === bValue
          );
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        } else {
          const aIndex = dataSource.findIndex((record) => record.type === a);
          const bIndex = dataSource.findIndex((record) => record.type === b);
          return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
        }
      });
    }
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: () => {
        return (
          <img
            alt='drag'
            src={dragIcon}
            className='htd-aside-right-columns-table-status-box-img'
          />
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      render: (value, record, index) => {
        if (record.$form) {
          // 自定义标题和按钮保持一致
          buttonList[2].children = dataSource[index].label;
          return (
            <AntdForm.Item name='type'>
              <Select
                suffixIcon={<SelectRightImg />}
                onChange={handleChangeCommand.bind(this, index)}
                className='htd-aside-right-operator-table-select'
              >
                {buttonList.map((button) => (
                  <Select.Option
                    value={button.value}
                    key={button.value}
                    disabled={isButtonOptionDisable(button)}
                  >
                    {value === 'custom' ? button.children : button.meaning}
                  </Select.Option>
                ))}
              </Select>
            </AntdForm.Item>
          );
        }
        return value;
      },
    },
    {
      width: 60,
      align: 'center',
      className: 'htd-aside-right-operator-table-operator',
      render: (_, record, index) => (
        <>
          {record.type === 'custom' && (
            <MyIcon
              type='icon-a-edit2x'
              className='left-svg-cla'
              onClick={handleEditItemClick.bind(this, record, index)}
            />
          )}
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, index)}
          >
            {/* <img src={deleteIcon} alt='edit' /> */}
            <MyIcon
              type='icon-a-delete2x'
              style={{ marginRight: '6px' }}
              className='left-svg-cla'
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (item?.id) {
      form.resetFields();
    }
  }, [form, item?.id]);

  return (
    <div className='htd-operator-box'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={initialValues}
        onValuesChange={handleValuesChange}
        className='htd-operator-box-top'
      >
        <AntdForm.Item label='开启操作列' name='showOperator'>
          <Switch checked={!!operatorCol} />
        </AntdForm.Item>
        {!!operatorCol && (
          <>
            <AntdForm.Item label='列名' name='header'>
              <Input />
            </AntdForm.Item>
            <AntdForm.Item label='操作列固定' name='lock'>
              <Radio.Group style={{ borderRadius: '4px' }}>
                <Radio.Button value='null'>无</Radio.Button>
                <Radio.Button value='left'>左</Radio.Button>
                <Radio.Button value='right'>右</Radio.Button>
              </Radio.Group>
            </AntdForm.Item>
            <AntdForm.Item label='对齐方式' name='align'>
              <Radio.Group>
                <Radio.Button value='null'>无</Radio.Button>
                <Radio.Button value='left'>左</Radio.Button>
                <Radio.Button value='center'>中</Radio.Button>
                <Radio.Button value='right'>右</Radio.Button>
              </Radio.Group>
            </AntdForm.Item>
            <AntdForm.Item
              label='更多操作'
              name='more'
              tooltip='如果开启，则当操作按钮超过3个时，多余按钮放入【更多】按钮中！'
            >
              {/* @ts-ignore */}
              <Switch checked={operatorCol?.more} />
            </AntdForm.Item>
            <AntdForm.Item label='宽度' name='columnWidth'>
              <Input
                className='htd-operator-box-top-numberI'
                style={{ height: '28px' }}
              />
            </AntdForm.Item>
            <AntdForm.Item label='隐藏' name='operatorHidden'>
              <VariableInput
                globalDsConfig={tree.dsConfig}
                onChange={(value) => {
                  const obj = { operatorHidden: value };
                  handleValuesChange(obj);
                }}
                {...props}
              >
                {/* @ts-ignore */}
                <Switch checked={operatorCol?.operatorHidden} />
              </VariableInput>
            </AntdForm.Item>
            {/* <AntdForm.Item label='最大展示数' name='totalNum'>
              <InputNumber
                className='htd-operator-box-top-numberI'
                style={{ height: '28px' }}
              />
            </AntdForm.Item> */}
          </>
        )}
      </AntdForm>
      {!!operatorCol && (
        <>
          <EditTable
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size='small'
            bordered={false}
            draggable
            onDragEnd={handleDragEnd}
            // showHeader={false}
            showHeader={Boolean(dataSource?.length)}
            className='htd-aside-right-operator-table'
          />
          {currentRecord && <EditPanel />}
          <div className='htd-aside-right-operator-button-wrapper'>
            <Button
              className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
              onClick={handleAddLine}
            >
              添加一项
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default OperatorColumnPanel;

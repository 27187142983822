export class ScriptManager {
  private dom: HTMLScriptElement | null;

  constructor() {
    this.dom = null;
  }

  createNode() {
    this.dom = document.createElement('script');
  }

  updateContent(content: string) {
    this.removeNode();
    this.createNode();
    this.dom!.innerHTML = content;
    this.attach();
  }

  attach() {
    if (this.dom) {
      document.body.appendChild(this.dom);
    }
  }

  removeNode() {
    if (this.dom && this.dom.parentNode === document.body) {
      document.body.removeChild(this.dom);
    }
  }
}

import React, { useCallback } from 'react';
import { Form as AntdForm, Switch } from 'antd';
import { cloneDeep } from 'lodash';
import VariableInput from 'components/VariableInput';
import { ConfigPanelComponentProp } from '@htd/common';

const DataPanel: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const [form] = AntdForm.useForm();

  const update = useCallback(() => {
    if (item?.props) item.props = cloneDeep(item.props);
    forceUpdate?.(item?.id);
  }, [forceUpdate, item]);

  /**
   * 变更字段值
   */
  const handleValuesChange = useCallback(
    (changedValues: any) => {
      for (const key in changedValues) {
        const [type, name] = key.split('_');
        if (item.props) {
          if (type === 'tableQuery') {
            item.props['query'].value[name] = changedValues[key];
          }
        }
      }
      update();
    },
    [update, item]
  );

  return (
    <section className='htd-aside-right-main-section'>
      <AntdForm
        form={form}
        labelAlign='left'
        onValuesChange={handleValuesChange}
      >
        <AntdForm.Item label='接口地址' name='tableQuery_url'>
          <VariableInput
            globalDsConfig={tree.dsConfig}
            example={
              <span>
                /hfpm/v1/${'\u007b'}this.getCurrentTenant().tenantId{'\u007d'}
                /bank/list
              </span>
            }
          />
        </AntdForm.Item>
        {/* <AntdForm.Item
          label='接口类型'
          name='tableQuery_method'
          initialValue='GET'
        >
          <Select>
            <Select.Option value='GET'>GET</Select.Option>
            <Select.Option value='POST'>POST</Select.Option>
            <Select.Option value='PUT'>PUT</Select.Option>
            <Select.Option value='DELETE'>DELETE</Select.Option>
          </Select>
        </AntdForm.Item> */}
        <AntdForm.Item
          label='分页'
          name='tableQuery_paging'
          initialValue={true}
          valuePropName='checked'
        >
          <Switch />
        </AntdForm.Item>
      </AntdForm>
    </section>
  );
};

export default DataPanel;

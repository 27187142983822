import React, { FC, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';
import { Router, RouterProvider, Routes } from './router';
import { StoreProvider, useStore } from './store';
import Home from './views/Home';
import { ObjectType } from '@htd/common';
import './init-global';
import './antd-override.less';

const routes: Routes = [{ path: '/', component: Home }];

export interface AppProps {
  config: {
    token: string;
    self: ObjectType;
  };
}

const Main: FC<{
  config: any;
}> = (props) => {
  const { dispatch } = useStore();
  // 把外部传过来的配置数据存到状态树中
  useEffect(() => {
    // login();
    dispatch({
      type: 'global/config',
      payload: props.config,
    });
  }, [dispatch, props.config]);
  return <Router />;
};

const App: FC<AppProps> = (props) => {
  return (
    <ConfigProvider locale={zh_CN}>
      <RouterProvider routes={routes}>
        <StoreProvider>
          <Main config={props.config} />
        </StoreProvider>
      </RouterProvider>
    </ConfigProvider>
  );
};

export default App;

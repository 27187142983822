/*
 * @Author: your name
 * @Date: 2021-09-27 17:02:46
 * @LastEditTime: 2021-11-06 14:58:55
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /hzero-code-generator/packages/designer/src/hzero-adapter/components/HPage/Header.js
 */
import React, { Component } from 'react';
// import { Icon } from 'choerodon-ui';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default class PageHeader extends Component {
  onBackBtnClick = () => {};

  linkToChange = (url) => {};

  render() {
    const { title, backPath, children } = this.props;
    let backBtn = '';
    if (backPath) {
      backBtn = (
        <div
          key='page-head-back-btn'
          className='page-head-back-btn'
          onClick={() => {
            window.history.back();
          }}
        >
          {/* <Icon
            type='arrow-left'
            className='back-btn'
            onClick={this.onBackBtnClick}
          /> */}
          <ArrowLeftOutlined />
        </div>
      );
    }
    return (
      <div className='page-head'>
        {backBtn}
        {title && (
          <span key='page-head-title' className='page-head-title'>
            {title}
          </span>
        )}
        <div key='page-head-operator' className='page-head-operator'>
          {children}
        </div>
      </div>
    );
  }
}

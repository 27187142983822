import { Mapping } from '@htd/common';
import { FormField } from 'components/placeholders';
import { BASIC_PROPS, OTHER_PROPS } from 'utils/config';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const EmailField: Mapping = {
  name: 'EmailField',
  title: '邮箱输入框',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'email',
  iconCode: 'email',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('emailField', '邮箱输入'),
    defaultValue: {
      type: 'string',
      label: '默认值',
      isDS: true,
      group: OTHER_PROPS,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = 'hzero@hand-china.com'; //
          你的逻辑... value = 'vip@hand-china.com'; return value;{'\u007d'}
        </pre>
      ),
    },
    // 关系分组
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  placeholderComponent: FormField,
  validate: {},
  showValidate: ['minLength', 'maxLength'],
};

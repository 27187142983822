import { FC, useContext, useState, useEffect, useMemo } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { Tree } from 'choerodon-ui';
// import { createFromIconfontCN } from '@ant-design/icons';
import { DesignComponent, DynamicObject } from '@htd/common';
import { DesignContext } from 'views/Home/DesignContext';

// import MyIcon from 'components/myIcon';
import './index.less';

import { createFromIconfontCN } from '@ant-design/icons';

const CreateMyIcon = createFromIconfontCN({
  scriptUrl: 'iconfont.js', // 在 iconfont.cn 上生成
});

const TreeNode = Tree.TreeNode;

const canDragLabel = [
  'TextField',
  'Lov',
  'NumberField',
  'Select',
  'Currency',
  'DatePicker',
  'EmailField',
  'IntlField',
  'Output',
  'Password',
  'RichText',
  'Range',
  'Switch',
  'TextArea',
  'Upload',
  'UrlField',
];

const ListTree: FC = () => {
  // 不知道为什么直接设置showIcon会显示一个'文件'的图标
  const [showLine, setShowLine] = useState(true);
  useEffect(() => {
    setShowLine({
      //@ts-ignore
      showLeafIcon: false,
    });
  }, []);

  const { tree, onSetting, cMap, forceUpdate } = useContext(DesignContext);

  const nodeTree = useMemo(() => {
    const nodeTree = cloneDeep(tree);
    const root = nodeTree.children;
    const content = root[1]; // Content节点
    if (content) {
      const result: DesignComponent[] = [];
      const contentChildren = content.children;
      let contentChildrenLength = contentChildren.length;
      while (contentChildrenLength--) {
        if (contentChildren[contentChildrenLength].name === 'Modal') {
          result.push(contentChildren[contentChildrenLength]);
          contentChildren.splice(contentChildrenLength, 1);
        }
      }
      root.push(...result.reverse());
    }
    return nodeTree;
  }, [tree]);

  const onSelect = (selectedKeys: any, info: any) => {
    const target = cMap.get(selectedKeys[0]);
    if (target) onSetting?.(target);
  };

  const onCheck = (checkedKeys: any, info: any) => {
    // console.log('onCheck', checkedKeys, info);
  };

  const onTreeDrop = (info: any) => {
    if (!info.dropToGap) {
      return; // 阻止把节点移入根节点的children里面
    }
    if (![...canDragLabel].includes(info.node['data-Ref']?.name)) {
      return; // 阻止把节点移入除基础组件和Form外的位置
    }
    if (!canDragLabel.includes(info.dragNode['data-Ref']?.name)) {
      return; // 只允许基础组件拖拽
    }
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data: any, key: string, callback?: any) => {
      data.forEach((item: any, index: number, arr: any) => {
        if (item.id === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };
    const data: DesignComponent[] = tree.children;
    let dragObj: any;
    loop(data, dragKey, (item: any, index: any, arr: any) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (info.dropToGap) {
      let ar: any;
      let i: any;
      loop(data, dropKey, (item: any, index: any, arr: any) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar?.splice(i, 0, dragObj);
      } else {
        ar?.splice(i + 1, 0, dragObj);
      }
    }
    if (forceUpdate) {
      forceUpdate();
    }
  };

  const renderTreeNodes = (data: any) => {
    return data.map((item: any) => {
      let title = item.props?.label?.value || item.title;
      if (item.name === 'Button') {
        title = item.props.children.value;
      }
      if (item.name === 'ExcelExport') {
        title = item.props.buttonText.value;
      }
      if (item.name === 'Modal' || item.name === 'Header') {
        title = item.props.title.value;
      }
      if (!isEmpty(item?.children)) {
        return (
          <TreeNode
            title={title}
            key={item.id}
            data-Ref={item}
            data-icon={item.iconCode}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          title={title}
          data-Ref={item}
          data-icon={item.iconCode}
        />
      );
    });
  };
  if (
    nodeTree.children.length &&
    nodeTree.children.find((c) => c.componentId === 'custom-footer') &&
    !tree.isShowFooter
  ) {
    const i = nodeTree.children.findIndex(
      (c) => c.componentId === 'custom-footer'
    );
    nodeTree.children.splice(i, 1);
  }
  return nodeTree.children.length ? (
    <Tree
      className='htd-aside-left-main-tree'
      showLine={showLine}
      showIcon
      icon={(props: DynamicObject) => {
        const iconCode = props['data-icon'] || 'a-shu1x';
        const svgCode = 'icon-' + iconCode;
        return <CreateMyIcon type={svgCode} style={{ color: '#A9ADB7' }} />;
      }}
      onSelect={onSelect}
      onCheck={onCheck}
      defaultExpandAll
      draggable // 开启拖拽功能
      onDrop={onTreeDrop}
    >
      {renderTreeNodes(nodeTree.children)}
    </Tree>
  ) : null;
};
export default ListTree;

import { MappingListItem } from '../util';
import { Layout2_4, Layout1_3 } from './Layout';
import { Row, Col } from './Grid';

export const layoutList: Array<MappingListItem> = [
  {
    id: 'hcg-layout-pro-row',
    name: '行容器',
    mapping: Row,
  },
  {
    id: 'hcg-layout-pro-col',
    name: '列容器',
    mapping: Col,
  },
  {
    id: 'hcg-layout-component_1*3',
    name: '一行三列',
    mapping: Layout1_3,
  },
  {
    id: 'hcg-layout-component_2*4',
    name: '两行四列',
    mapping: Layout2_4,
  },
];

import React, { FC, useEffect, useContext, useState } from 'react';
import { Switch, Form as AntdForm, Select } from 'antd';
import {
  ConfigPanelComponentProp,
  DesignComponent,
  DsProps,
} from '@htd/common';
import { ConfigContext } from './ConfigPanel/ConfigContext';
import { handleTableCancelNewCreateFunc } from './utils';
import './index.less';
import SelectRightImg from 'components/selectRightImg';
import { cloneDeep } from 'lodash';

const DataSetPanel: FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const [form] = AntdForm.useForm();
  const { dsQueryFields, dsConfig } = useContext(ConfigContext);
  const getDsConfigList = () => {
    return (tree.dsConfig || []).map((d) => {
      const compList: DesignComponent[] = [];
      const findComp = (children: DesignComponent[]) => {
        children.forEach((child) => {
          if (child.dsKey === d.dsKey) {
            compList.push(child);
          }
          if (child.children && child.children.length) {
            findComp(child.children);
          }
        });
      };
      findComp(tree.children);
      if (compList.length > 0) {
        d.bindComponent = compList.map((c) => c.id || '');
      } else {
        d.bindComponent = [];
      }
      return d;
    });
  };
  const dsConfigList = getDsConfigList();
  useEffect(() => {
    form.setFieldsValue({
      dsKey: item?.dsKey,
      isNewCreact: item.props?.createNewDs?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);
  const handleValuesChange = (changedValue: any, allValues: any) => {
    // 取消传入组件标识
    if (
      changedValue &&
      changedValue.hasOwnProperty('isNewCreact') &&
      changedValue.isNewCreact === false
    ) {
      handleTableCancelNewCreateFunc(dsConfig, item);
    }
    if (!allValues.dsKey) {
      const dsConfig: any = tree.dsConfig.find((c) => c.dsKey === item.dsKey);
      dsConfig.queryFields = dsQueryFields || [];
      item!.props!.dataSet.value =
        {
          ...dsConfig,
          queryFields: dsQueryFields,
          dsName: undefined,
          dsKey: undefined,
        } || {};
    }
    // 表格设置查询条件，后面的表格字段相同并且绑定同一实体，查询字段消失问题
    if (dsQueryFields && dsQueryFields.length) {
      // 表格先添加查询字段，在勾选ds后queryFields丢失问题
      tree.dsConfig = cloneDeep(
        tree.dsConfig.map((c) => {
          return c.dsKey === allValues.dsKey
            ? { ...c, queryFields: dsQueryFields || [] }
            : c;
        })
      );
    }
    item.dsKey = allValues.dsKey;
    if (item.props) {
      item.props.createNewDs = {
        value: allValues.isNewCreact,
        type: 'boolean',
        label: '独立Ds',
        hidden: true,
        ignore: true,
      };
    }
    form.setFieldsValue({ allValues });
    forceUpdate?.(item?.id);
  };

  const [currentDs, setCurrentDs] = useState<DsProps>();
  useEffect(() => {
    const ds = tree.dsConfig.find((ds) => ds.dsKey === item?.dsKey);
    setCurrentDs(ds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);
  const handleChangeSelectDs = (dsKey: string) => {
    const ds = tree.dsConfig.find((ds) => ds.dsKey === dsKey);
    if (ds?.bindComponent && !ds?.bindComponent.includes(dsKey)) {
      ds?.bindComponent.push(dsKey);
    }
    setCurrentDs(ds);
  };

  return (
    <div className='htd-ds-box'>
      <AntdForm
        form={form}
        labelAlign='left'
        initialValues={{
          dsKey: item?.dsKey,
        }}
        onValuesChange={handleValuesChange}
      >
        <AntdForm.Item label='选择实体' name='dsKey'>
          <Select
            allowClear={true}
            placeholder='请选择'
            suffixIcon={<SelectRightImg />}
            onChange={handleChangeSelectDs}
          >
            {dsConfigList.map((i) => (
              <Select.Option
                value={i.dsKey}
                key={i.dsKey}
                // disabled={(i.bindComponent || []).length > 0}
              >
                {i.dsName}
              </Select.Option>
            ))}
          </Select>
        </AntdForm.Item>
        {currentDs?.bindComponent && currentDs.bindComponent.length > 0 && (
          <AntdForm.Item
            label='传入组件标识'
            name='isNewCreact'
            valuePropName='checked'
            tooltip='如果勾选，则生成ds时会传入ds绑定组件索引，如多个组件绑定同一DS，生成DS时会分组件创建实例'
          >
            <Switch />
          </AntdForm.Item>
        )}
      </AntdForm>
    </div>
  );
};

export default DataSetPanel;

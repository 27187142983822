import template from '@babel/template';
import generate from '@babel/generator';
import { getParam, transformKvArr2Obj, transformObject2Ast, } from '@htd/common';
/**
 * 点击节点联动查询
 */
export function treeNodeClickWithQuery({ querydsName, otherParam = [], }) {
    const search = transformKvArr2Obj(getParam('search', otherParam, {}));
    const createFunctionBodyAST = template(`{
    const search = PLACEHOLDER_DATASET_SEARCH;
    setQueryParameter(this.getDs('PLACEHOLDER_QUERY_DS_NAME'), search)
    this.getDs('PLACEHOLDER_QUERY_DS_NAME').query();
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_QUERY_DS_NAME: querydsName,
        PLACEHOLDER_DATASET_SEARCH: transformObject2Ast(search),
    });
    return {
        libs: [
            {
                name: ['setQueryParameter'],
                path: '@htd/helper/lib/utils/dataSet',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}

import { ConfigPanelComponentProp, Mapping } from '@htd/common';
import { Collapse } from 'antd';
import { Form as ProForm } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { CaretRightOutlined } from '@ant-design/icons';
import DataSetPanel from './DataSetPanel';
import DataSourcePanel from './DataSourcePanel';
import FormItem from './FormItem';

export const Form: Mapping = {
  name: 'Form',
  type: 'container',
  lib: 'choerodon-ui/pro',
  title: '表单',
  iconCode: 'Form',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '表单',
      label: '标题',
    },
    columns: {
      type: 'number-range',
      min: 1,
      max: 6,
      value: 3,
      label: '列',
    },
    useColon: { type: 'boolean', label: '显示冒号', value: true },
    labelWidth: { type: 'string', label: '标签宽度', value: 100 },
    labelLayout: {
      type: 'collection',
      label: '标签布局',
      value: 'horizontal',
      options: [
        { label: '水平', value: 'horizontal' },
        { label: '垂直', value: 'vertical' },
        { label: '浮动 ', value: 'float' },
      ],
    },
    // conditionHidden: { label: '隐藏', type: 'hidden', value: false, conditionType: 'negative' },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，表单处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      // variableExample: (
      //   <pre>this.getDs('formDs').current?.get('fieldA')!=="CREATE"</pre>
      // ),
      label: '隐藏',
    },
    dataSet: {
      type: 'object',
      label: '数据源', // 如果关联了数据源，则该属性无效
      hidden: true,
      ignore: true,
      value: {
        fields: [],
        queryFields: [],
      },
    },
  },
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    return (
      <Collapse
        defaultActiveKey={1}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel key='1' header='数据属性'>
          <DataSetPanel {...props} />
          <FormItem {...props} />
        </Collapse.Panel>
        <Collapse.Panel key='dataSource' header='自定义数据源' forceRender>
          <DataSourcePanel {...props} />
        </Collapse.Panel>
      </Collapse>
    );
  },
  canDrop(target) {
    return target?.name !== 'Form';
  },
  placeholderComponent: ProForm as any,
  children: [],
};

import { axios } from '../../utils';

/*
 * @Author: wen.chen02@hand-china.com
 * @Description: Hzero util request 包
 */
const request = (url, params = {}) => {
  const { method = 'get', body, query } = params;
  return axios.request({
    url,
    method,
    params: query,
    data: method === 'get' ? body : body,
  });
};

export default request;

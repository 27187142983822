import { Icon } from 'choerodon-ui';
import React, { useContext, useEffect } from 'react';
import { Form as AntdForm, Input, Switch } from 'antd';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from './ConfigContext';
import VariableInput from 'components/VariableInput';

interface Props {
  record?: any;
}

const FieldEditPanel: React.FC<Props> = ({ record }) => {
  const { onSettingConfig } = useContext(ConfigContext);
  const { settingItem, forceUpdate, tree } = useContext(DesignContext);
  const { configItem, title, tableColumns } = useContext(ConfigContext);
  const [fieldForm] = AntdForm.useForm();

  useEffect(() => {
    fieldForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configItem?.index]);

  /**
   * fieldForm表单值变更
   * @param changedValues
   */
  const handleChangeFieldValues = (changedValues: any) => {
    // 标签页只能默认选中一个
    if (title === '标签页' && changedValues.defaultActive) {
      tableColumns.forEach((item) => {
        item.defaultActive = false;
      });
    }

    for (const key in changedValues) {
      const value = changedValues[key];
      if (record) {
        (record as any)[key] = value;
      }
    }
    forceUpdate?.(settingItem?.id);
  };

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    onSettingConfig?.(undefined);
  };

  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header className='htd-aside-right-header'>
        <span>子标签页</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main
        className='file-edit-panel-content-back base-box'
        style={{ marginTop: 8 }}
      >
        <AntdForm
          form={fieldForm}
          labelAlign='left'
          initialValues={record}
          onValuesChange={handleChangeFieldValues}
        >
          <AntdForm.Item label='唯一标识' name='dataIndex'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item label='标题' name='label'>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item label='标题多语言' name='labelMultilingualCode'>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item label='默认选中' name='defaultActive'>
            <Switch checked={record.defaultActive} />
          </AntdForm.Item>
          <AntdForm.Item label='禁用' name='disabled'>
            <VariableInput globalDsConfig={tree.dsConfig}>
              <Switch checked={record.disabled} />
            </VariableInput>
          </AntdForm.Item>
        </AntdForm>
      </main>
    </div>
  );
};

export default FieldEditPanel;

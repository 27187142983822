import { store } from '.';
import { Dispatch } from './store';

export interface Effect {
  (store: { dispatch: Dispatch; state: any }, ...args: any[]): Promise<any>;
}

export const createEffect =
  (effect: Effect) =>
  (...args: any[]) =>
    effect(store, ...args);

import React, { useContext } from 'react';
import { Popconfirm, Button, Form as AntdForm, Input, Switch } from 'antd';
import MyIcon from 'components/myIcon';
import FieldEditPanel from './FieldEditPanel';
import * as uuid from 'uuid';

import './index.less';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from './ConfigContext';
import { ensureChildren, generateCanvasComponentById } from 'utils';
import { dragIcon } from 'utils/unifiedExportIcon';

const ColumnPanel: React.FC = () => {
  const { settingItem: item, forceUpdate, cMap } = useContext(DesignContext);
  const { configItem, onSettingConfig, tableColumns, title } =
    useContext(ConfigContext);

  // 当前数据
  const currentRecord =
    configItem?.index !== undefined && configItem?.type === 'column'
      ? tableColumns[configItem.index]
      : undefined;

  /**
   * 新增字段
   * @param {any} data
   * @param {boolean} fromDsFields 是否切换ds时，从ds新建
   */
  const handleAddLine = (data?: any, fromDsFields?: boolean) => {
    const dataIndex = `${
      title === '标签页' ? 'TabPane' : 'CollapsePanel'
    }_${uuid.v4().slice(0, 5)}`;
    // 默认columns属性
    const createData = {
      dataIndex: dataIndex,
      label: title,
      defaultActive: false,
      disabled: false,
    };
    tableColumns.push(createData);
    const stepComponent = generateCanvasComponentById(
      title === '标签页'
        ? 'htd-container-pro-tabPane'
        : 'htd-container-collapsePane',
      item?.id
    );
    if (stepComponent) {
      // @ts-ignore
      ensureChildren(item);
      item?.children?.splice(item.children.length, 0, stepComponent);
      cMap.set(stepComponent.id, stepComponent);
    }

    forceUpdate?.(item?.id);
  };

  const handleDeleteLine = (record: any, index: number) => {
    // 删除columns中的字段
    tableColumns.splice(index, 1);
    item?.children?.splice(index, 1);
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  /**
   * 修改字段属性
   * @param {string} attrName 字段属性名
   * @param {record} record 字段所有属性
   * @param {any} value 字段属性值
   * @param {number} index 字段在columns中的索引
   */
  const handleChangeValue = (
    attrName: string,
    record: any,
    value: any,
    index?: number
  ) => {
    // 标签页只能默认选中一个
    if (title === '标签页' && value === true) {
      tableColumns.forEach((item) => {
        item.defaultActive = false;
      });
    }
    (tableColumns as any[])[index!][attrName] = value;
    forceUpdate?.(item?.id);
  };

  /**
   * 设置当前编辑字段
   */
  const handleEditItemClick = (_: any, index: number) => {
    onSettingConfig?.(
      configItem?.index === index && configItem?.type === 'column'
        ? undefined
        : { index, type: 'column' }
    );
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: (_, record, index) => {
        return (
          <img
            alt='drag'
            src={dragIcon}
            className='htd-aside-right-columns-table-status-box-img'
          />
        );
      },
    },
    {
      title: '标题',
      dataIndex: 'label',
      width: 94,
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='label'>
            <Input
              onBlur={(e) => {
                handleChangeValue('label', record, e.target.value, index);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '默认展开',
      dataIndex: 'defaultActive',
      width: 94,
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='defaultActive'>
            <Switch
              checked={value}
              onChange={(e) =>
                handleChangeValue('defaultActive', record, e, index)
              }
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      width: 40,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <MyIcon
            type='icon-a-edit2x'
            className='svg-cla'
            onClick={handleEditItemClick.bind(this, record, index)}
          />
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            <MyIcon
              type='icon-a-delete2x'
              style={{ marginRight: '6px' }}
              className='svg-cla'
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className='data-table-h'>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={tableColumns}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        showHeader={Boolean(tableColumns?.length)}
      />
      {currentRecord && <FieldEditPanel record={currentRecord} />}
      <div className='htd-aside-right-columns-button-wrapper jus-conn'>
        <Button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine.bind(this, undefined, false)}
        >
          {`添加${title}`}
        </Button>
      </div>
    </div>
  );
};

export default ColumnPanel;

import React, { useMemo, useState, useCallback } from 'react';
import { Button, Form as AntdForm, Input } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import { ConfigPanelComponentProp } from '@htd/common';
import MyIcon from 'components/myIcon';
import { dragIcon } from 'utils/unifiedExportIcon';
import FieldEditPanel from './FieldEditPanel';

import './index.less';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';

const ColumnPanel: React.FC<ConfigPanelComponentProp> = ({
  // tree,
  item,
  forceUpdate,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>();

  // 表格列
  const tableColumns: any[] = useMemo(
    () => item?.props?.columns?.value,
    [item?.props?.columns?.value]
  );

  const dataSource = useMemo(() => {
    return tableColumns
      .filter((c) => c.dataIndex)
      .map((c) => {
        const data: any = {};
        for (const k in c) {
          if (k === 'dataIndex') data[k] = c[k];
          // else if (Object.keys(DataSetFieldProps).indexOf(k) !== -1)
          else if (k.startsWith('ds_')) data[k] = c[k];
          else data[`col_${k}`] = c[k];
        }
        return data;
      });
  }, [tableColumns]);

  const currentRecord = useMemo(
    () => (currentIndex !== undefined ? dataSource[currentIndex] : {}),
    [currentIndex, dataSource]
  );

  const update = useCallback(() => {
    if (item?.props?.columns?.value?.length)
      item.props.columns.value = cloneDeep(tableColumns);
    forceUpdate?.(item?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate, item?.id, item.props?.columns?.value, tableColumns]);

  /**
   * 设置当前编辑字段
   */
  const handleEditItemClick = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  /**
   * 拖动排序
   */
  const handleDragEnd = useCallback(
    (dataSource: any[]) => {
      tableColumns.sort((a: any, b: any) => {
        const aIndex = dataSource.findIndex(
          (record) => record.dataIndex === a.dataIndex
        );
        const bIndex = dataSource.findIndex(
          (record) => record.dataIndex === b.dataIndex
        );
        return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
      });
      setCurrentIndex(undefined);
      update();
    },
    [tableColumns, update]
  );

  /**
   * 删除字段
   */
  const handleDeleteLine = useCallback(
    (record, index) => {
      tableColumns.splice(index, 1);
      update();
    },
    [tableColumns, update]
  );

  /**
   * 新增字段
   */
  const handleAddLine = useCallback(() => {
    const dataIndex = `field${tableColumns.length + 1}`;
    const title = `字段${tableColumns.length + 1}`;
    const createData = {
      key: dataIndex,
      dataIndex,
      resizable: true,
      title,
      fixed: 'null',
      align: 'null',
    };
    // 判断是否有操作列
    const haveOperator =
      tableColumns.length &&
      !tableColumns[tableColumns.length - 1].name &&
      tableColumns[tableColumns.length - 1].header === '操作';
    haveOperator
      ? tableColumns.splice(tableColumns.length - 1, 0, createData)
      : tableColumns.push(createData);

    update();
  }, [tableColumns, update]);

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index?) => {
      let [type, name, dsProps] = fieldName.split('_');
      if (type === 'dataIndex' || type === 'col')
        tableColumns[index][name || fieldName] = value;
      if (name === 'render') {
        tableColumns[index]['renderer'] = record.col_renderer;
      }
      if (type === 'ds') {
        if (!tableColumns[index][`${type}_${name}`])
          tableColumns[index][`${type}_${name}`] = {};
        if (!dsProps) {
          dsProps = 'isEnable';
          value = value.isEnable;
        }
        tableColumns[index][`${type}_${name}`][dsProps] = value;
      }
      update();
    },
    [tableColumns, update]
  );

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: (_, record, index) => {
        return (
          <img
            alt='drag'
            src={dragIcon}
            className='htd-aside-right-columns-table-status-box-img'
          />
        );
      },
    },
    {
      title: '标题',
      dataIndex: 'col_title',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='col_title'>
            <Input
              onBlur={(e) => {
                handleChangeValue('col_title', record, e.target.value, index);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '字段名',
      dataIndex: 'dataIndex',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='dataIndex'>
            <Input
              onBlur={(e) => {
                // 判断是否为空
                if (e.target.value) {
                  // 校验字段是否驼峰命名
                  if (
                    !new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/).test(e.target.value)
                  ) {
                    record.$form.setFields([
                      { name: 'dataIndex', errors: ['请以驼峰命名'] },
                    ]);
                    return;
                  }
                  // 筛选是否存在重复字段
                  const list = dataSource.filter(
                    (d) => d.name === e.target.value && d.name !== value
                  );
                  if (list.length)
                    record.$form.setFields([
                      { name: 'dataIndex', errors: ['字段名不能重复'] },
                    ]);
                  else {
                    record.$form.setFields([{ name: 'dataIndex', errors: [] }]);
                    handleChangeValue(
                      'dataIndex',
                      record,
                      e.target.value,
                      index
                    );
                  }
                } else {
                  record.$form.setFields([
                    { name: 'dataIndex', errors: ['字段名不能为空'] },
                  ]);
                }
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '操作',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <MyIcon
            type='icon-a-edit2x'
            className='left-svg-cla'
            onClick={handleEditItemClick.bind(this, index)}
          />
          <MyIcon
            type='icon-a-delete2x'
            style={{ marginRight: '6px' }}
            className='left-svg-cla'
            onClick={handleDeleteLine.bind(this, record, index)}
          />
        </>
      ),
    },
  ];

  return (
    <section className='htd-aside-right-main-section'>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        onDragEnd={handleDragEnd}
        showHeader={Boolean(dataSource?.length)}
      />
      <div className='htd-aside-right-columns-button-wrapper'>
        <Button
          // icon={<PlusOutlined />}
          // size='middle'
          type='text'
          onClick={handleAddLine.bind(this)}
        >
          添加字段
        </Button>
      </div>
      {currentIndex !== undefined && (
        <FieldEditPanel
          item={item}
          dataSource={dataSource}
          record={currentRecord}
          index={currentIndex}
          setIndex={setCurrentIndex}
          onChange={handleChangeValue}
        />
      )}
    </section>
  );
};

export default ColumnPanel;

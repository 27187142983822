import React, { FC, useMemo, useEffect, useContext } from 'react';
import {
  Form as AntdForm,
  // InputNumber,
  DatePicker,
  Checkbox,
  Popover,
  Switch,
} from 'antd';
import { InputNumber } from 'choerodon-ui';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ConfigPanelComponentProp } from '@htd/common';
import ValidateConfigPanel from './ValidateConfigPanel';
import { useCallback } from 'react';
import { cloneDeep, debounce, isArray } from 'lodash';
import { DesignContext } from 'views/Home/DesignContext';
import { updateDsFieldsToModel } from '@/mapping/data/Table/utils';
// import { ConfigContext } from '../../mapping/data/Table/ConfigPanel/ConfigContext';
import {
  handleFormCuDataFunc,
  handleChnageFormPropsFunc,
} from '../../views/Home/AsideRight/utils';

type ValidateAttributeItem = {
  fieldName: string;
  label: string;
  defaultInput?: React.ReactNode;
  messageType?: string;
  messagePlaceholder?: string;
  needVariable?: boolean;
  needConfig?: boolean;
  hidden?: boolean;
};

const ValidateAttribute: FC<ConfigPanelComponentProp> = ({
  // record,
  // onChange,
  tree,
  item,
}) => {
  const [form] = AntdForm.useForm();
  const { forceUpdate, backendConfig, cMap } = useContext(DesignContext);
  // const { dsConfig } = useContext(ConfigContext);
  const { dsConfig } = tree;
  const type: any = item.fieldType;

  // 处理applicationFor数据（如果设置了applicationFor，从里面取解析数据）
  let itemCopy: any = cloneDeep(item);
  const parentComponent: any = cMap.get(item?.parentId);
  const isNewDsFlag = parentComponent?.props?.createNewDs?.value || false;
  if (
    parentComponent?.name === 'Form' &&
    dsConfig &&
    dsConfig.length &&
    isNewDsFlag
  ) {
    const dsKey = parentComponent?.dsKey;
    const cuConfig = dsConfig.find((df: any) => dsKey && dsKey === df.dsKey);
    const itemFieldKey = item?.props?.fieldKey?.value || itemCopy?.itemCopy;
    const currentField: any = cuConfig?.fields?.find(
      (cff: any) =>
        itemFieldKey &&
        (cff.fieldKey === itemFieldKey ||
          cff?.props?.fieldKey?.value === itemFieldKey)
    );
    // 从ds中获取配置数据
    if (currentField) {
      const data = handleFormCuDataFunc(
        parentComponent,
        currentField,
        ['min', 'max', 'minLength', 'maxLength', 'required', 'pattern'],
        'validate'
      );
      if (data) {
        const validate = { ...data.props?.validate, ...data.validate };
        itemCopy.validate = validate;
        // 取消【传入组件标识】之后 用于 表格 表单显示
        ['min', 'max', 'minLength', 'maxLength', 'required', 'pattern'].forEach(
          (attributeKey: any) => {
            itemCopy[attributeKey] = data[attributeKey];
          }
        );
      }
    }
  }
  const update = useCallback(() => {
    item.validate = cloneDeep(item.validate);
    forceUpdate?.(item?.id);
  }, [forceUpdate, item]);

  const getComponent = useCallback(
    (value: any) => {
      let realValue: any = moment.isMoment(value) ? value : moment(value);
      switch (type) {
        case 'number':
          return <InputNumber value={value} />;
        case 'date':
          return <DatePicker value={realValue} allowClear />;
        case 'dateTime':
          return <DatePicker value={realValue} showTime allowClear />;
        case 'time':
          return <DatePicker value={value} picker='time' allowClear />;
        default:
          return null;
      }
    },
    [type]
  );

  useEffect(() => {
    form.resetFields();
  }, [form, item.id]);

  const validateAttributeList: ValidateAttributeItem[] = useMemo(() => {
    return [
      {
        fieldName: 'required',
        label: '必填',
        defaultInput: <Switch checked={itemCopy.validate.required?.value} />,
        messageType: 'valueMissing',
        messagePlaceholder: '请输入{label}。',
        needVariable: true,
        needConfig: true,
      },
      {
        fieldName: 'min',
        label: '最小值',
        defaultInput: getComponent(itemCopy.validate.min?.value),
        messageType: 'rangeUnderflow',
        messagePlaceholder: '{label}必须大于或等于{min}。',
        needVariable: true,
        needConfig: true,
        hidden: !itemCopy.showValidate?.includes('min'),
      },
      {
        fieldName: 'max',
        label: '最大值',
        defaultInput: getComponent(itemCopy.validate.max?.value),
        messageType: 'rangeOverflow',
        messagePlaceholder: '{label}必须小于或等于{max}。',
        needVariable: true,
        needConfig: true,
        hidden: !itemCopy.showValidate?.includes('max'),
      },
      {
        fieldName: 'minLength',
        label: '最小长度',
        defaultInput: (
          <InputNumber
            step='1'
            min={0}
            max={itemCopy.validate.maxLength?.value}
            precision={0}
          />
        ),
        messageType: 'tooShort',
        messagePlaceholder:
          '请将该内容增加到{minLength}个或更多字符（目前您使用了{length}个字符）。',
        needConfig: true,
        hidden: !itemCopy.showValidate?.includes('minLength'),
      },
      {
        fieldName: 'maxLength',
        label: '最大长度',
        defaultInput: (
          <InputNumber
            step='1'
            min={itemCopy.validate.minLength?.value}
            precision={0}
          />
        ),
        messageType: 'tooLong',
        messagePlaceholder:
          '请将该内容减少到{maxLength}个或更少字符（目前您使用了{length}个字符）。',
        needConfig: true,
        hidden: !itemCopy.showValidate?.includes('maxLength'),
      },
      {
        fieldName: 'pattern',
        label: '正则表达式',
        messageType: 'patternMismatch',
        messagePlaceholder: '请输入有效的值。',
        needConfig: true,
      },
      {
        fieldName: 'validator',
        label: '自定义校验',
        // needConfig: true,
      },
    ];
  }, [
    itemCopy.validate.required?.value,
    itemCopy.validate.min?.value,
    itemCopy.validate.max?.value,
    itemCopy.validate.maxLength?.value,
    itemCopy.validate.minLength?.value,
    itemCopy.showValidate,
    getComponent,
  ]);

  const getExtraContent = (validateItem: ValidateAttributeItem) => {
    return validateItem.needConfig ? (
      <Popover
        content={
          <ValidateConfigPanel
            tree={tree}
            record={itemCopy.validate}
            type={validateItem.fieldName}
            label={validateItem.label}
            defaultInput={validateItem.defaultInput}
            messageType={validateItem.messageType}
            messagePlaceholder={validateItem.messagePlaceholder}
            handleChangeValue={handleChangeValue}
            needVariable={validateItem.needVariable}
          />
        }
        trigger='click'
        placement='left'
        destroyTooltipOnHide
      >
        {getTextContext(validateItem)}
        <EditOutlined />
      </Popover>
    ) : null;
  };

  const getTextContext = (validateItem: ValidateAttributeItem) => {
    const numberList = ['max', 'min', 'maxLength', 'minLength'];
    const patternList = ['pattern'];
    if (![...numberList, ...patternList].includes(validateItem.fieldName)) {
      return null;
    }
    const getObjectValue = (data: any) => {
      if (data && (data as any).__isVariable) {
        return (data as any)?.value;
      }
      if (itemCopy?.name === 'DatePicker' && data) {
        return moment(data).format('YYYY-MM-DD');
      }
      return data;
    };
    const getPatternObjectValue = (data: any) => {
      if (data && (data as any).name) {
        return (data as any)?.name;
      } else if (data && (data as any).value) {
        return (data as any)?.value;
      }
      return '';
    };
    return (
      <span className='antd-form-number'>
        {patternList.includes(validateItem.fieldName)
          ? getPatternObjectValue(itemCopy.validate[validateItem.fieldName]) // 正则表达式显示名称
          : getObjectValue(itemCopy.validate[validateItem.fieldName]?.value)}
      </span>
    );
  };

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index?) => {
      // 设置applicationFor
      // 拿到ds中对应数据
      const parentComponent = cMap.get(item?.parentId);
      if (
        parentComponent?.name === 'Form' &&
        dsConfig &&
        isArray(dsConfig) &&
        dsConfig.length
      ) {
        const dsKey = parentComponent?.dsKey;
        const cuDsConfig = dsConfig.find(
          (df: any) => dsKey && df.dsKey === dsKey
        );
        const cuField: any = cuDsConfig?.fields?.find(
          (cff: any) =>
            item?.props?.fieldKey?.value &&
            item?.props?.fieldKey?.value === cff.props?.fieldKey?.value
        );
        if (cuField) {
          // 改变数据props
          handleChnageFormPropsFunc(parentComponent, cuField, fieldName, value);
        }
        console.log('cuField', cuField);
      }
      item.validate[fieldName] = value;
      form.setFieldsValue({
        ...item.validate,
      });
      updateDsFieldsToModel(
        backendConfig,
        dsConfig,
        {},
        item,
        '',
        'form',
        'validate',
        cMap.get(item?.parentId)
      );
      update();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, item.validate, update]
  );

  /**
   * 勾选变化
   */
  const handleValuesChange = debounce((changedValues: any) => {
    for (const key in changedValues) {
      handleChangeValue(
        key,
        item.validate,
        typeof changedValues[key] === 'object'
          ? { ...item.validate[key], ...changedValues[key] }
          : changedValues[key]
      );
    }
  });

  /**
   * 获取校验字段名
   * @param validateItem
   * @returns
   */
  const getFieldName = (validateItem: ValidateAttributeItem) => {
    if (validateItem.fieldName === 'required') {
      return [validateItem.fieldName, 'value'];
    }
    return [validateItem.fieldName, '__isEnable'];
  };

  return (
    <>
      <section className='htd-aside-right-main-section htd-aside-right-main-validator'>
        <AntdForm
          form={form}
          initialValues={itemCopy.validate}
          labelAlign='left'
          onValuesChange={handleValuesChange}
        >
          {validateAttributeList
            .filter((validateItem) => !validateItem.hidden)
            .map((validateItem) => (
              <AntdForm.Item
                key={validateItem.fieldName}
                label={validateItem.label}
                name={getFieldName(validateItem)}
                valuePropName='checked'
                extra={getExtraContent(validateItem)}
              >
                <Checkbox />
              </AntdForm.Item>
            ))}
        </AntdForm>
      </section>
    </>
  );
};

export default ValidateAttribute;

import template from '@babel/template';
import generate from '@babel/generator';
/**
 * 数据源字段求和并赋值
 */
export function sumFields({ eventSourceName, eventTargetName, eventTargetDataSet, }) {
    const createFunctionBodyAST = template(`{
    if(params.name==='PLACEHOLDER_EVENT_SOUREC_NAME'){
        let sum = 0;
        params.dataSet.records.forEach(r=>sum+=r.get(params.name))
        
        if(!this.getDs('PLACEHOLDER_EVENT_TARGET_DATASET').current){
          this.getDs('PLACEHOLDER_EVENT_TARGET_DATASET').create();
        }
        this.getDs('PLACEHOLDER_EVENT_TARGET_DATASET').current.set('PLACEHOLDER_EVENT_TARGET_NAME',sum)
    }
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_EVENT_SOUREC_NAME: eventSourceName,
        PLACEHOLDER_EVENT_TARGET_DATASET: eventTargetDataSet,
        PLACEHOLDER_EVENT_TARGET_NAME: eventTargetName,
    });
    return {
        libs: [],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
/**
 * 使用自定义代码段实现dataSet 字段联动
 */
export function dataSetUpdateEventByCostomFormula({ eventSourceName, formula, }) {
    const createFunctionBodyAST = template(`{
    if(params.name==='PLACEHOLDER_EVENT_SOUREC_NAME'){
        PLACEHOLDER_FORMULA
    }
  }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_EVENT_SOUREC_NAME: eventSourceName,
        PLACEHOLDER_FORMULA: typeof formula === 'string' ? formula : formula.value,
    });
    return {
        libs: [],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}

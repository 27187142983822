export const DRAG_TYPE_DEFAULT = 'htd-drag-box';

export const EMPTY_ARRAY: any[] = [];

export const EMPTY_OBJECT: any = {};

export const PLACEHOLDER_ID = 'DRAG_PLACEHOLDER';

export const HTD_PROPS_HOLDER = 'HTD_PROPS_HOLDER';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

export const API_HOST_KEY = 'HZERO_API_HOST';

export const ORGANIZATION_ID_KEY = 'ORGANIZATION_ID';

export const DATA_HCG_FLAG: string = 'data-hcg_flag';

export const BLOCK_STORAGE_KEY = 'block_storage';

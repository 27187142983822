import { FC, cloneElement, useContext, useMemo } from 'react';
import { Tabs as ProTabs, Icon } from 'choerodon-ui/pro';
import { useState } from 'react';
import { DesignContext } from 'views/Home/DesignContext';
import { emptyArray } from 'utils';
import cls from 'classnames';

import './index.less';

const renderStatusIcon = (status: string) => {
  switch (status) {
    case 'error':
      return 'close';
    case 'finish':
      return 'check';
    default:
      return 'check';
  }
};

const Steps: FC = (props: any) => {
  const { settingItem } = useContext(DesignContext);
  const TabPaneList = props.children?.props?.children || emptyArray;
  const [activeKey, SetActiveKey] = useState('0');
  const onChange = (item: any) => {
    SetActiveKey(item);
  };

  useMemo(() => {
    TabPaneList.forEach((item: any, index: any) => {
      if (item.key === settingItem?.id) {
        SetActiveKey(index.toString());
      }
    });
  }, [TabPaneList, settingItem?.id]);
  const stepsRecords = props?.columns || [];
  const isHidden = props.progressDot ? 'step-hidden' : ''; // 点状布局
  return (
    <div
      className={cls(
        'htd-placeholder-steps',
        isHidden && 'htd-placeholder-spot'
      )}
    >
      {cloneElement(
        props.children,
        {},
        TabPaneList.length && [
          <ProTabs
            key='steps'
            activeKey={activeKey}
            onChange={onChange} // current属性通过代码生成
          >
            {TabPaneList.map((tabpane: any, index: number) => {
              return (
                <ProTabs.TabPane
                  disabled={stepsRecords[index]?.disabled} // 配置的禁用属性
                  tab={
                    <div
                      className={
                        !stepsRecords[index]?.icon &&
                        ['error', 'finish'].includes(
                          stepsRecords[index]?.status
                        )
                          ? `c7n-steps-item c7n-steps-item-${stepsRecords[index]?.status}`
                          : cls(
                              'c7n-steps-item',
                              +activeKey > index &&
                                !stepsRecords[index]?.icon &&
                                'c7n-steps-item-finish',
                              +activeKey === index &&
                                !stepsRecords[index]?.icon &&
                                'c7n-steps-item-process',
                              +activeKey < index &&
                                !stepsRecords[index]?.icon &&
                                'c7n-steps-item-wait'
                            )
                      }
                    >
                      <div className={`c7n-steps-item-icon ${isHidden}`}>
                        {stepsRecords[index]?.icon ? (
                          <Icon type={stepsRecords[index]?.icon} /> // 配置的图标
                        ) : (
                          <span
                            className={
                              !stepsRecords[index]?.icon &&
                              ['error', 'finish'].includes(
                                stepsRecords[index]?.status
                              )
                                ? `icon icon-${renderStatusIcon(
                                    stepsRecords[index]?.status
                                  )}`
                                : cls(
                                    +activeKey > index && 'icon icon-check',
                                    +activeKey <= index && 'c7n-steps-icon'
                                  )
                            }
                          >
                            {+activeKey > index ||
                            ['error', 'finish'].includes(
                              stepsRecords[index]?.status
                            )
                              ? ''
                              : index + 1}
                          </span>
                        )}
                      </div>
                      <div
                        className={cls(
                          'c7n-steps-item-content',
                          isHidden && 'c7n-steps-item-spot'
                        )}
                      >
                        {
                          isHidden && (
                            <div
                              className={cls(
                                'c7n-steps-item-dot',
                                stepsRecords[index]?.status === 'error' &&
                                  'c7n-error-dot',
                                (['finish', 'process'].includes(
                                  stepsRecords[index]?.status
                                ) ||
                                  +activeKey >= index) &&
                                  'c7n-finish-dot',
                                +activeKey === index && 'c7n-active-dot'
                              )}
                            />
                          ) // 点
                        }
                        {index !== TabPaneList.length - 1 && isHidden && (
                          <div className='c7n-steps-item-line' /> // 线条
                        )}
                        <div className='c7n-steps-item-title font-size13'>
                          {
                            stepsRecords[index]?.title // 配置的步骤条选项标题
                          }
                          {index !== TabPaneList.length - 1 && !isHidden && (
                            <div className='c7n-steps-item-line' /> // 线条
                          )}
                        </div>
                        <div className='c7n-steps-item-description font-size13'>
                          {
                            stepsRecords[index]?.description // 配置的步骤条选项描述
                          }
                        </div>
                      </div>
                    </div>
                  }
                  key={index}
                >
                  {tabpane}
                </ProTabs.TabPane>
              );
            })}
          </ProTabs>,
        ]
      )}
    </div>
  );
};

export { Steps };

import { useStore } from '@/store';
// import { addLocalCategoryEffect } from '@/store/reducers/block';
import { addInlineCategory } from '@/services/block-service';
import { Form, Input, Modal, message } from 'antd';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';

export interface AddLocalCategoryModalProps {
  visible?: boolean;
  onClose?: Function;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const AddLocalCategoryModalContent: FC<{
  onChange?: (v: string) => any;
  value?: string;
  defaultValue?: string;
  noLayout?: boolean;
}> = ({ onChange, value, defaultValue, noLayout }) => {
  const { state } = useStore((state) => state.block);
  const [form] = Form.useForm();
  const handleInputChange = (_: any, all: any) => {
    onChange?.(all.name);
  };
  useEffect(() => {
    form.setFieldsValue({ name: defaultValue });
  }, [form, defaultValue]);

  // useEffect(() => {
  //   form.setFieldsValue({ name: value });
  // }, [form, value]);

  return (
    <Form
      {...(noLayout ? {} : formLayout)}
      form={form}
      onValuesChange={handleInputChange}
    >
      <Form.Item
        label='分类名称'
        name='name'
        rules={[
          {
            validator(_, value) {
              return validateCategory(value, [
                // ...state.localCategoryList,
                ...state?.inlineCategoryList?.map(
                  (ele: any) => ele?.categoryName
                ),
              ]);
            },
          },
        ]}
      >
        <Input value={defaultValue} />
      </Form.Item>
    </Form>
  );
};

const AddLocalCategoryModal: FC<AddLocalCategoryModalProps> = ({
  visible,
  onClose,
}) => {
  const [cateFormValue, setCateFormValue] = useState('');
  const { state } = useStore((state) => state.block);
  const handleAddCategory = async () => {
    // addLocalCategoryEffect(cateFormValue);
    await addInlineCategory({
      categoryCode: cateFormValue,
      categoryName: cateFormValue,
    });
    // window.$htd.emit('block/category');
    message.success('添加分类成功');
    onClose?.();
  };
  return (
    <Modal
      visible={visible}
      onOk={handleAddCategory}
      onCancel={() => onClose?.()}
      title='添加分类'
      width={400}
      okButtonProps={{
        disabled:
          !cateFormValue ||
          [
            // ...state.localCategoryList,
            ...state?.inlineCategoryList?.map((ele: any) => ele?.categoryName),
          ].includes(cateFormValue),
      }}
    >
      <AddLocalCategoryModalContent noLayout onChange={setCateFormValue} />
    </Modal>
  );
};

export default AddLocalCategoryModal;

function validateCategory(value: string, currentList: string[]) {
  if (!value) {
    return Promise.reject(new Error('分类名称必填'));
  } else if (currentList.includes(value)) {
    return Promise.reject(new Error('当前分类已存在'));
  }
  return Promise.resolve();
}

import { Mapping } from '@htd/common';
import { Row as ProRow, Col as ProCol } from 'components/placeholders';
import { isC7nPro } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Row: Mapping = {
  name: 'Row',
  iconCode: 'Row',
  title: '行容器',
  type: 'container',
  lib: 'choerodon-ui/pro',
  props: { ...BASE_PROPS_TYPES },
  placeholderComponent: ProRow as any,
  canDrop(target) {
    return target?.name !== 'Row';
  },
  onDrop(item) {
    return item?.name === 'Col';
  },
  children: [],
};

export const Col: Mapping = {
  name: 'Col',
  iconCode: 'Col',
  title: '列容器',
  type: 'inline-container',
  lib: 'choerodon-ui/pro',
  props: {
    ...BASE_PROPS_TYPES,
    span: {
      type: 'number-range',
      min: 1,
      max: 24,
      value: 12,
      label: '跨度',
    },
    textLayout: {
      label: '内容布局',
      type: 'collection',
      options: [
        { value: 'left', label: '居左' },
        { value: 'center', label: '居中' },
        { value: 'right', label: '居右' },
      ],
      value: 'right',
    },
  },
  placeholderComponent: ProCol as any,
  canDrop(target) {
    return target?.name === 'Row' && isC7nPro(target);
  },
  children: [],
};

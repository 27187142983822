import { isArray } from 'lodash';

export function getFormatResponseData(data: any) {
  const { content } = data;
  let dataObj: any = {};
  if (isArray(content)) {
    dataObj = content[0] || {};
  } else {
    dataObj = data || {};
  }
  return Object.entries(dataObj).map(([key, value]) => ({
    // type: value === undefined || value === null ? 'string' : typeof value,
    name: key,
    label: key,
  }));
}

import React, { FC, useContext } from 'react';
// @ts-ignore
import ListTree from '@/components/ListTree';
import { DesignContext } from '../DesignContext';
import DataSetList from './DataSetList';
import CompList from './CompList';
// import { Icon } from 'choerodon-ui';
import { Tooltip } from 'antd';
import { MenuType } from '@htd/common';
import './index.less';
import {
  questionCircleIcon,
  titleArrowIcon,
  iconDsSelIcon,
  iconDsIcon,
  pubSelIcon,
  pubIcon,
  treeSelIcon,
  treeIcon,
} from '../../../utils/unifiedExportIcon';
import BackendDesign from './BackendDesign';

const AsideLeft: FC = () => {
  const { currentMenu: type, onChangeMenu: setType } =
    useContext(DesignContext);
  // <ListTree tree={tree} onSetting={onSetting} onTreeSelect={onTreeSelect} />

  const handleChange = function (type: MenuType) {
    setType?.(type);
  };
  const renderAsideContent = () => {
    let title;
    let content;
    switch (type) {
      case 'tree':
        title = '大纲树';
        content = <ListTree />;
        break;
      case 'components':
        title = '资源';
        content = <CompList />;
        break;
      case 'dataSet':
        title = '数据源';
        content = <DataSetList />;
        break;
      case 'backend':
        title = '业务模型';
        content = <BackendDesign />;
        break;
      default:
        return <></>;
    }
    return (
      <>
        <header className='htd-aside-left-header'>
          <div>
            <img
              alt='隐藏'
              src={titleArrowIcon}
              className='htd-aside-left-header-backImg'
              onClick={setType?.bind(this, undefined)}
            />
            <span>{title}</span>
          </div>
          <Tooltip title='对应技术端为数据模型'>
            <img
              alt='疑问'
              src={questionCircleIcon}
              style={{ width: '15px', height: '15px' }}
            />
          </Tooltip>
          {/* <Icon type='close' onClick={setType?.bind(this, undefined)} /> */}
        </header>
        <main className='htd-aside-left-main'>{content}</main>
      </>
    );
  };

  return (
    <>
      <div className='htd-aside-left-memu'>
        <div
          className={type === 'tree' ? 'menu-selected' : ''}
          onClick={() => handleChange('tree')}
        >
          <img alt='大纲' src={type === 'tree' ? treeSelIcon : treeIcon} />
        </div>
        <div
          className={type === 'components' ? 'menu-selected' : ''}
          onClick={() => handleChange('components')}
        >
          <img alt='资源' src={type === 'components' ? pubSelIcon : pubIcon} />
        </div>
        <div
          style={{ display: 'none' }}
          className={type === 'dataSet' ? 'menu-selected' : ''}
          onClick={() => handleChange('dataSet')}
        >
          <img
            alt='数据源'
            src={type === 'dataSet' ? iconDsSelIcon : iconDsIcon}
          />
        </div>
        <div
          className={type === 'backend' ? 'menu-selected' : ''}
          onClick={() => handleChange('backend')}
        >
          <img
            alt='业务模型'
            src={type === 'backend' ? iconDsSelIcon : iconDsIcon}
          />
        </div>
      </div>
      {type && <aside className='htd-aside-left'>{renderAsideContent()}</aside>}
    </>
  );
};

export default AsideLeft;

import { FormField } from 'components/placeholders';
import { Mapping } from '@htd/common';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
// import { HTD_PROPS_HOLDER } from 'utils/constants';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';

export const IntlField: Mapping = {
  name: 'IntlField',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  title: '多语言输入框',
  fieldType: 'intl',
  iconCode: 'IntlField',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    // name: {
    //   label: '数据字段',
    //   type: 'string',
    //   value: `IntlField${HTD_PROPS_HOLDER}`,
    // },
    ...getInputCommon('intlField', '多语言输入'),
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  placeholderComponent: FormField,
  children: [],
  validate: {},
  showValidate: ['minLength', 'maxLength'],
};

import moment from 'moment';
import * as uuid from 'uuid';
import qs from 'query-string';
import { ACCESS_TOKEN_KEY } from './constants';
import { getStorage, setStorage } from './storage';
import { ObjectType } from '@htd/common';

export function getAccessToken() {
  return getStorage(ACCESS_TOKEN_KEY);
}
export function setAccessToken(token: string) {
  return setStorage(ACCESS_TOKEN_KEY, token);
}

// 获取 当前浏览器地址中的 参数数据
function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

// TODO: 获取ID
export function getCurrentOrganizationId() {
  const searchOrganizationId = getQueryVariable('tenantId');
  return getUserData()?.organizationId || searchOrganizationId || '0';
}

export function isTenantRoleLevel() {
  return true;
}

export function setUserData(data: any) {
  (window as any).__htd_user_data = data;
}

export function getUserData() {
  return (window as any).__htd_user_data;
}

export function getRequestId() {
  const time = moment().format('x').split('').reverse().join('');
  const time1 = time.substring(0, 3);
  const time2 = time.substring(3, 7);
  const time3 = time.substring(7);
  const id = uuid.v4().replace(/-/g, '');
  const uuid1 = id.substring(0, 12);
  const uuid2 = id.substring(12);
  return time1 + uuid1 + time2 + uuid2 + time3;
}

export const emptyArray: any = [];

export const emptyObject: any = [];

export const getQueryValueByKey = <T = any>(key: string): T => {
  return qs.parse(window.location.search.slice(1))[key] as any;
};

export const openTab = ({
  url,
  query,
  target,
}: {
  url: string;
  query?: ObjectType;
  target?: string;
}) => {
  const queryStr = qs.stringify({
    ...qs.parse((url.split('?')[1] || '').slice(1)),
    ...(query || {}),
  });
  const finalUrl = `${url}?${queryStr}`;
  window.open(finalUrl, target);
};

import template from '@babel/template';
import generate from '@babel/generator';
export function enableRender() {
    const createFunctionBodyAST = template(`{
      return enableRender(params.value);
    }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['enableRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function enableRenderPerformance() {
    const createFunctionBodyAST = template(`{
      return enableRender(params.rowData[params.dataIndex]);
    }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['enableRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function yesOrNoRender() {
    const createFunctionBodyAST = template(`{
        return yesOrNoRender(params.value);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['yesOrNoRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function yesOrNoRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return yesOrNoRender(params.rowData[params.dataIndex]);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['yesOrNoRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function priorityRender() {
    const createFunctionBodyAST = template(`{
        return priorityRender(params.value);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['priorityRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function priorityRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return priorityRender(params.rowData[params.dataIndex]);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['priorityRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function dateRender() {
    const createFunctionBodyAST = template(`{
        return dateRender(params.value);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['dateRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function dateRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return dateRender(params.rowData[params.dataIndex]);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['dateRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function timeRender() {
    const createFunctionBodyAST = template(`{
        return timeRender(params.value);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['timeRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function timeRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return timeRender(params.rowData[params.dataIndex]);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['timeRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function dateTimeRender() {
    const createFunctionBodyAST = template(`{
        return dateTimeRender(params.value);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['dateTimeRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function dateTimeRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return dateTimeRender(params.rowData[params.dataIndex]);
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [
            {
                name: ['dateTimeRender'],
                path: 'utils/renderer',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function customRender({ functionName }) {
    const createFunctionBodyAST = template(`{
        return PLACEHOLDER_FUNCTION_NAME.bind(this)(params)
      }`);
    const functionBody = createFunctionBodyAST({
        PLACEHOLDER_FUNCTION_NAME: functionName,
    });
    return {
        libs: [
            {
                name: [functionName],
                path: './pageLogic',
            },
        ],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function rowIndexRender() {
    const createFunctionBodyAST = template(`{
        return params.record.index+1;
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}
export function rowIndexRenderPerformance() {
    const createFunctionBodyAST = template(`{
        return params.rowIndex+1;
      }`);
    const functionBody = createFunctionBodyAST();
    return {
        libs: [],
        async: false,
        functionParameter: ['params'],
        functionBody,
        functionBodyStr: generate(functionBody).code,
    };
}

import { ObjectType } from '@htd/common';

export function getSession(key: string) {
  const value = sessionStorage.getItem(key);
  if (value && value !== 'undefined') {
    return JSON.parse(value);
  }
}

export function setSession(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getStorage(key: string) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
}

export function setStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export class Storage {
  private storageKey = '__storage__';
  constructor(storageKey?: string) {
    this.storageKey = storageKey || this.storageKey;
  }
  private ensureStorageValue(value: any) {
    let finalValue: any = {};
    if (value) {
      try {
        const parsed = JSON.parse(value);
        finalValue = typeof parsed === 'object' ? parsed : finalValue;
      } catch (error) {}
    }
    return finalValue;
  }
  public read<T = any>(key?: string): T {
    const storageValue = this.ensureStorageValue(
      localStorage.getItem(this.storageKey)
    );
    return key ? storageValue[key] : storageValue;
  }
  public write(key: string, value: any): void {
    const storageValue = this.ensureStorageValue(
      localStorage.getItem(this.storageKey)
    );
    storageValue[key] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(storageValue));
  }
}

/**
 * 获取存储工具, 单例模式
 * 使用方法：
 * const helper = getStorageHelper('some/storageKey');
 * helper.read('some/key');
 * helper.write('some/key', someValue)
 */
export const getStorageHelper = (() => {
  const helperMap: ObjectType<Storage> = {};
  return (key: string) => {
    let helper = helperMap[key];
    if (!helper) {
      helper = new Storage();
    }
    return helper;
  };
})();

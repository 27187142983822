import { API_HOST, CLIENT_ID } from '../utils/config';
import { axios } from '../utils';

export function login() {
  window.location.href = `${API_HOST}/oauth/oauth/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${window.location.href}`;
}

export function fetchSelf() {
  const url = `/iam/hzero/v1/users/self`;
  return axios.get(url).then((res) => {
    return res;
  });
}

export function lovView(params: any) {
  const url = `/hpfm/v1/lov-view-headers`;
  return axios.get(url, params).then((res) => {
    return res;
  });
}

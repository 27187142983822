import { FC, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import cls from 'classnames';
import { ScriptManager } from 'utils/ScriptManager';
import './index.less';

export interface PreviewContainerProps {
  content?: string;
  visible?: boolean;
  className?: string;
  onClose?: Function;
}

const PreviewContainer: FC<PreviewContainerProps> = ({
  visible,
  content,
  className,
  onClose,
}) => {
  const container = useMemo(() => {
    const div = document.createElement('div');
    // div.className = cls('htd-page-preview-container');
    document.body.appendChild(div);
    return div;
  }, []);
  const scriptManager = useMemo(() => new ScriptManager(), []);
  useEffect(() => {
    if (visible && content) {
      scriptManager.updateContent(content);
    }
  }, [visible, content, scriptManager]);
  // const handleClose = () => {
  //   onClose?.();
  // };
  return ReactDOM.createPortal(
    <div
      className={cls('htd-page-preview-container', className)}
      style={{ display: visible ? '' : 'none' }}
    >
      {/* <header className='htd-page-preview-container-header'>
        <h2 className='htd-page-preview-container-header-title'>页面预览</h2>
        <span
          className='htd-page-preview-container-close'
          onClick={handleClose}
        >
          X
        </span>
      </header> */}

      <div className='htd-page-preview-root' id='htd-page-preview-root' />
    </div>,
    container
  );
};

export default PreviewContainer;

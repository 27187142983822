import React, {
  createElement,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form, Collapse } from 'antd';
// import { debounce } from 'lodash';
import './index.less';
import {
  AvaliableEvents,
  DesignComponent,
  DsProps,
  ExtraConfigSections,
  ObjectType,
  PropTypesObj,
} from '@htd/common';
import { CaretRightOutlined } from '@ant-design/icons';

import PropField from './PropField';
import { useCallback } from 'react';
// import { emptyObject } from 'utils';
import { parsePropGroups } from './util';
import { BASIC_PROPS, OTHER_PROPS } from 'utils/config';
// import { shouldUseMultilingualCode } from 'utils/multilingual-util';
import { DATA_HCG_FLAG } from 'utils';
import { isMultilingualProps } from 'utils/multilingual-util';
import CommonActionPanel from 'components/CommonActionPanel';
import VariableInput from 'components/VariableInput';
import { DesignContext } from 'views/Home/DesignContext';
import ValidateAttribute from 'components/FormVolidate/ValidateAttribute';
import CascadePanel from 'components/FormCascade';
import LovPara from 'components/LovPara';
import { formItemPropsChangeEffectDsConfig } from '@/mapping/container/Form/utils';

const { Item, useForm } = Form;

const { Panel } = Collapse;

export interface CommonConfigPanelProps {
  clearDefault?: boolean; // 清除默认的配置表单
  onChange?(allValue: ObjectType): any;
  useDs?: boolean;
  configTypes: PropTypesObj;
  configDSTypes: PropTypesObj;
  config?: DesignComponent;
  // 额外的配置区域
  extra?: ExtraConfigSections;
  avaliableEvents?: AvaliableEvents;
  // 全局的ds配置列表，对应context内的dsConfig
  globalDsConfig?: DsProps[];
  onDsChange?: (dsKey: string, oldKey: string) => any;
  onActionChange?: (e: any) => any;
  onDeleteEvent?: (eventName: string) => any;
  isMultilingual?: boolean;
  setForceUpdate?: any;
}

const CommonConfigPanel: FC<CommonConfigPanelProps> = (props) => {
  const {
    onChange,
    clearDefault,
    configTypes,
    configDSTypes,
    // useDs,
    extra,
    avaliableEvents,
    globalDsConfig,
    config,
    // onDsChange = noop,
    onActionChange,
    onDeleteEvent,
    isMultilingual,
  } = props;

  const { tree, cMap, forceUpdate, backendConfig } = useContext(DesignContext);

  const propGroups = parsePropGroups(configTypes, { isMultilingual, config });

  const allValue = useMemo(() => {
    return Object.keys(configTypes).reduce((old, key) => {
      if (key === 'applicationFor') {
        return { ...old, [key]: configTypes[key] };
      }
      return { ...old, [key]: configTypes[key].value };
    }, {});
  }, [configTypes]);

  const [configForm] = useForm();
  const [previousValue, setPreviousValue] = useState({});

  useEffect(() => {
    const componentName = (config || {}).name;
    const values = Object.keys(configTypes || {}).reduce((old, k) => {
      // 如果是需要多语言扩展的属性，需要手动setFieldValue
      if (isMultilingualProps(componentName!, k)) {
        const multilingualPropsName = `${k}MultilingualCode`;
        return {
          ...old,
          [k]: configTypes?.[k]?.value,
          [multilingualPropsName]: configTypes?.[k]?.multilingualCode,
        };
      }
      return { ...old, [k]: configTypes?.[k]?.value };
    }, {});
    configForm.setFieldsValue(values);
    setPreviousValue(values);
  }, [configForm, configTypes, config, configTypes.name?.value]);

  const handleChange = (_: any, allValue: any) => {
    // @ts-ignore
    if (previousValue.fieldKey) {
      // @ts-ignore
      allValue.fieldKey = previousValue.fieldKey;
    }
    if (
      allValue.name &&
      !new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/).test(allValue.name)
    ) {
      configForm.setFields([{ name: 'name', errors: ['请以驼峰命名'] }]);
      return;
    }
    if (allValue.name === configDSTypes.bind?.value) {
      configForm.setFields([
        { name: 'name', errors: ['数据字段不能和绑定字段相同'] },
      ]);
      allValue.name = '';
    } else {
      configForm.setFields([{ name: 'name', errors: [] }]);
      // 如果是表单子组件name or label修改，额外执行ds修改联动
      formItemPropsChangeEffectDsConfig(
        cMap.get(config?.parentId),
        config,
        allValue,
        previousValue,
        tree,
        backendConfig,
        forceUpdate
      );
    }
    setPreviousValue(allValue);
    onChange?.(allValue);
  };

  const onSectionChange = useCallback(
    (value: ObjectType) => {
      onChange?.({
        ...configTypes,
        ...value,
        ...configDSTypes,
      });
    },
    [onChange, configTypes, configDSTypes]
  );

  // const handleDsChange = (v: string) => {
  //   const oldValue = config.dsKey;
  //   config.dsKey = v;
  //   onDsChange?.(config.dsKey, oldValue);
  //   dsForm.setFieldsValue({ dsKey: v });
  // };

  return (
    <div className='htd-common-config-panel common-config-panel-box'>
      <Form
        form={configForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onValuesChange={handleChange}
        labelAlign='left'
      >
        <Collapse
          defaultActiveKey={[BASIC_PROPS, OTHER_PROPS]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {!clearDefault &&
            propGroups.map(({ id, title, children }) => (
              <Panel header={title} key={id} id={id}>
                <section
                  key={id}
                  className='htd-common-config-panel-section file-edit-panel-content-back auto-complete-box'
                >
                  {children
                    .filter(
                      ({ name }) =>
                        !(name === 'bind' && (config || {}).name === 'Lov')
                    ) // lov组件不加载bind默认属性配置
                    .map(
                      ({
                        name,
                        hidden,
                        label,
                        valueConfigurable,
                        showHelp,
                        help,
                        variableExample,
                        ...rest
                      }) => {
                        return hidden ? null : (
                          <Item
                            tooltip={showHelp && help}
                            label={label}
                            name={name}
                            key={name}
                          >
                            {valueConfigurable ? (
                              <VariableInput
                                globalDsConfig={tree.dsConfig}
                                example={variableExample}
                              >
                                <PropField
                                  {...rest}
                                  allValue={{
                                    ...allValue,
                                    ...configForm.getFieldsValue(),
                                  }}
                                />
                              </VariableInput>
                            ) : (
                              <PropField
                                {...rest}
                                allValue={{
                                  ...allValue,
                                  ...configForm.getFieldsValue(),
                                }}
                                topOnChange={onChange}
                                parentItem={cMap.get(config?.parentId)}
                              />
                            )}
                          </Item>
                        );
                      }
                    )}
                </section>
              </Panel>
            ))}
          {extra?.map(({ title, component }) => (
            <Panel key={title} header={title}>
              <main className='htd-common-config-panel-section-body'>
                {createElement(
                  component,
                  {
                    props: configTypes,
                    onChange: onSectionChange,
                  },
                  null
                )}
              </main>
            </Panel>
          ))}
          {config?.validate && (
            <Panel
              key='validate'
              header='校验'
              className='validate-attribute-panel-box'
            >
              <ValidateAttribute tree={tree} item={config} />
            </Panel>
          )}
          {config?.lovParaData && (
            <Panel key='lovParaData' header='查询参数'>
              <LovPara tree={tree} item={config} />
            </Panel>
          )}
          {config?.cascadeData && (
            <Panel key='cascadeData' header='关系'>
              <CascadePanel tree={tree} item={config} />
            </Panel>
          )}
          {avaliableEvents?.length && (
            <Panel key='action' header='动作'>
              <CommonActionPanel
                configProps={config?.props}
                compFlag={config?.props?.[DATA_HCG_FLAG].value as string}
                globalDsConfig={globalDsConfig}
                avaliableEvents={avaliableEvents}
                onActionOk={onActionChange}
                onDeleteEvent={onDeleteEvent}
              />
            </Panel>
          )}
        </Collapse>
      </Form>
      {/* {useDs && (
        <section className='htd-common-config-panel-section'>
          <header className='htd-common-config-panel-section-header'>
            数据源属性
          </header>
          <main className='htd-common-config-panel-section-body'>
            <Form form={dsForm} labelAlign='left'>
              <Item
                label='选择数据源'
                name='dsKey'
                key='dsKey'
                rules={[{ required: true, message: '必须选择一个数据源' }]}
              >
                <Select
                  value={config?.dsKey}
                  options={globalDsConfig?.map(({ dsName, dsKey }) => ({
                    label: dsName,
                    value: dsKey,
                  }))}
                  onChange={handleDsChange}
                  allowClear
                />
              </Item>
            </Form>
          </main>
        </section>
      )} */}
    </div>
  );
};

export default CommonConfigPanel;

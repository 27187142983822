/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Description: DataSet 事件触发预置逻辑
 */
import React from 'react';
import { TemplateParamType } from '@htd/common';
import { dataSetUpdateEventByCostomFormula, sumFields } from './events';
const DataSetEvents = [
    {
        code: 'SUM_FIELDS_DATASET_UPDATE',
        name: '数据求和',
        describe: '当前字段在数据源中的记录条数进行求和统计，统计结果可赋值给某一数据源字段',
        requiredParam: [
            {
                name: '源字段',
                index: 'eventSourceName',
                describe: '指定是哪个字段引起的触发',
                type: TemplateParamType.string,
            },
            {
                name: '目标值',
                index: 'eventTargetName',
                describe: '指定是哪个字段接受更改',
                type: TemplateParamType.string,
            },
            {
                name: '数据源',
                index: 'eventTargetDataSet',
                describe: '指定接受更改字段字段数据源',
                type: TemplateParamType.dataSetName,
            },
        ],
        function: sumFields,
    },
    {
        code: 'ASSOCIAT_FIELD_DATASET_UPDATE',
        name: '数据值公式计算',
        requiredParam: [
            {
                name: '触发源字段',
                index: 'eventSourceName',
                describe: '指定是哪个字段引起的触发',
                type: TemplateParamType.string,
            },
            {
                name: '公式',
                index: 'formula',
                describe: '计算公式，js代码段',
                type: TemplateParamType.variableString,
                helper: (React.createElement("div", null,
                    "\u6B64\u5904\u4F7F\u7528",
                    React.createElement("span", { className: 'code' },
                        React.createElement("a", { href: 'https://open-hand.gitee.io/choerodon-ui/zh/procmp/dataset/dataset#dataset-events', target: '_blank', rel: 'noreferrer' }, "dataSet Update Event API")),
                    "\u53EF\u901A\u8FC7",
                    React.createElement("span", { className: 'code' }, "params"),
                    "\u53C2\u6570\u83B7\u53D6",
                    React.createElement("span", { className: 'code' }, "dataSet,record,name,value,oldValue"),
                    ". Eg:",
                    React.createElement("pre", { className: 'code' }, "params.record.set(\"fieldA\", params.value+1);"))),
            },
        ],
        function: dataSetUpdateEventByCostomFormula,
    },
];
export default DataSetEvents;

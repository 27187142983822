/*
 * @Author: wen.chen02@hand-china.com
 * @Description: 单元格渲染模板
 */
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT, } from '@htd/common';
import { enableRender, yesOrNoRender, priorityRender, dateRender, timeRender, dateTimeRender, customRender, enableRenderPerformance, yesOrNoRenderPerformance, priorityRenderPerformance, dateRenderPerformance, timeRenderPerformance, dateTimeRenderPerformance, rowIndexRenderPerformance, rowIndexRender, } from './renders';
const CellRenderers = [
    {
        code: 'enableRender',
        name: '启用/禁用',
        describe: '值等于【1】时，渲染为【启用】，否则为【禁用】',
        function: enableRender,
    },
    {
        code: 'yesOrNoRender',
        name: '是/否',
        describe: '值等于【1】时，渲染为是，否则为否',
        function: yesOrNoRender,
    },
    {
        code: 'priorityRender',
        name: '高/中/低',
        describe: '值(0-100)等小于【35】时，渲染为【低】，大于【65】时，渲染为【高】，否则为【中】',
        function: priorityRender,
    },
    {
        code: 'dateRender',
        name: '日期格式',
        describe: DEFAULT_DATE_FORMAT,
        function: dateRender,
    },
    {
        code: 'timeRender',
        name: '时间格式',
        describe: DEFAULT_TIME_FORMAT,
        function: timeRender,
    },
    {
        code: 'dateTimeRender',
        name: '日期时间格式',
        describe: DEFAULT_DATETIME_FORMAT,
        function: dateTimeRender,
    },
    {
        code: 'rowIndexRender',
        name: '行号',
        describe: '根据数据顺序从1开始显示行索引',
        function: rowIndexRender,
    },
    {
        code: 'customRender',
        name: '自定义渲染',
        describe: '手动实现个性化渲染，参考C7nPro Table Column renderer API',
        function: customRender,
    },
];
export const PerformanceCellRenderers = [
    {
        code: 'enableRender',
        name: '启用/禁用',
        describe: '值等于【1】时，渲染为【启用】，否则为【禁用】',
        function: enableRenderPerformance,
    },
    {
        code: 'yesOrNoRender',
        name: '是/否',
        describe: '值等于【1】时，渲染为是，否则为否',
        function: yesOrNoRenderPerformance,
    },
    {
        code: 'priorityRender',
        name: '高/中/低',
        describe: '值(0-100)等小于【35】时，渲染为【低】，大于【65】时，渲染为【高】，否则为【中】',
        function: priorityRenderPerformance,
    },
    {
        code: 'dateRender',
        name: '日期格式',
        describe: DEFAULT_DATE_FORMAT,
        function: dateRenderPerformance,
    },
    {
        code: 'timeRender',
        name: '时间格式',
        describe: DEFAULT_TIME_FORMAT,
        function: timeRenderPerformance,
    },
    {
        code: 'dateTimeRender',
        name: '日期时间格式',
        describe: DEFAULT_DATETIME_FORMAT,
        function: dateTimeRenderPerformance,
    },
    {
        code: 'rowIndexRender',
        name: '行号',
        describe: '根据数据顺序从1开始显示行索引',
        function: rowIndexRenderPerformance,
    },
    {
        code: 'customRender',
        name: '自定义渲染',
        describe: '手动实现个性化渲染，参考C7nPro Table Column renderer API',
        function: customRender,
    },
];
export default CellRenderers;

import { saveInlineBlock, saveLocalBlock } from '@/services/block-service';
import { useStore } from '@/store';
import {
  fetchInlineCategoryEffect,
  fetchLocalCategoryEffect,
  fetchInlineBlockListEffect,
} from '@/store/reducers/block';
import { Modal, Form, Input, Radio, Switch, message, Tooltip } from 'antd';
import AddLocalCategoryModal from 'components/AddLocalCategoryModal';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { generateBlockDataToSave, shouldDisableSaveDs } from 'utils';
import { DesignContext } from 'views/Home/DesignContext';
import CategorySelect from './CategorySelect';
import { ReactComponent as HelpIcon } from './assets/help.svg';
export interface BlockSaveModalProps {
  visible?: boolean;
  onClose?: () => any;
  data: any;
}

const { useForm } = Form;

const BlockSaveModal: FC<BlockSaveModalProps> = ({
  visible,
  onClose,
  data,
}) => {
  const [blockForm] = useForm();
  const { state } = useStore();
  const [cateModalVisible, setCateModalVisible] = useState(false);
  const { tree } = useContext(DesignContext);

  const {
    block: {
      // localCategoryList,
      inlineCategoryList = [],
    },
  } = state;

  const handleAddLocalCategory = useCallback(() => {
    setCateModalVisible(true);
  }, []);
  const disableSaveDs = useMemo(() => shouldDisableSaveDs(data), [data]);
  useEffect(() => {
    if (visible) {
      if (disableSaveDs) {
        blockForm.setFieldsValue({ saveDs: true });
      }
      fetchLocalCategoryEffect();
      fetchInlineCategoryEffect();
    }
  }, [blockForm, disableSaveDs, visible]);

  const handleSave = useCallback(() => {
    blockForm.validateFields().then(async (value) => {
      onClose?.();
      if (value.saveType === 'local') {
        await saveLocalBlock({
          ...value,
          data: generateBlockDataToSave(cloneDeep(data), tree, value.saveDs),
        });
      }
      if (value.saveType === 'online') {
        await saveInlineBlock({
          ...value,
          data: generateBlockDataToSave(cloneDeep(data), tree, value.saveDs),
        }).then((res) => {
          if (res) {
            fetchInlineBlockListEffect({
              categoryId: value.type,
            });
          }
        });
      }
      blockForm.resetFields();
      onClose?.();
      message.success('保存区块成功');
    });
  }, [blockForm, data, onClose, tree]);
  const handleBlockModalCancel = useCallback(() => {
    onClose?.();
    blockForm.resetFields();
  }, [blockForm, onClose]);

  return (
    <>
      <Modal
        title='保存为区块'
        visible={visible}
        onOk={handleSave}
        onCancel={handleBlockModalCancel}
        className='htd-block-save-modal'
        width={400}
      >
        <Form form={blockForm}>
          <Form.Item
            name='type'
            label='分类'
            rules={[{ required: true, message: '分类必选' }]}
          >
            <CategorySelect
              options={inlineCategoryList.map((c: any) => ({
                label: c?.categoryName,
                value: c?.id,
              }))}
              onAdd={handleAddLocalCategory}
            />
          </Form.Item>
          <Form.Item
            name='name'
            label='名称'
            initialValue='区块'
            rules={[{ required: true, message: '必填' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='saveType' initialValue='online' label='类型'>
            <Radio.Group
              options={[
                { label: '本地JSON区块', value: 'local', disabled: true },
                { label: '在线JSON区块', value: 'online' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name='saveDs'
            label={
              <span className='htd-block-save-modal-tooltip-label'>
                <span>数据保存</span>
                <Tooltip
                  title={
                    <div>
                      <p>
                        1、勾选数据配置时，会将组件的数据字段、数据源等数据相关配置存储
                      </p>
                      <p>
                        2、未勾选数据配置时，将不会存储相关数据配置，会生成默认值或清空值
                      </p>
                    </div>
                  }
                >
                  <HelpIcon />
                </Tooltip>
              </span>
            }
            initialValue={true}
            valuePropName='checked'
          >
            <Switch disabled={disableSaveDs} />
          </Form.Item>
        </Form>
      </Modal>
      <AddLocalCategoryModal
        visible={cateModalVisible}
        onClose={() => setCateModalVisible(false)}
      />
    </>
  );
};

export default BlockSaveModal;

import React, { forwardRef } from 'react';
import cls from 'classnames';
import './index.less';

const Div = forwardRef((props: any, ref: any) => {
  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenFormFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;

  // const { width, height, backgroundColor, textAlign, className } = props;
  // const style = {
  //   'width': (![null, ''].includes(width) && !isNaN(width)) ? width + 'px' : width,
  //   'height': (![null, ''].includes(height) && !isNaN(height)) ? height + 'px' : height,
  //   'background-color': backgroundColor,
  //   'text-align': textAlign,
  // }
  return (
    <div
      className={cls(
        'htd-placeholder-div',
        isHaveChildrenFormFlag && 'not-chr'
      )}
      // style={style}
      ref={ref}
    >
      {props.children}
    </div>
  );
});
export { Div };

import {
  createContext,
  createElement,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ObjectType } from '@htd/common';
import { Routes, Route } from './types';

export type RouteMatched = Route & { query: ObjectType<string> };

export interface RouterContextValue {
  routeMap: Routes;
  currentPath: string;
  matched?: RouteMatched;
  push(path: string): void;
}

const RouterContext = createContext<RouterContextValue>({} as any);

const { Provider, Consumer: RouterConsumer } = RouterContext;

export interface RouterProviderProps {
  routes: Routes;
}

const STORAGE_KEY = 'CODE_GENERATOR_STORAGE_KEY';

const RouterProvider: FC<RouterProviderProps> = ({ routes, children }) => {
  const [currentPath, setCurrentPath] = useState('');
  const [matched, setMatched] = useState<RouteMatched | undefined>();

  const push = useCallback(
    (path: string) => {
      setCurrentPath(path);
      sessionStorage.setItem(STORAGE_KEY, path);
      const found = routes.find((r) => r.path === path);
      if (found) {
        setMatched({ ...found, query: {} });
      }
    },
    [routes]
  );
  useEffect(() => {
    const currentPath = sessionStorage.getItem(STORAGE_KEY) || '/';
    push(currentPath);
    return () => {
      sessionStorage.removeItem(STORAGE_KEY);
    };
  }, [push]);
  const contextValue = useMemo<RouterContextValue>(
    () => ({ routeMap: routes, currentPath, push, matched }),
    [currentPath, matched, push, routes]
  );
  return createElement(
    Provider,
    {
      value: contextValue,
    },
    children
  );
};

export { RouterConsumer, RouterProvider, RouterContext };

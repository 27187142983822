import { Mapping } from '@htd/common';
import { FormField } from 'components/placeholders';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import {
  BASE_PROPS_TYPES,
  FORM_ITEM_TYPES,
  getInputCommon,
} from 'utils/prop-types';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';
import { OTHER_PROPS } from 'utils/config';

export const Output: Mapping = {
  name: 'Output',
  title: '文本展示',
  type: 'inline-block',
  lib: 'choerodon-ui/pro',
  fieldType: 'string',
  iconCode: 'Output',
  props: {
    ...BASE_PROPS_TYPES,
    ...FORM_ITEM_TYPES,
    ...getInputCommon('output', '文本展示'),
    name: {
      label: '数据字段',
      type: 'string',
      value: `output${HTD_PROPS_HOLDER}`,
    },
    defaultValue: {
      label: '默认值',
      type: 'string',
      value: '',
      isDS: true,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'} let value = '张三'; // 你的逻辑... value =
          '李四'; return value;{'\u007d'}
        </pre>
      ),
      group: OTHER_PROPS,
    },
    update: {
      type: 'function',
      label: '值更新',
      hidden: true,
      ignore: true,
    },
  },
  availableEvents: [
    {
      name: 'update',
      meaning: '值更新',
      list: DataSetEvents.filter((i) => i.code && i.name && i.function),
    },
  ],
  children: [],
  placeholderComponent: FormField,
};

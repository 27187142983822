import { Modal, Form, Space, Radio } from 'antd';
import React, { FC, useCallback } from 'react';
import { useContext } from 'react';
import { baseList } from '../../mapping/base';

import { DesignContext } from 'views/Home/DesignContext';
import { MappingListItem } from '@/mapping/util';
import { dropNewItemToList } from 'utils';
import { DesignComponent } from '@htd/common';
export interface ChangecFormComponentModalProps {
  visible?: boolean;
  onClose?: () => any;
}

/**
 * 数据同步
 */
const applyDataProps = [
  'label',
  'fieldKey',
  'name',
  'disabled',
  'conditionHidden',
];
function synchronousData(item: DesignComponent) {
  return applyDataProps.map((a) => ({ key: a, value: item.props?.[a] }));
}

const ENABLE_CHANGE_COMPONENT = [
  ...baseList
    .filter((b: MappingListItem) => b.mapping)
    .map((b: MappingListItem) => ({
      name: b.id,
      title: b.mapping?.title,
    })),
];
const { useForm } = Form;

const ChangecFormComponentModal: FC<ChangecFormComponentModalProps> = ({
  visible,
  onClose,
}) => {
  const [form] = useForm();
  const { settingItem, cMap, forceUpdate, tree, onSetting, onDelete } =
    useContext(DesignContext);

  const handleChange = useCallback(() => {
    const toType = form.getFieldValue('formType');
    if (!toType) {
      return;
    }
    const to = settingItem?.parentId!!;
    const parent = cMap.get(settingItem?.parentId);
    const parentAreaChildren = parent?.children;
    let index = -1;
    if (parent?.name && parentAreaChildren) {
      const targetIndex = parentAreaChildren.findIndex(
        (c) => c.id === settingItem?.id
      );
      if (targetIndex !== -1) {
        index = targetIndex;
      }
    }
    const applyData = synchronousData(settingItem!!);
    onDelete?.(settingItem!!);
    // 基于toType创建一个新的组件
    // 修改当前组件为新组件
    dropNewItemToList(
      cMap,
      to,
      parent,
      index,
      tree,
      toType!,
      onSetting,
      false,
      forceUpdate,
      applyData
    );
  }, [settingItem, cMap, tree, onSetting, onDelete, forceUpdate, form]);
  return (
    <>
      <Modal
        title='改变组件类型'
        visible={visible}
        onCancel={onClose}
        onOk={handleChange}
        className='htd-change-component-modal'
        // width={400}
      >
        <Form form={form}>
          <Form.Item name='formType' initialValue='online' label='类型'>
            <Radio.Group>
              <Space direction='horizontal' wrap>
                {ENABLE_CHANGE_COMPONENT.map((ecc) => (
                  <Radio
                    style={{ width: '130px' }}
                    value={ecc.name || ''}
                    disabled={ecc.name === settingItem?.componentId}
                  >
                    {ecc.title}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangecFormComponentModal;

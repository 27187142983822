import { FC } from 'react';
import { Form as AntdForm, Input } from 'antd';
// import VariableInput from 'components/VariableInput';
// import { DesignContext } from 'views/Home/DesignContext';
import { IconPicker } from 'choerodon-ui/pro';
// import SelectRightImg from 'components/selectRightImg';

interface BaseAttributeProps {
  currentColumn: any;
  onChange: Function;
}

// interface statusProps {
//   name: string;
//   value: string;
// }

// const statusList = [
//   { name: 'process', value: 'process' },
//   { name: 'wait', value: 'wait' },
//   { name: 'finish', value: 'finish' },
//   { name: 'error', value: 'error' },
// ];

const BaseAttribute: FC<BaseAttributeProps> = ({ currentColumn, onChange }) => {
  // const { tree } = useContext(DesignContext);

  // const handleChange = (value: string) => {
  //   onChange({ status: value });
  // };
  return (
    <section className='htd-aside-right-main-section'>
      <main className='htd-aside-right-main' style={{ padding: 8 }}>
        <AntdForm.Item label='唯一标识' name='dataIndex'>
          <Input disabled />
        </AntdForm.Item>
        <AntdForm.Item label='标题' name='title'>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item label='描述' name='description'>
          <Input />
        </AntdForm.Item>
        {/* <AntdForm.Item label='禁用' name='disabled'>
          <VariableInput globalDsConfig={tree.dsConfig}>
            <Switch checked={!!currentColumn?.disabled} />
          </VariableInput>
        </AntdForm.Item> */}
        <AntdForm.Item label='图标' name='icon'>
          <IconPicker />
        </AntdForm.Item>
        {/* <AntdForm.Item label='状态' name='status'>
          <VariableInput globalDsConfig={tree.dsConfig}>
          <Select value={currentColumn?.status} suffixIcon={<SelectRightImg />}>
            {statusList.map((p: statusProps) => (
              <Select.Option value={p.value} key={p.value}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
          </VariableInput>
        </AntdForm.Item> */}
      </main>
    </section>
  );
};

export default BaseAttribute;

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import { configure, message, getConfig as getC7nConfig } from 'choerodon-ui';
import { Config } from 'choerodon-ui/lib/configure';
import { notification } from 'choerodon-ui';
import {
  generateUrlWithGetParam,
  getAccessToken,
  getCurrentOrganizationId,
  isTenantRoleLevel,
  getRequestId,
  API_HOST_KEY,
  getSession,
} from './utils';
import { axios } from './utils/net';
import { TableQueryBarType } from 'choerodon-ui/pro/lib/table/enum';
import * as config from './utils/config';

(window as any).getConfig = getC7nConfig;

export const getEnvConfig = (): any => {
  const sessionApi = getSession(API_HOST_KEY);
  return sessionApi
    ? {
        ...config,
        API_HOST: sessionApi,
      }
    : config;
};

const getConfig = (k: string): any => void 0;

// TODO: 获取menuId
const getMenuId = () => '';

const lovDefineAxiosConfig: any = (code: any, field: any) => {
  let publicFlag = false;
  if (field?.get('lovPara')) {
    const { publicMode } = field.get('lovPara');
    publicFlag = publicMode;
  }
  const { API_HOST, HZERO_PLATFORM } = getEnvConfig();
  return {
    url: `${API_HOST}${HZERO_PLATFORM}/v1/${
      // eslint-disable-next-line no-nested-ternary
      publicFlag
        ? 'pub/'
        : isTenantRoleLevel()
        ? `${getCurrentOrganizationId()}/`
        : ''
    }lov-view/info?viewCode=${code}`,
    method: 'GET',
    transformResponse: [
      (data: any) => {
        // 对 data 进行任意转换处理
        let originData: any = {};

        try {
          originData = JSON.parse(data);
        } catch (e) {
          console.error(e, data);
          return data;
        }

        const {
          height,
          viewCode = code,
          valueField = 'value',
          displayField = 'name',
          pageSize = 5,
          queryFields = [],
          tableFields = [],
          // queryUrl,
        } = originData;
        let { title } = originData;
        if (originData.failed) {
          title = `值集视图未定义: "${code}", 请维护值集视图!`;
        } else if (!originData.lovCode) {
          title = `lov ${code} loading...`;
        }
        const lovItems: any[] = [];
        // let tableWidth = 0;
        queryFields.forEach((queryItem: any = {}) => {
          const lovItem = {
            lovId: viewCode,
            lovItemId: `query_${queryItem.field}`,
            gridFieldName: queryItem.field,
            gridField: 'N',
            display: queryItem.label,
            autocompleteField: 'Y',
            conditionField: 'Y',
            isAutocomplete: 'N',
            conditionFieldWidth: null,
            conditionFieldLabelWidth: null,
            conditionFieldType: queryItem.dataType,
            conditionFieldSelectCode:
              queryItem.dataType === 'SELECT' ? queryItem.sourceCode : null,
            conditionFieldLovCode: null,
            conditionFieldName: null,
            conditionFieldTextfield: null,
            conditionFieldNewline: 'N',
            conditionFieldSelectUrl: null,
            conditionFieldSelectVf: null,
            conditionFieldSelectTf: null,
            conditionFieldSequence: 1,
            gridFieldSequence: 1,
          };
          lovItems.push(lovItem);
        });
        tableFields.forEach((tableItem: any) => {
          const lovItem = {
            lovId: viewCode,
            lovItemId: `table_${tableItem.dataIndex}`,
            gridFieldName: tableItem.dataIndex,
            gridFieldWidth: tableItem.width,
            gridFieldAlign: 'left',
            autocompleteField: 'Y',
            conditionField: 'N',
            isAutocomplete: 'N',
            gridField: 'Y',
            display: tableItem.title,
            conditionFieldWidth: null,
            conditionFieldLabelWidth: null,
            conditionFieldType: null,
            conditionFieldSelectCode: null,
            conditionFieldName: null,
            conditionFieldTextfield: null,
            conditionFieldNewline: 'N',
            conditionFieldSelectUrl: null,
            conditionFieldSelectVf: null,
            conditionFieldSelectTf: null,
            conditionFieldLovCode: null,
            conditionFieldSequence: 1,
            gridFieldSequence: 1,
          };
          lovItems.push(lovItem);
          // tableWidth += tableItem.width;
        });

        return {
          originData: {
            lovCode: code,
            ...originData,
          },
          code: viewCode,
          title,
          description: title,
          lovId: viewCode,
          placeholder: title,
          sqlId: viewCode,
          customSql: null,
          queryColumns: 2,
          customUrl: null,
          textField: displayField,
          valueField,
          delayLoad: 'N',
          needQueryParam: 'N',
          editableFlag: 'Y',
          canPopup: 'Y',
          lovPageSize: pageSize,
          treeFlag: 'N',
          idField: null,
          parentIdField: null,
          lovItems,
          width: 710,
          height,
        };
      },
    ],
  };
};

const lovQueryAxiosConfig = (
  code: any,
  lovConfig: any = {},
  props: any,
  lovQueryUrl: string
) => {
  const { queryUrl, lovCode } = lovConfig.originData || {};
  const { API_HOST, HZERO_PLATFORM } = getEnvConfig();
  let url = `${API_HOST}${HZERO_PLATFORM}/v1/${
    isTenantRoleLevel() ? `${getCurrentOrganizationId()}/` : ''
  }lovs/data?lovCode=${lovCode}`;
  if (lovQueryUrl) {
    url = lovQueryUrl;
  } else if (queryUrl) {
    url = generateUrlWithGetParam(queryUrl, { lovCode });
    const organizationRe = /{organizationId}|{tenantId}/g;
    if (organizationRe.test(url)) {
      const tId = getCurrentOrganizationId();
      url = url.replace(organizationRe, tId);
    }
    // url = `${url}${url.indexOf('?') ? '&' : '?'}lovCode=${lovCode}`;
  }
  return {
    url,
    method: 'GET',
  };
};

const generatePageQuery = ({ page, pageSize, sortName, sortOrder }: any) => ({
  page: page === undefined ? page : page - 1,
  size: pageSize,
  sort: sortName && (sortOrder ? `${sortName},${sortOrder}` : sortName),
});

/**
 * 鉴权 401
 * @param status 状态码
 */
const authrIntercept = (status: number) => {
  if (status === 401) {
    // 重新登录
  }
  return true;
};

const loadConfig = () => {
  const jsonMimeType = 'application/json';

  const withTokenAxios = axios;

  // 微前端模式下， 这个语句块会多次执行， 所以加一个条件限制， 只能执行一次
  withTokenAxios.defaults = {
    ...withTokenAxios.defaults,
    headers: {
      ...(withTokenAxios.defaults || {}).headers,
      // Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': jsonMimeType,
      Accept: jsonMimeType,
      'X-Requested-With': 'XMLHttpRequest',
      // baseURL: API_HOST,
    },
  };

  // Add a request interceptor
  withTokenAxios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      let { url = '' } = config;
      const { API_HOST } = getEnvConfig();
      if (url.indexOf('://') === -1 && !url.startsWith('/_api')) {
        url = `${API_HOST}${url}`;
      }
      // TODO: MenuId怎么获取
      const MenuId = getMenuId();
      // 添加额外的请求头
      const patchRequestHeaderConfig = getConfig('patchRequestHeader');
      let patchRequestHeader;
      if (patchRequestHeaderConfig) {
        if (typeof patchRequestHeaderConfig === 'function') {
          patchRequestHeader = patchRequestHeaderConfig();
        } else {
          patchRequestHeader = patchRequestHeaderConfig;
        }
      }

      if (MenuId) {
        return {
          ...config,
          url,
          headers: {
            ...config.headers,
            Authorization: `bearer ${getAccessToken()}`,
            'H-Menu-Id': `${getMenuId()}`,
            'H-Request-Id': `${getRequestId()}`,
            ...patchRequestHeader,
          },
        };
      } else {
        return {
          ...config,
          url,
          headers: {
            ...config.headers,
            Authorization: `bearer ${getAccessToken()}`,
            'H-Request-Id': `${getRequestId()}`,
            ...patchRequestHeader,
          },
        };
      }
    },
    (error: any) =>
      // Do something with request error
      Promise.reject(error)
  );

  withTokenAxios.interceptors.response.use((response: AxiosResponse) => {
    const { status, data } = response || {};
    if (!authrIntercept(status)) {
      return;
    }
    if (status === 204) {
      return undefined;
    }
    if (data && data.failed) {
      throw data;
    } else if (data && data.detailsMessage) {
      let m = require('./assets/icon_page_wrong.svg');
      if (m.__esModule) {
        m = m.default;
      }
      notification.error({
        icon: <></>,
        description: '',
        message: (
          <>
            <img
              src={m}
              alt=''
              className='ant-notification-notice-message-img'
            />
            <div className='ant-notification-notice-message-content'>
              {status || data.detailsMessage}
            </div>
          </>
        ),
        className: 'request error',
      });
    } else {
      return data;
    }
  });

  message.config({
    placement: 'bottomRight',
    bottom: 48,
    duration: 2,
  });

  configure({
    lookupUrl: (code) => {
      const { API_HOST, HZERO_PLATFORM } = getEnvConfig();
      return `${API_HOST}${HZERO_PLATFORM}/v1/${
        isTenantRoleLevel() ? `${getCurrentOrganizationId()}/` : ''
      }lovs/data?lovCode=${code}`;
    },
    ripple: false,
    modalOkFirst: false,
    lovDefineAxiosConfig:
      lovDefineAxiosConfig as Config['lovDefineAxiosConfig'],
    lovQueryAxiosConfig: lovQueryAxiosConfig as Config['lovDefineAxiosConfig'],
    lovQueryUrl: undefined,
    // lookupBatchAxiosConfig,
    lookupAxiosMethod: 'GET',
    dataKey: 'content',
    totalKey: 'totalElements',
    axios: withTokenAxios,
    generatePageQuery: generatePageQuery as Config['generatePageQuery'],
    status: {
      add: 'create',
      update: 'update',
      delete: 'delete',
    },
    // iconfontPrefix: 'c7n',
    statusKey: '_status',
    tlsKey: '_tls',
    useColon: true,
    queryBar: TableQueryBarType.professionalBar,
    tableAutoHeightDiff: 100,
    feedback: {
      loadSuccess: () => {},
      loadFailed: (resp: any) => {
        if (resp && resp.failed) {
          notification.error({
            message: resp && resp.message,
            description: resp && resp.message,
          });
        } else if (resp && resp.response) {
          if (!authrIntercept(resp.response.status)) {
            return;
          }
          let m = require('./assets/icon_page_wrong.svg');
          if (m.__esModule) {
            m = m.default;
          }
          if (resp.response.data && resp.response.data.detailsMessage) {
            notification.error({
              icon: <></>,
              description: '',
              message: (
                <>
                  <img
                    src={m}
                    alt=''
                    className='ant-notification-notice-message-img'
                  />
                  <div className='ant-notification-notice-message-content'>
                    {resp.response.data.detailsMessage}
                  </div>
                </>
              ),
              className: 'request error',
            });
          } else {
            notification.error({
              icon: <></>,
              description: '',
              message: (
                <>
                  <img
                    src={m}
                    alt=''
                    className='ant-notification-notice-message-img'
                  />
                  <div className='ant-notification-notice-message-content'>
                    {resp.message}
                  </div>
                </>
              ),
              className: 'request error',
            });
          }
        }
      },
      submitSuccess: (resp) => {
        notification.success({
          message: resp && resp.message,
          description: resp && resp.message,
        });
      },
      submitFailed: (resp: any) => {
        if (resp && resp.failed) {
          notification.error({
            message: resp && resp.message,
            description: resp && resp.message,
          });
        } else if (resp && resp.response) {
          let m = require('./assets/icon_page_wrong.svg');
          if (m.__esModule) {
            m = m.default;
          }
          notification.error({
            icon: <></>,
            description: '',
            message: (
              <>
                <img
                  src={m}
                  alt=''
                  className='ant-notification-notice-message-img'
                />
                <div className='ant-notification-notice-message-content'>
                  {resp.message}
                </div>
              </>
            ),
            className: 'request error',
          });
        }
      },
    },
    transport: {
      tls: ({ dataSet, name: fieldName }) => {
        // TODO: 先使用 dataSet.current 下个版本 c7n 会 把 record 传进来
        const _token = dataSet?.current?.get('_token');
        const { HZERO_PLATFORM } = getEnvConfig();
        return {
          url: `${HZERO_PLATFORM}/v1/multi-language`,
          method: 'GET',
          params: { _token, fieldName },
          transformResponse: (data) => {
            try {
              const jsonData = JSON.parse(data);
              if (jsonData && !jsonData.faied) {
                const tlsRecord: any = {};
                jsonData.forEach((intlRecord: any) => {
                  tlsRecord[intlRecord.code] = intlRecord.value;
                });
                return [{ [fieldName]: tlsRecord }];
              }
            } catch (e) {
              // do nothing, use default error deal
            }
            return data;
          },
        };
      },
    },
  });
};

export { lovDefineAxiosConfig, loadConfig };

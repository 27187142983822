import React, { forwardRef } from 'react';
import cls from 'classnames';
import './index.less';

export const Form = forwardRef((props: any, ref: any) => {
  const { useColon } = props;
  const formCls = cls(
    'htd-placeholder-form',
    useColon && 'htd-placeholder-form-useColon'
  );

  // 判断 是否有下级内容元素
  const chrValue: any = props?.children;
  const isHaveChildrenFormFlag: number =
    chrValue &&
    chrValue['props'] &&
    chrValue.props.children &&
    chrValue.props.children.length;

  return (
    <div
      className={cls(formCls, isHaveChildrenFormFlag && 'not-chr')}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

import { Mapping } from '@htd/common';
import {
  Header as PageHeader,
  Content as PageContent,
  Footer as PageFooter,
} from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Header: Mapping = {
  name: 'Header',
  title: '页面标题',
  iconCode: 'Header',
  type: 'container',
  lib: 'components/Page',
  props: {
    ...BASE_PROPS_TYPES,
    title: {
      type: 'string',
      value: '页面头',
      label: '标题',
      // ignore: true,
    },
    textLayout: {
      label: '内容布局',
      type: 'collection',
      options: [
        { value: 'left', label: '居左' },
        { value: 'center', label: '居中' },
        { value: 'right', label: '居右' },
      ],
      value: 'right',
    },
    backPath: {
      type: 'string',
      value: '',
      label: '返回路由',
    },
  },
  placeholderComponent: PageHeader as any,
  fixed: true,
  children: [],
  boxStyle: { height: '44px' },
};

export const Content: Mapping = {
  name: 'Content',
  title: '内容区',
  iconCode: 'Content',
  type: 'container',
  lib: 'components/Page',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '页面内容',
      label: '标题',
      ignore: true,
      hidden: true,
    },
  },
  placeholderComponent: PageContent as any,
  fixed: true,
  children: [],
  // boxStyle: { height: 'calc(100% - 50px)' },
  boxStyle: { height: '100%' },
};

export const Footer: Mapping = {
  name: 'Footer',
  title: '底部栏',
  iconCode: 'Footer',
  type: 'container',
  lib: '',
  props: {
    ...BASE_PROPS_TYPES,
    title: {
      type: 'string',
      value: '底部栏',
      label: '标题',
    },
    textLayout: {
      label: '内容布局',
      type: 'collection',
      options: [
        { value: 'center', label: '居中' },
        { value: 'left', label: '居左' },
        { value: 'right', label: '居右' },
      ],
      value: 'right',
    },
    textMargin: {
      label: '内容间距',
      type: 'number',
      value: 8,
    },
  },
  placeholderComponent: PageFooter as any,
  fixed: true,
  children: [],
  boxStyle: { height: '44px' },
};

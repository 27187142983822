import React, { FC, useState } from 'react';
import cls from 'classnames';
import { FormInstance, Input, Select } from 'antd';
import VariableInput from 'components/VariableInput';
import { DsProps, TemplateParamType } from '@htd/common';
import MyIcon from 'components/myIcon';
import SelectRightImg from 'components/selectRightImg';

const ArrayInput: FC<{
  globalDsConfig?: DsProps[];
  form: FormInstance<any>;
  param: any;
  isKeyValue?: boolean;
  valueType?: string;
}> = ({ globalDsConfig, form, param, isKeyValue, valueType = 'string' }) => {
  const arr: any[] = form.getFieldValue(param.index) || [];
  if (!arr.length) arr.push(isKeyValue ? {} : '');
  const [array, setArray] = useState(arr);
  const renderContent = () => {
    return array.map((i: any, idx: number) => {
      const input1Value = isKeyValue ? i.key : i;
      const input2Value = isKeyValue ? i.value : i;
      return (
        <div className='htd-common-action-modal-input-array-box'>
          <div className='htd-common-action-modal-input-array-line' key={idx}>
            <div className='htd-common-action-modal-input-array-line-input'>
              <Input
                value={input1Value}
                onChange={(e: any) => {
                  if (/^[0-9]+/.test(e.target.value || '')) {
                    // 变量名不能用数字开头
                    return;
                  }
                  if (isKeyValue) {
                    array.splice(idx, 1, {
                      key: e.target.value,
                      value: input2Value,
                    });
                  } else {
                    array.splice(idx, 1, e.target.value);
                  }
                  form.setFieldsValue({
                    [param.index]: array,
                  });
                  setArray([...array]);
                }}
              />
              {renderValueInput(input1Value, input2Value, idx)}
            </div>
          </div>
          {array.length > 1 && (
            <div className='htd-common-action-modal-input-array-line-action'>
              <MyIcon
                type='icon-a-delete2x'
                onClick={() => {
                  array.splice(idx, 1);
                  setArray([...array]);
                }}
                // 这种写法 不生效？？
                // style={{
                //   visibility: array.length !== 1 ? 'visible' : 'hidden',
                // }}
              />
            </div>
          )}
        </div>
      );
    });
  };

  const renderValueInput = (
    input1Value: any,
    input2Value: any,
    idx: number
  ) => {
    if (isKeyValue) {
      if (valueType === TemplateParamType.dataSetName) {
        return (
          <Select
            suffixIcon={<SelectRightImg />}
            onChange={(e: any) => {
              array.splice(idx, 1, {
                key: input1Value,
                value: e,
              });
              form.setFieldsValue({
                [param.index]: array,
              });
              setArray([...array]);
            }}
            value={input2Value}
          >
            {(globalDsConfig || []).map((ds) => (
              <Select.Option value={ds.dsName}>{ds.dsName}</Select.Option>
            ))}
          </Select>
        );
      }

      return (
        <VariableInput
          globalDsConfig={globalDsConfig}
          value={input2Value}
          example={param.helper}
          onChange={(e: any) => {
            array.splice(idx, 1, {
              key: input1Value,
              // value: e && e.__isVariable ? e.value : e,
              value: e, // 变量取值带上变量标识
            });
            form.setFieldsValue({
              [param.index]: array,
            });
            setArray([...array]);
          }}
        />
      );
    }
  };

  return (
    <div
      className={cls(
        'htd-common-action-modal-input-array',
        isKeyValue && 'htd-common-action-modal-input-array-key-value'
      )}
    >
      {renderContent()}
      <div className='add-action-box'>
        <div
          className='t-j-api-model-box'
          onClick={() => {
            array.push(isKeyValue ? {} : '');
            setArray([...array]);
          }}
        >
          <MyIcon type='icon-a-add_circle2x' className='left-svg-cla' />
          <span className='t-j-api-model-box-title'>添加</span>
        </div>
      </div>
    </div>
  );
};

export default ArrayInput;

export class PubSub {
  private eMap: Map<string, Array<any>>;

  constructor() {
    this.eMap = new Map();
  }

  on(evt: string, handler: any) {
    if (typeof handler !== 'function') {
      throw new Error('Event handler is not a function.');
    }
    const list = this.eMap.get(evt);
    if (!list) {
      this.eMap.set(evt, []);
    }
    this.eMap.get(evt)!.push(handler);
  }

  off(evt: string, handler: any) {
    this.eMap.set(
      evt,
      (this.eMap.get(evt) || []).filter((h) => h !== handler)
    );
  }

  emit(evt: string, ...args: any[]) {
    this.eMap.get(evt)?.forEach((h) => h(...args));
  }
}

import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Input, Popconfirm, Button } from 'antd';
import { ConfigPanelComponentProp } from '@htd/common';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { DesignContext } from 'views/Home/DesignContext';
import MyIcon from 'components/myIcon';

const OptionsColumns: React.FC<ConfigPanelComponentProp> = ({ item }) => {
  const { forceUpdate } = useContext(DesignContext);

  // 表格列
  const dataSource: any[] = useMemo(
    () => item?.props?.options?.value || [],
    [item?.props?.options?.value]
  );

  const update = useCallback(() => {
    if (item.props) {
      item.props.options.value = cloneDeep(item?.props?.options?.value);
      // console.log(item.props.options);
      forceUpdate?.(item?.id);
    }
  }, [forceUpdate, item]);

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index) => {
      // @ts-ignore
      dataSource[index][fieldName] = value;
      update();
    },
    [dataSource, update]
  );

  // 删除行
  const handleDeleteLine = useCallback(
    (record, index) => {
      item.props && item.props.options.value.splice(index, 1);
      update();
    },
    [item.props, update]
  );

  /**
   * 新增字段
   */
  const handleAddLine = useCallback(() => {
    const createData = {
      value: '',
      meaning: '',
    };
    dataSource.push(createData);
    update();
  }, [dataSource, update]);

  const columns: ColumnsType<any> = [
    {
      title: '值',
      dataIndex: 'value',
      align: 'center',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='value'>
            <Input
              onBlur={(e) => {
                // 判断是否为空
                if (e.target.value) {
                  // 筛选是否存在重复字段
                  const list = dataSource.filter(
                    (d) => d.value === e.target.value && d.value !== value
                  );
                  if (list.length)
                    record.$form.setFields([
                      { name: 'value', errors: ['值不能重复'] },
                    ]);
                  else {
                    record.$form.setFields([{ name: 'value', errors: [] }]);
                    handleChangeValue('value', record, e.target.value, index);
                  }
                } else {
                  record.$form.setFields([
                    { name: 'value', errors: ['值不能为空'] },
                  ]);
                }
              }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '含义',
      align: 'center',
      dataIndex: 'meaning',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='meaning'>
            <Input
              onBlur={(e) => {
                handleChangeValue('meaning', record, e.target.value, index);
              }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '操作',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            <MyIcon type='icon-a-delete2x' className='left1-svg1-cla1' />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='htd-aside-right-columns-button-wrapper'>
        <Button
          icon={<PlusOutlined />}
          size='middle'
          type='text'
          onClick={handleAddLine.bind(this)}
        >
          添加值
        </Button>
      </div>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        showHeader={Boolean(dataSource?.length)}
      />
    </>
  );
};

export default OptionsColumns;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isButton = exports.isContainer = exports.isDsComponent = exports.isC7n = exports.isC7nPro = void 0;
var constants_1 = require("./constants");
var isC7nPro = function (comp) {
    return (comp === null || comp === void 0 ? void 0 : comp.lib) === 'choerodon-ui/pro';
};
exports.isC7nPro = isC7nPro;
function isC7n(comp) {
    return (comp === null || comp === void 0 ? void 0 : comp.lib) === 'choerodon-ui';
}
exports.isC7n = isC7n;
function isDsComponent(comp) {
    return constants_1.dsComponentsList.includes((comp === null || comp === void 0 ? void 0 : comp.name) || '') && (0, exports.isC7nPro)(comp);
}
exports.isDsComponent = isDsComponent;
function isContainer(componentType) {
    return ['inline-container', 'container', 'layout'].includes(componentType);
}
exports.isContainer = isContainer;
function isButton(componentName) {
    return ['Button', 'ExcelExport'].includes(componentName);
}
exports.isButton = isButton;

import { FC, cloneElement, useContext, useMemo } from 'react';
import { Tabs as ProTabs } from 'choerodon-ui/pro';
import { useState } from 'react';
import { DesignContext } from 'views/Home/DesignContext';
import { emptyArray } from 'utils';
import cls from 'classnames';

const Tabs: FC = (props: any) => {
  const { settingItem } = useContext(DesignContext);
  const TabPaneList = props.children?.props?.children || emptyArray;
  const [activeKey, SetActiveKey] = useState('0');
  const onChange = (item: any) => {
    SetActiveKey(item);
  };

  useMemo(() => {
    TabPaneList.forEach((item: any, index: any) => {
      if (item.key === settingItem?.id) {
        SetActiveKey(index.toString());
      }
    });
  }, [TabPaneList, settingItem?.id]);

  return (
    <div className='htd-placeholder-tabs tabs-over-flow'>
      {cloneElement(
        props.children,
        {},
        TabPaneList.length && [
          <ProTabs
            key='tabs'
            defaultActiveKey={activeKey}
            onChange={onChange}
            type={props.type}
            tabPosition={props.tabPosition}
            className={cls(
              {
                'htd-placeholder-tabs-content-margin-left':
                  props.tabPosition === 'left',
              },
              {
                'htd-placeholder-tabs-content-margin-right':
                  props.tabPosition === 'right',
              }
            )}
          >
            {TabPaneList.map((tabpane: any, index: number) => {
              return (
                <ProTabs.TabPane
                  tab={props?.columns[index]?.label || `${index}`}
                  key={index}
                  disabled={props?.columns[index]?.disabled}
                >
                  {tabpane}
                </ProTabs.TabPane>
              );
            })}
          </ProTabs>,
        ]
      )}
    </div>
  );
};

export { Tabs };

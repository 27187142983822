/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Description: 行操作事件预置逻辑， 默认的函数参数为(record)
 */
import { TemplateParamType } from '@htd/common';
import { clickForChangeRoute, clickForDelete, clickForEditor, clickForQuery, clickForSave, goToHzeroTabDomain, openPageModal, } from './events';
const LineEvents = [
    {
        code: 'CLICK_FOR_QUERY',
        name: '点击重新查询数据源',
        requiredParam: [
            {
                name: '数据源',
                index: 'dataSet',
                type: TemplateParamType.dataSetName,
            },
        ],
        otherParam: [
            {
                name: '查询条件',
                index: 'search',
                describe: 'eg: [{key: status, value: create}, {key: {orderId}, value: record.get("orderId")}]',
                type: TemplateParamType.keyValueArray,
            },
        ],
        function: clickForQuery,
    },
    {
        code: 'CLICK_FOR_EDITOR',
        name: '点击进入行编辑模式',
        function: clickForEditor,
    },
    {
        code: 'CLICK_FOR_CHANGEROUTE',
        name: '点击跳转页面',
        requiredParam: [
            {
                name: '路径',
                index: 'path',
                describe: '如需获得当前行内部数据，可输入【/xx/xx/:id】后在路径参数进行配置id取值【record.get("id")】',
                type: TemplateParamType.string,
            },
            {
                name: '页签名称',
                index: 'name',
                type: TemplateParamType.string,
            },
        ],
        otherParam: [
            {
                name: '查询参数',
                index: 'search',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '路径参数',
                index: 'local',
                type: TemplateParamType.keyValueArray,
            },
        ],
        function: clickForChangeRoute,
    },
    {
        code: 'OPEN_HZERO_TAB_DOMIAN',
        name: '模型下页面跳转',
        describe: '当前业务模型下多页面之间的跳转配置',
        requiredParam: [
            {
                name: '页签编码',
                index: 'code',
                describe: '设计器内部多页面对应的页面编码',
                type: TemplateParamType.pageList,
            },
            {
                name: '页签路由',
                index: 'path',
                describe: 'Hzero系统中页面访问路径',
                type: TemplateParamType.string,
            },
            {
                name: '页签名称',
                index: 'name',
                describe: 'Hzero系统前端打开时页签的显示名称',
                type: TemplateParamType.string,
            },
        ],
        otherParam: [
            {
                name: '查询参数',
                index: 'search',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '路径参数',
                index: 'local',
                type: TemplateParamType.keyValueArray,
            },
            {
                name: '当前页面打开',
                index: 'openInCurrent',
                describe: '默认不勾选，打开新页签',
                type: TemplateParamType.boolean,
            },
        ],
        function: goToHzeroTabDomain,
    },
    {
        code: 'CLICK_FOR_SAVE',
        name: '点击保存当前行',
        requiredParam: [
            {
                name: '接口地址',
                index: 'url',
                describe: '租户信息：{tenantId}，用户信息：{currentUserId}',
                type: TemplateParamType.string,
            },
            {
                name: '接口类型',
                index: 'method',
                describe: 'POST,PUT,DELETE',
                type: TemplateParamType.string,
                defaultValue: 'POST',
            },
        ],
        function: clickForSave,
    },
    {
        code: 'CLICK_FOR_DELETE',
        name: '点击删除当前行',
        requiredParam: [
            {
                name: '接口地址',
                index: 'url',
                describe: '租户信息：{tenantId}，用户信息：{currentUserId}',
                type: TemplateParamType.string,
            },
            {
                name: '接口类型',
                index: 'method',
                describe: 'POST,PUT,DELETE',
                type: TemplateParamType.string,
                defaultValue: 'POST',
            },
        ],
        function: clickForDelete,
    },
    {
        code: 'CLICK_FOR_OPENMODAL',
        name: '点击打开弹框',
        describe: '根据弹框组件的key打开弹框',
        requiredParam: [
            {
                name: '选择弹框',
                index: 'modalFlag',
                describe: '需要打开的弹框的组件唯一标识',
                type: TemplateParamType.modal,
            },
        ],
        function: openPageModal,
    },
    {
        code: 'CLICK_FOR_CUSTOMER',
        name: '自定义行为',
    },
];
export default LineEvents;

import { Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import { Collapse as ProCollapse } from 'components/placeholders';
import { CollapsePanel as ProCollapsePanel } from 'components/placeholders';
import * as uuid from 'uuid';
import tabsConfig from './TabsConfig';

export const Collapse: Mapping = {
  name: 'Collapse',
  type: 'container',
  lib: 'choerodon-ui',
  iconCode: 'Collapse',
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '折叠面板',
      label: '标题',
      hidden: true,
    },
    defaultActiveKey: {
      type: 'object',
      value: undefined,
      label: '初始化选中的key',
      hidden: true,
    },
    expandIconPosition: {
      type: 'collection',
      label: '图标位置',
      value: 'left',
      options: [
        { label: '左', value: 'left' },
        { label: '右', value: 'right' },
        { label: '文字右', value: 'text-right' },
      ],
    },
    bordered: {
      type: 'collection',
      label: '样式',
      value: true,
      options: [
        { label: '边框', value: true },
        { label: '无边框', value: false },
      ],
    },
    accordion: {
      type: 'boolean',
      value: false,
      label: '手风琴模式',
    },
    trigger: {
      type: 'collection',
      label: '触发位置',
      value: 'header',
      options: [
        { label: '面板', value: 'header' },
        { label: '图标', value: 'icon' },
      ],
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，折叠面板处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
    columns: {
      type: 'object',
      label: '折叠面板',
      hidden: true,
      value: [
        {
          dataIndex: `CollapsePanel_${uuid.v4().slice(0, 5)}`,
          label: '折叠面板',
          defaultActive: true,
          disabled: false,
        },
        {
          dataIndex: `CollapsePanel_${uuid.v4().slice(0, 5)}`,
          label: '折叠面板',
          defaultActive: true,
          disabled: false,
        },
      ],
    },
  },
  canDrop(target) {
    return !['Collapse', 'Panel'].includes(target?.name);
  },
  onDrop(item) {
    return item?.name === 'Panel';
  },
  placeholderComponent: ProCollapse,
  configPanelComponent: tabsConfig,
  children: [],
};

export const CollapsePanel: Mapping = {
  name: 'Panel',
  type: 'container',
  lib: 'choerodon-ui',
  from: 'Collapse.Panel',
  iconCode: 'Collapse',
  fixed: true,
  props: {
    ...BASE_PROPS_TYPES,
    label: {
      type: 'string',
      value: '子折叠面板',
      label: '标题',
      ignore: true,
    },
  },
  canDrop(target) {
    return false;
  },
  placeholderComponent: ProCollapsePanel as any,
  children: [],
};

/*
 * @Author: your name
 * @Date: 2021-08-25 20:15:08
 * @LastEditTime: 2021-09-02 11:10:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /hzero-code-generator/packages/designer/src/mapping/container/Modal/ModalSizeInput.ts
 */
import React, { FC } from 'react';
import { Form, Input } from 'antd';
import './index.less';

export const ModalSizeInput: FC<any> = () => {
  return (
    <div className='modal-size'>
      <Form.Item name={['modalSize', 'width']} className='modal-size-item'>
        <Input placeholder='宽度(px)' style={{ display: 'inline-block' }} />
      </Form.Item>
      <Form.Item name={['modalSize', 'height']} className='modal-size-item'>
        <Input placeholder='高度(px)' style={{ display: 'inline-block' }} />
      </Form.Item>
    </div>
  );
};

import { Mapping } from '@htd/common';
import { Div as DivPlaceholder } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Div: Mapping = {
  name: 'div',
  iconCode: 'Div',
  type: 'container',
  lib: '',
  title: '块容器',
  props: {
    ...BASE_PROPS_TYPES,
    className: {
      type: 'string',
      value: '',
      label: '样式限定',
      showHelp: true,
      help: '设置className样式',
    },
    width: {
      type: 'string',
      value: '',
      label: '宽度',
      showHelp: true,
      help: '支持px、%、auto等',
    },
    height: {
      type: 'string',
      value: '',
      label: '高度',
      showHelp: true,
      help: '支持px、%、auto等',
    },
    textAlign: {
      type: 'string',
      value: '',
      label: '文本对齐方式',
      showHelp: true,
      help: '支持left、right、auto等',
    },
    backgroundColor: {
      type: 'string',
      value: '',
      label: '背景颜色',
      showHelp: true,
      help: '支持十六进制、rgba、单词等有效颜色值',
    },
  },
  placeholderComponent: DivPlaceholder,
  children: [],
};

/*
 * @Author: wen.chen02@hand-china.com
 * @Description: 变量输入框
 */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { CodeArea } from 'choerodon-ui/pro';
import { Modal, Button, Row, Col, Collapse, Input } from 'antd';
import { Input as ProInput } from 'choerodon-ui';
import { FunctionOutlined, CaretRightOutlined } from '@ant-design/icons';
// import JSFormatter from 'choerodon-ui/pro/lib/code-area/formatters/JSFormatter';
// import 'choerodon-ui/pro/lib/code-area/lint/javascript';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import MyIcon from 'components/myIcon';
import './index.less';
import { useRef } from 'react';
import { DsProps, ObjectType, templateVariables } from '@htd/common';
import { DesignContext } from 'views/Home/DesignContext';
import { cloneDeep } from 'lodash';

const { Search } = ProInput;
type ExtraVariableType = ObjectType<{
  name: string;
  code: string;
  usage?: JSX.Element | string;
}>;

interface VariableInputProps {
  globalDsConfig?: DsProps[];
  value?: any;
  render?: any;
  onChange?: (v: any) => void;
  extraVariable?: ExtraVariableType;
  example?: React.ReactNode;
}

const VariableInput: React.FC<VariableInputProps> = (props) => {
  const [isVariable, setIsVariable] = useState(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState<string | undefined>();
  useEffect(() => {
    let value = props.value;
    if (typeof value === 'object' && value?.__isVariable) {
      value = value.value;
      setIsVariable(true);
    } else {
      setIsVariable(false);
    }
    setValue(value);
  }, [props.value]);
  const onChange = useMemo(() => props.onChange, [props.onChange]);

  const setVariableModel = useCallback(() => {
    setShow(true);
  }, []);
  const setUnVariableModel = useCallback(() => {
    setShow(false);
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      const value = event?.target ? event?.target?.value : event;
      // 解决插入子输入组件引发onchage响应卡顿
      if (!props.children) {
        setValue(value);
      }
      onChange?.(value);
    },
    [onChange, props.children]
  );

  const handleVariableModalOk = useCallback(
    (code: string) => {
      setValue(code);
      let __isVariable = false;
      if (code && code !== '') {
        // 如果代码不为空，表明为变量，否则不是变量
        __isVariable = true;

        setIsVariable(true);
        onChange?.({
          __isVariable,
          value: code,
        });
      } else {
        onChange?.(undefined);
      }
    },
    [onChange]
  );

  const handleVariableModalClear = useCallback(() => {
    setValue(undefined);
    setIsVariable(false);
    setShow(false);
    // @ts-ignore
    onChange?.(undefined);
  }, [onChange]);

  const modalValue = isVariable ? value : undefined;

  const comp =
    props.children && !isVariable ? (
      <>
        {React.cloneElement(props.children as React.ReactElement, {
          disabled: isVariable,
          onChange,
          value,
        })}
        <FunctionOutlined
          className='htd-variable-input-icon'
          onClick={setVariableModel}
        />
      </>
    ) : (
      <Input
        disabled={isVariable}
        onChange={handleInputChange}
        value={value}
        addonAfter={
          <div
            className='htd-variable-input-icon-fx'
            onClick={setVariableModel}
          >
            <FunctionOutlined />
          </div>
        }
      />
    );

  return (
    <>
      <div className='htd-variable-input'>
        {comp}
        {show && (
          <VariableModal
            globalDsConfig={props.globalDsConfig}
            show={show}
            onClose={setUnVariableModel}
            onOk={handleVariableModalOk}
            onClear={handleVariableModalClear}
            value={modalValue}
            extraVariable={props.extraVariable}
            example={props.example}
          />
        )}
      </div>
    </>
  );
};

interface VariableModalProps {
  globalDsConfig?: DsProps[];
  value?: string;
  show: boolean;
  onOk: (code: string) => any;
  onClose: () => any;
  onClear: () => any;
  extraVariable?: ExtraVariableType;
  example?: React.ReactNode;
}

const VariableModal: React.FC<VariableModalProps> = (props) => {
  const { show, extraVariable } = props;
  const [code, setCode] = useState<string | undefined>(props.value);

  const { cMap } = useContext(DesignContext);

  const [usage, setUsage] = useState<string>('');
  const ref = useRef<CodeArea>(null);
  const { onOk, onClose } = useMemo(
    () => ({
      onOk: props.onOk,
      onClose: props.onClose,
      // onClear: props.onClear,
    }),
    [props.onOk, props.onClose]
  );

  const dsConfigList = useMemo(() => {
    // 计算下ds中使用了新建ds的数据
    const res = cloneDeep(props.globalDsConfig) || [];
    if (props.globalDsConfig) {
      props.globalDsConfig.forEach((dsc) => {
        if (dsc.bindComponent && dsc.bindComponent.length > 0) {
          dsc.bindComponent.forEach((dscb) => {
            const comp = cMap.get(dscb);
            if (comp && comp.props?.createNewDs.value === true) {
              const key = comp.props?.['data-hcg_flag'].value;
              const dsName = dsc.dsName;
              if (!res.find((r) => r.dsName === `${dsName}${key}`)) {
                res.push({
                  dsName: `${dsName}${key}`,
                } as DsProps);
              }
            }
          });
        }
      });
    }
    return res;
  }, [props.globalDsConfig, cMap]);

  const handleCodeChange = useCallback((value) => {
    setCode(value);
  }, []);

  const handleOk = useCallback(() => {
    // if (code) {
    onOk(code as string);
    onClose();
    // }
  }, [code, onOk, onClose]);

  // const handleClear = useCallback(() => {
  //   setCode(undefined);
  //   const editor = ref.current?.element.editor;
  //   editor.setValue('');
  //   onClear();
  // }, [onClear]);

  const handleMenuCilck = useCallback(
    (v: any) => {
      if (code) {
        const editor = ref.current?.element.editor;
        const pos = editor.getCursor();
        editor.replaceRange(v.code, pos);
      } else {
        setCode(v.code);
      }
      setUsage(v.usage);
    },
    [code]
  );

  const footer = [
    // <Button onClick={handleClear} key='clear' className='left-button-box'>
    //   清除
    // </Button>,
    <Button onClick={onClose} key='cancel'>
      取消
    </Button>,
    <Button onClick={handleOk} key='ok' type='primary'>
      确定
    </Button>,
  ];

  /**
   * 代码域配置
   */
  const codeAreaOptions = useMemo(
    () => ({
      // theme: 'material',
      mode: 'javascript',
      styleActiveLine: true,
      autofocus: true,
      lineNumbers: true,
      lineWrapping: false,
      height: '300px',
    }),
    []
  );

  const handleSetDsValue = (dsConfig: DsProps) => {
    handleMenuCilck({ code: `this.getDs('${dsConfig.dsName}')` });
  };

  return (
    <Modal
      visible={show}
      title='变量取值'
      footer={footer}
      closable={false}
      width='51.9%'
      wrapClassName='htd-variable-input-modal-wrap'
    >
      {props.example && (
        <div className='htd-variable-input-modal-example'>
          当前参数输入示例: <br />
          {props.example}
        </div>
      )}
      <Row className='variable-top-menu-row'>
        <Col span={6} style={{ marginRight: '16px' }}>
          <div className='htd-variable-input-modal-title'>参考选择</div>
        </Col>
        <Col span={18}>
          <div className='htd-variable-input-modal-title'>值配置</div>
        </Col>
      </Row>
      <Row style={{ height: 400 }} className='variable-middle-menu-row'>
        <Col span={6} className='variable-middle-menu-row-col-left'>
          <div className='htd-variable-input-modal-chosepanel'>
            <div className='htd-variable-input-modal-menu'>
              <div className='search-box'>
                <Search
                  placeholder='搜索'
                  className='htd-aside-left-search'
                  // onSearch={(value) => setValue(value)}
                  enterButton={<MyIcon type='icon-a-search2x' />}
                  style={{ marginBottom: '8px' }}
                />
              </div>
              <Collapse
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className='htd-config-panel'
                defaultActiveKey={['dataSet', 'system']}
              >
                {!!dsConfigList.length && (
                  <Collapse.Panel key='dataSet' header='数据源' forceRender>
                    {dsConfigList.map((dsConfig) => (
                      <div
                        key={dsConfig.dsName}
                        onClick={handleSetDsValue.bind(this, dsConfig)}
                        className='data-list-item'
                      >
                        {dsConfig.dsName}
                      </div>
                    ))}
                  </Collapse.Panel>
                )}
                <Collapse.Panel key='system' header='系统参数' forceRender>
                  {Object.values(templateVariables).map((v) => (
                    <div
                      key={v.code}
                      onClick={() => {
                        handleMenuCilck(v);
                      }}
                      className='data-list-item'
                    >
                      {v.name}
                    </div>
                  ))}
                </Collapse.Panel>
                {extraVariable && (
                  <Collapse.Panel key='other' header='其他'>
                    {Object.values(extraVariable).map((v) => (
                      <div
                        key={v.code}
                        onClick={() => {
                          handleMenuCilck(v);
                        }}
                        className='data-list-item'
                      >
                        {v.name}
                      </div>
                    ))}
                  </Collapse.Panel>
                )}
              </Collapse>
              {/* <Menu mode='inline' defaultOpenKeys={['dataSet', 'system']}>
                {!!dsConfigList.length && (
                  <Menu.SubMenu key='dataSet' title='数据源'>
                    {dsConfigList.map((dsConfig) => (
                      <Menu.Item
                        key={dsConfig.dsName}
                        onClick={handleSetDsValue.bind(this, dsConfig)}
                      >
                        {dsConfig.dsName}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )}
                <Menu.SubMenu key='system' title='系统参数'>
                  {Object.values(templateVariables).map((v) => (
                    <Menu.Item
                      key={v.code}
                      onClick={() => {
                        handleMenuCilck(v);
                      }}
                    >
                      {v.name}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
                {extraVariable && (
                  <Menu.SubMenu key='other' title='其他'>
                    {Object.values(extraVariable).map((v) => (
                      <Menu.Item
                        key={v.code}
                        onClick={() => {
                          handleMenuCilck(v);
                        }}
                      >
                        {v.name}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )}
              </Menu> */}
            </div>
          </div>
        </Col>
        <Col span={18}>
          <div className='htd-variable-input-modal-config'>
            <div className='htd-variable-input-modal-code-wapper'>
              <CodeArea
                ref={ref}
                options={codeAreaOptions}
                value={code}
                onChange={handleCodeChange}
                // formatter={JSFormatter}
              />
            </div>
            {!!usage && (
              <div className='htd-variable-input-modal-example htd-variable-input-modal-code-helper'>
                <div className='htd-variable-input-modal-title title-yf'>
                  用法
                </div>
                {typeof usage === 'string' ? (
                  <div
                    className='title-ms'
                    dangerouslySetInnerHTML={{ __html: usage }}
                  />
                ) : (
                  usage
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default VariableInput;

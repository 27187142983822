import { Attributes, createElement, ReactNode } from 'react';
import { DesignConfig, Mapping, PropTypesObj } from '@htd/common';
import { HTD_PROPS_HOLDER } from 'utils/constants';
import { Universal } from '@/components/placeholders';
import DropArea from '@/components/DragDrop/DropArea';
import { cloneDeep } from 'lodash';

export const processPropsHolder = (value: any): PropTypesObj => {
  const result: PropTypesObj = {};
  const random = Math.floor(Math.random() * 100).toString(10);
  if (value) {
    Object.keys(value).forEach((k) => {
      const { type, value: v } = value[k];
      const realValue =
        typeof v === 'string'
          ? v.replace(new RegExp(HTD_PROPS_HOLDER, 'g'), random)
          : v;
      result[k] = { ...value[k], type, value: realValue };
    });
  }
  return result;
};

export const renderComponent = (
  tree: DesignConfig,
  mapping: Mapping,
  children?: any,
  dropAreaOptions?: any
): ReactNode => {
  const { props = {}, placeholderComponent, id, dsKey } = mapping;

  const dsConfig = tree.dsConfig.find((i) => i.dsKey === dsKey);

  return createElement(
    placeholderComponent || Universal,
    {
      ...Object.keys(props).reduce(
        (old, next) => ({
          ...old,
          [next === 'children' ? 'shadowChildren' : next]:
            next === 'dataSet' && dsConfig
              ? cloneDeep(dsConfig)
              : cloneDeep(props[next].value),
        }),
        {}
      ),
      designerProps: { id },
    } as Attributes,
    dropAreaOptions
      ? createElement(DropArea, dropAreaOptions, children)
      : children
  );
};

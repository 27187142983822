import { createContext } from 'react';
import { DesignComponent } from '@htd/common';
import { MappingListItem } from '../../mapping/util';

export type DragEndResult = {
  source: {
    index: number;
    id: string | number;
  };
  destination: {
    id: string;
    // index: string;
  };
};

export type ReorderResult = {
  targetIndex: number;
  item: any;
  targetContainer: string;
  targetDisplay: string;
};

export type DropResult = {
  from: string;
  to: string;
  data: MappingListItem | DesignComponent;
  index: number;
};

export interface DragDropContextValue {
  onDragEnd?: (result: DragEndResult) => any;
  onReOrder?: (result: ReorderResult) => any;
  onReOrderReady?: (result: ReorderResult) => any;
  onDrop?: (result: DropResult) => any;
  /**
   * onDrop 调用前执行。返回 false 阻止 onDrop 事件执行
   */
  beforeDrop?: (result: DropResult) => boolean;
  onClearPlaceholder?(): any;
}

export const DragDropContext = createContext<DragDropContextValue>({} as any);

export const DragDropProvider = DragDropContext.Provider;

export const DragDropConsumer = DragDropContext.Consumer;

import { dropItemToList, generateCanvasComponentById } from 'utils';

const ehok = {};
// 模拟拖拽组件生成
const createDefTable = (
  { to, index, from },
  cMap,
  forceUpdate,
  onSetting,
  tree,
  ds,
  data
) => {
  const tableCompId = 'htd-advanced-pro-table';
  const tableCompName = '表格';
  const comp = generateCanvasComponentById(tableCompId, to);
  // 向ds/backendconfig/cols中同步字段数据
  if (comp.props?.columns?.value) {
    comp.dsKey = data.dsKey;
    const dsconfig = tree.dsConfig.find((dsc) => dsc.dsKey === data.dsKey);
    comp.props.columns.value = ds.selected.map((s) => {
      // 对于Type类型，要同步到ds中
      const dsf = dsconfig.fields.find((f) => f.fieldKey === s.get('fieldKey'));
      if (dsf) {
        dsf.fieldType = s.get('type');
        dsf.type = s.get('type');
      }
      return {
        applicationFor: {
          hidden: true,
          ignore: true,
          label: '传入标识字段',
          type: 'object',
          value: {},
        },
        fieldKey: s.get('fieldKey'),
        hideable: true,
        name: s.get('name'),
        renderer: undefined,
        titleEditable: true,
        tooltip: 'none',
        type: s.get('type'),
      };
    });

    // 补充必要属性
    comp.props.createNewDs = {
      value: false,
      type: 'boolean',
      label: '独立Ds',
      hidden: true,
      ignore: true,
    };
  }
  // 插入画布
  dropItemToList(
    {
      from,
      to,
      index,
      data: { name: tableCompName, id: tableCompId, mapping: comp },
    },
    cMap,
    forceUpdate,
    onSetting,
    tree,
    true
  );
};

ehok.table = createDefTable;

const createDefForm = (
  { to, index, from },
  cMap,
  forceUpdate,
  onSetting,
  tree,
  ds,
  data
) => {
  const formCompId = 'htd-container-pro-form';
  const fromCompName = '表单';
  const comp = generateCanvasComponentById(formCompId, to);
  comp.dsKey = data.dsKey;
  // 处理子节点
  const fromChildren = ds.selected.map((s) => {
    const type = s
      .get('type')
      .charAt(0)
      .toLowerCase()
      .concat(s.get('type').substring(1));

    const chComp = generateCanvasComponentById(`hcg-base-pro-${type}`, comp.id);
    if (chComp) {
      chComp.props.label.value = s.get('label');
      chComp.props.fieldKey.value = s.get('fieldKey');
      chComp.props.name.value = s.get('name');
      // cMap添加组件
      cMap.set(chComp.id, chComp);
    }
    return chComp;
  });
  // 补充必要属性
  comp.props.createNewDs = {
    value: false,
    type: 'boolean',
    label: '独立Ds',
    hidden: true,
    ignore: true,
  };
  comp.children = fromChildren.filter((c) => c);
  // 向ds/backendconfig中同步字段数据 接口获取数据时已经同步 - 此处同步props到ds field中
  const currentds = tree.dsConfig.find((d) => d.dsKey === comp.dsKey);
  currentds.fields.forEach((f) => {
    const ccf = comp.children.find(
      (cc) => cc.props?.fieldKey.value === f.fieldKey
    );
    if (ccf) {
      f.props = ccf.props;
    }
  });
  // 插入画布
  dropItemToList(
    {
      from,
      to,
      index,
      data: { name: fromCompName, id: formCompId, mapping: comp },
    },
    cMap,
    forceUpdate,
    onSetting,
    tree,
    true
  );
};

ehok.form = createDefForm;

const craeteComp = (compType, ds, ctx, data, dropRes = {}) => {
  const { tree, forceUpdate, onSetting, cMap } = ctx;
  const { index, to } = dropRes;
  if (Object.keys(ehok).includes(compType)) {
    ehok[compType](
      {
        from: 'list',
        to: to || 'hzero-content',
        index: index || tree.children[1].children.length,
      },
      cMap,
      forceUpdate,
      onSetting,
      tree,
      ds,
      data
    );
  }
};
export default craeteComp;

import { TimeLine as TimeLinePlaceholder } from 'components/placeholders';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ConfigPanelComponentProp, Mapping } from '@htd/common';
import { BASE_PROPS_TYPES } from 'utils/prop-types';
import SetDataForm from './SetDataForm';

export const TimeLine: Mapping = {
  name: 'Timeline',
  title: '时间轴',
  type: 'inline-block',
  lib: 'choerodon-ui',
  iconCode: 'timeline',
  props: {
    ...BASE_PROPS_TYPES,
    dataConfigType: {
      type: 'collection',
      label: '数据模式',
      options: [
        { value: 'api', label: '接口' },
        { value: 'fixed', label: '固定值' },
      ],
      value: 'fixed',
      ignore: true,
    },
    dataQuery_url: {
      hidden: true,
      type: 'string',
      label: '数据接口配置',
      ignore: true,
    },
    dataConfig_arr: {
      hidden: true,
      ignore: true,
      type: 'object',
      label: '数据固定值配置',
      value: [
        { color: 'green', value: '服务单创建' },
        { color: 'green', value: '服务单接收' },
        { color: 'green', value: '进入处理队列' },
        { color: 'red', value: '服务单异常-保单人不匹配' },
      ],
    },
  },
  placeholderComponent: TimeLinePlaceholder,
  configPanelComponent: (props: ConfigPanelComponentProp) => {
    return (
      <Collapse
        defaultActiveKey={1}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel key='1' header='数据设置'>
          <SetDataForm {...props} />
        </Collapse.Panel>
      </Collapse>
    );
  },
  children: [],
};

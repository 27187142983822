/*
 * @Author: wen.chen02@hand-china.com
 * @Description: hzero渲染工具包映射
 */
import moment from 'moment';
import { Badge, message } from 'choerodon-ui';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@htd/common';

const statusMap = ['error', 'success'];

/**
 * 将列渲染成链接模式
 * @param {C7nPro.Record} record
 */
export function clickRender(params) {
  const handleClick = () => {
    message.info('点击行触发行为，此处仅预览');
  };
  return (
    <a onClick={handleClick} href>
      {params.text}
    </a>
  );
}

/**
 * 返回 启用/禁用 对应的多语言 并加上状态
 * @param {0|1} v 启用状态
 * return 1 ? enable(多语言) : disabled(多语言)
 */
export function enableRender(v) {
  return (
    <Badge
      status={statusMap[v === 1 ? 1 : 0]}
      text={v === 1 ? '启用' : '禁用'}
    />
  );
}

/**
 * 返回 是/否 多语言 并加上对应的状态
 * @param {1|any} v 值
 * @return 1 -> yes(多语言), other -> no(多语言)
 */
export function yesOrNoRender(v) {
  return (
    <Badge status={statusMap[v === 1 ? 1 : 0]} text={v === 1 ? '是' : '否'} />
  );
}

/**
 * 优先级渲染(低中高)
 * @param {number} [v = 0] - 优先级数值
 * @returns ReactNode
 */
export function priorityRender(v = 0) {
  // eslint-disable-next-line no-nested-ternary
  return v < 35 ? (
    <span
      style={{ display: 'inline-block', width: '65%', background: '#52c41a' }}
    >
      低
    </span>
  ) : v > 65 ? (
    <span
      style={{ display: 'inline-block', width: '65%', background: '#f5222d' }}
    >
      高
    </span>
  ) : (
    <span
      style={{ display: 'inline-block', width: '65%', background: '#faad14' }}
    >
      中
    </span>
  );
}

/**
 * 日期(date)的 render
 * @param {?String} dateStr - 日期的字符串
 */
export function dateRender(dateStr) {
  // console.log(dateStr);
  return dateStr && moment(dateStr).format(DEFAULT_DATE_FORMAT);
}

/**
 * 时间(dateTime)的 render
 * @export
 * @param {?String} dateTimeStr - 时间日期的字符串
 * @returns
 */
export function dateTimeRender(dateTimeStr) {
  return dateTimeStr && moment(dateTimeStr).format(DEFAULT_DATETIME_FORMAT);
}

/**
 * 时间(time)的 render
 * @param {?String} timeStr - 时间日期的字符串
 */
export function timeRender(timeStr) {
  return timeStr && moment(timeStr).format(DEFAULT_TIME_FORMAT);
}

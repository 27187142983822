/*
 * @Author: wen.chen02@hand-china.com
 * @Description: formatterCollections工具适配
 */

function formatterCollections() {
  return (comp) => comp;
}

export default formatterCollections;

import { FC, useMemo, useContext } from 'react';
import DataSetEvents from '@htd/helper/lib/templates/dataset-events';
import LineEvents from '@htd/helper/lib/templates/line-events';
import { DesignContext } from 'views/Home/DesignContext';
import { DATA_HCG_FLAG } from 'utils/constants';
import CommonActionPanel from 'components/CommonActionPanel';
import { cloneDeep } from 'lodash';

const EventAttribute: FC<{
  record: any;
}> = ({ record }) => {
  const { settingItem: item, tree, forceUpdate } = useContext(DesignContext);

  const eventFields: any[] = useMemo(() => {
    return item?.props?.eventFields?.value;
  }, [item?.props?.eventFields?.value]);

  const eventFieldProps = useMemo(() => {
    const props: any = {};
    for (const field of eventFields) {
      props[`${field.name}_${field.eventType}`] = {
        value: {
          eventCode: field.eventCode,
          eventName: field.funcObject.eventName,
          eventParam: field.funcObject.eventParam,
        },
      };
    }
    return props;
  }, [eventFields]);

  const eventList = useMemo(() => {
    return DataSetEvents.filter((i) => i.code && i.name && i.function);
  }, []);

  const cellEventList = useMemo(() => {
    return LineEvents.filter(
      (i) =>
        i.code &&
        i.name &&
        i.function &&
        ['CLICK_FOR_CHANGEROUTE', 'OPEN_HZERO_TAB_DOMIAN'].includes(i.code)
    );
  }, []);

  const handleActionOk = (e: any) => {
    const eventFieldIndex = eventFields.findIndex(
      (i: any) => i.eventType === e.eventName && i.name === record.name
    );
    const event = {
      eventType: e.eventName,
      eventCode: e.eventValue.eventCode,
      name: record.name,
      funcObject: e.eventValue,
    };

    if (eventFieldIndex === -1) {
      eventFields.push(event);
    } else {
      eventFields.splice(eventFieldIndex, 1, event);
    }

    if (item?.props?.eventFields?.value) {
      item.props.eventFields.value = cloneDeep(eventFields);
      forceUpdate?.(item?.id);
    }
  };

  const handleDeleteAction = (eventName: string) => {
    const eventIndex = eventFields.findIndex(
      (i) => i.eventType === eventName && i.name === record.name
    );
    if (eventIndex > -1) {
      eventFields.splice(eventIndex, 1);

      if (item?.props?.eventFields?.value) {
        item.props.eventFields.value = cloneDeep(eventFields);
        forceUpdate?.(item?.id);
      }
    }
  };

  return (
    <section className='file-edit-panel-content-back'>
      <CommonActionPanel
        fieldName={record.name}
        configProps={eventFieldProps as any}
        compFlag={item?.props?.[DATA_HCG_FLAG].value}
        globalDsConfig={tree.dsConfig}
        avaliableEvents={[
          {
            name: 'update',
            meaning: '值更新',
            list: eventList,
            defaultParams: eventList.map((e) => ({
              code: e.code,
              defaultParam: {
                eventSourceName: record.name,
              },
            })),
          },
          {
            name: 'onClick',
            meaning: '点击事件',
            list: cellEventList,
            defaultParams: cellEventList.map((e) => ({
              code: e.code,
              defaultParam: {
                eventSourceName: record.name,
              },
            })),
          },
        ]}
        onActionOk={handleActionOk}
        onDeleteEvent={handleDeleteAction}
      />
    </section>
  );
};

export default EventAttribute;

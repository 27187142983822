import { MappingListItem } from '../util';
import { Footer } from './Footer';

export const advancedList: Array<MappingListItem> = [
  {
    id: 'htd-advanced-search',
    name: '搜索框',
  },
  {
    id: 'htd-advanced-pagination',
    name: '分页器',
  },
  {
    id: 'htd-advanced-footer',
    name: '底部通栏',
    mapping: Footer,
  },
  {
    id: 'htd-advanced-iframe',
    name: 'iFrame',
  },
  {
    id: 'htd-advanced-qrcode',
    name: '二维码',
  },
  {
    id: 'htd-advanced-barCode',
    name: '条形码',
  },
  {
    id: 'htd-advanced-chart',
    name: '图表',
  },
  {
    id: 'htd-advanced-breadcrumb',
    name: '面包屑',
  },
  {
    id: 'htd-advanced-carousel',
    name: '轮播图',
  },
];

import React, { FC } from 'react';
import { Switch as ProSwitch } from 'choerodon-ui/pro';

const Switch: FC<any> = (props) => {
  return (
    <div className='htd-placeholder-form-field'>
      <div className='htd-placeholder-form-field-label'>{props.label}</div>
      <ProSwitch className='htd-placeholder-button' readOnly>
        {props.children}
      </ProSwitch>
    </div>
  );
};

export { Switch };

/*
 * @Author: wen.chen02@hand-china.com
 * @Description: 布局组件
 */
import React, { forwardRef } from 'react';

const Layout: React.FC<any> = forwardRef((props, ref: any) => {
  return <div ref={ref}>{props.children}</div>;
});

export { Layout };

import { FC, useContext } from 'react';
import { Range as FormRange } from 'choerodon-ui/pro';
import cls from 'classnames';
import { DesignContext } from 'views/Home/DesignContext';

const Range: FC<any> = (props) => {
  const { cMap } = useContext(DesignContext);
  const item = cMap.get(props.designerProps.id)

  const clsNames = cls(
    'htd-placeholder-form-field',
    item?.validate?.required && 'htd-placeholder-form-field-required',
  );
  return (
    <div className={clsNames}>
      <div className='htd-placeholder-form-field-label'>{props.label}</div>
      <FormRange
        className='htd-placeholder-button'
        value={5}
        min={0}
        max={10}
        readOnly
      />
    </div>
  );
};

export { Range };

import { useStore } from '@/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ObjectType } from '@htd/common';
import cls from 'classnames';
import { DragBox } from 'components/DragDrop';
import './index.less';
import {
  // addLocalCategoryEffect,
  BlockModuleState,
  // deleteLocalBlockEffect,
  fetchInlineBlockListEffect,
  fetchInlineCategoryEffect,
  // fetchLocalBlockListEffect,
  // fetchLocalCategoryEffect,
  // updateLocalCategoryEffect,
} from '@/store/reducers/block';
import { deleteInlineBlock, addInlineCategory } from '@/services/block-service';
import { useMemoState } from 'hooks';
import { AddLocalCategoryModalContent } from 'components/AddLocalCategoryModal';
import { useRef } from 'react';
import { Input, message } from 'antd';
import RenameModal from './RenameModal';
import { Modal, Popconfirm } from 'antd';
import { ReactComponent as EditIcon } from './img/edit@2x.svg';
import { ReactComponent as DeleteIcon } from './img/delete@2x.svg';
import { ReactComponent as SearchIcon } from './img/search@2x.svg';
import { ReactComponent as PlusIcon } from './img/plus.svg';

const getListId = (cate: string) => `htd-block-list-${cate}`;

const BlockList: FC = () => {
  const { state } = useStore<BlockModuleState>((state) => state.block);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [renameItem, setRenameItem] = useState(undefined);
  const [newCate, setNewCate] = useState('');
  const [search, setSearch] = useState('');
  useEffect(() => {
    // fetchLocalCategoryEffect(); // 不使用本地区块
    fetchInlineCategoryEffect();
    window.$htd.on('block/category', fetchInlineCategoryEffect);
    return () => {
      window.$htd.on('block/category', fetchInlineCategoryEffect);
    };
  }, []);
  const [selected, setSelected] = useMemoState<string>(
    'block_category_selected',
    ''
  );

  const localList: ObjectType<any[]> = state.local || {};
  const handleSelectCategory = useCallback(
    (cate: any) => {
      setSelected(cate?.id);
      fetchInlineBlockListEffect({
        categoryId: cate?.id,
      });
    },
    [setSelected]
  );
  useEffect(() => {
    const container = listContainerRef.current;
    if (container) {
      container.querySelector(`#${getListId(selected)}`)?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selected]);

  // 添加在线分类
  const handleAddInlineCategory = async (newData: string, cate?: any) => {
    await addInlineCategory({
      objectVersionNumber: cate?.objectVersionNumber,
      id: cate?.id,
      _token: cate?._token,
      // categoryCode: newData,
      categoryName: newData,
    }).then((res) => {
      if (res) {
        fetchInlineCategoryEffect();
        if (cate?.id) {
          fetchInlineBlockListEffect({
            categoryId: cate?.id,
          });
        }
      }
    });
    // window.$htd.emit('block/category');
    message.success('添加分类成功!!');
  };

  const getBlockActions = (block: any, category: string) => {
    return [
      {
        title: '重命名',
        icon: <EditIcon />,
        action: () => setRenameItem({ ...block, category }),
      },
      // {
      //   title: '另存为',
      //   icon: <SaveOutlined />,
      //   action: () => {},
      // },
      {
        title: '删除',
        icon: <DeleteIcon />,
        action: () => {
          Modal.confirm({
            content: `确定删除区块 ${block?.name} ?`,
            onOk: async () => {
              // deleteLocalBlockEffect({ name: block.name, category })
              await deleteInlineBlock({
                id: block.id,
                type: block.category,
                data: block.blockData,
                _token: block._token,
              });
              fetchInlineBlockListEffect({
                categoryId: block?.categoryId,
              });
              message.success('删除区块成功');
            },
          });
        },
      },
    ];
  };
  const addCategoryForm = (
    <AddLocalCategoryModalContent
      noLayout
      value={newCate}
      defaultValue=''
      onChange={(v) => setNewCate(v)}
    />
  );
  const handleOnClick = (value1: string) => {
    return (
      <AddLocalCategoryModalContent
        noLayout
        value={newCate}
        defaultValue={value1}
        onChange={(v) => setNewCate(v)}
      />
    );
  };
  return (
    <section className='htd-blocks-container'>
      <header className='htd-blocks-container-header'>
        <span className='htd-blocks-container-header-search'>
          <Input
            placeholder='搜索区块'
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon />
        </span>
      </header>
      <aside className='htd-blocks-aside'>
        <ul className='htd-blocks-aside-list'>
          {/* ...state.localCategoryList */}
          {state?.inlineCategoryList?.map((cate: any) => (
            <li
              key={cate?.categoryName}
              className={cls('htd-blocks-aside-list-item', 'block-one-item', {
                'htd-blocks-aside-list-item-active': selected === cate.id,
              })}
              onClick={() => handleSelectCategory(cate)}
            >
              {cate?.categoryName}
              <Popconfirm
                title={() => handleOnClick(cate?.categoryName)}
                placement='topRight'
                overlayClassName='htd-blocks-new-category'
                okButtonProps={{
                  disabled:
                    !newCate ||
                    [
                      // ...state.localCategoryList,
                      ...state?.inlineCategoryList?.map(
                        (ele: any) => ele?.categoryName
                      ),
                    ].includes(newCate),
                }}
                onConfirm={async () => {
                  handleAddInlineCategory(newCate, cate);
                }}
                // onConfirm={() => handleAddLocalCategoryEffect(newCate)}
                onCancel={() => setNewCate('')}
              >
                <div className='block-one-item-icon'>
                  <EditIcon />
                </div>
              </Popconfirm>
            </li>
          ))}
          <li
            className={cls(
              'htd-blocks-aside-list-item',
              'htd-blocks-aside-list-item-add'
            )}
          >
            <Popconfirm
              title={addCategoryForm}
              placement='topRight'
              overlayClassName='htd-blocks-new-category'
              okButtonProps={{
                // disabled: !newCate || state.localCategoryList.includes(newCate),
                disabled:
                  !newCate ||
                  state.inlineCategoryList
                    ?.map((ele: any) => ele?.categoryName)
                    .includes(newCate),
              }}
              onConfirm={() => handleAddInlineCategory(newCate, {})}
              onCancel={() => setNewCate('')}
            >
              <div>
                <PlusIcon />
                分类
              </div>
            </Popconfirm>
          </li>
        </ul>
      </aside>
      <main ref={listContainerRef} className='htd-blocks-content'>
        {/* ...state.localCategoryList */}
        {/* {!isEmpty(localList) && state?.inlineCategoryList
          .map((ele: any) => ele?.categoryName)
          ?.map((cate) => (
            <div
              id={getListId(cate)}
              key={cate}
              className='htd-blocks-content-list'
            >
              <h4 className='htd-blocks-content-list-title'>{ }</h4>
              {(localList[cate] || [])
                .filter((b) => new RegExp(`${search}`, 'i').test(b.name))
                .map((block) => (
                  <div
                    key={block.mapping.id}
                    title={block.name}
                    className='htd-blocks-content-list-container'
                  >
                    <div className='htd-blocks-content-list-container-actions'>
                      {getBlockActions(block, cate).map(({ icon, action }) => (
                        <span
                          className='htd-blocks-content-list-container-actions-action'
                          onClick={action}
                        >
                          {icon}
                        </span>
                      ))}
                    </div>
                    <DragBox
                      context='list'
                      data={block}
                      className='htd-blocks-content-list-item'
                      canDrag={!!block.mapping}
                    >
                      <div>{block.name}</div>
                    </DragBox>
                  </div>
                ))}
            </div>
          ))} */}
        {Object.keys(localList).map((e) => (
          <div id={getListId(e)} key={e} className='htd-blocks-content-list'>
            <h4 className='htd-blocks-content-list-title'>{e}</h4>
            {(localList[e] || [])
              .filter((b) => new RegExp(`${search}`, 'i').test(b.name))
              .map((block) => (
                <div
                  key={block.mapping.id}
                  title={block.name}
                  className='htd-blocks-content-list-container'
                >
                  <div className='htd-blocks-content-list-container-actions'>
                    {getBlockActions(block, e).map(({ icon, action }) => (
                      <span
                        className='htd-blocks-content-list-container-actions-action'
                        onClick={action}
                      >
                        {icon}
                      </span>
                    ))}
                  </div>
                  <DragBox
                    context='list'
                    data={block}
                    className='htd-blocks-content-list-item'
                    canDrag={!!block.mapping}
                  >
                    <div>{block.name}</div>
                  </DragBox>
                </div>
              ))}
          </div>
        ))}
      </main>
      <RenameModal
        block={renameItem}
        visible={!!renameItem}
        onClose={() => setRenameItem(undefined)}
        fetchInlineBlockListEffect={fetchInlineBlockListEffect}
      />
    </section>
  );
};

export default BlockList;

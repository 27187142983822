import { PropTypesObj } from '@htd/common';
// import * as uuid from 'uuid';
import { BASIC_PROPS, OTHER_PROPS } from './config';
import { DATA_HCG_FLAG, HTD_PROPS_HOLDER } from './constants';

export const BASE_PROPS_TYPES: PropTypesObj = {
  [DATA_HCG_FLAG]: {
    type: 'string',
    value: undefined,
    label: '唯一标识',
    disabled: true,
  },
};

export const FORM_ITEM_TYPES: PropTypesObj = {
  colSpan: {
    label: '所占列数',
    type: 'number-range',
    value: 1,
    min: 1,
    max: 3,
    group: OTHER_PROPS,
  },
  newLine: {
    label: '换行',
    type: 'boolean',
    value: false,
    showHelp: true,
    help: '开启后，当前字段在新的一行显示',
    group: OTHER_PROPS,
  },
  applicationFor: {
    label: '传入标识字段',
    type: 'object',
    hidden: true,
    ignore: true,
    value: {},
  },
  // width: {
  //   label: '宽度',
  //   type: 'number',
  //   value: '',
  // },
};

export const getInputCommon = (type: string, meaning: string): PropTypesObj => {
  return {
    label: {
      type: 'string',
      value: `${meaning}${HTD_PROPS_HOLDER}`,
      label: '标题',
      help: '只在Form下生效',
      showHelp: true,
      isDS: true,
    },
    fieldKey: {
      type: 'string',
      // value: uuid.v4(),
      value: '',
      label: '标题',
      hidden: true,
      ignore: true,
      isDS: true,
    },
    name: {
      type: 'string',
      value: `${type}${HTD_PROPS_HOLDER}`,
      label: '数据字段',
    },
    disabled: {
      type: 'boolean',
      label: '禁用',
      value: undefined,
      valueConfigurable: true,
      variableExample: (
        <pre>
          (params)=&gt;{'\u007b'}
          let flag = false; // 你的逻辑... flag = true; return flag;{'\u007d'}
        </pre>
      ),
      isDS: true,
    },
    conditionHidden: {
      type: 'boolean',
      label: '隐藏',
      value: false,
      conditionType: 'negative',
      valueConfigurable: true,
      variableExample: (
        <pre>this.getDs('formds').current?.get('fieldA')==="NEW"</pre>
      ),
      ignore: true,
    },
    clearButton: {
      type: 'boolean',
      value: true,
      label: '清除按钮',
    },
    placeholder: {
      type: 'string',
      label: '输入提示',
      value: undefined,
      isDS: true,
    },
    bind: {
      type: 'string',
      label: '字段绑定',
      value: '',
      isDS: true,
      ignore: true,
      group: BASIC_PROPS,
    },
  };
};

// 表格默认属性

export const BASE_COLUMN_PROPS = {
  hideable: true,
  titleEditable: true,
  tooltip: 'none',
  renderer: undefined,
  applicationFor: {
    label: '传入标识字段',
    type: 'object',
    hidden: true,
    ignore: true,
    value: {},
  },
};

export const BASE_DS_PROPS = {
  type: 'string',
};

/**
 * 字段类型值列表
 */
export const FIELD_TYPE_LIST = [
  { value: 'string', meaning: '文本' },
  { value: 'intl', meaning: '多语言' },
  { value: 'number', meaning: '数字' },
  { value: 'boolean', meaning: '布尔' },
  { value: 'date', meaning: '日期' },
  { value: 'dateTime', meaning: '日期时间' },
  { value: 'time', meaning: '时间' },
  { value: 'object', meaning: '对象' },
];

/**
 * 列固定值列表
 */
export const COLUMN_LOCK_LIST = [
  { value: 'null', meaning: '无' },
  { value: 'left', meaning: '左' },
  { value: 'right', meaning: '右' },
];

/**
 * 列对齐方式值列表
 */
export const COLUMN_ALIGN_LIST = [
  { value: 'null', meaning: '无' },
  { value: 'left', meaning: '左' },
  { value: 'center', meaning: '中' },
  { value: 'right', meaning: '右' },
];

/**
 * 列提示信息值列表
 */
export const COLUMN_TOOLTIP_LIST = [
  { value: 'none', meaning: '无' },
  { value: 'overflow', meaning: '超出范围' },
  { value: 'always', meaning: '一直' },
];

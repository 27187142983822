/*
 * @Author: your name
 * @Date: 2021-11-02 15:08:20
 * @LastEditTime: 2021-11-12 14:18:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hzero-code-generator\packages\designer\src\mapping\data\Table\DynamicTablePanel.tsx
 */
import React, { useState } from 'react';
import { Button } from 'antd';
import DynamicConfigModal from './DynamicConfigModal';
import { ConfigPanelComponentProp } from '@htd/common';

const DynamicTablePanel: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenDynamicConfigModal = () => {
    setModalVisible(true);
  };

  const handleCloseDynamicConfigModal = () => {
    setModalVisible(false);
  };

  const handleDynamicConfigModalOk = (data: any) => {
    if (item.props) {
      item.props.dynamicTableConfig.value = data;
    }
    setModalVisible(false);
    forceUpdate?.();
  };

  const handleDeleteDynamicConfig = (index: number) => {
    if (item.props) {
      item.props.dynamicTableConfig.value =
        item.props.dynamicTableConfig.value.splice(index, 1);
    }
    forceUpdate?.();
  };

  return (
    <div className='htd-common-action-panel common-action-panel-box'>
      <div className='htd-aside-right-columns-button-wrapper jus-conn'>
        <Button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleOpenDynamicConfigModal}
        >
          动态列配置
        </Button>
      </div>
      {item.props?.dynamicTableConfig.value.length > 0 && (
        <div>
          当前配置
          <br />
          {item.props?.dynamicTableConfig.value.map((fc: any) => (
            <div>{fc.fxDesc}</div>
          ))}
        </div>
      )}
      {modalVisible && (
        <DynamicConfigModal
          modalVisible={modalVisible}
          onCancel={handleCloseDynamicConfigModal}
          onOk={handleDynamicConfigModalOk}
          onDelete={handleDeleteDynamicConfig}
          item={item}
        />
      )}
    </div>
  );
};

export default DynamicTablePanel;

import { Table, Form } from 'antd';
import * as uuid from 'uuid';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import React, { useRef, useEffect, useCallback } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { noDataIcon } from '../../utils/unifiedExportIcon';

import './index.less';

const type = 'DraggableBodyRow';

// const DraggableBodyRow = ({
//   index,
//   moveRow,
//   className,
//   draggable,
//   ...restProps
// }: any) => {
//   const ref = useRef();
//   const [{ isOver, dropClassName }, drop] = useDrop({
//     accept: type,
//     collect: (monitor: DropTargetMonitor<any>) => {
//       const { index: dragIndex } = monitor.getItem() || {};
//       if (dragIndex === index) {
//         return {};
//       }
//       return {
//         isOver: monitor.isOver(),
//         dropClassName:
//           dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
//       };
//     },
//     drop: (item: any) => {
//       moveRow(item.index, index);
//     },
//   });
//   const [, drag] = useDrag({
//     type,
//     item: { index },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });

//   drop(drag(ref));

//   return (
//     <tr
//       // @ts-ignore
//       ref={ref}
//       className={`${className}${isOver ? dropClassName : ''}`}
//       {...restProps}
//       style={{ ...restProps.style, cursor: 'grab' }}
//     />
//   );
// };
const DraggableBodyTd = ({
  index,
  moveRow,
  className,
  draggable,
  ...restProps
}: any) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: DropTargetMonitor<any>) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index
            ? ' only-table-icon-drop-over-downward'
            : ' only-table-icon-drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <td
      // @ts-ignore
      ref={ref}
      className={`ant-table-cell ${className}${isOver ? dropClassName : ''}`}
      {...restProps}
    />
  );
};

const EditableRow = ({
  draggable,
  record,
  rowValueChange,
  index,
  ...restProps
}: any) => {
  const [form] = Form.useForm();
  if (record) {
    record.$form = form;
  }
  const { moveRow, ...noDraggableProps } = restProps;
  const [firstTd, ...otherTd] = noDraggableProps.children;
  const { children, ...otherProps } = firstTd.props;
  // 将 td 的图标 和 配置 分别结构出来 用otherProps.render("",otherProps.record, index)显示 其余配置传入拖动配置

  const row = draggable ? (
    // <DraggableBodyRow {...restProps} index={index} />
    // 仅图标 拖动
    <tr {...noDraggableProps}>
      {
        <DraggableBodyTd {...otherProps} moveRow={moveRow} index={index}>
          {/* 对应table 的写法为 render */}
          {otherProps.render('', otherProps.record, index)}
        </DraggableBodyTd>
      }
      {otherTd}
    </tr>
  ) : (
    <tr {...noDraggableProps} />
  );
  const handleValuesChange = useCallback(
    (values: any) => {
      if (rowValueChange) {
        for (let key in values) {
          record[key] = values[key];
        }
        rowValueChange(record, index);
      }
    },
    [record, rowValueChange, index]
  );
  useEffect(() => {
    form.resetFields();
  }, [form, record]);
  return (
    <Form
      form={form}
      name={uuid.v4()}
      initialValues={record}
      component={false}
      onValuesChange={handleValuesChange}
    >
      {row}
    </Form>
  );
};

interface EditTableProps {
  columns: ColumnsType<any>;
  dataSource?: any[];
  pagination?: false | TablePaginationConfig;
  size?: SizeType;
  bordered?: boolean;
  className?: string;
  onDragEnd?: any;
  draggable?: boolean;
  showHeader?: boolean;
  rowValueChange?: (v: any, index: number) => void;
}

const EditTable: React.FC<EditTableProps> = ({
  columns,
  dataSource,
  pagination,
  size,
  bordered,
  className,
  onDragEnd,
  draggable,
  showHeader,
  rowValueChange,
}) => {
  const realDataSource = dataSource?.map((d, i) => ({
    ...d,
    __key: String(i),
  }));

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRow = dataSource?.[dragIndex];
    dataSource?.splice(dragIndex, 1);
    dataSource?.splice(hoverIndex, 0, dragRow);

    onDragEnd?.(dataSource);
  };
  const onRow = (record: any, index?: number) => ({
    record,
    index,
    moveRow,
    draggable,
  });
  const rowClassName = () => 'editable-row';
  const components = {
    body: {
      row: (props: any) =>
        realDataSource && realDataSource.length ? (
          <EditableRow {...props} rowValueChange={rowValueChange} />
        ) : (
          <div></div>
        ),
    },
  };
  // 设置 表格无数据时 插画
  if (showHeader) {
    // && realDataSource && realDataSource.length
    return (
      <Table
        columns={columns}
        dataSource={realDataSource}
        components={components}
        onRow={onRow as any}
        pagination={pagination}
        size={size}
        bordered={bordered}
        rowClassName={rowClassName}
        locale={{ emptyText: '暂无数据' }}
        className={className}
        showHeader={showHeader}
        rowKey='__key'
      />
    );
  } else {
    return (
      <div className='table-null-right-box'>
        <img alt='No data' src={noDataIcon} />
        <span>暂无数据</span>
      </div>
    );
  }
  // return (
  //   <Table
  //     columns={columns}
  //     dataSource={realDataSource}
  //     components={components}
  //     onRow={onRow as any}
  //     pagination={pagination}
  //     size={size}
  //     bordered={bordered}
  //     rowClassName={rowClassName}
  //     locale={{ emptyText: '暂无数据' }}
  //     className={className}
  //     showHeader={showHeader}
  //     rowKey='__key'
  //   />
  // );
};

export default EditTable;

import { useCallback, useState } from 'react';
import { getSession, setSession } from 'utils';

const getSessionKey = (key: string) => `MEMO_STATE_${key}`;

/**
 * 记忆化的 useState，刷新页面时可以保持
 * @param key
 * @param initialValue
 * @returns
 */
export function useMemoState<T = any>(
  key: string,
  initialValue: T
): [T, (state: T | Function) => void] {
  const [state, setState] = useState<T>(
    () => getSession(getSessionKey(key)) || initialValue
  );
  const setMemoState = useCallback(
    (value: T | Function) => {
      if (typeof value === 'function') {
        setState((old) => {
          const finalState = (value as Function)(old);
          setSession(getSessionKey(key), finalState);
          return finalState;
        });
      } else {
        setState(value);
        setSession(getSessionKey(key), value);
      }
    },
    [key]
  );
  return [state, setMemoState];
}

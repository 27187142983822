import { Select } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as AddIcon } from './assets/add.svg';
import SelectRightImg from 'components/selectRightImg';

const CategorySelect: FC<{
  options: Array<{ label: string; value: any }>;
  onChange?: (v: any) => any;
  value?: any;
  onAdd?: () => any;
}> = ({ options, onChange, value, onAdd }) => {
  return (
    <div className='htd-category-select'>
      <Select
        value={value}
        options={options}
        onChange={onChange}
        suffixIcon={<SelectRightImg />}
      />
      <span className='htd-category-select-add' onClick={onAdd}>
        <AddIcon />
        <span>添加分类</span>
      </span>
    </div>
  );
};

export default CategorySelect;

import { Mapping } from '@htd/common';
import React, { forwardRef } from 'react';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Footer: Mapping = {
  name: 'Footer-NOCODE',
  iconCode: 'Footer',
  type: 'container',
  lib: '',
  title: '底部通栏',
  props: {
    ...BASE_PROPS_TYPES,
  },
  placeholderComponent: forwardRef((props: any, ref: any) => {
    return <></>;
  }),
  children: [],
};

import React from 'react';
import { observer } from 'mobx-react';
import {
  Modal,
  DataSet,
  Form,
  TextField,
  Lov,
  Switch,
  Select,
} from 'choerodon-ui/pro';
import { FieldType } from 'choerodon-ui/pro/lib/data-set/enum';
import { cloneDeep } from 'lodash';

/**
 * 业务实体
 */
export function openBusinessEntityModal({
  type = 'CREATE',
  entitys,
  data,
  handleOk,
}: {
  type: string;
  entitys?: any;
  data?: any;
  handleOk: Function;
}) {
  const parentEneitys = Object.values(entitys).filter((e: any) =>
    data
      ? data.tableName !== e.tableName &&
        !e.totalParentEntity?.includes(data.tableName)
      : true
  ) as any[];

  const options = new DataSet({
    data: parentEneitys,
    fields: [{ name: 'tableName' }, { name: 'entityName' }],
  });
  const ds = new DataSet({
    fields: [
      {
        name: 'isBaseTable',
        label: '基于表创建',
        type: FieldType.boolean,
        disabled: type !== 'CREATE',
        trueValue: 1,
        falseValue: 0,
        defaultValue: 0,
      },
      {
        name: 'tableName',
        label: '表名',
        required: true,
        disabled: type !== 'CREATE',
        dynamicProps: {
          pattern({ record }) {
            const isBaseTable = record.get('isBaseTable');
            if (isBaseTable) {
              return '';
            }
            return '^[a-z]+([_]?[a-z]+)*$';
          },
        },
        // pattern: '^[a-z]+([_]?[a-z]+)*$',
        defaultValidationMessages: {
          patternMismatch: '只允许小写字母以及下划线',
        },
      },
      {
        name: 'tableNameOfDatabase',
        label: '表名',
        disabled: type !== 'CREATE',
        dynamicProps: {
          required: ({ record }) => record.get('isBaseTable') === 1,
        },
        lovCode: 'HCGR.TABLE_QUERY',
        type: FieldType.object,
      },
      {
        name: 'entityName',
        label: '实体名称',
        required: true,
        disabled: type !== 'CREATE',
        // pattern: '^[A-Za-z]+$',
        pattern: '^[A-Za-z]?([a-z]+[A-Z]?)+$',
        defaultValidationMessages: {
          patternMismatch: '实体名称只能由字母组成,并且为驼峰类型',
        },
        dynamicProps: {
          // disabled: ({ record }) => record.get('isBaseTable') === 1,
        },
      },
      {
        name: 'tableDisplayName',
        label: '实体描述',
        required: true,
        dynamicProps: {
          // disabled: ({ record }) => record.get('isBaseTable') === 1,
        },
      },
      {
        name: 'parentEntity',
        label: '父实体',
        options,
        textField: 'entityName',
        valueField: 'tableName',
      },
      {
        name: 'relType',
        label: '关系类型',
        lookupCode: 'HCGR.RELEATIONSHIP_TYPE',
        dynamicProps: {
          required: ({ record }) => {
            return record.get('parentEntity');
          },
        },
      },
    ],
    events: {
      update: ({ record, name, value, oldValue }: any) => {
        if (name === 'tableNameOfDatabase' && value) {
          record.set('tableName', value.tableName);
          // record.set('entityName', value.entityName);
          // 去掉 自动生成名称
          // const str = replaceUnderLine(value.tableName);
          // record.set(
          //   'entityName',
          //   str.slice(0, 1).toUpperCase() + str.slice(1)
          // );
          record.set('tableDisplayName', value.tableDisplayName);
          record.set('datasourceCode', value.datasourceCode);
        } else if (['parentEntity'].includes(name) && value) {
          handelParentEntityChangeFunc(record, value, oldValue);
        }
        // 去掉 自动生成名称
        // if (name === 'tableName' && record.get('isBaseTable') === 0 && value) {
        //   const str = replaceUnderLine(value);
        //   record.set(
        //     'entityName',
        //     str.slice(0, 1).toUpperCase() + str.slice(1)
        //   );
        // }
      },
    },
  });

  // 实体父级关系变化
  const handelParentEntityChangeFunc = (
    record: any,
    value: string,
    oldValue: string
  ) => {
    // 实体数据列表
    const currentEneitysList = Object.values(entitys) as any[];
    // 找到当前数据的 父级
    const obj = currentEneitysList.filter((f: any) => value === f.tableName);
    if (obj?.length) {
      // 查看父级是否有父级
      const parentEntityDataNameList = obj[0].totalParentEntity || [];
      let itemDataList = cloneDeep(parentEntityDataNameList);
      // 已经关联 然后改变关系
      if (oldValue) {
        const currParentList = record.get('totalParentEntity') || [];
        // 改变当前数据的关系
        currParentList.forEach((i: any, index: number) => {
          if (i === oldValue) {
            currParentList.splice(index, 1, value);
          }
        });
        // 找到当前关系的 所有 后续关系
        currentEneitysList.forEach((A: any) => {
          const list = A.totalParentEntity || [];
          if (list.includes(data?.tableName) && list.includes(oldValue)) {
            // 改变后续数据的关系
            list.forEach((i: any, index: number) => {
              if (i === oldValue) {
                list.splice(index, 1, value);
              }
            });
          }
        });
      } else {
        // 新增关系
        itemDataList.push(value);
        record.set('totalParentEntity', itemDataList);
        // 若有后续关系 则 修改后续关系
        // 找到当前关系的 所有 后续关系
        currentEneitysList.forEach((A: any) => {
          const list = A.totalParentEntity || [];
          if (list.includes(data?.tableName)) {
            // 将新父级关系添加进去
            list.unshift(value);
          }
        });
      }
    }
  };

  if (data) {
    ds.loadData([data]);
  } else {
    ds.create();
  }
  Modal.open({
    title: `${type === 'CREATE' ? '新建' : '编辑'}业务实体`,
    children: <BusinessEntityModal dataSet={ds} />,
    okText: '确定',
    onOk: async () => {
      const f = await ds.validate();
      if (f) {
        const data = ds.current?.toJSONData();
        data.isNew = type === 'CREATE';
        return handleOk(data);
      } else {
        return f;
      }
    },
  });
}

const BusinessEntityModal: React.FC<any> = observer(
  ({ dataSet }: { dataSet: DataSet }) => {
    return (
      <Form dataSet={dataSet}>
        <Switch name='isBaseTable' />
        {dataSet.current?.get('isBaseTable') === 0 && (
          <TextField name='tableName' />
        )}
        {dataSet.current?.get('isBaseTable') === 1 && (
          <Lov name='tableNameOfDatabase' />
        )}
        <TextField name='entityName' />
        <TextField name='tableDisplayName' />
        <Select name='parentEntity' />
        <Select name='relType' />
      </Form>
    );
  }
);

/**
 * @description: 将带有下划线的字母转换成驼峰
 * @param {*} val 带下划线字母
 * @param {*} char 下划线字符_
 * @return {*} 驼峰
 */
export const replaceUnderLine = (name: String) => {
  const loweName = name.toLocaleLowerCase();
  let str = loweName;
  const [, ...orther] = loweName.split('_');
  if (orther && orther.length >= 1) {
    str = orther.join('_');
  }
  // eslint-disable-next-line no-useless-escape
  return str.replace(/\_(\w)/g, (_all, letter) => {
    return letter.toUpperCase();
  });
};

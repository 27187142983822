import React from 'react';
import { Modal, DataSet, Form, TextField, Lov } from 'choerodon-ui/pro';
import { FieldIgnore, FieldType } from 'choerodon-ui/pro/lib/data-set/enum';
/**
 * 业务模型
 */
export function openBusinessModelModal({
  type = 'CREATE',
  data,
  handleOk,
}: {
  type: string;
  data?: any;
  handleOk: Function;
}) {
  const ds = new DataSet({
    fields: [
      {
        name: 'modelCode',
        label: '模型编码',
        disabled: type !== 'CREATE',
        required: true,
        pattern: '^[A-Z][A-Z._]{1,29}$',
        defaultValidationMessages: {
          patternMismatch:
            '全大写并且必须以字母开头，可包含"_"、"."，并且不超过30位',
        },
      },
      { name: 'modelName', label: '模型名称', required: true },
      { name: 'description', label: '模型描述' },
      {
        name: 'service',
        label: '服务名',
        lovCode: 'HADM.ROUTE.SERVICE_PATH.ORG',
        type: FieldType.object,
        // lovPara:{lovCode: 'HADM.ROUTE.SERVICE_PATH'},
        required: true,
        textField: 'name',
        valueField: 'serviceCode',
        ignore: FieldIgnore.always,
      },
      {
        name: 'serviceName',
        bind: 'service.serviceCode',
      },
      {
        name: 'serviceRoute',
        bind: 'service.name',
      },
      {
        name: 'tenantId',
        defaultValue: 0,
      },
    ],
  });
  if (data) {
    ds.loadData([data]);
  } else {
    ds.create();
  }
  Modal.open({
    title: `${type === 'CREATE' ? '新建' : '编辑'}业务模型`,
    children: <BusinessModelModal dataSet={ds} />,
    okText: '确定',
    onOk: async () => {
      const f = await ds.validate();
      if (f) {
        handleOk(ds.current?.toJSONData());
      } else {
        return f;
      }
    },
  });
}

const BusinessModelModal: React.FC<any> = ({ dataSet }) => {
  return (
    <Form dataSet={dataSet}>
      <TextField name='modelCode' />
      <TextField name='modelName' />
      <TextField name='description' />
      <Lov name='service' />
    </Form>
  );
};

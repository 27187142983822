import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Collapse } from 'antd';
import { Icon } from 'choerodon-ui';
import { CaretRightOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import BaseAttribute from './BaseAttribute';

interface Props {
  record: any;
  index: number;
  setIndex: (index?: number) => any;
  onChange: (fieldName: string, record: any, value: any, index?: number) => any;
}

const FieldEditPanel: React.FC<Props> = ({
  record,
  index,
  setIndex,
  onChange,
}) => {
  const [[form]] = useState(AntdForm.useForm());

  /**
   * record变更时，重设form的值
   */
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...record,
    });
  }, [form, record]);

  /**
   * 关闭面板
   */
  const handleClosePanel = () => {
    setIndex(undefined);
  };

  /**
   * 变更字段值
   */
  const handleValuesChange = debounce((changedValues: any) => {
    for (const key in changedValues) {
      record[key] = changedValues[key];
      onChange(key, record, changedValues[key], index);
    }
  }, 500);
  return (
    <div className='htd-aside-right-table-field-edit-panel'>
      <header className='htd-aside-right-header'>
        <span>字段属性</span>
        <Icon type='close' onClick={handleClosePanel} />
      </header>
      <main className='htd-aside-right-main'>
        <Collapse
          defaultActiveKey={['base']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel key='base' header='基础属性'>
            <AntdForm
              form={form}
              labelAlign='left'
              onValuesChange={handleValuesChange}
            >
              <BaseAttribute
                currentColumn={record}
                onChange={handleValuesChange}
              />
            </AntdForm>
          </Collapse.Panel>
        </Collapse>
      </main>
    </div>
  );
};

export default FieldEditPanel;

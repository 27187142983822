import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import * as uuid from 'uuid';
import { cloneDeep } from 'lodash';
import { ObjectType } from '@htd/common';
import {
  callRemote,
  getCurrentOrganizationId,
  openTab,
  sourceTransform,
} from 'utils';
import { sendMessageToParent } from 'utils/message';
import { axios, ORGANIZATION_ID_KEY, getSession } from '../../utils';
import { Tooltip, Icon } from 'choerodon-ui';
import { Modal, DataSet, Form, TextField, Lov } from 'choerodon-ui/pro';
import PreviewContainer from 'components/PreviewContainer';
import { FunctionContextValue, FunctionProvider } from './FunctionContext';
import { notification } from 'antd';
import Page from './PageIndex';
import pageInfoDS from './pageInfoDS';
import './index.less';

/**
 * 向后端传递数据ModalJson格式调整
 */
function requestModalJsonData(data: any) {
  const modelJson = cloneDeep(data);
  // 1.移除模型code结构
  // 2.entitys转数组结构
  const values: any[] = Object.values(modelJson);
  if (modelJson && Object.values(modelJson).length > 0) {
    values[0].entitys = Object.values(values[0].entitys);
    if (values[0].entitys) {
      values[0].entitys.forEach((e: any) => {
        (e.column || []).forEach((c: any) => {
          if (!c.fieldKey) {
            notification.error({
              description: c.fieldName,
              message: 'fieldKey为空',
            });
          }
        });
      });
    }
    return values[0];
  }
  // 如果给后端传的是空，就先报个错，记录下操作步骤
  notification.warning({
    message: '未检测到业务模型配置',
  });
  return {};
}
/**
 * 接收后端ModalJson数据格式调整
 */
function responseModalJsonData(modelJson: string) {
  // 1.添加模型code结构
  // 2.entitys转对象
  let data = {};
  try {
    const model = JSON.parse(modelJson);
    const entitys: ObjectType = {};
    if (model.entitys instanceof Array) {
      model.entitys.forEach((d: any) => {
        entitys[d.tableName] = d;
      });
      model.entitys = entitys;
    }
    if (model.modelCode) {
      data = {
        [model.modelCode]: model,
      };
    }
  } catch {}
  return data;
}

const Home = () => {
  // 多页面数据结构
  const [pageList, setPageList] = useState<any>([
    {
      tree: {
        root: 'page',
        children: [],
        dsConfig: [],
      },
      pageId: uuid.v4(),
      pageCode: 'default',
      pageName: '默认页面',
      pagePath: 'Default',
      routerPath: '/default',
    },
  ]);
  const [backendConfig, setBackendConfig] = useState({}); // 模型实体数据
  const [functionData, setFunctionData] = useState<ObjectType>({}); // 功能数据结构，用于保存提交后的数据做同步
  const [currentPage, setCurrentPage] = useState<String>(pageList[0].pageId); // 当前定位的页面id
  const [preview, setPreview] = useState({
    visible: false,
    content: '',
    loading: false,
  }); // 页面预览数据

  const pageDS = useMemo(() => new DataSet({ ...pageInfoDS() }), []);

  const modalRender = useCallback(
    () => (
      // @ts-ignore
      <Form dataSet={pageDS} labelAlign='left'>
        <TextField
          name='pageCode'
          // @ts-ignore
          showHelp='tooltip'
          help='设计器内部多页面交互配置编码'
        />
        <TextField
          name='pageName'
          // @ts-ignore
          showHelp='tooltip'
          help='Hzero系统前端打开时页签的显示名称'
        />
        <TextField
          name='routerPath'
          // @ts-ignore
          showHelp='tooltip'
          help='Hzero系统中页面访问路径'
        />
        <TextField
          name='pagePath'
          // @ts-ignore
          showHelp='tooltip'
          help='代码插入项目后的文件路径'
        />
        <TextField
          name='intlPrefix'
          // @ts-ignore
          showHelp='tooltip'
          help='生成代码使用的多语言前缀'
        />
        <Lov name='pageTemplateLov' />
      </Form>
    ),
    [pageDS]
  );

  /**
   * @description: 获取url上面的query值
   * @param {string} name
   * @return {*}
   */
  const getQueryString = (name: string) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  /**
   * @description: 获取初始化数据
   */
  useEffect(() => {
    // 是否是预览模式
    const preview = getQueryString('preview');
    const pageCode = getQueryString('pageCode');
    if (preview === 'true' && pageCode) {
      const codeList: any = localStorage.getItem('PREVIEW_CODE_LIST');
      setPreview((old) => ({
        ...old,
        visible: true,
        content:
          JSON.parse(codeList).find((data: any) => data.pageCode === pageCode)
            ?.previewCode || '',
      }));
    } else {
      sendMessageToParent({ type: 'htd/mounted' });
      setTimeout(() => {
        const url = `/hcgr/v1/${
          getSession(ORGANIZATION_ID_KEY) || 0
        }/function-page-jsons/${getQueryString('functionId')}`;
        // const url2 = `/hcgr/v1/${
        //   getSession(ORGANIZATION_ID_KEY) || 0
        // }/functions?functionId=${getQueryString('functionId')}`;
        // axios.get(url2).then((res) => {
        //   console.log(res);
        // });
        axios
          .get(url)
          .then((res: any) => {
            if (res && !res.failed) {
              if (res.uiJson) {
                setPageList(JSON.parse(res.uiJson));
                setCurrentPage(JSON.parse(res.uiJson)[0].pageId);
              }
              if (res.modelJson) {
                setBackendConfig(responseModalJsonData(res.modelJson));
              }
              setFunctionData(res);
            }
          })
          .catch((err) => {
            notification.error({
              message: '提示',
              description: err.message,
            });
          });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description: 页面预览
   */
  const handlePreviewPage = async () => {
    setPreview((o) => ({ ...o, loading: true }));
    const previewCodeList: any = [];
    await pageListRef.current.reduce(async (total: any, data: any) => {
      await total;
      const code = await callRemote({
        type: 'htd/generate',
        payload: { ...data.tree, isPreview: true },
      });
      const content = sourceTransform(code) ?? '';
      previewCodeList.push({ pageCode: data.pageCode, previewCode: content });
    }, undefined);
    localStorage.setItem('PREVIEW_CODE_LIST', JSON.stringify(previewCodeList));
    setPreview((o) => ({ ...o, loading: false }));
    openTab({
      url: window.location.origin + window.location.pathname,
      query: {
        preview: true,
        pageCode: pageListRef.current[0].pageCode,
        tenantId: getCurrentOrganizationId(),
      },
    });
  };

  /**
   * @description: 插入项目
   */
  const handleInsertToProject = async () => {
    await pageListRef.current.reduce(async (total: any, data: any) => {
      await total;
      await callRemote({
        type: 'htd/generate',
        payload: { ...data.tree, isPreview: true, intlCode: data.intlPrefix },
      });
    }, undefined);
    sendMessageToParent({
      type: 'htd/insertToProject',
      payload: {
        pageList: JSON.parse(JSON.stringify(pageListRef.current)),
        modelJson: JSON.parse(
          JSON.stringify(requestModalJsonData(backendConfig))
        ),
      },
    });
    console.log(pageListRef.current);

    window.$htd.emit('insertToProject', pageListRef.current);
  };

  // 获取页面JSON
  const handleAxiosGetTree = async ({ tree, pageId }: any) => {
    let newTree = tree;
    let newPageId = pageId;
    const pageTemplateId = pageDS?.current?.get('pageTemplateId');
    if (pageTemplateId) {
      const url = `/hcgr/v1/${
        getSession(ORGANIZATION_ID_KEY) || 0
      }/hand-builder-page-template-infos/${pageTemplateId}`;
      const result: any = await axios.get(url);
      if (result && !result.failed) {
        const templateJsonData = JSON.parse(result.templateJsonData);
        newTree = templateJsonData?.tree;
        newPageId = uuid.v4();
      }
    }
    return {
      tree: newTree,
      pageId: newPageId,
    };
  };

  /**
   * @description: 页面切换事件
   * @param {*} pageConfig 当前页面的数据
   */
  const pageChange = useCallback((pageConfig: any) => {
    setCurrentPage(pageConfig.pageId);
  }, []);

  /**
   * @description: 新建编辑页面
   */
  const pageOperate = (pageConfig: any) => {
    if (pageConfig) {
      pageDS.loadData([
        {
          pageName: pageConfig.pageName,
          pageCode: pageConfig.pageCode,
          pagePath: pageConfig.pagePath,
          routerPath: pageConfig.routerPath,
          intlPrefix: pageConfig.intlPrefix,
        },
      ]);
    } else {
      pageDS.create({});
    }
    pageDS?.current
      ?.getField('pageTemplateLov')
      ?.set('lovCode', 'HCGR.PAGE_TEMPLATE');
    Modal.open({
      key: Modal.key(),
      title: '页面设置',
      children: modalRender(),
      style: {
        width: '32%',
      },
      closable: true,
      className: 'htd-page-config-modal',
      // drawer: true,
      onOk: async () => {
        const codeValidate = pageList
          .map((data: any) => data.pageCode)
          .includes(pageDS?.current?.get('pageCode'));
        if (await pageDS.validate()) {
          if (pageConfig) {
            if (
              codeValidate &&
              pageDS?.current?.get('pageCode') !== pageConfig.pageCode
            ) {
              notification.error({
                message: '提示',
                description: '页面编码重复，请重新输入！',
              });
              return false;
            }
            const pageTemplateId = pageDS?.current?.get('pageTemplateId');
            const { tree, pageId }: any = await handleAxiosGetTree(pageConfig);

            const newPage = pageList.map((data: any) =>
              data.pageId === pageConfig.pageId
                ? {
                    ...data,
                    tree,
                    pageId,
                    pageName: pageDS?.current?.get('pageName'),
                    pageCode: pageDS?.current?.get('pageCode'),
                    pagePath: pageDS?.current?.get('pagePath'),
                    routerPath: pageDS?.current?.get('routerPath'),
                    intlPrefix: pageDS?.current?.get('intlPrefix'),
                  }
                : data
            );
            setPageList(newPage);
            if (pageTemplateId) {
              setCurrentPage(pageId);
            }
          } else {
            if (codeValidate) {
              notification.error({
                message: '提示',
                description: '页面编码重复，请重新输入！',
              });
              return false;
            }
            const { tree, pageId }: any = await handleAxiosGetTree({
              tree: { root: 'page', children: [], dsConfig: [] },
              pageId: uuid.v4(),
            });
            const newPage = {
              pageId,
              pageName: pageDS?.current?.get('pageName'),
              pageCode: pageDS?.current?.get('pageCode'),
              pagePath: pageDS?.current?.get('pagePath'),
              routerPath: pageDS?.current?.get('routerPath'),
              intlPrefix: pageDS?.current?.get('intlPrefix'),
              tree,
            };

            setPageList([...pageList, newPage]);
            setCurrentPage(newPage.pageId);
          }
        } else {
          return false;
        }
      },
      onClose: () => {
        pageDS.reset();
      },
    });
  };

  /**
   * @description: 删除页面
   * @param {*} pageConfig 当前页面的数据 如果有值为编辑操作，反则新建
   * @return {*}
   */
  const deletePage = useCallback(
    (e, pageConfig) => {
      e.stopPropagation();
      Modal.confirm({
        title: '提示',
        children: <div>是否确定删除该页面？</div>,
      }).then((button: string) => {
        if (button === 'ok') {
          const newPageList = pageList.filter(
            (data: any) => data.pageId !== pageConfig.pageId
          );
          setPageList(newPageList);
          if (pageConfig.pageId === currentPage) {
            setCurrentPage(newPageList[0].pageId);
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageList]
  );

  // 划重点  这里这里, 解决state访问旧值的问题
  const functionDataRef = useRef(functionData);
  const pageListRef = useRef(pageList);
  const backendConfigRef = useRef(backendConfig);

  useEffect(() => {
    functionDataRef.current = functionData;
    pageListRef.current = pageList;
    backendConfigRef.current = backendConfig;
  }, [functionData, pageList, backendConfig]);

  /**
   * @description: 保存按钮
   */
  const saveFuntionConfig = () => {
    const url = `/hcgr/v1/${
      getSession(ORGANIZATION_ID_KEY) || 0
    }/function-page-jsons`;
    // const es1 = Object.values(backendConfig) || [];
    // const esData: any = es1.length ? es1[0] : {};
    // const es = esData.entitys || {};
    // const esList = Object.values(es) || [];
    // let flag = false;
    // esList.forEach((E: any) => {
    //   const column = E.column || [];
    //   column.forEach((c: any) => {
    //     if (c.primaryKey === 1) {
    //       flag = true;
    //     }
    //   });
    // });
    // let params = [
    //   {
    //     ...functionDataRef.current,
    //     functionId: getQueryString('functionId'),
    //     _status: functionDataRef.current.id ? 'update' : 'create',
    //     uiJson: JSON.stringify(pageListRef.current),
    //     modelJson: '',
    //   },
    // ];
    // if (!flag) {
    //   // 没有主键
    //   notification.warning({
    //     message: '警告',
    //     description: '存在实体未设置主键',
    //   });
    // } else {
    //   params[0].modelJson = JSON.stringify(requestModalJsonData(backendConfig));
    // }
    const uiJson = pageListRef.current.map((pc: any) => {
      pc.tree.intlCode = pc.intlPrefix;
      return pc;
    });

    const params = [
      {
        ...functionDataRef.current,
        functionId: getQueryString('functionId'),
        _status: functionDataRef.current.id ? 'update' : 'create',
        uiJson: JSON.stringify(uiJson),
        modelJson: JSON.stringify(
          requestModalJsonData(backendConfigRef.current)
        ),
      },
    ];
    return axios
      .post(url, params)
      .then((res: any) => {
        if (res && !res.failed) {
          setFunctionData({ ...functionData, ...res[0] });
          notification.success({
            message: '提示',
            description: '保存成功',
          });
        }
        return res;
      })
      .catch((err) => {
        notification.error({
          message: '提示',
          description: err.message,
        });
      });
  };

  /**
   * @description: 同步按钮
   */
  const syncFuntionConfig = () => {
    Modal.confirm({
      title: '业务模型同步',
      children: <div>同步前需保存配置，是否确认？</div>,
    }).then((button: string) => {
      if (button === 'ok') {
        saveFuntionConfig()
          .then((res) => {
            if (res && !res.failed) {
              return axios.post(
                `/hcgr/v1/${
                  getSession(ORGANIZATION_ID_KEY) || 0
                }/function-page-jsons/sync-model/${getQueryString(
                  'functionId'
                )}`,
                {
                  functionId: getQueryString('functionId'),
                }
              );
            }
          })
          .catch((err) => {
            notification.error({
              message: '提示',
              description: err.message,
            });
          });
      }
    });
  };

  /**
   * @description: 模型实体下的关联的ds列表数据
   * @return {*}
   */
  const dsArr = useMemo(() => {
    const entitys: any = Object.values(backendConfig)?.[0];
    const dsList = Object.values(entitys?.entitys || {}).map((data: any) => ({
      dsKey: data.dsKey,
      dsName: data.entityName,
      fields: data.column || [],
      apis: data.apis || [],
    }));
    return dsList;
  }, [backendConfig]);

  /**
   * @description: 作用：ds列表发生变更，会将列表数据和所有页面的dsConfig进行同步
   */
  useEffect(() => {
    pageList.forEach((data: any) => {
      dsArr.forEach((ds: any) => {
        const isExist = data.tree.dsConfig.find(
          (config: any) => config.dsKey === ds.dsKey
        );
        if (!isExist) {
          data.tree.dsConfig.push({
            dsName: ds.dsName,
            dsKey: ds.dsKey,
            fields: ds.fields || [],
            queryFields: [],
            transport: ds.apis.reduce((old: any, item: any) => {
              return { ...old, [item.key]: item };
            }, {}),
          });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsArr, pageList]);

  // context共享pageList数据
  const functionContext = useMemo<FunctionContextValue>(
    () => ({
      pageList,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageList]
  );

  return (
    <>
      {pageList.map((data: any) => {
        return (
          currentPage === data.pageId && (
            <>
              <div className='page-tabs'>
                {pageList.map((data: any) => (
                  <div
                    className={
                      currentPage === data.pageId
                        ? 'page-tabs-tab-active'
                        : 'page-tabs-tab'
                    }
                    onClick={() => pageChange(data)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      pageOperate(data);
                    }}
                  >
                    <Tooltip
                      title={`${data.pageName}${data.pageId}`}
                      placement='bottomLeft'
                      mouseEnterDelay={1}
                    >
                      <div className='page-title'>{data.pageName}</div>
                    </Tooltip>
                    {pageList.length > 1 && (
                      <div className='page-close'>
                        <Icon
                          type='close'
                          onClick={(e) => deletePage(e, data)}
                        />
                      </div>
                    )}
                  </div>
                ))}
                <div
                  className='add-page-button'
                  onClick={() => pageOperate('')}
                >
                  <Tooltip
                    title='新建页面'
                    placement='bottom'
                    mouseEnterDelay={1}
                  >
                    +
                  </Tooltip>
                </div>
              </div>
              <FunctionProvider value={functionContext}>
                <Page
                  key={data.pageId}
                  pageData={data}
                  saveFuntionConfig={saveFuntionConfig}
                  syncFuntionConfig={syncFuntionConfig}
                  pageList={pageList}
                  setPageList={setPageList}
                  backendConfig={backendConfig}
                  setBackendConfig={setBackendConfig}
                  handlePreviewPage={handlePreviewPage}
                  handleInsertToProject={handleInsertToProject}
                />
                <PreviewContainer
                  visible={preview.visible}
                  content={preview.content}
                />
              </FunctionProvider>
            </>
          )
        );
      })}
    </>
  );
};

export default Home;

import React, { FC, useMemo } from 'react';
import { DataSet, Table as ProTable } from 'choerodon-ui/pro';
// import { ColumnProps } from 'choerodon-ui/pro/lib/table/Column';

const mockData = Array.from({ length: 1 });

const Table: FC<any> = (props) => {
  const dataSet = useMemo(() => {
    const data_hcg_flag = props['data-hcg_flag'];
    const queryFieldsData = props.dataSet?.queryFields || [];
    let queryFieldData: any = [];
    queryFieldsData.forEach((af: any) => {
      const applicationFor = af.applicationFor;
      if (applicationFor) {
        const applicationForQueryData = applicationFor?.query || [];
        if (applicationForQueryData.length) {
          // 设置了applicationFor属性  则表示 这个查询条件属于某一特定ds
          applicationForQueryData.forEach((aqf: any) => {
            if (
              data_hcg_flag &&
              aqf.cmp === data_hcg_flag &&
              aqf.value === true
            ) {
              queryFieldData.push(af);
            }
          });
        } else {
          queryFieldData.push(af);
        }
      } else {
        // 没有设置applicationFor属性，则为公共属性  直接显示
        queryFieldData.push(af);
      }
    });
    props.dataSet.queryFields = queryFieldData;
    return new DataSet({
      ...props.dataSet,
      data: mockData as any[],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSet]);
  const columns = useMemo(() => {
    return props.columns.map((p: any) => {
      // 操作列  设置长度
      if (p.command) {
        // @ts-ignore
        if (p.columnWidth) {
          // @ts-ignore
          p.width = p.columnWidth + 'px';
        }
        // 更多
        if (p.more && p.command && p.command.length > 3) {
          p.command.splice(2, 0, [
            'edit',
            { children: '更多', icon: '', key: 'more' },
          ]);
          p.command = p.command.splice(0, 3);
        }
      }
      return { ...p, renderer: undefined };
    });
  }, [props.columns]);
  return (
    <ProTable
      border={props.border}
      rowNumber={props.rowNumber}
      columnResizable={props.columnResizable}
      customizable={props.customizable}
      customizedCode={props.customizedCode}
      columnHideable={props.columnHideable}
      columnTitleEditable={props.columnTitleEditable}
      columnDraggable={props.columnDraggable}
      queryFieldsLimit={props.queryFieldsLimit}
      queryBar={props.queryBar === 'default' ? undefined : props.queryBar}
      buttons={props.buttons}
      columns={columns}
      dataSet={dataSet}
      className='htd-placeholder-table'
    />
  );
};

export { Table };

import React, { FC } from 'react';

const style = { minWidth: '100px', minHeight: '14px' };
const Span: FC<any> = (props) => {
  return (
    <div style={style}>
      <span>{props.shadowChildren}</span>
    </div>
  );
};

export { Span };

import React, { useEffect, useState, useContext } from 'react';
import {
  Popconfirm,
  Button,
  Form as AntdForm,
  Input,
  // Popover
} from 'antd';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { usePrevious } from '@htd/common';
import MyIcon from 'components/myIcon';
import MyPopconfirm from 'components/Popconfirm';
import { addDsFieldsToModel } from '@/mapping/container/Form/utils';
import * as uuid from 'uuid';

import FieldEditPanel from './FieldEditPanel';
import { dragIcon } from '../../../../utils/unifiedExportIcon';

import './index.less';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';
import { BASE_COLUMN_PROPS, BASE_DS_PROPS } from 'utils/prop-types';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigContext } from '../ConfigPanel/ConfigContext';
// import FieldListPopover from './FieldListPopover';
import FieldListBatchChange from './FieldListBatchChange';

const ColumnPanel: React.FC = () => {
  const {
    settingItem: item,
    tree,
    forceUpdate,
    backendConfig,
  } = useContext(DesignContext);
  const {
    configItem,
    onSettingConfig,
    dsConfig,
    tableDsConfig,
    dsFields,
    dsQueryFields,
    tableColumns,
  } = useContext(ConfigContext);
  const [notExistFieldList, setNotExistFieldList] = useState<any[]>([]); // 切换ds后，ds中不存在的字段列表

  // 切换ds前的dsKey
  const previousItemKey = usePrevious<string>(item?.dsKey);

  // 切换组件前的组件id
  const previousItemId = usePrevious<string>(item?.id);

  /*
   * 字段数据
   * 合并columns属性和fields属性
   * 以字段名name为标识，columns属性添加col_前缀，fields属性添加ds_前缀
   *
   * 判断是否存在查询字段，若存在查询字段，设置query属性为true
   *
   * 合并后，根据newFieldList和notExistFieldList给字段加上状态标识
   * new: 新增字段
   * notexist: ds中不存在的字段
   */
  const dataSource = tableColumns
    .filter((c: any) => c.fieldKey && !c.command)
    .map((c: any) => {
      const data: any = {};
      for (const k in c) {
        if (k === 'name') data[k] = c[k];
        if (k === 'fieldKey') data[`ds_${k}`] = c[k];
        else data[`col_${k}`] = (c as any)[k];
      }
      const field = dsFields?.find(
        (f: any) =>
          c.fieldKey === f.fieldKey ||
          c.fieldKey === f.props?.fieldKey?.value ||
          c.name === f.name
      );
      for (const k in field) {
        if (k === 'name') continue;
        else data[`ds_${k}`] = (field as any)?.[k];
      }
      const queryField = dsQueryFields?.find(
        (f: any) => c.fieldKey === f.fieldKey
      );
      data['query'] = !!queryField;
      if (queryField) {
        for (const k in queryField) {
          if (k === 'name') continue;
          else data[`query_${k}`] = (queryField as any)?.[k];
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (notExistFieldList.includes(c.fieldKey)) data.__status = 'notexist';
      return data;
    });

  const selectedRowKeysList = dataSource.map((dm: any) => dm.ds_fieldKey);

  useEffect(() => {
    tableColumns.forEach((t: any) => {
      if (!t.fieldKey) {
        t.fieldKey = uuid.v4();
      }
    });
    if (!item?.dsKey) {
      ((tableDsConfig || {}).fields || []).forEach((f: any) => {
        if (!f.fieldKey) {
          const tF: any =
            tableColumns.find((c: any) => c.name === f.name) || {};
          f.fieldKey = tF.fieldKey;
        }
      });
    }
    // onSettingConfig?.(undefined);
    // forceUpdate?.(item?.id);
  }, [
    dataSource,
    forceUpdate,
    onSettingConfig,
    item?.dsKey,
    tableDsConfig,
    tableColumns,
    item?.id,
  ]);

  // 当前数据
  const currentRecord =
    configItem?.index !== undefined && configItem?.type === 'column'
      ? dataSource[configItem.index]
      : undefined;

  /**
   * 获取字段最大索引
   * @param originNum
   * @returns
   */
  const getNum = (originNum: number): number => {
    const name = `field${originNum}`;
    const existIndex = dsFields?.findIndex((f) => f.name === name);
    if (existIndex! > -1) {
      return getNum(originNum + 1);
    }
    return originNum;
  };

  /**
   * 新增字段
   * @param {any} data
   * @param {boolean} fromDsFields 是否切换ds时，从ds新建
   */
  const handleAddLine = (data?: any, fromDsFields?: boolean) => {
    // 判断是否有操作列
    const haveOperator =
      tableColumns.length && tableColumns[tableColumns.length - 1].command;

    const num = Boolean(haveOperator)
      ? getNum(tableColumns.length)
      : getNum(tableColumns.length + 1);
    const name = `field${num}`;
    const label = `字段${num}`;
    const fieldKey = uuid.v4();

    // 默认columns属性
    const createData = {
      ...BASE_COLUMN_PROPS,
      name,
      fieldKey,
      ...data,
    };
    // 如果存在操作列，则将新增列添加到tableColumns倒数第二列，否则添加到最后一列
    haveOperator
      ? tableColumns.splice(tableColumns.length - 1, 0, createData)
      : tableColumns.push(createData);

    if (!fromDsFields) {
      // 默认fields属性
      const dsFieldProps = {
        ...BASE_DS_PROPS,
        name,
        label,
        fieldKey,
      };
      dsFields?.push(dsFieldProps as any);
      if (dsConfig) {
        // 设置 图标
        const cuDsKey = dsConfig.dsKey;
        const backendData: any = Object.values(backendConfig)?.[0];
        const entitys = backendData?.entitys || {};
        const currentEntitysDataList = Object.values(entitys) || [];
        const cuData: any = currentEntitysDataList.find(
          (ite: any) => ite.dsKey === cuDsKey
        );
        const column = cuData?.column || [];
        const keyList = column.map((ite: any) => ite.fieldKey);
        const nameList = column.map((ite: any) => ite.name);
        if (!keyList.includes(fieldKey) && !nameList.includes(name)) {
          const list = cloneDeep(notExistFieldList);
          list.push(fieldKey);
          setNotExistFieldList([...list]);
        }
      }
      forceUpdate?.(item?.id);
    }
  };

  /**
   * 删除列
   * 关联了数据源的情况下，不删除关联数据源的字段
   */
  const handleDeleteLine = (record: any, index: number) => {
    // 删除columns中的字段
    tableColumns.splice(index, 1);
    if (!item?.dsKey) {
      const index = tableDsConfig?.fields?.findIndex(
        (f) => f.name === record.name
      );
      if (index! > -1) tableDsConfig?.fields?.splice(index!, 1);
    }
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  /**
   * @description: 删除数据源-如果是查询字段也删除
   * @param {any} record
   * @param {number} index
   * @return {*}
   */
  const onDeleteOther = (record: any, index: number) => {
    const deleteIndex = dsQueryFields?.findIndex(
      (field) => field.name === record.name
    );
    if (record.query && typeof deleteIndex !== 'undefined' && dsQueryFields) {
      dsQueryFields.splice(deleteIndex, 1);
    }
    // 删除columns中的字段
    handleDeleteLine(record, index);
  };

  // 表格字段名变化时同步变化查询域
  const deleteQueryFieldsFunc = (record: any, value: any, type: string) => {
    const deleteIndex = dsQueryFields?.findIndex(
      (field: any) => field.fieldKey === record.query_fieldKey
    );
    const currentField = dsFields?.find(
      (i: any) => i.fieldKey === record.ds_fieldKey
    );
    if (currentField) {
      const currentFieldData: any = cloneDeep(currentField);
      if (type === 'ds') {
        // 改标题
        currentFieldData.label = value;
      } else if (type === 'name') {
        currentFieldData.name = value;
      }
      if (
        typeof deleteIndex !== 'undefined' &&
        deleteIndex > -1 &&
        dsQueryFields
      ) {
        dsQueryFields.splice(deleteIndex, 1, { ...currentFieldData });
      }
    }
  };

  /**
   * 修改字段属性
   * @param {string} attrName 字段属性名
   * @param {record} record 字段所有属性
   * @param {any} value 字段属性值
   * @param {number} index 字段在columns中的索引
   */
  const handleChangeValue = (
    attrName: string,
    record: any,
    value: any,
    index?: number
  ) => {
    /*
     * 以下划线分割属性名，用type区分是columns属性还是fields属性
     * type为name时表示字段名
     */
    const [type, name] = attrName.split('_');
    if (type === 'name' || type === 'col') {
      (tableColumns as any[])[index!][name || attrName] = value;
    }
    if (type === 'name' || type === 'ds') {
      const fieldIndex = dsFields?.findIndex((f) => f.name === record.name);
      if (fieldIndex! > -1)
        (dsFields as any)![fieldIndex!][name || attrName] = value;
    }
    deleteQueryFieldsFunc(record, value, type);
    // 同步实体
    const dsKey = dsConfig?.dsKey;
    if (dsKey) {
      const backendModelMap: any = Object.values(backendConfig)?.[0];
      const entitys = backendModelMap?.entitys || {};
      const currentEntitysList = Object.values(entitys) || [];
      const currentEntity: any = currentEntitysList.find(
        (item: any) => item.dsKey === dsKey
      );
      const column = currentEntity?.column || [];
      const currentField = column.find(
        (field: any) => field.fieldKey === record.ds_fieldKey
      );
      if (currentField) {
        if (type === 'ds') {
          currentField.fieldName = value;
          currentField.label = value;
        } else if (type === 'name') {
          currentField.fieldCodeJava = value;
          currentField.name = value;
        }
      }
    }
    forceUpdate?.(item?.id);
  };

  /**
   * 设置当前编辑字段
   */
  const handleEditItemClick = (_: any, index: number) => {
    onSettingConfig?.(
      configItem?.index === index && configItem?.type === 'column'
        ? undefined
        : { index, type: 'column' }
    );
  };

  /**
   * 拖动排序
   */
  const handleDragEnd = (dataSource: any[]) => {
    tableColumns.sort((a: any, b: any) => {
      const aIndex = dataSource.findIndex((record) => record.name === a.name);
      const bIndex = dataSource.findIndex((record) => record.name === b.name);
      return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
    });
    onSettingConfig?.(undefined);
    forceUpdate?.(item?.id);
  };

  /**
   * 删除ds以及columns字段
   */
  const handleDeleteField = (record: any, index: number) => {
    tableColumns.splice(index, 1);
    const dsKey = dsConfig?.dsKey;
    // 当前实体字段
    const backendModelList = Object.keys(backendConfig) || [];
    const currentModelData =
      (backendModelList &&
        backendModelList.length &&
        backendConfig[backendModelList[0]]) ||
      {};
    const { entitys = {} } = currentModelData;
    const currentBackendConfigList = Object.keys(entitys) || [];
    const currentBackendConfig = currentBackendConfigList.find(
      (R) => entitys[R].dsKey === dsKey
    );
    if (currentBackendConfig) {
      let cuData = currentBackendConfig && entitys[currentBackendConfig];
      const columnList = cloneDeep(cuData?.column) || [];
      const mIndex = columnList.findIndex((m: any) => m.name === record.name);
      if (mIndex !== undefined && mIndex > -1) {
        entitys[currentBackendConfig]?.column.splice(mIndex, 1);
      }
    }

    const fIndex = dsConfig?.fields?.findIndex((f) => f.name === record.name);
    if (fIndex !== undefined && fIndex > -1)
      dsConfig?.fields?.splice(fIndex, 1);
    forceUpdate?.(item?.id);
  };

  /**
   * 确认保留字段在ds中
   */
  const handleConfirmField = (record: any) => {
    const notExistFieldIndex = notExistFieldList.findIndex(
      (f) => f === record.ds_fieldKey
    );
    if (notExistFieldIndex > -1)
      notExistFieldList.splice(notExistFieldIndex, 1);
    setNotExistFieldList([...notExistFieldList]);
    // 实体中添加字段
    if (dsConfig) {
      const name = record.name;
      const label = record.ds_label;
      const fieldKey = record.ds_fieldKey;
      const dsFieldProps = {
        ...BASE_DS_PROPS,
        name,
        label,
        fieldKey,
        fieldCodeJava: name,
        fieldName: label,
        fieldOrder: null,
        fieldType: 'string',
        relationField: 0,
        searchable: 0,
        inputNecessary: 0,
        defaultValue: null,
        lookupCode: null,
        codeRule: null,
        valid: null,
        // validValue: null,
        primaryKey: null,
      };
      const cuDsKey = dsConfig.dsKey;
      const backendData: any = Object.values(backendConfig)?.[0];
      const entitys = backendData?.entitys || {};
      const currentEntitysDataList = Object.values(entitys) || [];
      const cuData: any = currentEntitysDataList.find(
        (ite: any) => ite.dsKey === cuDsKey
      );
      if (cuData) {
        const column = cuData?.column || [];
        const keyList = column.map((c: any) => c.fieldKey);
        const nameList = column.map((c: any) => c.name);
        if (column.length) {
          if (!keyList.includes(fieldKey) && !nameList.includes(name)) {
            column.push(dsFieldProps);
          }
        } else {
          const list = [];
          list.push(dsFieldProps);
          cuData.column = list;
        }
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: (_, record, index) => {
        switch (record.__status) {
          case 'notexist':
            return (
              <div className='htd-aside-right-columns-table-status-box'>
                <Popconfirm
                  trigger='hover'
                  title='实体中不存在该字段，是否保留？'
                  okText='保留'
                  cancelText='移除'
                  cancelButtonProps={{ danger: true }}
                  onConfirm={handleConfirmField.bind(this, record)}
                  onCancel={handleDeleteField.bind(this, record, index)}
                >
                  <InfoCircleOutlined
                    style={{ color: '#f66', cursor: 'pointer' }}
                  />
                </Popconfirm>
                <img
                  alt='drag'
                  src={dragIcon}
                  className='htd-aside-right-columns-table-status-box-img'
                />
              </div>
            );
          default:
            return (
              <img
                alt='drag'
                src={dragIcon}
                className='htd-aside-right-columns-table-status-box-img'
              />
            );
        }
      },
    },
    {
      title: '标题',
      dataIndex: 'ds_label',
      render: (value, record) =>
        record.$form ? (
          <AntdForm.Item name='ds_label'>
            <Input
              onBlur={(e) => {
                handleChangeValue('ds_label', record, e.target.value);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '字段名',
      dataIndex: 'name',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='name'>
            <Input
              onBlur={(e) => {
                // 判断是否为空
                if (e.target.value) {
                  // 校验字段是否驼峰命名
                  if (
                    !new RegExp(/^[a-z]+[a-zA-Z0-9]{0,}$/).test(e.target.value)
                  ) {
                    record.$form.setFields([
                      { name: 'name', errors: ['请以驼峰命名'] },
                    ]);
                    return;
                  }
                  // 筛选是否存在重复字段
                  // const existIndex = dsFields?.findIndex(
                  //   (f) => f.name === e.target.value && f.name !== value
                  // );
                  // if (existIndex! > -1)
                  //   record.$form.setFields([
                  //     { name: 'name', errors: ['字段名不能重复'] },
                  //   ]);
                  // else {
                  record.$form.setFields([{ name: 'name', errors: [] }]);
                  handleChangeValue('name', record, e.target.value, index);
                  // }
                } else {
                  record.$form.setFields([
                    { name: 'name', errors: ['字段名不能为空'] },
                  ]);
                }
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      width: 40,
      align: 'center',
      title: item?.dsKey ? (
        // <Popover
        //   trigger='click'
        //   content={<FieldListPopover onCreate={handleAddLine} />}
        // >
        //   <SettingOutlined />
        // </Popover>
        <SettingOutlined
          onClick={() => setbatchChangeFieldVisable(!batchChangeFieldVisable)}
        />
      ) : (
        ''
      ),
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <MyIcon
            type='icon-a-edit2x'
            className='svg-cla'
            onClick={handleEditItemClick.bind(this, record, index)}
          />
          <MyPopconfirm
            title='确认删除？'
            onConfirm={handleDeleteLine.bind(this, record, index)}
            onDeleteOther={onDeleteOther.bind(this, record, index)}
            disabled={!record.query}
            placement='left'
          >
            <MyIcon
              type='icon-a-delete2x'
              style={{ marginRight: '6px' }}
              className='svg-cla'
            />
          </MyPopconfirm>
        </>
      ),
    },
  ];

  /**
   * dsKey变化时，添加DS字段
   */
  useEffect(() => {
    if (
      item?.id === previousItemId &&
      item?.dsKey !== previousItemKey &&
      item?.dsKey
    ) {
      // 当前实体字段
      const backendModelList = Object.keys(backendConfig) || [];
      const currentModelData =
        (backendModelList &&
          backendModelList.length &&
          backendConfig[backendModelList[0]]) ||
        {};
      const { entitys = {} } = currentModelData;
      const currentBackendConfigList = Object.keys(entitys) || [];
      const currentBackendConfig = currentBackendConfigList.find(
        (R) => entitys[R].dsKey === item.dsKey
      );
      let cuData = currentBackendConfig && entitys[currentBackendConfig];
      const columnList = cloneDeep(cuData?.column) || [];
      // const oldModelFields = previousItemKey ? columnList : tableDsConfig?.fields;

      const oldDsFields = previousItemKey
        ? tree.dsConfig.find((i) => i.dsKey === previousItemKey)?.fields
        : tableDsConfig?.fields;
      if (dsFields) {
        const notExistFieldList: any[] = [];
        tableColumns
          .filter((column: any) => column.fieldKey)
          .forEach((column: any) => {
            const tableDsField = oldDsFields?.find(
              (field: any) => field.fieldKey === column.fieldKey
            );
            const existIndex = dsFields.findIndex(
              (field: any) => field.fieldKey === column.fieldKey
            );
            const existNameIndex = dsFields.findIndex(
              (field: any) => field.name === column.name
            );
            const existModelIndex = columnList.findIndex(
              (fieldI: any) => fieldI.fieldKey === column.fieldKey
            );
            const existModelNameIndex = columnList.findIndex(
              (fieldI: any) => fieldI.name === column.name
            );
            if (existModelIndex === -1 && existModelNameIndex === -1) {
              notExistFieldList.push(column.fieldKey);
            }
            if (tableDsField) {
              const tableDsFieldData = cloneDeep(tableDsField);
              if (existIndex > -1) {
                const dsFieldsData = cloneDeep(dsFields[existIndex]);
                // 同步时保留表单字段数据
                dsFields[existIndex] = { ...dsFieldsData, ...tableDsFieldData };
              } else if (existNameIndex > -1) {
                const dsFieldsData = cloneDeep(dsFields[existNameIndex]);
                dsFields[existNameIndex] = {
                  ...dsFieldsData,
                  ...tableDsFieldData,
                };
              } else {
                dsFields.push(tableDsField);
              }
            }
          });
        setNotExistFieldList(notExistFieldList);
      }
      const dsConfigData = cloneDeep(dsConfig);

      addDsFieldsToModel(backendConfig, dsConfigData);
      forceUpdate?.(item?.id);
    } else if (item?.id !== previousItemId) {
      setNotExistFieldList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.dsKey, item?.id]);

  const [batchChangeFieldVisable, setbatchChangeFieldVisable] = useState(false);

  return (
    <div className='data-table-h'>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        onDragEnd={handleDragEnd}
        showHeader={Boolean(dataSource?.length)}
      />
      {currentRecord && (
        <FieldEditPanel
          record={currentRecord}
          dsConfigData={dsConfig}
          item={item}
        />
      )}
      <div className='htd-aside-right-columns-button-wrapper jus-conn'>
        <Button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine.bind(this, undefined, false)}
        >
          添加字段
        </Button>
      </div>
      {batchChangeFieldVisable && (
        <FieldListBatchChange
          onClose={setbatchChangeFieldVisable}
          onCreate={handleAddLine}
          selectedRowKeysList={selectedRowKeysList}
          item={item}
        />
      )}
    </div>
  );
};

export default ColumnPanel;

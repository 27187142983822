import React, { FC, useMemo } from 'react';
import { DataSet, Tree as ProTree } from 'choerodon-ui/pro';
import { treeMockData } from '@htd/common';

const Tree: FC<any> = (props) => {
  const dataSet = useMemo(() => {
    return new DataSet({
      ...props.dataSet,
      parentField: props.parentField,
      expandField: props.expandField,
      idField: props.idField,
      data: treeMockData as any[],
    });
  }, [props.dataSet, props.parentField, props.expandField, props.idField]);
  return (
    <ProTree
      showLine={props.showLine}
      dataSet={dataSet}
      checkable={props.checkable}
      className='htd-placeholder-table'
      renderer={({ record }) => record?.get(props.textField)}
    />
  );
};

export { Tree };

import { FC, useMemo } from 'react';
import { Form as AntdForm, Radio, Select } from 'antd';
import { InputNumber } from 'choerodon-ui';
import { COLUMN_LOCK_LIST, COLUMN_ALIGN_LIST } from 'utils/prop-types';
import { DynamicObject } from '@htd/common';
import { PerformanceCellRenderers } from '@htd/helper/lib/templates/cell-renderer';
import SelectRightImg from 'components/selectRightImg';
interface ColumnAttributeProps {
  record: any;
}

const ColumnAttribute: FC<ColumnAttributeProps> = ({ record }) => {
  const cellRendererList = useMemo(() => {
    return PerformanceCellRenderers.filter((i) => i.code && i.name);
  }, []);
  return (
    <section className='htd-aside-right-main-section'>
      <AntdForm.Item label='列固定' name='col_fixed'>
        <Radio.Group>
          {COLUMN_LOCK_LIST.map((type) => (
            <Radio.Button value={type.value} key={type.value}>
              {type.meaning}
            </Radio.Button>
          ))}
        </Radio.Group>
      </AntdForm.Item>
      <AntdForm.Item label='对齐方式' name='col_align'>
        <Radio.Group>
          {COLUMN_ALIGN_LIST.map((type) => (
            <Radio.Button value={type.value} key={type.value}>
              {type.meaning}
            </Radio.Button>
          ))}
        </Radio.Group>
      </AntdForm.Item>
      <AntdForm.Item label='列宽' name='col_width' className='htd-input-width'>
        <InputNumber />
      </AntdForm.Item>
      <AntdForm.Item label='重渲染' name='col_render'>
        <Select
          suffixIcon={<SelectRightImg />}
          allowClear
          onChange={(e) => {
            let params: DynamicObject = {};
            if (e === 'customRender') {
              params['functionName'] = `${record.dataIndex}CustomRender`;
            }
            record.col_renderer = cellRendererList
              .find((c) => c.code === e)
              ?.function?.(params);
          }}
        >
          {cellRendererList.map((renderer) => {
            return (
              <Select.Option value={renderer.code} key={renderer.code}>
                {renderer.name}
              </Select.Option>
            );
          })}
        </Select>
      </AntdForm.Item>
    </section>
  );
};

export default ColumnAttribute;

import { cloneDeep, isArray } from 'lodash';

// 从applicationFor中提取数据(Form的)
export function handleFormCuDataFunc(
  item: any,
  currentField: any,
  attributeList: any,
  validate?: string
) {
  const cuData: any = cloneDeep(currentField);
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  if (data_hcg_flag) {
    attributeList.forEach((af: any) => {
      const cuBindList = cuData?.applicationFor?.[af] || [];
      if (cuBindList.length) {
        for (let i = 0; i < cuBindList.length; i++) {
          if (validate) {
            // 校验 转换
            if (cuBindList[i].cmp === data_hcg_flag) {
              cuData.validate = cuData.validate || {};
              cuData.validate[af] = cuBindList[i].value;
            }
          } else {
            if (
              cuBindList[i].cmp === data_hcg_flag &&
              cuData.props &&
              cuData.props[af]
            ) {
              cuData.props[af].value = cuBindList[i].value;
              break;
            }
          }
        }
      } else {
        // 基础属性转换
        if (cuData.props && cuData.props[af]) {
          cuData.props[af].value = cuData[af] || cuData.props[af].value;
        }
      }
    });
  }
  return cuData;
}

// 设置applicationFor(Form的)
export function handleChnageFormPropsFunc(
  item: any,
  currentField: any,
  key: string,
  value: any
) {
  const isNewDsFlag = item?.props?.createNewDs?.value || false;
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  const list = ['pattern', 'min', 'max', 'maxLength', 'minLength', 'required'];
  const cuPropsData = currentField.props;
  if (list.includes(key)) {
    // 校验
    if (data_hcg_flag && isNewDsFlag) {
      // 设置了 单独ds
      const applicationFor: any = (currentField as any)?.applicationFor || {};
      const appData = applicationFor[key];
      let specialValue = value;
      let oldData: any = {};
      if (isArray(appData)) {
        oldData = applicationFor[key].find(
          (af: any) => af.cmp === data_hcg_flag
        );
        specialValue =
          typeof value === 'object' ? { ...oldData?.value, ...value } : value;
      }
      if (isArray(appData)) {
        if (oldData) {
          oldData.value = specialValue;
        } else {
          if (specialValue) {
            applicationFor[key].push({
              cmp: data_hcg_flag,
              value: specialValue,
            });
          }
        }
      } else {
        if (specialValue) {
          applicationFor[key] = [
            {
              cmp: data_hcg_flag,
              value: specialValue,
            },
          ];
        }
      }
      (currentField as any).applicationFor = applicationFor;
    } else {
      (currentField as any).validate = (currentField as any).validate || {};
      (currentField as any).validate[key] = value;
      // 在外层设置一份数据用于显示表格
      currentField[key] = value;
    }
  } else {
    if (data_hcg_flag && isNewDsFlag) {
      // 设置了 单独ds
      const applicationFor: any = (currentField as any)?.applicationFor || {};
      const list1 = [
        'bind',
        'query',
        'lovCode',
        'ignore',
        'multiple',
        'required',
        'lookupCode',
        'defaultValue',
      ];
      if (list1.includes(key)) {
        const appData = applicationFor[key];
        let specialValue = value;
        let oldData: any = {};
        let oldDataIndex: any = -1;
        if (isArray(appData)) {
          oldData = applicationFor[key].find(
            (af: any) => af.cmp === data_hcg_flag
          );
          oldDataIndex = applicationFor[key].findIndex(
            (af: any) => af.cmp === data_hcg_flag
          );
          specialValue =
            typeof value === 'object' ? { ...oldData?.value, ...value } : value;
        }
        if (isArray(appData)) {
          if (oldData) {
            if (specialValue) {
              oldData.value = specialValue;
            } else {
              // 没有数据 就删除掉 这个单独数据
              if (oldDataIndex! > -1) {
                appData.splice(oldDataIndex, 1);
              }
            }
          } else {
            if (specialValue) {
              applicationFor[key].push({
                cmp: data_hcg_flag,
                value: specialValue,
              });
            }
          }
        } else {
          if (specialValue) {
            applicationFor[key] = [
              {
                cmp: data_hcg_flag,
                value: specialValue,
              },
            ];
          }
        }
        // 没有数据就删除掉这个属性
        if (appData && !appData.length) {
          delete applicationFor[key];
        }
      } else {
        (cuPropsData as any)[key].value = value;
      }
      (currentField as any).applicationFor = applicationFor;
    } else {
      if (cuPropsData && cuPropsData[key]) {
        (cuPropsData as any)[key].value = value;
      } else {
        currentField[key] = value;
      }
    }
  }
}

// 取消 传入组件标识
export function handleFormCancelNewCreateFunc(tree: any, item: any) {
  const itemDsKey = item?.dsKey;
  const dsConfig = tree?.dsConfig || [];
  const data_hcg_flag = item?.props?.['data-hcg_flag']?.value;
  const list = ['required', 'pattern', 'min', 'max', 'maxLength', 'minLength'];
  if (itemDsKey && dsConfig.length) {
    const cuDsConfig = dsConfig.find((df: any) => itemDsKey === df.dsKey);
    if (cuDsConfig) {
      const fields = cuDsConfig.fields || [];
      const itemFields = item?.children || [];
      itemFields.forEach((isf: any) => {
        const cuDsField: any = fields.find(
          (ff: any) => ff.fieldKey === isf.fieldKey || ff.name === isf.name
        );
        if (cuDsField) {
          const cuFieldApplicationFor = cuDsField.applicationFor || {};
          Object.keys(cuFieldApplicationFor).forEach((vf: any) => {
            const cuKeyValue = cuFieldApplicationFor[vf];
            if (isArray(cuKeyValue) && cuKeyValue.length && data_hcg_flag) {
              cuKeyValue.forEach((vfh: any, index: number) => {
                if (vfh.cmp === data_hcg_flag) {
                  if (list.includes(vf)) {
                    // 校验部分
                    const cuValidate = cloneDeep(cuDsField.validate) || {};
                    const totalValidate = { ...cuValidate, [vf]: vfh.value };
                    cuDsField.validate = totalValidate;
                  }
                  // 设置外层的值
                  cuDsField[vf] = vfh.value;
                  if (cuDsField && cuDsField.props && cuDsField.props[vf]) {
                    // 设置里层的值
                    cuDsField.props[vf].value = vfh.value;
                  }
                  // 清除applicationFor里面对应的值
                  cuKeyValue.splice(index, 1);
                }
              });
            }
          });
        }
      });
    }
  }
}

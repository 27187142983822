import React from 'react';
import { DataSet } from 'choerodon-ui/pro';
const currentTenant = {
    tenantId: 0,
};
const currentUser = {
    id: 0,
};
/**
 * 设计器生成页面的的基类
 * 主要为了处理DataSet、Search、Location、默认生命周期控制、通用方法封装、hoc?
 */
class BaseComponent extends React.Component {
    constructor() {
        super(...arguments);
        // 可以想想这里怎么对DataSet对象缓存，如果启用缓存该如何管理缓存
        this._dataSetMap = new Map();
        /**
         * 添加DataSet
         * @param {string} dsName
         * @param {DataSet} ds
         */
        this.setDs = (dsName, ds) => {
            if (ds instanceof DataSet) {
                this._dataSetMap.set(dsName, ds);
            }
            else {
                throw "you can't set the object into this map with not DataSet type ";
            }
        };
        /**
         * 获取DataSet
         * @param {string | string[]} dsName
         * @returns {DataSet | DataSet[]}
         */
        this.getDs = (dsName) => {
            if (!dsName) {
                return null;
            }
            if (typeof dsName === 'string' || dsName instanceof String) {
                return this._dataSetMap.get(dsName);
            }
            if (dsName instanceof Array) {
                return dsName.map((n) => this._dataSetMap.get(String(n)));
            }
        };
        /**
         * 获取当前的页面路由
         * @returns {string} path
         */
        this.getCurrentRoute = () => {
            const { match: { path = '' }, } = this.props;
            return path;
        };
        /**
         * 获取url上的路径参数及查询参数
         * @returns {object}
         */
        this.getCurrentUrlParams = () => {
            const { match: { params = {} }, } = this.props;
            return params;
        };
        /**
         * 获取当前页面跳转时的state参数
         * @returns {object}
         */
        this.getCurrentLocalState = () => {
            // const { state } = this.location;
            console.log(this);
            return {};
        };
        /**
         * 获取用户信息
         * @returns {object: {id, realName, loginName}}
         */
        this.getCurrentUser = () => {
            return currentUser;
        };
        /**
         * 获取租户信息
         * @returns {object: {tenantId, tenantName, tenantCode}}
         */
        this.getCurrentTenant = () => {
            return currentTenant;
        };
        /**
         * 获取系统信息
         * @returns {object: {lang}}
         */
        this.getSystem = () => {
            return { lang: 'Zh-CN' };
        };
        this.state = {};
    }
}
export default BaseComponent;

import React, { useState } from 'react';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ConfigPanelComponentProp, DsProps } from '@htd/common';
import ColumnPanel from './ColumnPanel';
import { ConfigItem, ConfigProvider } from './ColumnPanel/ConfigContext';
import { isEqual } from 'lodash';

const ConfigPanel: React.FC<ConfigPanelComponentProp> = (props) => {
  const { item } = props;

  const title = item?.name === 'Tabs' ? '标签页' : '折叠面板';

  // 当前选中的字段 { index: 字段索引, type: 属性类型 }
  const [configItem, setConfigItem] = useState<ConfigItem>();

  // 表格组件中的数据源字段信息
  const tableDsConfig: DsProps = item?.props?.dataSet?.value;

  // 表格列
  const tableColumns = item?.props?.columns?.value;

  // 表格属性Context
  const configContextValue = {
    configItem,
    onSettingConfig: (item?: ConfigItem) => {
      if (!isEqual(configItem, item)) {
        setConfigItem(item);
      }
    },
    tableDsConfig,
    title,
    tableColumns,
  };

  return (
    <ConfigProvider value={configContextValue}>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='htd-config-panel'
        defaultActiveKey={['data']}
      >
        <Collapse.Panel key='data' header={title} forceRender>
          <ColumnPanel />
        </Collapse.Panel>
      </Collapse>
    </ConfigProvider>
  );
};

export default ConfigPanel;

import { MappingListItem } from '../util';
import { Modal } from './Modal';
import { Tabs, TabPane } from './Tabs';
import { Steps, Step } from './Steps';
import { Collapse, CollapsePanel } from './Collapse';
import { Card } from './Card';
// import { List } from './List/index';
import { Div } from './Div';
import { Spin } from './Spin';

export const containerList: Array<MappingListItem> = [
  {
    id: 'htd-container-pro-tabs',
    name: '标签页',
    mapping: Tabs,
    childrenId: 'htd-container-pro-tabPane',
  },
  {
    id: 'htd-container-pro-tabPane',
    name: '子标签页',
    mapping: TabPane,
    hidden: true,
  },
  {
    id: 'htd-container-pro-steps',
    name: '步骤条',
    mapping: Steps,
    childrenId: 'htd-container-pro-step',
  },
  {
    id: 'htd-container-pro-step',
    name: '步骤条选项',
    mapping: Step,
    hidden: true,
  },
  {
    id: 'htd-container-modal',
    name: '弹框',
    mapping: Modal,
  },
  {
    id: 'htd-container-collapse',
    name: '折叠面板',
    mapping: Collapse,
    childrenId: 'htd-container-collapsePane',
  },
  {
    id: 'htd-container-collapsePane',
    name: '子折叠面板',
    mapping: CollapsePanel,
    hidden: true,
  },
  {
    id: 'htd-container-pro-modal',
    name: '弹框',
  },
  {
    id: 'htd-container-card',
    name: '卡片',
    mapping: Card,
  },
  // {
  //   id: 'htd-container-list',
  //   name: '列表',
  //   mapping: List,
  // },
  {
    id: 'htd-container-div',
    name: '块容器',
    mapping: Div,
  },
  {
    id: 'htd-container-spin',
    name: '加载中',
    mapping: Spin,
  },
];

import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { Button, Form as AntdForm, Input, Popconfirm } from 'antd';
import * as uuid from 'uuid';
// import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DesignContext } from 'views/Home/DesignContext';
import { ConfigPanelComponentProp } from '@htd/common';
import MyIcon from 'components/myIcon';

import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { ColumnsType } from 'antd/lib/table';
import { generateCanvasComponentById, ensureChildren } from 'utils';
import { dragIcon } from 'utils/unifiedExportIcon';

import FieldEditPanel from './FieldEditPanel';
import './index.less';

const ColumnPanel: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const { cMap } = useContext(DesignContext);
  const [[form]] = useState(AntdForm.useForm());
  const [currentIndex, setCurrentIndex] = useState<number>();
  // 表格列
  const tableColumns: any[] = useMemo(
    () => item?.props?.columns?.value,
    [item?.props?.columns?.value]
  );

  const currentRecord = useMemo(
    () => (currentIndex !== undefined ? tableColumns[currentIndex] : {}),
    [currentIndex, tableColumns]
  );

  const update = useCallback(() => {
    if (item?.props?.columns?.value?.length)
      item.props.columns.value = cloneDeep(tableColumns);
    forceUpdate?.(item?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate, item?.id, item.props?.columns?.value, tableColumns]);

  /**
   * record变更时，重设form的值
   */
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...currentRecord,
    });
  }, [form, currentRecord]);

  /**
   * 设置当前编辑字段
   */
  const handleEditItemClick = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  /**
   * 拖动排序
   */
  const handleDragEnd = useCallback(
    (dataSource: any[]) => {
      const sortList: any[] = [];
      tableColumns.sort((a: any, b: any) => {
        const aIndex = dataSource.findIndex(
          (record) => record.dataIndex === a.dataIndex
        );
        const bIndex = dataSource.findIndex(
          (record) => record.dataIndex === b.dataIndex
        );
        if (aIndex < bIndex) {
          if (
            sortList.some(
              (one) => one.aIndex === aIndex && one.bIndex === bIndex
            )
          ) {
            //
          } else {
            sortList.unshift({ aIndex, bIndex });
          }
        }
        return aIndex === -1 || bIndex === -1 ? 1 : aIndex - bIndex;
      });
      if (sortList.length > 0) {
        sortList.forEach((sort) => {
          const { aIndex, bIndex } = sort;
          [item.children[aIndex], item.children[bIndex]] = [
            item.children[bIndex],
            item.children[aIndex],
          ];
        });
      }
      setCurrentIndex(undefined);
      update();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableColumns, update]
  );

  /**
   * 删除步骤选项
   */
  const handleDeleteLine = useCallback(
    (record, index) => {
      tableColumns.splice(index, 1);
      item.children?.splice(index, 1);
      update();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableColumns, update]
  );

  /**
   * 新增步骤
   */
  const handleAddLine = useCallback(() => {
    const dataIndex = `Step_${uuid.v4().slice(0, 5)}`;
    const title = `步骤名称`;
    const description = `操作步骤的具体描述`;
    const createData = {
      title,
      description,
      dataIndex,
    };
    tableColumns.push(createData);

    const stepComponent = generateCanvasComponentById(
      'htd-container-pro-step',
      item?.id
    );
    if (stepComponent) {
      ensureChildren(item);
      item.children?.splice(item.children.length, 0, stepComponent);
      cMap.set(stepComponent.id, stepComponent);
    }
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns, update]);

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index?) => {
      tableColumns[index][fieldName] = value;
      update();
    },
    [tableColumns, update]
  );
  const columns: ColumnsType<any> = [
    {
      key: 'status',
      className: 'htd-aside-right-columns-table-status',
      render: (_, record, index) => {
        return (
          <img
            alt='drag'
            src={dragIcon}
            className='htd-aside-right-columns-table-status-box-img'
          />
        );
      },
    },
    {
      title: '标题',
      dataIndex: 'title',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='title'>
            <Input
              onBlur={(e) => {
                handleChangeValue('title', record, e.target.value, index);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      render: (value, record, index) =>
        record.$form ? (
          <AntdForm.Item name='description'>
            <Input
              onBlur={(e) => {
                handleChangeValue('description', record, e.target.value, index);
              }}
            />
          </AntdForm.Item>
        ) : (
          value
        ),
    },
    {
      title: ' ',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        // <>
        //   <EditOutlined onClick={handleEditItemClick.bind(this, index)} />
        //   <DeleteOutlined
        //     onClick={handleDeleteLine.bind(this, record, index)}
        //   />
        // </>
        <>
          <MyIcon
            type='icon-a-edit2x'
            className='svg-cla'
            onClick={handleEditItemClick.bind(this, index)}
          />
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            <MyIcon type='icon-a-delete2x' className='svg-cla' />
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <section>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={tableColumns}
        pagination={false}
        size='small'
        bordered={false}
        draggable
        onDragEnd={handleDragEnd}
        showHeader={Boolean(tableColumns?.length)}
      />
      <div className='htd-aside-right-columns-button-wrapper jus-conn'>
        <Button
          className='htd-home-header-actions-generate htd-home-header-actions-generate-primary'
          onClick={handleAddLine.bind(this, undefined, false)}
        >
          添加步骤
        </Button>
      </div>
      {currentIndex !== undefined && (
        <FieldEditPanel
          record={currentRecord}
          index={currentIndex}
          setIndex={setCurrentIndex}
          onChange={handleChangeValue}
        />
      )}
    </section>
  );
};

export default ColumnPanel;

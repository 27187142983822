import React, { useMemo, useEffect } from 'react';
import { Form as AntdForm, Switch } from 'antd';
import { InputNumber } from 'choerodon-ui';
import { ConfigPanelComponentProp, DsProps } from '@htd/common';

const PaginationPanel: React.FC<ConfigPanelComponentProp> = ({
  tree,
  item,
  forceUpdate,
}) => {
  const [form] = AntdForm.useForm();

  // 数据源配置
  const dsConfig: DsProps | undefined = useMemo(
    () =>
      item.dsKey
        ? tree.dsConfig.find((i) => i.dsKey === item.dsKey)
        : item?.props?.dataSet?.value,
    [item.dsKey, item?.props?.dataSet?.value, tree.dsConfig]
  );

  const initialValues = {
    // pagination:
    //   item?.props?.pagination?.value === undefined
    //     ? true
    //     : item?.props?.pagination?.value,
    pageSize: dsConfig?.pageSize || 10,
    paging: dsConfig?.paging === undefined ? true : dsConfig?.paging,
  };

  /**
   * 值变更
   * @param changedValue
   */
  const handleValuesChange = (changedValue: any) => {
    for (const key in changedValue) {
      // if (key === 'pagination' && item?.props?.pagination?.value) {
      //   item.props.pagination.value = changedValue[key]
      //     ? changedValue[key]
      //     : undefined;
      // }
      if (key === 'pageSize' && dsConfig) {
        dsConfig.pageSize = changedValue[key] || 10;
      }
      if (key === 'paging' && dsConfig) {
        dsConfig.paging = changedValue[key];
      }
    }
    form.setFieldsValue(changedValue);
    forceUpdate?.(item?.id);
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  return (
    <AntdForm
      form={form}
      labelAlign='left'
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      // style={{ padding: '0 8px' }}
      className='pagination-form-box'
    >
      <AntdForm.Item
        label='开启分页器'
        // name='pagination'
        name='paging'
        valuePropName='checked'
      >
        <Switch />
      </AntdForm.Item>
      <AntdForm.Item label='PageSize' name='pageSize'>
        <InputNumber />
      </AntdForm.Item>
    </AntdForm>
  );
};

export default PaginationPanel;

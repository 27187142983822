/*
 * @Author: wen.chen02@hand-china.com
 * @Description: intl工具适配
 */

const intl = {
  get: (code) => {
    // find intl by code
    return new intld(null);
  },
};

function intld(v) {
  this.v = v;
  this.d = (def) => {
    if (this.v) {
      return v;
    }
    return def;
  };
}

export default intl;

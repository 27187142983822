import { FC, useState, useMemo, useEffect } from 'react';
import { Modal, Row, Col, message, Form as AntdForm } from 'antd';
import './index.less';
import List from './List';
import Form from './Form';
import { AvaliableEvents, DsProps, PropType } from '@htd/common/lib';
import { capitalize, isFunction } from 'lodash';

const ActionModal: FC<{
  fieldName?: string;
  compFlag: string;
  globalDsConfig?: DsProps[];
  avaliableEvents: AvaliableEvents;
  modalVisible: boolean;
  onOk?: (e: any) => any;
  onCancel?: () => any;
  settingItem?: {
    eventName: string;
    eventMeaning: string;
    props?: PropType;
  };
  noCustomEvent?: boolean;
}> = ({
  fieldName,
  compFlag,
  globalDsConfig,
  avaliableEvents,
  modalVisible,
  onOk,
  onCancel,
  settingItem,
  noCustomEvent,
}) => {
  const [paramForm] = AntdForm.useForm();
  // 默认的动作选择
  const defaultCurrentAction = useMemo(
    () => ({
      eventType: avaliableEvents.length > 0 ? avaliableEvents[0]?.name : '',
      actionType:
        avaliableEvents.length > 0 && avaliableEvents[0]?.list.length > 0
          ? avaliableEvents[0]?.list[0]?.code
          : '',
    }),
    [avaliableEvents]
  );

  const [currentAction, setCurrentAction] =
    useState<{ eventType: string; actionType: string }>(defaultCurrentAction);
  const actionTypeList = useMemo(() => {
    const event = avaliableEvents.find(
      (i) => i.name === currentAction?.eventType
    );
    return event?.list || [];
  }, [avaliableEvents, currentAction?.eventType]);
  // 事件配置是否有自定义显示
  const [noCustomEventConf, setNoCustomEventConf] = useState<boolean>(false);
  // 事件配置是否有需要参数
  const [customEventParamConf, setCustomEventParamConf] =
    useState<boolean>(false);
  useEffect(() => {
    avaliableEvents.forEach((a) => {
      if (a.name === currentAction.eventType) {
        setNoCustomEventConf(a.noCustomEvent || false);
        setCustomEventParamConf(a.customHasParam || false);
      }
    });
  }, [avaliableEvents, currentAction]);
  const currentEvent = useMemo(() => {
    if (currentAction?.actionType === 'custom') {
      return undefined;
    }
    return actionTypeList.find((i) => i.code === currentAction?.actionType);
  }, [actionTypeList, currentAction?.actionType]);

  const defaultParam = useMemo(() => {
    const event = avaliableEvents.find(
      (i) => i.name === currentAction?.eventType
    );
    const param = event?.defaultParams?.find(
      (p) => p.code === currentEvent?.code
    );
    return param?.defaultParam;
  }, [avaliableEvents, currentAction?.eventType, currentEvent?.code]);

  useEffect(() => {
    if (settingItem) {
      const action = {
        eventType: settingItem.eventName,
        actionType: settingItem?.props?.value?.eventCode || 'custom',
      };
      setCurrentAction(action);
      const params: any = {};
      const { otherParam = [], ...requiredParam } =
        settingItem?.props?.value?.eventParam || {};
      otherParam.forEach((i: any) => {
        params[i.key] = i.value;
      });
      for (const i in requiredParam) {
        params[i] = requiredParam[i];
      }
      paramForm.setFieldsValue(params);
    }
  }, [paramForm, settingItem]);

  const handleModalOk = () => {
    if (!currentAction?.eventType) {
      message.error('请选择触发动作');
      return;
    }
    if (!currentAction?.actionType) {
      message.error('请选择动作类型');
      return;
    }
    if (currentAction?.actionType === 'custom') {
      onOk?.({
        eventName: currentAction?.eventType,
        eventValue: {
          funcName: `handle${compFlag}${fieldName || ''}${capitalize(
            currentAction?.eventType
          )}`,
          param: customEventParamConf ? ['params'] : [],
          funcLibs: [
            {
              name: [
                `handle${compFlag}${fieldName || ''}${capitalize(
                  currentAction?.eventType
                )}`,
              ],
              source: './pageLogic.js',
            },
          ],
        },
      });
    } else {
      paramForm.validateFields().then((values) => {
        if (isFunction(currentEvent.function)) {
          const param: any = {};
          (currentEvent?.requiredParam || []).forEach((p: any) => {
            param[p.index] = values[p.index];
          });
          param.otherParam = (currentEvent?.otherParam || []).map((p: any) => ({
            key: p.index,
            value: values[p.index],
          }));
          const func = currentEvent.function?.(param);
          onOk?.({
            eventName: currentAction?.eventType,
            eventValue: {
              funcName: `handle${compFlag}${fieldName || ''}${capitalize(
                currentAction?.eventType
              )}${currentEvent.code}`,
              param: func.functionParameter,
              funcStr: func.functionBody,
              isAsync: func.async,
              funcLibs: (func.libs || []).map((item: any) => ({
                name: item.name,
                source: item.path,
              })),
              eventCode: currentEvent.code,
              eventName: currentEvent.name,
              eventParam: param,
            },
          });
          paramForm.resetFields();
          setCurrentAction(defaultCurrentAction);
        }
      });
    }
  };

  const handleModalCancel = () => {
    onCancel?.();
    paramForm.resetFields();
    setCurrentAction(defaultCurrentAction);
  };
  return (
    <Modal
      visible={modalVisible}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      title='动作'
      width='55.6%'
      className='htd-common-action-modal'
      okText='确定'
      cancelText='取消'
      destroyOnClose
    >
      <Row className='top-title-box-row'>
        <div className='top-title-box-row-left-box'>
          <Col span={4}>触发动作</Col>
          <Col span={6}>动作类型</Col>
        </div>
        <Col span={14}>配置动作</Col>
      </Row>
      <Row className='htd-common-action-modal-content'>
        <div className='top-title-box-row-left-box'>
          <Col span={4} className='first-list-cf'>
            <List
              type='eventType'
              list={avaliableEvents}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
            />
          </Col>
          <Col span={6} className='htd-common-action-modal-content-action-type'>
            <List
              type='actionType'
              list={actionTypeList}
              paramForm={paramForm}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              noCustomEvent={noCustomEvent || noCustomEventConf}
              defaultParam={defaultParam}
            />
          </Col>
        </div>
        <Col span={14} className='htd-common-action-modal-content-action-from'>
          <Form
            paramForm={paramForm}
            currentAction={currentAction}
            currentEvent={currentEvent}
            globalDsConfig={globalDsConfig}
            defaultParam={defaultParam}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ActionModal;

import { Action, createNamespaceReducer } from '../store';

export const globalReducer = createNamespaceReducer(
  'global',
  function (state: any, action: Action) {
    switch (action.type) {
      case 'init': {
        return { ...state, ...action.payload };
      }
      default:
        return state;
    }
  }
);

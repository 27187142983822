import { Form, FormInstance, Input, Select, Switch } from 'antd';
import React, { FC, useEffect, useMemo } from 'react';
import { DesignConfig } from '@htd/common';
import VariableInput from 'components/VariableInput';
import DataSetFieldValidators from '@htd/helper/lib/templates/dataset-field-validator';
import SelectRightImg from 'components/selectRightImg';
import './index.less';
// import { isUndefined } from 'lodash';

const ValidateConfigPanel: FC<{
  tree: DesignConfig;
  record: any;
  type: string;
  messageType?: string;
  messagePlaceholder?: string;
  label: string;
  defaultInput?: React.ReactNode;
  needVariable?: boolean;
  onChange: (fieldName: string, values: any) => any;
  panelForm: FormInstance<any>;
}> = ({
  tree,
  record,
  type,
  messageType,
  messagePlaceholder,
  label,
  defaultInput,
  needVariable,
  onChange,
  panelForm: form,
}) => {
  // const [form] = Form.useForm();

  const patternList = useMemo(() => {
    return DataSetFieldValidators.filter(
      (item) => item.validatType === 'pattern'
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...record[type],
    });
  }, [form, messageType, record, type]);

  // const handleValuesChange = useCallback(
  //   (changedValues: any) => {
  //     changedValues = {
  //       ...changedValues,
  //       messageType,
  //     };
  //     onChange(type, changedValues);
  //   },
  //   [type, messageType, onChange]
  // );

  const handlePatternTypeChange = (e: any) => {
    const patternObj = patternList.find((p) => p.code === e);
    if (patternObj) {
      const values = {
        value: String(patternObj.value || ''),
        name: patternObj.name,
        errorMsg: patternObj.errorMsg,
      };
      form.setFieldsValue(values);
      // handleValuesChange(values);
      const preValues = form.getFieldsValue();
      onChange(type, {
        ...preValues,
        ...values,
      });
    }
  };

  const getPatternField = () => {
    if (type === 'ds_pattern') {
      return (
        <>
          <Form.Item label='正则类型' name='patternType'>
            <Select
              onChange={handlePatternTypeChange}
              suffixIcon={<SelectRightImg />}
            >
              {patternList.map((p) => (
                <Select.Option value={p.code} key={p.code}>
                  {p.name}
                </Select.Option>
              ))}
              <Select.Option value='custom'>自定义正则</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='正则表达式' name='value'>
            <Input
              disabled={form.getFieldValue('patternType') !== 'custom'}
              title={form.getFieldValue('value')}
            />
          </Form.Item>
        </>
      );
    }
    return null;
  };

  const getInputComp = () => {
    if (needVariable) {
      return (
        <VariableInput
          globalDsConfig={tree.dsConfig}
          extraVariable={{
            params: {
              name: '校验参数',
              code: '(params) =>',
              usage: (
                <>
                  <p>
                    可获取行数据以及字段名，例如：params.record、params.name
                  </p>
                  <p>
                    使用方法示例：(params) ={'>'} params.record.get('fieldName')
                    === 'field1'
                  </p>
                </>
              ),
            },
          }}
        >
          {defaultInput}
        </VariableInput>
      );
    }
    return defaultInput;
  };

  return (
    <>
      <Form
        form={form}
        className='htd-validate-config-panel'
        // onValuesChange={handleValuesChange}
      >
        <Form.Item label='启用' name='isEnable' valuePropName='checked'>
          <Switch />
        </Form.Item>
        {getPatternField()}
        {defaultInput && type !== 'ds_pattern' && (
          <Form.Item label={label} name='value'>
            {getInputComp()}
          </Form.Item>
        )}
        {messageType && (
          <>
            <Form.Item
              label='错误提示'
              name='errorMsg'
              initialValue={messagePlaceholder}
            >
              <Input
                title={messagePlaceholder}
                defaultValue={messagePlaceholder}
              />
            </Form.Item>
            <Form.Item name='messageType' noStyle initialValue={messageType} />
          </>
        )}
      </Form>
    </>
  );
};

export default ValidateConfigPanel;

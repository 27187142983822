import _axios from 'axios';
import { message, notification } from 'antd';
import { getAccessToken, setAccessToken } from './common';
import { API_HOST, DEV_API } from './config';
import { login } from '../services/login';
import qs from 'query-string';
import SockJs from 'sockjs-client';
import { API_HOST_KEY } from './constants';
import { getSession } from './storage';

const axios = _axios.create({
  baseURL: API_HOST,
});

(window as any).getSession = getSession;

axios.interceptors.request.use((config) => {
  config.baseURL = getSession(API_HOST_KEY) || API_HOST;
  Object.assign(config.headers, {
    Authorization: `bearer ${getAccessToken()}`,
  });
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      !(process.env.NODE_ENV === 'production' && !process.env.BUILD_ALPHA) &&
        login();
      message.error('401! 请在 HZERO 页面刷新后重新进入.');
      return Promise.reject(err);
    }
  }
);

export function checkToken() {
  const { access_token } = qs.parse(window.location.hash || '');
  if (access_token) {
    setAccessToken(access_token as string);
    window.history.pushState('', document.title, window.location.pathname);
  }
}

let callRemote: (action: { type: string; payload?: any }) => Promise<any>;
let socketTimer: any;
/**
 * 初始化websocket ———— 只在调试时使用，生产环境应从虹珊瑚中获取
 */
const initShadowCallRemote = () => {
  let sock = new SockJs(`${DEV_API}/coral-ui`, {});
  let opend = false;
  const reConnect = () => {
    if (socketTimer) {
      clearTimeout(socketTimer);
    }
    socketTimer = setTimeout(() => {
      initShadowCallRemote();
    }, 1000);
  };
  sock.onopen = () => {
    // console.log('Sockjs opened.');
    opend = true;
  };
  sock.onclose = () => {
    reConnect();
  };
  sock.onmessage = (e) => {
    const data = JSON.parse(e.data);
    (window as any).__htd__socket__handlers.forEach((h: Function) => h(data));
  };

  Object.assign(window as any, {
    __htd__socket__handlers: [],
    apiCallRemote: (action: any) => {
      return new Promise((resolve, reject) => {
        const { __htd__socket__handlers } = window as any;
        const handler = ({ type, payload }: any) => {
          if (type === `${action.type}/success`) {
            if (!action.keep) removeHandler();
            resolve(payload);
          }
          if (type === `${action.type}/failure`) {
            notification.error({
              message: '啊噢，出现异常了，请保存配置后联系管理员!',
              description: payload.errorMsg,
            });
            if (!action.keep) removeHandler();
            reject(payload);
          }
          if (type === `${action.type}/progress` && action.onProgress) {
            action.onProgress(payload);
          }
        };
        __htd__socket__handlers.push(handler);
        function removeHandler() {
          (window as any).__htd__socket__handlers =
            __htd__socket__handlers.filter((h: Function) => h !== handler);
        }
        const sendMsg = () => {
          const timeout = setTimeout(() => {
            if (!opend) {
              clearTimeout(timeout);
              sendMsg();
            } else {
              sock.send(JSON.stringify(action));
            }
          }, 10);
        };
        sendMsg();
      });
    },
  });
  callRemote = (window as any).apiCallRemote;
};

/**
 * 初始化真正的callRemote，需要在虹珊瑚内传过来
 * @param _callRemote
 */
// export const initCallRemoteToGlobal = (_callRemote: any) => {
//   callRemote = _callRemote;
// };

export { axios, callRemote, initShadowCallRemote };

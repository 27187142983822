import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Input, Popconfirm, Button, Tooltip } from 'antd';
import { ConfigPanelComponentProp } from '@htd/common';
import { ColumnsType } from 'antd/lib/table';
import {
  // DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import EditTable from 'components/EditTable';
import { DesignContext } from 'views/Home/DesignContext';
import MyIcon from 'components/myIcon';
import VariableInput from 'components/VariableInput';

const CascadePanel: React.FC<ConfigPanelComponentProp> = ({ tree, item }) => {
  const { forceUpdate } = useContext(DesignContext);

  // 表格列
  const dataSource: any[] = useMemo(
    () => item?.cascadeData || [],
    [item?.cascadeData]
  );

  const update = useCallback(() => {
    item.cascadeData = cloneDeep(item.cascadeData);
    forceUpdate?.(item?.id);
  }, [forceUpdate, item]);

  /**
   * 修改字段值
   */
  const handleChangeValue = useCallback(
    (fieldName, record, value, index) => {
      // @ts-ignore
      dataSource[index][fieldName] = value;
      update();
    },
    [dataSource, update]
  );

  // 删除行
  const handleDeleteLine = useCallback(
    (record, index) => {
      item.cascadeData.splice(index, 1);
      update();
    },
    [item.cascadeData, update]
  );

  /**
   * 新增字段
   */
  const handleAddLine = useCallback(() => {
    const createData = {
      fileName: '',
      fileValue: '',
    };
    dataSource.push(createData);
    update();
  }, [dataSource, update]);

  const columns: ColumnsType<any> = [
    {
      title: '查询参数',
      dataIndex: 'fileLabel',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='fileLabel'>
            <Input
              onBlur={(e) => {
                handleChangeValue('fileLabel', record, e.target.value, index);
              }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '级联字段名',
      dataIndex: 'fileValue',
      render: (value, record, index) =>
        record.$form ? (
          <Form.Item name='fileValue'>
            {/* <Input
              onBlur={(e) => {
                handleChangeValue('fileValue', record, e.target.value, index);
              }}
            /> */}
            <VariableInput
              globalDsConfig={tree.dsConfig}
              // onChange={(e) => {
              //   if(typeof e === 'object') {
              //     handleChangeValue('fileValue', record, e, index);
              //   } else {
              //     handleChangeValue('fileValue', record, e, index);
              //   }
              // }}
            />
          </Form.Item>
        ) : (
          value
        ),
    },
    {
      title: '操作',
      width: 60,
      align: 'center',
      className: 'htd-aside-right-columns-table-operator',
      render: (_, record, index) => (
        <>
          <Popconfirm
            title='确认删除？'
            okText='确认'
            cancelText='取消'
            onConfirm={handleDeleteLine.bind(this, record, index)}
          >
            {/* <DeleteOutlined /> */}
            <MyIcon type='icon-a-delete2x' className='svg-cla' />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='htd-aside-right-columns-button-wrapper'>
        <Button
          icon={<PlusOutlined />}
          size='middle'
          type='text'
          onClick={handleAddLine.bind(this)}
        >
          添加级联关系
          <Tooltip
            title={
              <div>
                cascadeMap：快码和Lov查询时的级联参数映射，例如：cascadeMap：
                {'{'}parentCodeValue: ‘city’{'}'},
                其中city是当前所在数据源的其他字段名，parentCodeValue是快码和Lov的查询参数。
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://open.hand-china.com/document-center/doc/product/10217/10561?doc_id=157480&doc_code=130503'
                >
                  详情
                </a>
              </div>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Button>
      </div>
      <EditTable
        className='htd-aside-right-columns-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size='small'
        bordered={false}
        showHeader={Boolean(dataSource?.length)}
        rowValueChange={(v, i) => {
          dataSource[i].fileValue = v.fileValue;
        }}
      />
    </>
  );
};

export default CascadePanel;

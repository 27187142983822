/*
 * @Author: your name
 * @Date: 2021-08-31 17:39:22
 * @LastEditTime: 2021-08-31 19:24:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hzero-code-generator\packages\designer\src\mapping\container\Card.ts
 */
// import { ConfigPanelComponentProp, Mapping } from '@htd/common';
import { Mapping } from '@htd/common';
import { Card as ContainerCard } from 'components/placeholders';
import { BASE_PROPS_TYPES } from 'utils/prop-types';

export const Card: Mapping = {
  name: 'Card',
  iconCode: 'Card',
  type: 'container',
  lib: 'choerodon-ui',
  title: '卡片',
  props: {
    ...BASE_PROPS_TYPES,
    title: {
      type: 'string',
      value: '卡片',
      label: '标题',
    },
    bordered: {
      type: 'boolean',
      value: '',
      label: '边框',
    },
    width: {
      type: 'string',
      value: '',
      label: '卡片宽度',
    },
    conditionHidden: {
      type: 'boolean',
      value: false,
      isCondition: true,
      conditionType: 'negative',
      showHelp: true,
      help: '当条件达成时，卡片处于隐藏状态',
      ignore: true,
      valueConfigurable: true,
      label: '隐藏',
    },
  },
  placeholderComponent: ContainerCard,
  children: [],
};

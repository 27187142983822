"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSetFieldProps = exports.DsTransportEnum = void 0;
var DsTransportEnum;
(function (DsTransportEnum) {
    DsTransportEnum["read"] = "\u67E5\u8BE2";
    DsTransportEnum["create"] = "\u65B0\u5EFA";
    DsTransportEnum["update"] = "\u66F4\u65B0";
    DsTransportEnum["destroy"] = "\u5220\u9664";
    DsTransportEnum["submit"] = "\u4FDD\u5B58";
    DsTransportEnum["export"] = "\u5BFC\u51FA";
})(DsTransportEnum = exports.DsTransportEnum || (exports.DsTransportEnum = {}));
/**
 * ds 字段属性
 */
exports.DataSetFieldProps = {
    required: 'required',
    min: 'min',
    max: 'max',
    minLength: 'minLength',
    maxLength: 'maxLength',
    pattern: 'pattern',
    validator: 'validator',
};
